import { Fragment, useState, useEffect, useMemo } from 'react';
import { TabPanel, TabView } from "primereact/tabview";
import './CampaignMonitoringDetails.scss';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import {
    mai_industrylabelrateicon,
    mai_abtestingicon,
    mai_historydatarateicon,
    mai_email_redicon,
    mai_email_greenicon,
    mai_email_blueicon,
    mai_indicator_lightred,
    mai_indicator_blue,
    mai_indicator_orange,
    mai_indicator_lightblue,
    mai_configure_icon,
    mai_download_icon,
    mai_businessuser_chart,
    mai_primaryuser_chart,
    mai_buyersignor_chart,
    mai_uploadImg,
    mai_datarange_icon,
    mai_tick_solidblue_icon
} from '../../../../../../src/assets/images/index';
import ChartComponent from '../../../../../shared/components/chart/ChartComponent';
import { NavLink, useParams } from 'react-router-dom';
import { CampaignGeneralDetail } from '../../../../../models/types';
import useHttp from '../../../../../hooks/use-http';
import CampaignLensService from '../../../../../services/campaignlens.service';
import UtilService from '../../../../../services/util.service';
import data from './campaignmonitoring.json';
import { FusionChartCountriesLabels } from '../../../../../models/constants';

const CampaignMonitoringDetailsComponent = () => {
    const params = useParams();
    const [campaign, setCampaign] = useState<CampaignGeneralDetail>({
        name: "",
        description: "",
        is_private: false,
        audience: "",
        start_date: "",
        end_date: "",
        sucess_criteria: "",
        budget: "",
        exclusion: "",
        manager: "",
        can_be_accessed_by: [],
    });
    const { sendRequest: getCampaign } = useHttp();

    const [segmentsList, setSelectedSegmentsList] = useState<any>(null);
    const [selectedEmail, setSelectedEmail] = useState<string>("all");
    const [selectedSegmenttype, setSelectedSegmenttype] = useState<{ name: string, code: string }>({ name: "Open", code: "open" });
    const [selectedGeographytype, setSelectedGeographytype] = useState<{ name: string, code: string }>({ name: "Open", code: "open" });
    const [date1, setDate1] = useState<Date | Date[] | undefined>(undefined);
    const [dates2, setDates2] = useState<Date | Date[] | undefined>(undefined);
    const [mailTrendLineChartConfig, setmailTrendLineChartConfig] = useState<any>({
        type: "msline",
        renderAt: "chart-container",
        width: "100%",
        height: "20%",
        dataFormat: "json",
        dataSource: {
            chart: {
                caption: "",
                subCaption: "",
                xAxisName: "December 2022",
                yAxisMinValue: "0",
                yAxisMaxValue: "100",
                numberSuffix: "%",
                showLabels: "1",
                showLegend: "0",
                showValues: "0",
                theme: "fusion",
                showXAxisValue: "1"
            },
            categories: [
                {
                    category: [{
                        label: 1
                    },
                    {
                        label: 2
                    },
                    {
                        label: 3
                    },
                    {
                        label: 4
                    },
                    {
                        label: 5
                    },
                    {
                        label: 6
                    },
                    {
                        label: 7
                    },
                    {
                        label: 8
                    },
                    {
                        label: 9
                    },
                    {
                        label: 10
                    },
                    {
                        label: 11
                    },
                    {
                        label: 12
                    },
                    {
                        label: 13
                    },
                    {
                        label: 14
                    },
                    {
                        label: 15
                    },
                    {
                        label: 16
                    },
                    {
                        label: 17
                    },
                    {
                        label: 18
                    },
                    {
                        label: 19
                    },
                    {
                        label: 20
                    },
                    {
                        label: 21
                    },
                    {
                        label: 22
                    },
                    {
                        label: 23
                    },
                    {
                        label: 24
                    },
                    {
                        label: 25
                    },
                    {
                        label: 26
                    },
                    {
                        label: 27
                    },
                    {
                        label: 28
                    },
                    {
                        label: 29
                    },
                    {
                        label: 30
                    }],
                },
            ],
            dataset: [
                {
                    seriesname: "A/B Testing Conversion Rate",
                    data: [{
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    },
                    {
                        value: 10
                    }],
                },
                {
                    seriesname: "Conversion Rate",
                    data: [{
                        value: 15
                    },
                    {
                        value: 13
                    },
                    {
                        value: 10
                    },
                    {
                        value: 20
                    },
                    {
                        value: 13
                    },
                    {
                        value: 17
                    },
                    {
                        value: 19
                    },
                    {
                        value: 11
                    },
                    {
                        value: 14
                    },
                    {
                        value: 15
                    },
                    {
                        value: 17
                    },
                    {
                        value: 10
                    },
                    {
                        value: 17
                    },
                    {
                        value: 16
                    },
                    {
                        value: 10
                    },
                    {
                        value: 14
                    },
                    {
                        value: 12
                    },
                    {
                        value: 10
                    },
                    {
                        value: 12
                    },
                    {
                        value: 15
                    },
                    {
                        value: 15
                    },
                    {
                        value: 15
                    },
                    {
                        value: 20
                    },
                    {
                        value: 16
                    },
                    {
                        value: 12
                    },
                    {
                        value: 12
                    },
                    {
                        value: 15
                    },
                    {
                        value: 19
                    },
                    {
                        value: 11
                    },
                    {
                        value: 20
                    }],
                },
                {
                    seriesname: "Click Rate",
                    data: [{
                        value: 36
                    },
                    {
                        value: 37
                    },
                    {
                        value: 50
                    },
                    {
                        value: 38
                    },
                    {
                        value: 34
                    },
                    {
                        value: 47
                    },
                    {
                        value: 45
                    },
                    {
                        value: 30
                    },
                    {
                        value: 30
                    },
                    {
                        value: 41
                    },
                    {
                        value: 30
                    },
                    {
                        value: 43
                    },
                    {
                        value: 48
                    },
                    {
                        value: 25
                    },
                    {
                        value: 26
                    },
                    {
                        value: 48
                    },
                    {
                        value: 49
                    },
                    {
                        value: 50
                    },
                    {
                        value: 38
                    },
                    {
                        value: 43
                    },
                    {
                        value: 43
                    },
                    {
                        value: 38
                    },
                    {
                        value: 26
                    },
                    {
                        value: 31
                    },
                    {
                        value: 44
                    },
                    {
                        value: 38
                    },
                    {
                        value: 46
                    },
                    {
                        value: 25
                    },
                    {
                        value: 46
                    },
                    {
                        value: 30
                    }],
                },
                {
                    seriesname: "Open Rate",
                    data: [{
                        value: 43
                    },
                    {
                        value: 58
                    },
                    {
                        value: 51
                    },
                    {
                        value: 55
                    },
                    {
                        value: 49
                    },
                    {
                        value: 54
                    },
                    {
                        value: 58
                    },
                    {
                        value: 60
                    },
                    {
                        value: 56
                    },
                    {
                        value: 53
                    },
                    {
                        value: 55
                    },
                    {
                        value: 60
                    },
                    {
                        value: 58
                    },
                    {
                        value: 47
                    },
                    {
                        value: 44
                    },
                    {
                        value: 48
                    },
                    {
                        value: 56
                    },
                    {
                        value: 58
                    },
                    {
                        value: 42
                    },
                    {
                        value: 52
                    },
                    {
                        value: 44
                    },
                    {
                        value: 58
                    },
                    {
                        value: 42
                    },
                    {
                        value: 53
                    },
                    {
                        value: 59
                    },
                    {
                        value: 52
                    },
                    {
                        value: 59
                    },
                    {
                        value: 59
                    },
                    {
                        value: 57
                    },
                    {
                        value: 55
                    }],
                },
            ],
            // trendlines: [
            //   {
            //     line: [
            //       {
            //         startvalue: "17022",
            //         color: "#6baa01",
            //         valueOnRight: "1",
            //         displayvalue: "Average",
            //       },
            //     ],
            //   },
            // ],
        },
    });
    const [mailDonutChartConfig, setmailDonurChartConfig] = useState<any>({
        type: "doughnut2d",
        width: "100%",
        height: "160",
        dataFormat: "json",
        dataSource: {
            chart: {
                pieRadius: "100%",   
                doughnutRadius: "70%",            
                caption: "",
                subCaption: "",
                numberPrefix: "",
                bgColor: "#ffffff",
                startingAngle: "310",
                showLegend: "0",
                defaultCenterLabel: "<b>1941</b><br />Total Mails",               
                centerLabelFontSize: "15",
                showTooltip: "0",
                showLabels: "0",
                showValues: "0",
                decimals: "0",
                theme: "fusion",
                captionPadding: "0",
                chartTopMargin: "0",
                chartBottomMargin: "0",
                enableSlicing: "0"
            },
            data: [
                {
                    label: "open",
                    value: "300",
                },
                {
                    label: "clicked",
                    value: "300",
                },
                {
                    label: "device",
                    value: "300",
                },
            ],
        },
    });
    const [SegmentChartConfig, setSegmentChartConfig] = useState({
        type: "treemap",
        containerBackgroundOpacity: "0",
        width: "100%",
        height: "20%",
        dataFormat: "json",
        dataSource: {
            chart: {
                animation: "0",
                hideTitle: "1",
                plotToolText:
                    "<div><b>$label</b><br/> <b>Count: </b>$value<br/><b>Percentage: </b>$svalue %</div>",
                spacex: "0",
                spacey: "0",
                horizontalPadding: "1",
                verticalPadding: "1",
                hoveronallsiblingleaves: "1",
                plotborderthickness: "0.5",
                plotbordercolor: "666666",
                legendpadding: "0",
                legendItemFontSize: "10",
                legendItemFontBold: "1",
                showLegend: "1",
                legendPointerWidth: "8",
                legenditemfontcolor: "3d5c5c",
                algorithm: "squarified",
                caption: "",
                legendScaleLineThickness: "0",
                legendCaptionFontSize: "10",
                legendaxisbordercolor: "bfbfbf",
                subcaption: "",
                legendCaption: "",
                showBorder: "0",
                showParent: "0",
                showToolTip: "1",
                showChildLabels: "1",
                theme: "fusion",
            },
            data: [
                {
                    label: "",
                    fillcolor: "ffffff",
                    data: [
                        {
                            label: "Segment 1",
                            value: "519",
                            sValue: "1.09",
                            fillcolor: UtilService.returnColorSchema()[0],
                        },
                        {
                            label: "Segment 2",
                            value: "448",
                            sValue: "1.48",
                            fillcolor: UtilService.returnColorSchema()[1],
                        },
                        {
                            label: "Segment 3",
                            value: "416",
                            sValue: "1.13",
                            fillcolor: UtilService.returnColorSchema()[2],
                        },
                        {
                            label: "Segment 4",
                            value: "304",
                            sValue: "1.18",
                            fillcolor: UtilService.returnColorSchema()[3],
                        },
                        {
                            label: "Segment 5",
                            value: "159",
                            sValue: "1.36",
                            fillcolor: UtilService.returnColorSchema()[4],
                        },
                    ],
                },
            ],
            colorrange: {
                mapbypercent: "1",
                gradient: 0,
                minvalue: "-25",
                code: "e24b1a",
                startlabel: "Decline",
                endlabel: "Rise",
                color: [
                    {
                        code: "ffffff",
                        maxvalue: "0",
                        label: "Static",
                    },
                    {
                        code: "6da81e",
                        maxvalue: "25",
                        label: "AVERAGE",
                    },
                ],
            },
        },
    });
    const [WorldmapChartConfig, setWorldmapChartConfig] = useState({
        type: "worldwithcountries",
        width: "100%",
        height: "19%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                caption: "",
                theme: "fusion",
                formatNumberScale: "0",
                numberSuffix: "M",
            },
            data: [
                {
                    id: "27",
                    value: "515",
                },
                {
                    id: "159",
                    value: "373",
                },
                {
                    id: "141",
                    value: "3875",
                },
                {
                    id: "142",
                    value: "727",
                },
                {
                    id: "113",
                    value: "885",
                },
                {
                    id: "193",
                    value: "32",
                },
            ],
        },
    });

    const onCreatedByChange = (e: { value: any }) => {
        setSelectedSegmentsList(e.value);
    }
    const segmentsdropdownlist = [
        { name: 'Open', code: 'open' },
        { name: 'Click', code: 'click' },
        { name: 'Bounce', code: 'bounce' },
        { name: 'Conversion', code: 'conversion' }
    ];
    const emailList: any = {
        "all": "All Email",
        "email1": "Email 1",
        "email2": "Email 2",
        "email3": "Email 3"
    };

    const campaignStatus = useMemo(() => {
        let status: string = "Pre-Campaign";
        const startDate: Date | undefined = new Date(campaign.start_date);
        const endDate: Date | undefined = new Date(campaign.end_date);
        if (startDate && endDate && startDate?.getTime() < new Date().getTime() && endDate?.getTime() > new Date().getTime()) {
            status = "On-Going";
        } else if (endDate && endDate?.getTime() < new Date().getTime()) {
            status = "Post-Campaign";
        }

        return (
            <span className={`status-badge status-${status.toLowerCase()}`}>
                {status}
            </span>
        );
    }, [campaign]);
    useEffect(() => {
        if (params?.cid) {
            const resHandler = (res: any) => {
                setCampaign(res.campaign);
            }
            getCampaign(CampaignLensService.getCampaignGeneraldetail(params?.cid), resHandler)
        }
    }, [getCampaign, params?.cid])

    useEffect(() => {
        let cloneSegmentChartConfig = { ...SegmentChartConfig };
        cloneSegmentChartConfig.dataSource.data[0].data = (data as any)[selectedEmail].segment[selectedSegmenttype?.code ?? "open"]?.map((item: any, index: number) => ({ ...item, fillcolor: UtilService.returnColorSchema()[index] })) as [];
        setSegmentChartConfig(cloneSegmentChartConfig);
    }, [selectedEmail, selectedSegmenttype]);

    useEffect(() => {
        let cloneWorldMapChartConfig = { ...WorldmapChartConfig };
        cloneWorldMapChartConfig.dataSource.data = (data as any)[selectedEmail].geography[selectedGeographytype?.code ?? "open"]?.map((item: any) => ({ ...item, id: FusionChartCountriesLabels[item.id] ?? "104" }));
        setWorldmapChartConfig(cloneWorldMapChartConfig);
        console.log("cloneWorldMapChartConfig", cloneWorldMapChartConfig);
    }, [selectedEmail, selectedGeographytype]);

    useEffect(() => {
        let cloneMailTrendLineChartConfig = { ...mailTrendLineChartConfig };
        cloneMailTrendLineChartConfig.dataSource.dataset = (data as any)[selectedEmail].trendline;
        setmailTrendLineChartConfig(cloneMailTrendLineChartConfig);
    }, [selectedEmail]);

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper audiencedetails campaignmonitoringdetailswrap'>
                <div className="audiencedetails__tabs tabs-common-wrap">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="campaignmonitorintabs-heading">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3>{campaign.name} {campaignStatus}</h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="daterangewrap">
                                            <h4 className='mb-0'>Date Range</h4>
                                            <Calendar id="range" value={dates2} onChange={(e) => setDates2(e.value as Date)} selectionMode="range" placeholder='10 Sep 2022 - 10 Dec 2022 ' readOnlyInput />
                                            <img src={mai_datarange_icon} alt="" className='img-fluid ms-3' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabView>
                        <TabPanel header="Emails">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="box-shadow-wrapper convsratewrapp p-3">
                                        <h3 className="titlefonts18">Conversion rate by channel</h3>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="convsratewrapp__noofmails">
                                                    <ChartComponent chartConfigs={mailDonutChartConfig} />
                                                    {/* <img src={mai_conversionRate} alt="conversion rate" className="img-fluid" />
                                                    <div className="convsratewrapp__noofmails-totalmails">
                                                        <h4>1,941</h4>
                                                        <p>Total Mails</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <h2>26%</h2>
                                                <p>
                                                    Total Conversion Rate
                                                    by Email Channel
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="box-shadow-wrapper campmonitoringsummwrapp p-3">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="campmonitoringsummwrapp__box greenshadebox">
                                                    <div>
                                                        <img src={mai_abtestingicon} alt="ab testing" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <h4>19%</h4>
                                                        <p>
                                                            A/B Testing Conversion Rate
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="campmonitoringsummwrapp__box redshadebox">
                                                    <div>
                                                        <img src={mai_historydatarateicon} alt="historical data" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <h4>21%</h4>
                                                        <p>
                                                            Historical Data
                                                            Conversion Rate
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="campmonitoringsummwrapp__box blueshadebox">
                                                    <div>
                                                        <img src={mai_industrylabelrateicon} alt="industry label" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <h4>25%</h4>
                                                        <p>
                                                            Industry Level
                                                            Conversion Rate
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="campmonitoringsummwrapp-summarydesc">
                                                    <h5 className="mb-2">Summary</h5>
                                                    <p>The campaign was successful as we got a higher conversion rate compared to the benchmark numbers.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="box-shadow-wrapper emailerwrap px-0">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="emailerwrap__heading" data-bs-toggle="modal" data-bs-target="#campaignEmail">
                                                    <div>
                                                        <img src={mai_email_redicon} alt="email" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <p>Email1</p>
                                                        <h3>Introducing product ABC</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3" role="button">
                                            <div className="col-lg-12 col-md-12 col-sm-12" onClick={() => selectedEmail === 'email1' ? setSelectedEmail("all") : setSelectedEmail("email1")}>
                                                <div className="emailerwrap__list">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <h4>650</h4>
                                                            <p>Email Sent</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>65%</h4>
                                                            <p>Opened</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>46%</h4>
                                                            <p>Clicked</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>10%</h4>
                                                            <p>Bounce</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>20%</h4>
                                                            <p>Conversion Rate</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="emailerwrap__hrborder"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="emailerwrap__heading">
                                                    <div>
                                                        <img src={mai_email_greenicon} alt="email" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <p>Email2</p>
                                                        <h3>Standout features of product ABC</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3" role="button">
                                            <div className="col-lg-12 col-md-12 col-sm-12" onClick={() => selectedEmail === 'email2' ? setSelectedEmail("all") : setSelectedEmail("email2")}>
                                                <div className="emailerwrap__list">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <h4>510</h4>
                                                            <p>Email Sent</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>55%</h4>
                                                            <p>Opened</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>36%</h4>
                                                            <p>Clicked</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>9%</h4>
                                                            <p>Bounce</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>15%</h4>
                                                            <p>Conversion Rate</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="emailerwrap__hrborder"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="emailerwrap__heading">
                                                    <div>
                                                        <img src={mai_email_blueicon} alt="email" className="img-fluid" />
                                                    </div>
                                                    <div>
                                                        <p>Email3</p>
                                                        <h3>New Subscriber 20%</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3" role="button">
                                            <div className="col-lg-12 col-md-12 col-sm-12" onClick={() => selectedEmail === 'email3' ? setSelectedEmail("all") : setSelectedEmail("email3")}>
                                                <div className="emailerwrap__list">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <h4>781</h4>
                                                            <p>Email Sent</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>65%</h4>
                                                            <p>Opened</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>30%</h4>
                                                            <p>Clicked</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>11%</h4>
                                                            <p>Bounce</p>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <h4>19%</h4>
                                                            <p>Conversion Rate</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3 d-none">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="selectedemailwrap">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="emailerwrap__heading px-0">
                                                                <div>
                                                                    <img src={mai_tick_solidblue_icon} alt="tick" className="img-fluid" />
                                                                </div>
                                                                <div>
                                                                    <p>Email3</p>
                                                                    <h3>New Subscriber 20%</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="emailerwrap__list px-0">
                                                                <ul className="list-inline">
                                                                    <li className="list-inline-item">
                                                                        <h4>600</h4>
                                                                        <p>Email Sent</p>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <h4>65%</h4>
                                                                        <p>Opened</p>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <h4>46%</h4>
                                                                        <p>Clicked</p>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <h4>9%</h4>
                                                                        <p>Bounce</p>
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <h4>25%</h4>
                                                                        <p>Conversion Rate</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="box-shadow-wrapper contactrolewrap p-3">
                                                <div className="row mb-2">
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <h2 className="inner__heading">
                                                            Contact Roles
                                                        </h2>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="selectlist-wrap justify-content-end p-0">
                                                            <div className='selectlist me-0'>
                                                                <div className='monitoring-dropdownlist'>
                                                                    <Dropdown value={segmentsList} options={segmentsdropdownlist} onChange={onCreatedByChange} optionLabel="name" placeholder="Open" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                                        <div className="dropdown dot3-dropdownwrap rightaligndropdown">
                                                            <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <span className="pi pi-ellipsis-v dots3"></span>
                                                            </NavLink>
                                                            <ul className="dropdown-menu">
                                                                <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                                <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-ms-12">
                                                        <div className='contactrolewrap__desc'>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>21%</h3>
                                                                <p>Buyer/Signor</p>
                                                                <img src={mai_buyersignor_chart} alt="buyer" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>15%</h3>
                                                                <p>Primary User </p>
                                                                <img src={mai_primaryuser_chart} alt="primary user" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>11%</h3>
                                                                <p>Officer/Business User </p>
                                                                <img src={mai_businessuser_chart} alt="businessuser" className='img-fluid' />
                                                            </div>
                                                        </div>
                                                        <div className='contactrolewrap__desc bb-0'>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>20%</h3>
                                                                <p>Buyer/Signor</p>
                                                                <img src={mai_buyersignor_chart} alt="buyer" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>13%</h3>
                                                                <p>Primary User </p>
                                                                <img src={mai_primaryuser_chart} alt="primary user" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>10%</h3>
                                                                <p>Officer/Business User </p>
                                                                <img src={mai_businessuser_chart} alt="businessuser" className='img-fluid' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12 col-ms-12"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="box-shadow-wrapper contactrolewrap p-3">
                                                <div className="row mb-2">
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <h2 className="inner__heading">
                                                            Device Type
                                                        </h2>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="selectlist-wrap justify-content-end p-0">
                                                            <div className='selectlist me-0'>
                                                                <div className='monitoring-dropdownlist'>
                                                                    <Dropdown value={segmentsList} options={segmentsdropdownlist} onChange={onCreatedByChange} optionLabel="name" placeholder="Open" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                                        <div className="dropdown dot3-dropdownwrap rightaligndropdown">
                                                            <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <span className="pi pi-ellipsis-v dots3"></span>
                                                            </NavLink>
                                                            <ul className="dropdown-menu">
                                                                <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                                <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className='contactrolewrap__desc bb-0'>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>26%</h3>
                                                                <p>Buyer/Signor</p>
                                                                <img src={mai_buyersignor_chart} alt="buyer" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>18%</h3>
                                                                <p>Primary User </p>
                                                                <img src={mai_primaryuser_chart} alt="primary user" className='img-fluid' />
                                                            </div>
                                                            <div className='contactrolewrap__desctext'>
                                                                <h3>14%</h3>
                                                                <p>Officer/Business User </p>
                                                                <img src={mai_businessuser_chart} alt="businessuser" className='img-fluid' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="box-shadow-wrapper trendswrapper p-3">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <h2 className="inner__heading">
                                                    Trends
                                                    <span className="ms-2">{emailList[selectedEmail]}</span>
                                                </h2>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-12 text-end">
                                                <div className="indicatorlist">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item"> <img src={mai_indicator_lightblue} alt="open rate" className="img-fluid" /> Open Rate</li>
                                                        <li className="list-inline-item"><img src={mai_indicator_orange} alt="click rate" className="img-fluid" /> Click Rate</li>
                                                        <li className="list-inline-item"><img src={mai_indicator_blue} alt="conversion rate" className="img-fluid" /> Conversion Rate</li>
                                                        <li className="list-inline-item"><img src={mai_indicator_lightred} alt="AB testing" className="img-fluid" /> A/B Testing Conversion Rate</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                                <div className="dropdown dot3-dropdownwrap">
                                                    <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span className="pi pi-ellipsis-v dots3"></span>
                                                    </NavLink>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center px-5">
                                                {/* <img src={mai_trends_chart} alt="" className="img-fluid" /> */}
                                                <ChartComponent chartConfigs={mailTrendLineChartConfig} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="box-shadow-wrapper segmentswrapper p-3">
                                        <div className="row mb-3">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <h2 className="inner__heading">
                                                    Segments
                                                    <span className="ms-2">{emailList[selectedEmail]}</span>
                                                </h2>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="selectlist-wrap justify-content-end p-0">
                                                    <div className='selectlist me-0'>
                                                        <div className='monitoring-dropdownlist'>
                                                            <Dropdown value={selectedSegmenttype} options={segmentsdropdownlist} onChange={(e: any) => setSelectedSegmenttype(e.value)} optionLabel="name" placeholder="Open" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                                <div className="dropdown dot3-dropdownwrap rightaligndropdown">
                                                    <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span className="pi pi-ellipsis-v dots3"></span>
                                                    </NavLink>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                {/* <img src={mai_campaign_segments_chart} alt="segments" className="img-fluid" /> */}
                                                <ChartComponent chartConfigs={SegmentChartConfig} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="box-shadow-wrapper segmentswrapper p-3">
                                        <div className="row mb-3">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <h2 className="inner__heading">
                                                    Geography
                                                    <span className="ms-2">{emailList[selectedEmail]}</span>
                                                </h2>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="selectlist-wrap justify-content-end p-0">
                                                    <div className='selectlist me-0'>
                                                        <div className='monitoring-dropdownlist'>
                                                            <Dropdown value={selectedGeographytype} options={segmentsdropdownlist} onChange={(event: any) => setSelectedGeographytype(event.value)} optionLabel="name" placeholder="Open" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                                <div className="dropdown dot3-dropdownwrap rightaligndropdown">
                                                    <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span className="pi pi-ellipsis-v dots3"></span>
                                                    </NavLink>
                                                    <ul className="dropdown-menu">
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                        <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                {/* <img src={mai_campaign_geography_chart} alt="geography" className="img-fluid" /> */}
                                                <ChartComponent chartConfigs={WorldmapChartConfig} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>
                        <TabPanel header="Social Media">
                            Social Media Pannel
                        </TabPanel>
                        <TabPanel header="Direct Mail">
                            Direct Mail Pannel
                        </TabPanel>
                        <TabPanel header="Tele">
                            Tele Pannel
                        </TabPanel>
                    </TabView>
                </div>
            </div>


            {/* Campaign monitoring Email Popup */}
            <div className="modal fade filter-popup campaignEmailPopup" id="campaignEmail" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Email 1</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="audiencedetails__tabs tabswrap">
                                <TabView>
                                    <TabPanel header="Step1">
                                        <div className="row emailpopupform">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                                    <label htmlFor="countries" className="form-label">Send on</label>
                                                    <Calendar id="basic" value={date1} onChange={(e) => setDate1(e.value as Date)} readOnlyInput />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                                    <label htmlFor="countries" className="form-label">Enter subject</label>
                                                    <input type="text" className="form-control mai-input" placeholder='Enter subject' />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <label htmlFor="countries" className="form-label">Add headline</label>
                                                    <input type="text" className="form-control mai-input" placeholder='Add headline' />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label htmlFor="countries" className="form-label">Add URL</label>
                                                    <input type="text" className="form-control mai-input" placeholder='Add URL' />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Step2">
                                        <div className="row emailpopupform">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                                    <label htmlFor="countries" className="form-label">Content</label>
                                                    <textarea className="form-control" placeholder='Enter content'></textarea>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <label htmlFor="countries" className="form-label">Insert picture</label>
                                                    {/* <input type="file" className="form-control mai-input" placeholder='Add headline' /> */}
                                                    <div>
                                                        <img src={mai_uploadImg} alt="" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mai-next-button">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default CampaignMonitoringDetailsComponent;