import React, { Fragment, useState, useCallback, useRef } from "react";
import { ProgressBar } from 'primereact/progressbar';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import './AudienceDashboard.scss';
import {
    mai_revenue_icon,
    mai_pipeline_icon,
    mai_customers_icon,
    mai_export_icon,
    mai_share_icon,
    mai_back_icon,
    mai_pipeline_chart,
    mai_arrow_down_blue,
    mai_question_icon,
    mai_plus_gray_circle,
    mai_email_blue_icon,
    mai_ppt_icon,
    mai_jpg_icon,
    mai_pdf_icon,    
    mai_time_range,
    mai_configure_icon,
    mai_download_icon,
    mai_avg_cltv,
    mai_churnrate_chart,
    mai_csat_chart,
    mai_total_revenue,
    mai_total_customers_chart,
    mai_csat_icon,
    mai_avgcltv_icon,
    mai_web_engagement_icon,
    mai_brandequity_chart,
    mai_brand_equity_icon,
    mai_geography_icon,
    mai_filter_icon,
    mai_geographychart_big
} from '../../../../../assets/images/index';
import { NavLink } from "react-router-dom";
import KpiComponent from "../../../../workflow/customerlens/audiencebuilder/details/insights/kpi/KpiComponent";

const AudienceDashboardComponent = () => {

    const kpiItems = [
        {
            title: 'Waterfall',
            amount: '680,000',
            percent: '34%',
            items: [
                {
                    title: 'Existing',
                    amount: '452,522',
                    percent: '67%'
                },
                {
                    title: 'Churned',
                    amount: '245,522',
                    percent: '17%'
                },
                {
                    title: 'New',
                    amount: '23,522',
                    percent: '8%'
                },

            ]
        },
        {
            title: 'Revenue',
            amount: '$563K',
            percent: '56%',
            items: [
                {
                    title: 'Existing',
                    amount: '$200K',
                    percent: '67%',
                    growth: '7%'
                },
                {
                    title: 'Churned',
                    amount: '$100K',
                    percent: '17%'
                },
                {
                    title: 'New',
                    amount: '$75K',
                    percent: '8%'
                },

            ]
        },
        {
            title: 'Email Engagement',
            items: [
                {
                    title: 'Email Open rate',
                    amount: '20%',
                    increment: '7%'
                },
                {
                    title: 'Email CTR',
                    amount: '9%',
                    increment: '3%'
                },
                {
                    title: 'Conversion rate',
                    amount: '25%',
                    increment: '2%'
                },

            ]
        },
        {
            title: 'Product Engagement',
            items: [
                {
                    title: 'Avg. order group',
                    amount: '45 days'
                },
                {
                    title: 'Avg. order value',
                    amount: '$500K',
                    increment: '1.5%'
                },
                {
                    title: 'Avg. order frequency',
                    amount: '2',
                    decrement: '4%'
                },

            ]
        }
    ]

    const [kpiData, setKpiData] = useState(kpiItems);
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();

    const dragStart = (e: any, position?: number) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };

    const dragEnter = (e: any, position?: number) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };

    const drop = (e: any) => {
        const copyListItems = [...kpiData];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setKpiData(copyListItems);
    };

    const [selectedRow, setSelectedRow] = useState(null);
    const data = [
        { "Customer Name": "KB Corp", "TotalRevenue": "$53k", "TotalRevenue1": "$64k", "AbsoluteChange": "$11k", "AbsoluteChange1": "+11%", "top3products": "Product1 Product2 Product3" },
        { "Customer Name": "TG Corp", "TotalRevenue": "$58k", "TotalRevenue1": "$70k", "AbsoluteChange": "$12k", "AbsoluteChange1": "+12%", "top3products": "Product1 Product2 Product3" },
        { "Customer Name": "Wells Co", "TotalRevenue": "$57k", "TotalRevenue1": "$65k", "AbsoluteChange": "$8k", "AbsoluteChange1": "+8%", "top3products": "Product1 Product2 Product3" },
        { "Customer Name": "James Corp", "TotalRevenue": "$51k", "TotalRevenue1": "$59k", "AbsoluteChange": "$8k", "AbsoluteChange1": "+8%", "top3products": "Product1 Product2 Product3" }
    ];

    const CustomerData = [
        { "customers": "Jack Wills Corp", "Annualsales": "$53k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "M&& Group", "Annualsales": "$58k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "Moonlight LLP", "Annualsales": "$57k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "RedSqaure", "Annualsales": "$51k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },

        { "customers": "UniWays", "Annualsales": "$53k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "FHG Group", "Annualsales": "$58k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "Tag Global", "Annualsales": "$57k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "Silverline LLP", "Annualsales": "$51k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" },
        { "customers": "Hough’s Corp", "Annualsales": "$51k", "industry": "Other Services (except Public Administration)", "industrysegment": "5b: Risk Averse Cost Cutters" }
    ];

    const onSelectionHandler = useCallback((event: any) => {
        setSelectedRow(event.value);
        console.log(event.value);
    }, []);

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper audiencedetails px-0'>
                <div className='audiencedetails__title'>
                    <NavLink to="#"> <img src={mai_back_icon} alt="back" className="img-fluid" /></NavLink>
                    <span>North-east Region</span>
                    <NavLink to="#"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink>
                </div>
                <div className="audiencedetails__tabs">
                    <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="insights" role="tabpanel" aria-labelledby="insights-tab">
                            <div className="audiencedetails__insight-topheading">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="audiencedetails_calendar">
                                            <label htmlFor="minmax">Time</label>
                                            <input type="text" placeholder="Feb 2019 - Jan 2022" data-bs-toggle="modal" data-bs-target="#calendarpopup" readOnly />
                                            <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" />
                                        </div>
                                        <div className="audiencedetails_region">
                                            <div className='audiencelist__select'>
                                                <label htmlFor="createdby">Region</label>
                                                <select className="form-select" id="createdby" defaultValue={'All'}>
                                                    <option>All</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                                <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="heading-button d-flex justify-content-end">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={mai_export_icon} alt="export" className="img-fluid" /> Export
                                                </a>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#"><img src={mai_pdf_icon} alt="pdf" className="img-fluid" /> Export as .pdf</a></li>
                                                    <li><a className="dropdown-item" href="#"><img src={mai_jpg_icon} alt="jpeg" className="img-fluid" /> Export as .jpeg</a></li>
                                                    <li><a className="dropdown-item" href="#"><img src={mai_ppt_icon} alt="ppt" className="img-fluid" /> Export as .pptx</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="insights__datawrapper">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="customer__segment-chart boxshadow d-block p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_customers_icon} alt="users" className='img-fluid' /> <span> Customers</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <div className="dropdown industries-dropdown">
                                                        <NavLink className="" to="#">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-evenly mt-3">
                                                <div className="customer_chart">
                                                    <img src={mai_total_customers_chart} alt="customer-chart" className="img-fluid w-100" />
                                                </div>
                                                <div>
                                                    <ul className="customer-segments-list audience-dashboard-list list-inline">
                                                        <li>
                                                            <div>
                                                                <i className="pi pi-circle-fill color1"></i>
                                                            </div>
                                                            <div>
                                                                <p><strong>945</strong> <span>57%</span> </p>
                                                                <span className="light-text">Existing</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="pi pi-circle-fill color2"></i>
                                                            </div>
                                                            <div>
                                                                <p><strong>245</strong> <span>27%</span> </p>
                                                                <span className="light-text">Churned </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                <i className="pi pi-circle-fill color3"></i>
                                                            </div>
                                                            <div>
                                                                <p><strong>135</strong> <span>16%</span> </p>
                                                                <span className="light-text">New</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="customer__segment-chart boxshadow d-block p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_revenue_icon} alt="users" className='img-fluid' /> <span> Revenue</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <p className="increaserate d-inline me-2">
                                                        $1.23M <span>+23%</span>
                                                    </p>
                                                    <div className="dropdown industries-dropdown d-inline">
                                                        <NavLink className="" to="#">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="customer_chart">
                                                <img src={mai_total_revenue} alt="revenue" className="img-fluid w-100" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="customer__segment-chart boxshadow d-block p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_csat_icon} alt="users" className='img-fluid' /> <span> CSAT</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <p className="increaserate d-inline me-2">
                                                        75 <span>+7%</span>
                                                    </p>
                                                    <div className="dropdown industries-dropdown d-inline">
                                                        <NavLink className="" to="#">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="customer_chart">
                                                <img src={mai_csat_chart} alt="csat" className="img-fluid w-100" />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="customer__segment-chart boxshadow d-block p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_revenue_icon} alt="users" className='img-fluid' /> <span> Churn Rate</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <p className="increaserate d-inline me-2">
                                                        6% <span>-30%</span>
                                                    </p>
                                                    <div className="dropdown industries-dropdown d-inline">
                                                        <NavLink className="" to="#">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="customer_chart mt-3">
                                                <img src={mai_churnrate_chart} alt="churn" className="img-fluid w-100" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="insights__innerwrapper dropdownmain-wrapper">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_revenue_icon} alt="users" className='img-fluid' /> <span> MROI</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                    <div className="dropdown industries-dropdown dropdownmenu-wrapper">
                                                        <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                                            <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-end">
                                                    <p className="data-title">Tele</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="progressbar mt-2">
                                                        <ProgressBar value={40}></ProgressBar>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-start">
                                                    <h3 className="data-per">$103K <span>34%</span></h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-end">
                                                    <p className="data-title">Email</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="progressbar mt-2">
                                                        <ProgressBar value={40}></ProgressBar>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-start">
                                                    <h3 className="data-per">$65K <span>4%</span></h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-end">
                                                    <p className="data-title">Direct Mail</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="progressbar mt-2">
                                                        <ProgressBar value={40}></ProgressBar>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-start">
                                                    <h3 className="data-per">$344K <span>51%</span></h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-end">
                                                    <p className="data-title">Web</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="progressbar mt-2">
                                                        <ProgressBar value={40}></ProgressBar>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-12 text-start">
                                                    <h3 className="data-per">$23k <span>26%</span></h3>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="customer__segment-chart boxshadow d-block p-3">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_avgcltv_icon} alt="cltv" className='img-fluid' /> <span> Avg. CLTV</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <p className="increaserate d-inline me-2">
                                                        $190 <span>+11%</span>
                                                    </p>
                                                    <div className="dropdown industries-dropdown d-inline">
                                                        <NavLink className="" to="#">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="customer_chart">
                                                <img src={mai_avg_cltv} alt="clv" className="img-fluid w-100" />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="insights__innerwrapper">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_pipeline_icon} alt="pipeline" className="img-fluid" /> <span> Pipeline </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 my-1">
                                                    <img src={mai_pipeline_chart} alt="pipeline" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <ul className="list-inline insights-data insights-pipeline">
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>200</h2>
                                                                <p>Leads</p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>65</h2>
                                                                <p>Qualified </p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>42</h2>
                                                                <p>Opportunities</p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>20</h2>
                                                                <p>Converted</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="insights__innerwrapper dropdownmain-wrapper brandequity-wrapper">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_web_engagement_icon} alt="web" className="img-fluid" /> <span> Web Engagement</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                    <div className="dropdown industries-dropdown dropdownmenu-wrapper">
                                                        <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                                            <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <ul className="list-inline insights-data">
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>1.2 M</h2>
                                                                <p>Visitors in 2019</p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>1.7 M</h2>
                                                                <p>Visitors in 2022 </p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2 className="justify-content-between">
                                                                    <div className="positive">                                                                        
                                                                        <p className="positive-percent">+40%</p>
                                                                    </div>                                                                    
                                                                </h2>
                                                                <p>Change</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="insights__innerwrapper dropdownmain-wrapper-end">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_email_blue_icon} alt="email" className="img-fluid" /> <span> Email Engagement</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                    <div className="dropdown industries-dropdown dropdownmenu-wrapper">
                                                        <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                                            <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                <ul className="list-inline insights-data">
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>20% <span className="increase_horizontal">+7%</span></h2>
                                                                <p>Email Open Rate</p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>9% <span className="increase_horizontal">+3%</span></h2>
                                                                <p>Email CTR </p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>25% <span className="increase_horizontal">+2%</span></h2>
                                                                <p>Conversion Rate</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row mt-4">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="insights__innerwrapper brandequity-wrapper dropdownmain-wrapper">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_brand_equity_icon} alt="brand equity" className="img-fluid" /> <span> Brand Equity</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                    <div className="dropdown industries-dropdown dropdownmenu-wrapper">
                                                        <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className="pi pi-ellipsis-v dots3"></span>
                                                        </a>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                                            <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <ul className="list-inline insights-data">
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>5,533</h2>
                                                                <p>Mentions</p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2>10,252</h2>
                                                                <p>Engagement </p>
                                                            </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <div className='innertext__size'>
                                                                <h2 className="justify-content-between">
                                                                    <div className="positive">
                                                                        <span>Positive</span>
                                                                        <p className="positive-percent">30%</p>
                                                                    </div>
                                                                    <div className="negative">
                                                                        <span>Negative</span>
                                                                        <p className="negative-percent">2%</p>
                                                                    </div>
                                                                </h2>
                                                                <p>Sentiment</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-12 co-md-12 col-sm-12 mt-5">
                                                    <p className="chart-title">Mentions over time</p>
                                                </div>
                                                <div className="col-lg-12 co-md-12 col-sm-12">
                                                    <img src={mai_brandequity_chart} alt="brand equity" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="insights__innerwrapper">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_geography_icon} alt="geography" className="img-fluid" /> <span> Geography </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="tabs-wrapper">
                                                        <TabView>
                                                            <TabPanel header="Customers">
                                                                <img src={mai_geography_map} alt="map" className="img-fluid map-height" />
                                                            </TabPanel>
                                                            <TabPanel header="Revenue">
                                                                <img src={mai_geography_map} alt="map" className="img-fluid map-height" />
                                                            </TabPanel>
                                                        </TabView>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        <div className="insights__innerwrapper geography-countriesbox customtabs-wrap">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                    <h2 className='inner__heading'><img src={mai_geography_icon} alt="geography" className="img-fluid" /> <span> Geography </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                                    <div className="filter"><img src={mai_filter_icon} alt="filter" className="img-fluid" data-bs-toggle="modal" data-bs-target="#filterpopup" /></div>
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                    <div className="tabs-wrapper customtabs">
                                                        <TabView>
                                                            <TabPanel header="Customers">
                                                                <img src={mai_geographychart_big} alt="map" className="img-fluid" />
                                                            </TabPanel>
                                                            <TabPanel header="Revenue">
                                                                <img src={mai_geographychart_big} alt="map" className="img-fluid" />
                                                            </TabPanel>
                                                        </TabView>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <div className="addkpi" data-bs-toggle="modal" data-bs-target="#addkpi">
                                            <img src={mai_plus_gray_circle} alt="plus" className="img-fluid" />
                                            <p>Add KPI</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add KPI Modal pop up  */}
            <div className="modal fade audiencedetails__addkpimodal" id="addkpi" aria-labelledby="addkpiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Add KPI</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">                            
                                                   
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked1">
                                                Customers
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked2">
                                                Product Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked3" />
                                            <label className="form-check-label" htmlFor="checked3">
                                                Attribution
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked4" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked4">
                                                Revenues
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked5">
                                                Email Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked6" />
                                            <label className="form-check-label" htmlFor="checked6">
                                                CLTV
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked7" />
                                            <label className="form-check-label" htmlFor="checked7">
                                                P.o.P %
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked8" />
                                            <label className="form-check-label" htmlFor="checked8">
                                                Churn Rate
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked9" />
                                            <label className="form-check-label" htmlFor="checked9">
                                                CSAT
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked10" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked10">
                                                Pipeline
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked11" />
                                            <label className="form-check-label" htmlFor="checked11">
                                                MRoI
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">

                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Calendra Popup */}
            <div className="modal fade calendar-popup" id="calendarpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Time</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <label htmlFor="from">From</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="from" placeholder="2020" />
                                        </div>
                                        <div className="col-auto pe-0">
                                            <label htmlFor="to">To</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="to" placeholder="2022" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="selected-period">
                                        <p>Selected period <span>Feb 2020- Jan 2021</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <img src={mai_time_range} alt="time range" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Reset</button>
                            <button type="button" className="btn btn-primary mai-next-button">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filter Popup */}
            <div className="modal fade filter-popup" id="filterpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Filter</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="countries" className="form-label">Country</label>
                                    <select className="form-select" id="countries" name="Country">
                                        <option selected>USA</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <label htmlFor="region" className="form-label">Region</label>
                                    <select className="form-select" id="region" name="Region">
                                        <option selected disabled>Select Rgion</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <select className="form-select" id="state" name="State">
                                        <option selected disabled>Select State</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Reset</button>
                            <button type="button" className="btn btn-primary mai-next-button">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AudienceDashboardComponent;