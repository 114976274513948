import React from 'react'
import { mai_question_icon, mai_revenue_icon } from '../../../../../../../assets/images'
import UtilService from '../../../../../../../services/util.service';
import Loader from '../../../../../../../shared/components/loader/Loader';
import './KpiComponent.scss';

const KpiRevenueComponent = (props: any) => {
    return (
        <>
            <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                <Loader isLoading={Object.keys(props.item).length === 0 || props.loading}>
                    <div className="insights__innerwrapper">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h2 className='inner__heading'>
                                    <img src={mai_revenue_icon} alt="revenue" className="img-fluid" />
                                    <span className='kpititle'> {props?.item?.title}</span>
                                    {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                                </h2>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className='innertext__size d-flex justify-content-end'>
                                    <h2>${props?.item?.count && UtilService.numberFormatter(props?.item?.count)}<sub>{props?.item?.perc && Math.round(props?.item?.perc?.toFixed(0))}%</sub></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <ul className="list-inline insights-data">
                                    {
                                        props?.item?.profile?.map((profile: any) => {
                                            return (
                                                <li className="list-inline-item" key={`rev${profile?.label}`}>
                                                    <div className='innertext__size'>
                                                        {/* {value.growth !== undefined && <span className="increase">{value?.growth}</span>}
                                                    {value.regression !== undefined && <span className="decrease">{value?.regression}</span>} */}
                                                        <h2>
                                                            ${UtilService.numberFormatter(profile?.value)}

                                                            {/* {value.increment !== undefined && <span className="increase_horizontal">+{value.increment}</span>}
                                                        {value.decrement !== undefined && <span className="decrease_horizontal">+{value.decrement}</span>} */}
                                                        </h2>
                                                        <p>{profile?.label}</p>
                                                        <h2 className='mt-2'>
                                                            <sub>{profile.value !== 0 ? (UtilService.percentageCalculator(profile?.value, props?.item?.count)).toFixed(0) : 0}%</sub>
                                                        </h2>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        </>
    )
}

export default React.memo(KpiRevenueComponent);