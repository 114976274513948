const removeUnderscoreAsSpace = (str: string): any => {
  if (typeof str === "string" && str) {
    return str.replace(/_/g, " ");
  } else {
    return str;
  }
};
const getNumberasCommasepareted = (num: any): any => {
  if (typeof num === "string" && num) {
    return num;
  } else {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); //Number(parseFloat(num)).toLocaleString('en', { });
  }
};
const percentageCalculator = (a: number, b: number) => {
  return (a && b ? (a / b) * 100 : 0);
}
const returnColorSchema = (dark = false): any => {
  return dark
    ? [
      // "#FBBF24",
      // "#776ea9",
      // "#3265aa",
      // "#F9791C",
      // "#35B47E",
      // "#06B6D4",
      // "#7C2D12",
      // "#3B82F6",
      // "#EC4A44",
      // "#022F9E",


      "#EBA980",
      "#958EB7",
      "#EAA0BA",
      "#AC679E",
      "#F4D778",
      "#D6A7B6",
      "#77BFE2",
      "#EF9655",
      "#EBC0A7",
      "#6488B8",
    ]
    : [
      "#c8e6c9",
      "#feedaf",
      "#affefa",
      "#87d6b4",
      "#8592de",
      "#f3b562",
      "#5AB7E8",
      "#1C9A4E",
      "#989598",
      "#315EA5",
      "#9D2C7D",
      "#61BA93",
      "#7C6CAA",
      "#AF5E90",
      "#47254B",
      "#F38AB1",
      "#AFA2BF",
      "#B2D9F4",
      "#939598",
      "#839EC9",
      "#BEBFC1",
      "#917C93",
    ];
};
const classes = (): string[] => {
  return ['avatar1', 'avatar2', 'avatar3', 'avatar4', 'avatar5', 'avatar6', 'avatar7', 'avatar8', 'avatar9', 'avatar0'];
};
const getShortName = (fullName: string): string => {
  return fullName
    .split(" ")
    .map((n) => n[0])
    .join("");
};
const capitalizeTheFirstLetterOfEachWord = (words: string): any => {
  if (typeof words === "string" && words) {
    const separateWord = words.toLowerCase().split(" ");
    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  } else {
    return words;
  }
};
const numberFormatter = (value: number, units: any = null) => {
  let num = value >= 0 ? value : -1 * value;
  let returnNum;
  if (units) {
    if (units === 'K') {
      returnNum = (num / 1000).toLocaleString('en-us', { maximumFractionDigits: 2 }) + "K";
    } else if (units === 'M') {
      returnNum = (num / 1000000).toLocaleString('en-us', { maximumFractionDigits: 2 }) + "M";
    }
  } else {
    if (num > 999 && num < 1000000) {
      returnNum = (num / 1000).toLocaleString('en-us', { maximumFractionDigits: 2 }) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      returnNum = (num / 1000000).toLocaleString('en-us', { maximumFractionDigits: 2 }) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      returnNum = num.toLocaleString('en-us', { maximumFractionDigits: 2 }); // if value < 1000, nothing to do
    }
  }
  return value >= 0 ? returnNum : `-${returnNum}`;
};

const sortArrayBasedOnValues = (targetArray: Record<string, never>[], sortByArray: (string | number)[], key: string) => {
  return targetArray.sort((a: Record<string, never>, b: Record<string, never>) => {
    if (sortByArray.includes(a[key]) && !sortByArray.includes(b[key])) {
      return -1
    } else if (!sortByArray.includes(a[key]) && sortByArray.includes(b[key])) {
      return 1;
    } else {
      return 0;
    }

  })
};


const UtilService = {
  getShortName,
  removeUnderscoreAsSpace,
  getNumberasCommasepareted,
  capitalizeTheFirstLetterOfEachWord,
  numberFormatter,
  returnColorSchema,
  percentageCalculator,
  classes,
  sortArrayBasedOnValues
};

export default UtilService;
