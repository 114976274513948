import React, { Fragment,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    mai_plus,
    mai_arrow_right,
    mai_recomendations,
    mai_customer_lifetime,
    mai_segmentation,
    mai_audience_builder,
    mai_video_dummy
} from "../../../assets/images";
import { Url } from "../../../models/constants";
import { StoreActions } from "../../../store/actions";
import { StoreState } from "../../../store/store";
import './CustomerLensHome.scss';
const CustomerLensHomeComponent = () => {
    const userDetails = useSelector<StoreState, any>(state => state.currentUser);
    const dispatch = useDispatch();
    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
        const payload = { title,backUrl,share };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
    useEffect(()=>{
    headerTitleChangeHandler("Home",'','');   
    })
    return (
        <Fragment>
            <section className="mai-home__body">
                <div className="home__title row">
                    <div className="home__name col-lg-6 col-md-6 col-sm-12">
                        <h1>Welcome, {userDetails.name}</h1>
                    </div>
                    <div className="home__create col-lg-6 col-md-6 col-sm-12 text-end">
                        {/* <NavLink to="" className="welcome__create-anchor"><img src={mai_plus} alt="plus-icon" className="img-fluid welcome__plus-icon" /> Create</NavLink> */}
                        <div className="dropdown">
                            <NavLink to="" className="home__create-anchor" data-bs-toggle="dropdown" aria-expanded="false"><img src={mai_plus} alt="plus-icon" className="img-fluid home__plus-icon" /> Create</NavLink>
                            <ul className="dropdown-menu home__create-dropdown">
                                <li><NavLink onClick={() => headerTitleChangeHandler('Create Audience','','')} to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}`} className="dropdown-item home__create-dropdownitem"><i className="pi pi-plus home__plus"></i> Audience</NavLink></li>
                                <li><NavLink to="#" className="dropdown-item home__create-dropdownitem" ><i className="pi pi-plus home__plus"></i> Segmentation</NavLink></li>
                                <li><NavLink to="#" className="dropdown-item home__create-dropdownitem" ><i className="pi pi-plus home__plus"></i> Customer Lifetime Value</NavLink></li>
                                <li><NavLink to="#" className="dropdown-item home__create-dropdownitem" ><i className="pi pi-plus home__plus"></i> Recommendations</NavLink></li>
                                <li><NavLink to="#" className="dropdown-item home__create-dropdownitem" ><i className="pi pi-plus home__plus"></i> Executive Dashboard</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="home__modules row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="home__modules-heading">Modules</h2>
                    </div>
                </div>

                <div className="home__module-inner row">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__module-cols">
                            <div className="home__modules-icons">
                                <img src={mai_audience_builder} alt="audience_builder" className="img-fluid" />
                            </div>
                            <div>
                                <h3 className="home__modules-title">Audience Builder</h3>
                            </div>
                            <div>
                                <p className="home__modules-desc">
                                    Creates targeted audiences  based on stored attributes
                                </p>
                            </div>
                            <div className="home__modules-knowmore">
                                <NavLink to="">Learn more <img src={mai_arrow_right} alt="arrow right" className="img-fluid" /></NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__module-cols">
                            <div className="home__modules-icons">
                                <img src={mai_segmentation} alt="segmentation" className="img-fluid" />
                            </div>
                            <div>
                                <h3 className="home__modules-title">Segmentation</h3>
                            </div>
                            <div>
                                <p className="home__modules-desc">
                                    Calculates total worth to a business by a customer
                                </p>
                            </div>
                            <div className="home__modules-knowmore">
                                <NavLink to="">Learn more <img src={mai_arrow_right} alt="arrow right" className="img-fluid" /></NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__module-cols">
                            <div className="home__modules-icons">
                                <img src={mai_customer_lifetime} alt="customer lifetime" className="img-fluid" />
                            </div>
                            <div>
                                <h3 className="home__modules-title">Customer Lifetime</h3>
                            </div>
                            <div>
                                <p className="home__modules-desc">
                                    Calculates total worth to a business by a customer
                                </p>
                            </div>
                            <div className="home__modules-knowmore">
                                <NavLink to="">Learn more <img src={mai_arrow_right} alt="arrow right" className="img-fluid" /></NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__module-cols">
                            <div className="home__modules-icons">
                                <img src={mai_recomendations} alt="recomendations" className="img-fluid" />
                            </div>
                            <div>
                                <h3 className="home__modules-title">Recommendations</h3>
                            </div>
                            <div>
                                <p className="home__modules-desc">
                                    Analyses revenue and opportunities matrices,
                                </p>
                            </div>
                            <div className="home__modules-knowmore">
                                <NavLink to="">Learn more <img src={mai_arrow_right} alt="arrow right" className="img-fluid" /></NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home__howitworks row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="home__modules-heading">How it works</h2>
                    </div>
                </div>

                <div className="home__howitworks-inner row">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__howitworks-cols">
                            <div className="home__howitworks-img">
                                <img src={mai_video_dummy} alt="dummy video" className="img-fluid" />
                            </div>
                            <div>
                                <h4 className="home__howitworks-title">What are the efficient attributes?</h4>
                            </div>
                            <div>
                                <p className="home__howitworks-desc">
                                    Audience
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__howitworks-cols">
                            <div className="home__howitworks-img">
                                <img src={mai_video_dummy} alt="dummy video" className="img-fluid" />
                            </div>
                            <div>
                                <h4 className="home__howitworks-title">How to create optimum segments? </h4>
                            </div>
                            <div>
                                <p className="home__howitworks-desc">
                                    Segmentation
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__howitworks-cols">
                            <div className="home__howitworks-img">
                                <img src={mai_video_dummy} alt="dummy video" className="img-fluid" />
                            </div>
                            <div>
                                <h4 className="home__howitworks-title">Advantages of predictive CLV.</h4>
                            </div>
                            <div>
                                <p className="home__howitworks-desc">
                                    Customer Lifetime
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="home__howitworks-cols">
                            <div className="home__howitworks-img">
                                <img src={mai_video_dummy} alt="dummy video" className="img-fluid" />
                            </div>
                            <div>
                                <h4 className="home__howitworks-title">Create product recommendations.</h4>
                            </div>
                            <div>
                                <p className="home__howitworks-desc">
                                    Recommendation
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </Fragment>
    )
}
export default React.memo(CustomerLensHomeComponent);