import { TabPanel, TabView } from "primereact/tabview";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mai_question_icon } from "../../../../../../assets/images";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import UtilService from "../../../../../../services/util.service";
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import Loader from "../../../../../../shared/components/loader/Loader";
import { AudienceDataColumn } from "../../../../../../models/dataTemplate";

const TransactionsComponent = (props: any) => {
    const params = useParams();
    const { sendRequest: getAudienceInsightsTransactions, loading } = useHttp();
    const [transactionYearlyChartConfig, setTransactionYearlyChartConfig] = useState<any>({
        type: "msline",
        width: "100%",
        height: "13%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                xAxisname: "Year",
                xAxisNamePadding: "10",
                yAxisPosition: "right",
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "1",
                plottooltext: "<div style='display: flex; justify-content: space-between; min-width: 200px'><div>$seriesName:</div> <div><b>$dataValue</b></div> </div>",
                theme: "fusion",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "20",
                legendPadding: "0",
                canvasBottomPadding: "5",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                showLegend: "0"
            }
        }
    });
    const [transactionMonthlyChartConfig, setTransactionMonthlyChartConfig] = useState<any>({
        type: "msline",
        width: "100%",
        height: "13%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                xAxisname: "Month",
                xAxisNamePadding: "10",
                yAxisPosition: "right",
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "1",
                plottooltext: "<div style='display: flex; justify-content: space-between; min-width: 200px'><div>$seriesName:</div> <div><b>$dataValue</b></div></div>",
                theme: "fusion",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "20",
                legendPadding: "0",
                canvasBottomPadding: "5",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                showLegend: "0"
            }
        }
    });

    const getTransactions = useCallback((groupBy: string, date_mode: string) => {
        const filter: any = [];
        const group_by: any = [groupBy];
        const resHandler = (res: any) => {
            const months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            if (date_mode === 'Y') {
                const cloneTransactionYearlyChartConfig = { ...transactionYearlyChartConfig };
                cloneTransactionYearlyChartConfig.dataSource.categories = [{
                    category: [...res.response.profile.map((item: any) => ({
                        label: item.year.toString()
                    }))]
                }];
                const yearlyDataSet: any = [];
                ['avg_payments'].forEach((key: any) => {
                    yearlyDataSet.push({
                        seriesname: UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key)),
                        data: [...res.response.profile.map((item: any) => ({
                            value: item[key]
                        }))]
                    })
                })
                cloneTransactionYearlyChartConfig.dataSource.dataSet = [...yearlyDataSet];
                setTransactionYearlyChartConfig(cloneTransactionYearlyChartConfig);
            } else {
                /**** Monthly Chart Data */
                const cloneTransactionMonthlyChartConfig = { ...transactionMonthlyChartConfig };
                // const sortedMonthlyData = (res.response.profile.sort((a: any, b: any) => a.month - b.month)).sort((a: any, b: any) => a.year - b.year);
                cloneTransactionMonthlyChartConfig.dataSource.categories = [{
                    category: [...res.response.profile.map((item: any) => {
                        return { label: `${months[(item.month ?? 0) - 1]} ${item.year.toString()}` }
                    })]
                }];
                const monthlyDataSet: any = [];
                ['avg_payments'].forEach((key: any) => {
                    monthlyDataSet.push({
                        seriesname: UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key)),
                        data: [...res.response.profile.map((item: any) => ({
                            value: item[key] ?? 0
                        }))]
                    })
                })
                cloneTransactionMonthlyChartConfig.dataSource.dataSet = [...monthlyDataSet];
                setTransactionMonthlyChartConfig(cloneTransactionMonthlyChartConfig);
            }
        }
        if (props.region) {
            filter.push({field: AudienceDataColumn.REGION, label: "Region", value: {in: [props.region]}, operator: "null"});
        }
        if (props.start_date && props.end_date) {
            getAudienceInsightsTransactions(ApiService.getAudienceInsightsProfile({ aid: params.aid, group_by, start_date: props.start_date, end_date: props.end_date, date_mode, filter }), resHandler);
        }
    }, [getAudienceInsightsTransactions, params.aid, props.end_date, props.region, props.start_date]);

    useEffect(() => {
        getTransactions(AudienceDataColumn.YEAR, 'Y');
        getTransactions(AudienceDataColumn.YEAR, 'M');
    }, [getTransactions]);


    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="insights__innerwrapper insights-transactions-wrap">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-12">
                        <h2 className='inner__heading'><span> Transactions </span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    {/* <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                        <ul className="list-inline transactions-idicatorslist">
                            <li className="list-inline-item"><span className="products-usage-bar"></span> Avg. Product Usage</li>
                            <li className="list-inline-item"><span className="monthly-payment-bar"></span> Avg. Monthly Payments</li>
                            <li className="list-inline-item"><span className="avg-supply-bar"></span> Avg. Supplies</li>
                        </ul>
                    </div> */}
                    <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                        {/* <span className="pi pi-ellipsis-v dots3"></span> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="tabs-wrapper customtabs maptabview-wrap">
                            <TabView>
                                <TabPanel header="M">
                                    {loading || !transactionMonthlyChartConfig?.dataSource?.dataSet?.[0] ? (
                                    <Loader style={{ minHeight: "250px" }} />
                                    ) : (
                                    <ChartComponent chartConfigs={transactionMonthlyChartConfig} />
                                    )}
                                </TabPanel>
                                <TabPanel header="Y">
                                    {loading || !transactionYearlyChartConfig?.dataSource?.dataSet?.[0] ? (
                                    <Loader style={{ minHeight: "250px" }} />
                                    ) : (
                                    <ChartComponent chartConfigs={transactionYearlyChartConfig} />
                                    )}
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionsComponent;