import AuthenticationService from "../services/authentication.service";
import { Action, StoreActions } from "./actions";
export interface HeaderTitle {
title: string,
backUrl:string,
share:string
}
export const defaultTitle = {
    title: '',
    backUrl:'',
    share:'',
}
export function headerTitleReducer(state: HeaderTitle = {...defaultTitle}, action: Action<HeaderTitle>) {
    switch(action.type) {
                case StoreActions.UPDATE_HEADER_TITLE: {
                    AuthenticationService.setSessionStorage('title', action.payload.title);
                    return {
                        ...state,
                        ...action.payload
                    };
                }
                default:
                    AuthenticationService.setSessionStorage('title', state.title);
                    return state;
            }
}