import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { mai_shape, mai_edit, mai_notification, mai_arrow_down, mai_back_icon, mai_share_icon } from "../../assets/images";
import './Header.scss';
import { Sidebar } from 'primereact/sidebar';
import { useSelector } from "react-redux";
import { StoreState } from "../../store/store";
import AuthenticationHook from "../../hooks/AuthenticationHook";
import { UserAvatar } from "../../shared/components/userAvatar/userAvatar";
import { Tooltip } from "primereact/tooltip";
const HeaderComponent = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const navigate = useNavigate();
    const auth = AuthenticationHook.useAuth();
    const userDetails = useSelector<StoreState, any>(state => state.currentUser);
    let headerContent = useSelector<StoreState, any>((state: StoreState) => state.headerContent);
    useEffect(() => {
        // if (AuthenticationService.getSessionStorage('title') && AuthenticationService.getSessionStorage('title').length > 0) {
        //     headerContent = { tiltle: AuthenticationService.getSessionStorage('title')}
        // }
    }, [])
    const logoutHandler = () => {
        auth.signout(() => {
            navigate("/");
        });
    };
    return (
        <Fragment>
            <header className="mai-header sticky-top">
                <div className="header__menu">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <div className='audiencedetails__title mb-2 px-0'>
                                {headerContent.backUrl?
                                <NavLink to={headerContent.backUrl}> <img src={mai_back_icon} alt="back" className="img-fluid" /></NavLink>:''}
                                    <span>
                                    {headerContent.title.length > 25 ? (
                                        <>
                                        <Tooltip className="list-tooltip-wrap" target={`.headerTooltip`} position="bottom">
                                            {headerContent.title}
                                        </Tooltip>
                                        <div className={`headerTooltip`}>
                                            {`${headerContent.title.substring(0, 25)}...`}
                                        </div>
                                        </>
                                    )
                                        :
                                        (headerContent.title)
                                    }
                                    </span>
                                    {headerContent.share?<NavLink to="#"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink>:''}
                
            </div>
                        </li>
                    </ul>
                </div>
                <div className="header__profile">
                    <ul className="list-inline">
                        {/* <li className="list-inline-item">
                            <img src={mai_shape} alt="shape" className="img-fluid header__img-icons" />
                        </li>
                        <li className="list-inline-item">
                            <img src={mai_edit} alt="edit" className="img-fluid header__img-icons" />
                        </li> */}
                        <li className="list-inline-item">
                            <img src={mai_notification} alt="notification" className="img-fluid header__img-icons" onClick={() => setVisibleRight(true)} />
                            <Sidebar blockScroll={true} className="header__sidebar" visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                <h3 className="header__notification">Notifications</h3>
                                <div className="header__notifications-desc">
                                    <div className="header__notifications-para">
                                        <div className="header__notification-namedate">
                                            <div className="shortname__wrap"><span className="notification__shortname">JM</span></div>
                                            <div className="fullname__wrap"><p className="notification__fullname">Johnson Michael </p>
                                                <span className="notification__date">23 Sep 2022, at 09.15 AM</span>
                                            </div>
                                        </div>
                                        <div className="notification__desc">
                                            Has shared “North-east Q1” (Segmentation) with you & 4 others
                                        </div>
                                    </div>

                                    <div className="header__notifications-para">
                                        <div className="header__notification-namedate">
                                            <div className="shortname__wrap"><span className="notification__shortname shortname__color1">RS</span></div>
                                            <div className="fullname__wrap"><p className="notification__fullname">Robert Smith</p>
                                                <span className="notification__date">20 Sep 2022, at 12.15 PM</span>
                                            </div>
                                        </div>
                                        <div className="notification__desc">
                                            Has shared “North-east Q1” (Segmentation) with you & 4 others
                                        </div>
                                    </div>

                                    <div className="header__notifications-para">
                                        <div className="header__notification-namedate">
                                            <div className="shortname__wrap"><span className="notification__shortname shortname__color2">JM</span></div>
                                            <div className="fullname__wrap"><p className="notification__fullname">Johnson Michael </p>
                                                <span className="notification__date">23 Sep 2022, at 09.15 AM</span>
                                            </div>
                                        </div>
                                        <div className="notification__desc">
                                            Has shared “North-east Q1” (Segmentation) with you & 4 others
                                        </div>
                                    </div>

                                    <div className="header__notifications-para header__new-notifications">
                                        <strong>New!</strong><br></br>
                                        Hey we have launched  CustomerLens.
                                    </div>

                                    <div className="header__notifications-para">
                                        <div className="header__notification-namedate">
                                            <div className="shortname__wrap"><span className="notification__shortname shortname__color3">RS</span></div>
                                            <div className="fullname__wrap"><p className="notification__fullname">Robert Smith</p>
                                                <span className="notification__date">20 Sep 2022, at 12.15 PM</span>
                                            </div>
                                        </div>
                                        <div className="notification__desc">
                                            Has shared “North-east Q1” (Segmentation) with you & 4 others
                                        </div>
                                    </div>

                                    {/* <p className="header__notifications-para">
                                        <strong> Jonson M</strong> has shared <strong> “North-east Q1” (Segmentation)</strong> with you
                                    </p>
                                    <p className="header__notifications-para">
                                        <strong> Jonson M</strong> has shared <strong> “North-east Q1” (Segmentation)</strong> with you
                                    </p>
                                    <p className="header__notifications-para">
                                        <strong> Jonson M</strong> has shared <strong> “North-east Q1” (Segmentation)</strong> with you
                                    </p>
                                    <p className="header__notifications-para header__new-notifications">
                                        <strong>New!</strong><br></br>
                                        Hey we have launched  CustomerLens.
                                    </p> */}
                                </div>
                            </Sidebar>
                        </li>
                        <li className="list-inline-item">
                            <div className="header__profile-sec" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* <span className="header__profile-shortname">{UtilService.getShortName(userDetails.name)}</span> */}
                                <UserAvatar id={userDetails.id} name={userDetails.name} size={'normal'} />
                                <p className="header__profile-title">{userDetails.name}</p>
                                <img src={mai_arrow_down} alt="arrow-down" className="img-fluid  header__aarrow-icons" />
                            </div>
                            <div className="dropdown-menu header__logout">
                                <div className="dropdown-item" onClick={logoutHandler}><span className="pi pi-sign-out me-2"></span> Log Out</div>
                            </div>

                        </li>

                    </ul>
                    {/* <div className="dropdown">
                        <div className="d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="header__profile-shortname">{UtilService.getShortName(userDetails.name)}</span>
                            <p className="header__profile-title">{userDetails.name}</p>
                            <img src={mai_arrow_down} alt="arrow-down" className="img-fluid  header__aarrow-icons" />
                        </div>                            
                            <div className="dropdown-menu welcome__create-dropdown">
                                <a className="dropdown-item welcome__create-dropdownitem" href="#"><i className="pi pi-plus welcome__plus"></i> Audience</a>                                
                            </div>
                        </div> */}
                </div>
            </header>
        </Fragment>
    );
}
export default HeaderComponent;