import { Fragment } from "react";
import {
    mai_cross,
    mai_tick,
    mai_plus_solid_blue,
    mai_white_cross,
    mai_light_red_cross,
    mai_gray_dots,
    mai_blue_dots,
    mai_arrow_right,
    mai_search_bar
} from '../../../../../../assets/images/index';
const AudienceDetailRulesComponent = () => {
    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper p-0'>
                <div className='audiencecreate__form'>
                    <form>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="rules__wrapper">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    <img src={mai_tick} alt="tick" className='img-fluid me-2' /> Include
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">
                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <div className='rules__includes-data'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner' data-bs-toggle="modal" data-bs-target="#subscriptionModal">
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Subscription</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__and'>
                                                                        <div className='rules__and-border'></div> And
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner' data-bs-toggle="modal" data-bs-target="#annualsalesModal">
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Annual Sales</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='rules__includes-data rules__andorclub ms-0 d-none'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Subscription</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Annual Sales</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__andorwrap'>
                                                                        <span>AND</span>
                                                                        <span>OR</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-12 text-end">
                                                                <div className='rules__group'>
                                                                    <span>Group 1</span>
                                                                    <img src={mai_white_cross} alt="cross" className='img-fluid' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='rules__or'>
                                                        OR
                                                    </div>
                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Contract type</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-12 text-end">
                                                                <div className='rules__group'>
                                                                    <span>Group 2</span>
                                                                    <img src={mai_white_cross} alt="cross" className='img-fluid' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='rules__orgroup mt-2'>
                                            <img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> OR group
                                        </div>
                                        <div className="accordion-item pb-2 mt-4">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    <img src={mai_cross} alt="cross" className='img-fluid me-2' />  Exclude
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">
                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner' data-bs-toggle="modal" data-bs-target="#regionModal">
                                                                            <div><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div><p>Region</p></div>
                                                                            <div><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='rules__rightwrapper'>
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon4" className="form-control border-0" />
                                        </div>
                                    </div>
                                    <div className='rules__custprofile'>
                                        <div className="accordion" id="mai-accordian-rules">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                        Customer Profile
                                                    </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                SFDC Account ID
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Customer Number
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Account Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Tenure
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Usage Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Contract Type
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                        Firmographics
                                                    </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Region
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Annual sales
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                No. of employees
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Multi/single location
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading3">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                        Transaction
                                                    </button>
                                                </h2>
                                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body">
                                                        <p className='coming-soon m-0'> Coming Soon</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="submit" className="btn btn-primary mai-update-button">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default AudienceDetailRulesComponent;