import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import './CampaignMonitoring.scss';

const CampaignMonitoringComponent = () => {

    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}

export default CampaignMonitoringComponent;