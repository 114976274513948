import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './IndustryProduct.scss';
import { Columns } from '../../../../../../models/types';
import UtilService from '../../../../../../services/util.service';
import DataTable from '../../../../../../shared/components/datatable/DataTable';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../shared/components/loader/Loader';
import { TabPanel, TabView } from 'primereact/tabview';
import ChartComponent from '../../../../../../shared/components/chart/ChartComponent';
import { SegmentationDataColumn } from '../../../../../../models/dataTemplate';

const IndustryProductComponent = (props: any) => {
  let params: any = useParams();
  const [industryData, setIndustryData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [industryDistribution, setIndustryDistribution] = useState<any>([]);
  const [productDistribution, setProductDistribution] = useState<any>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const { sendRequest: getPersonaDetails, loading: getPersonaDetailsLoader } = useHttp();
  const { sendRequest: getPersonaDistributionDetails, loading: getPersonaDistributionLoader } = useHttp();
  const [industryCustomerPieChartConfig, setIndustryCustomerPieChartConfig] = useState<any>({
    type: "pie2d",
    width: "100%",
    height: "16%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        pieRadius: "90%",
        enableSlicing: "0",
        numberPrefix: "$",
        showPercentInTooltip: "0",
        decimals: "1",
        bgColor: "#fafafa",
        borderRadius: ".5rem",
        //Theme
        enableSmartLabels: "0",
        showLabels: "0",
        showValues: "0",
        toolTipBorderRadius: "20",
        legendPosition: "bottom",
        showToolTip: "0",
        showLegend: "0",
      },
      data: [],
    },
  });
  const [industryRevenuePieChartConfig, setIndustryRevenuePieChartConfig] = useState<any>({
    type: "pie2d",
    width: "100%",
    height: "16%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        pieRadius: "90%",
        enableSlicing: "0",
        numberPrefix: "$",
        showPercentInTooltip: "0",
        decimals: "1",
        bgColor: "#fafafa",
        borderRadius: ".5rem",
        //Theme
        enableSmartLabels: "0",
        showLabels: "0",
        showValues: "0",
        toolTipBorderRadius: "20",
        legendPosition: "bottom",
        showToolTip: "0",
        showLegend: "0",
      },
      data: [],
    },
  });
  const [productCustomerPieChartConfig, setProductCustomerPieChartConfig] = useState<any>({
    type: "pie2d",
    width: "100%",
    height: "16%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        pieRadius: "90%",
        enableSlicing: "0",
        numberPrefix: "$",
        showPercentInTooltip: "0",
        decimals: "1",
        bgColor: "#fafafa",
        //Theme
        enableSmartLabels: "0",
        showLabels: "0",
        showValues: "0",
        toolTipBorderRadius: "20",
        legendPosition: "bottom",
        showToolTip: "0",
        showLegend: "0",
      },
      data: [],
    },
  });
  const [productRevenuePieChartConfig, setProductRevenuePieChartConfig] = useState<any>({
    type: "pie2d",
    width: "100%",
    height: "16%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        pieRadius: "90%",
        enableSlicing: "0",
        numberPrefix: "$",
        showPercentInTooltip: "0",
        decimals: "1",
        bgColor: "#fafafa",
        //Theme
        enableSmartLabels: "0",
        showLabels: "0",
        showValues: "0",
        toolTipBorderRadius: "20",
        legendPosition: "bottom",
        showToolTip: "0",
        showLegend: "0",
      },
      data: [],
    },
  });
  const segmentationPersonaDetails = useCallback((groupBy: any, segments: any) => {
    const resHandler = (res: any) => {
      if (groupBy === SegmentationDataColumn.INDUSTRY) {
        setIndustryData(res.response ?? []);
      } else {
        setProductData(res.response ?? []);
      }
    };
    getPersonaDetails(ApiService.getSegmentationPersonaDetails(params.sid, groupBy, segments.map((item: any) => item.id), props.start_date, props.end_date, []), resHandler);
  }, [getPersonaDetails, params.sid, props.end_date, props.start_date]);

  const getChartsData = (data: Record<string, any>[], key: string, selectedItem?: string) => {
    const customerData = data.map(([segment_name, item]: any, index: number) => {
      return {
        label: props.selectedPersonas.find((persona: any) => persona.id.toString() === segment_name.substring(8))?.name,
        value: !selectedItem ? props.segmentPersonas.find((persona: any) => persona.id.toString() === segment_name.substring(8))?.customerValue ?? 0 : item.filter((value: any) => selectedItem ? value[key] === selectedItem : true).reduce((acc: number, value: any) => (acc + Number(value.count_customer_no ?? 0)), 0),
        color: UtilService.returnColorSchema(true)[index],
        percentage: 30
      };
    });
    const revenueData = data.map(([segment_name, item]: any, index: number) => {
      return {
        label: props.selectedPersonas.find((persona: any) => persona.id.toString() === segment_name.substring(8))?.name,
        value: !selectedItem ? props.segmentPersonas.find((persona: any) => persona.id.toString() === segment_name.substring(8))?.revenueValue ?? 0 : item.filter((value: any) => selectedItem ? value[key] === selectedItem : true).reduce((acc: number, value: any) => (acc + Number(value.sum_acv ?? 0)), 0),
        color: UtilService.returnColorSchema(true)[index],
        percentage: 30
      }
    });
    return { customerData, revenueData };
  }

  const getPersonaDistribution = useCallback((groupBy: string, segments: any) => {
    const resHandler = (res: any) => {
      if (groupBy === SegmentationDataColumn.INDUSTRY) {
        const data = Object.entries(res.response);
        setIndustryDistribution(data);
        const chartData = getChartsData(data, 'industry');
        setIndustryCustomerPieChartConfig((prevIndustryCustomerPieChartConfig: any) => ({
          ...prevIndustryCustomerPieChartConfig,
          dataSource: {
            ...prevIndustryCustomerPieChartConfig.dataSource,
            data: [...chartData.customerData],
          },
        }));
        setIndustryRevenuePieChartConfig((prevIndustryRevenuePieChartConfig: any) => ({
          ...prevIndustryRevenuePieChartConfig,
          dataSource: {
            ...prevIndustryRevenuePieChartConfig.dataSource,
            data: [...chartData.revenueData],
          },
        }));
      } else {
        const data = Object.entries(res.response);
        setProductDistribution(data);
        const chartData = getChartsData(data, 'product');
        setProductCustomerPieChartConfig((prevProductCustomerPieChartConfig: any) => ({
          ...prevProductCustomerPieChartConfig,
          dataSource: {
            ...prevProductCustomerPieChartConfig.dataSource,
            data: [...chartData.customerData],
          },
        }));
        setProductRevenuePieChartConfig((prevProductRevenuePieChartConfig: any) => ({
          ...prevProductRevenuePieChartConfig,
          dataSource: {
            ...prevProductRevenuePieChartConfig.dataSource,
            data: [...chartData.revenueData],
          },
        }));
      }

    }
    getPersonaDistributionDetails(ApiService.getSegmentationPersonaDistributionDetails(params.sid, groupBy, segments, props.start_date, props.end_date, []), resHandler)
  }, [getPersonaDistributionDetails, params.sid, props.end_date, props.start_date]);

  useEffect(() => {
    if (props.selectedPersonas.length > 0) {
      segmentationPersonaDetails(SegmentationDataColumn.INDUSTRY, props.selectedPersonas);
      segmentationPersonaDetails(SegmentationDataColumn.PRODUCT, props.selectedPersonas);
      getPersonaDistribution(SegmentationDataColumn.INDUSTRY, props.selectedPersonas.map((item: any) => item.id))
      getPersonaDistribution(SegmentationDataColumn.PRODUCT, props.selectedPersonas.map((item: any) => item.id))
    }
  }, [getPersonaDistribution, segmentationPersonaDetails, props.selectedPersonas]);

  const customersBodyTemplate = (rowData: any) => {
    return (
      <div className="progressbar">
        {UtilService.numberFormatter(rowData?.count_customer_no)}
      </div>
    );
  };
  const revenueBodyTemplate = (rowData: any) => {
    return (
      <h3 className="data-per">
        <span>{UtilService.numberFormatter(Number(rowData?.sum_acv))}</span>
      </h3>
    );
  };

  const industryBodyTemplate = (rowData: any) => {
    return (
      <h3 className="data-per">
        <span>{rowData.industry}</span>
      </h3>
    );
  };

  const industryColumns: Columns[] = [
    { field: "industry", header: "", sort: false, body: industryBodyTemplate },
    {
      field: "count_customer_no",
      header: "Total Customers",
      body: customersBodyTemplate,
      sort: true,
    },
    {
      field: "sum_acv",
      header: "Total Revenue",
      body: revenueBodyTemplate,
      sort: true,
    },
  ];

  const productsColumns: Columns[] = [
    { field: "product", header: "", sort: false, },
    {
      field: "count_customer_no",
      header: "Total Customers",
      body: customersBodyTemplate,
      sort: true,
    },
    {
      field: "sum_acv",
      header: "Total Revenue",
      body: revenueBodyTemplate,
      sort: true,
    },
  ];
  const onIndustrySelectionHandler = (event: any) => {
    setSelectedIndustry(event);

    const chartData = getChartsData(industryDistribution, 'industry', event.industry);
    setIndustryCustomerPieChartConfig((prevIndustryCustomerPieChartConfig: any) => ({
      ...prevIndustryCustomerPieChartConfig,
      dataSource: {
        ...prevIndustryCustomerPieChartConfig.dataSource,
        data: [...chartData.customerData],
      },
    }));
    setIndustryRevenuePieChartConfig((prevIndustryRevenuePieChartConfig: any) => ({
      ...prevIndustryRevenuePieChartConfig,
      dataSource: {
        ...prevIndustryRevenuePieChartConfig.dataSource,
        data: [...chartData.revenueData],
      },
    }));
  }
  const industryTableConfig: any = {
    selection: { mode: "single", handler: onIndustrySelectionHandler, selected: selectedIndustry },
    dataKey: "industry",
    cellSelection: false
  };
  const onProductSelectionHandler = (event: any) => {
    setSelectedProduct(event);

    const chartData = getChartsData(productDistribution, 'product', event.product);
    setProductCustomerPieChartConfig((prevProductCustomerPieChartConfig: any) => ({
      ...prevProductCustomerPieChartConfig,
      dataSource: {
        ...prevProductCustomerPieChartConfig.dataSource,
        data: [...chartData.customerData],
      },
    }));
    setProductRevenuePieChartConfig((prevProductRevenuePieChartConfig: any) => ({
      ...prevProductRevenuePieChartConfig,
      dataSource: {
        ...prevProductRevenuePieChartConfig.dataSource,
        data: [...chartData.revenueData],
      },
    }));
  }
  const productTableConfig = {
    selection: { mode: "single", handler: onProductSelectionHandler, selected: selectedProduct },
    dataKey: "product",
    cellSelection: false
  }

  return (
    <>
      <div className="insights__innerwrapper segmentpersona-insights industry-product-tabs">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            {/* <h2 className='inner__heading'><span> Geography </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2> */}
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 text-end">
            {/* <span className="pi pi-ellipsis-v dots3"></span> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="tabs-wrapper segment-personas-tabs personatabsbgwhite">
              <TabView>
                <TabPanel header="Industry">
                  <div className="row industrydata">
                    <div className="col-lg-4 col-md-4 col-sm-12 industry-prod-datatable">
                      {getPersonaDetailsLoader ? <Loader style={{ minHeight: '295px' }} /> :
                        <DataTable data={industryData} columns={industryColumns} config={industryTableConfig}></DataTable>}

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      {getPersonaDistributionLoader ? <Loader style={{ minHeight: '295px' }} /> :
                        <div className="customer__segment-chart row">
                          <div className="customer_chart col-lg-6 col-md-6 col-sm-12 px-0">
                            <ChartComponent chartConfigs={industryCustomerPieChartConfig}></ChartComponent>
                            <h2>Customers</h2>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='piechartpointers custom-Scroll'>
                              <ul className="customer-segments-list list-inline">
                                {industryCustomerPieChartConfig?.dataSource?.data?.map((item: any, index: number) => {
                                  return (
                                    <li key={`ind_cust_pers_${index}`}>
                                      <div>
                                        <i className="pi pi-circle-fill" style={{ 'color': `${item.color}` }}></i>
                                      </div>
                                      <div>
                                        <p>
                                          <strong>{UtilService.numberFormatter(item?.value)}</strong>
                                          {/* <span>{item?.percentage}%</span>{" "} */}
                                        </p>
                                        <span className="light-text">{item?.label}</span>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      {getPersonaDistributionLoader ? <Loader style={{ minHeight: '295px' }} /> :
                        <div className="customer__segment-chart row">
                          <div className="customer_chart col-lg-6 col-md-6 col-sm-12 px-0">
                            <ChartComponent chartConfigs={industryRevenuePieChartConfig}></ChartComponent>
                            <h2>Revenue</h2>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='piechartpointers custom-Scroll'>
                              <ul className="customer-segments-list list-inline">
                                {industryRevenuePieChartConfig?.dataSource?.data?.map((item: any, index: number) => {
                                  return (
                                    <li key={`ind_rev_pers_${index}`}>
                                      <div>
                                        <i className="pi pi-circle-fill" style={{ 'color': `${item.color}` }}></i>
                                      </div>
                                      <div>
                                        <p>
                                          <strong>${UtilService.numberFormatter(item?.value)}</strong>
                                          {/* <span>{item?.percentage}%</span>{" "} */}
                                        </p>
                                        <span className="light-text">{item?.label}</span>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel header="Product">
                  <div className="row industrydata">
                    <div className="col-lg-4 col-md-4 col-sm-12 industry-prod-datatable">
                      {getPersonaDetailsLoader ? <Loader style={{ minHeight: '295px' }} /> :
                        <DataTable data={productData} columns={productsColumns} config={productTableConfig}></DataTable>}

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      {(getPersonaDistributionLoader || !(productCustomerPieChartConfig.dataSource.data.length > 0)) ? <Loader style={{ minHeight: '295px' }} /> :
                        <div className="customer__segment-chart row">
                          <div className="customer_chart col-lg-6 col-md-6 col-sm-12 px-0">
                            <ChartComponent chartConfigs={productCustomerPieChartConfig}></ChartComponent>
                            <h2>Customers</h2>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='piechartpointers custom-Scroll'>
                              <ul className="customer-segments-list list-inline">
                                {productCustomerPieChartConfig?.dataSource?.data?.map((item: any, index: number) => {
                                  return (
                                    <li key={`pro_cust_pers_${index}`}>
                                      <div>
                                        <i className="pi pi-circle-fill" style={{ 'color': `${item.color}` }}></i>
                                      </div>
                                      <div>
                                        <p>
                                          <strong>{UtilService.numberFormatter(item?.value)}</strong>
                                          {/* <span>{item?.percentage}%</span>{" "} */}
                                        </p>
                                        <span className="light-text">{item?.label}</span>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      {(getPersonaDistributionLoader || !(productRevenuePieChartConfig.dataSource.data.length > 0)) ? <Loader style={{ minHeight: '295px' }} /> :
                        <div className="customer__segment-chart row">
                          <div className="customer_chart col-lg-6 col-md-6 col-sm-12 px-0">
                            <ChartComponent chartConfigs={productRevenuePieChartConfig}></ChartComponent>
                            <h2>Revenue</h2>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='piechartpointers custom-Scroll'>
                              <ul className="customer-segments-list list-inline">
                                {productRevenuePieChartConfig?.dataSource?.data?.map((item: any, index: number) => {
                                  return (
                                    <li key={`pro_rev_pers_${index}`}>
                                      <div>
                                        <i className="pi pi-circle-fill" style={{ 'color': `${item.color}` }}></i>
                                      </div>
                                      <div>
                                        <p>
                                          <strong>${UtilService.numberFormatter(item?.value)}</strong>
                                          {/* <span>{item?.percentage}%</span>{" "} */}
                                        </p>
                                        <span className="light-text">{item?.label}</span>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(IndustryProductComponent);
