import { Fragment, useState } from "react";
import './CreateSpendOptimization.scss';
import { NavLink } from "react-router-dom";
import { mai_plus_solid_blue, mai_question_icon } from '../../../../../../assets/images/index';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from 'primereact/inputswitch';

const CreateSpendOptimizationComponent = () => {
    const [selectedObjFunctions, setSelectedObjFunctions] = useState(null);
    const obgFunctionsList = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const [dates, setDates] = useState<Date | undefined>();
    const [checked, setChecked] = useState(true);

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Define channel theme</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Spend optimization</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper'>
                <div className='audiencecreate__form whiteboxheight'>
                    <form>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="objectivefunctions" className="form-label mai-label-sharewith">
                                                Objective Functions
                                                <img src={mai_question_icon} alt="quest" className="img-fluid ms-1" />
                                            </label>
                                            <Dropdown className="chooseaudience_input mai-input" value={selectedObjFunctions} onChange={(e) => setSelectedObjFunctions(e.value)} options={obgFunctionsList} optionLabel="name"
                                                placeholder="Current spend rate" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="campaignfrom" className="form-label mai-form-label">
                                                Start Date
                                                <img src={mai_question_icon} alt="quest" className="img-fluid ms-1" />
                                            </label>
                                            <div className="">
                                                <Calendar className="chooseaudience_input mai-input" value={dates} onChange={(e: any) => setDates(e.value)} selectionMode="range" readOnlyInput />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="row mt-2">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <h3 className="title16 m-0"><span>Constraints</span> <img src={mai_question_icon} alt="quest" className="img-fluid ms-1" /></h3>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-end justify-content-end">
                                                <InputSwitch className="custom-inputswitch" checked={checked} onChange={(e) => setChecked(e.value as boolean)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-7 col-sm-12 d-flex align-items-center">
                                                <div className="form-check form-check-inline ms-5">
                                                    <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="budgetplanner" value="Budget planner" defaultChecked />
                                                    <label className="form-check-label mai-radio-label" htmlFor="budgetplanner">Budget planner</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="salesplanner" value="Sales planner" />
                                                    <label className="form-check-label mai-radio-label" htmlFor="salesplanner">Sales planner</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <button type="button" className="btn btn-primary mai-cancel-button">Apply</button>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-lg-7 col-md-7 col-sm-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                                                <label className="form-check-label mai-label-access">Budget Details <img src={mai_question_icon} alt="quest" className='img-fluid ms-1' /></label>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <input type="text" className="form-control mai-input w-auto" id="dudgetdetails" name="dudgetdetails" placeholder='Enter overall budget' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4"></div>

                                    <div className="col-lg-7 col-md-7 col-sm-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="budgetdetails-table">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <h3>Theme</h3>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                                                            <h3>Historical</h3>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                                                            <h3>Periodical</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="budgetdetails-sub-heading">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12"></div>
                                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>Spend</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>Percentage%</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>Min spend</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>Max spend</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="budgetdetails-table budgetdetails-data">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                            <h2>Theme</h2>
                                                        </div>
                                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>$28000</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>100%</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>$5000</h3>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                                    <h3>$32000</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 mt-4"></div>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                                            <button className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-5 text-end">
                                            <button className="btn btn-primary mai-cancel-button me-3">Save as draft</button>
                                            <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    )
}
export default CreateSpendOptimizationComponent;