import React, { Fragment, useState } from "react";
import './SpendOptimizationInsights.scss';
import { NavLink } from "react-router-dom";
import {
    mai_back_icon,
    mai_share_icon,
    mai_question_icon,
    mai_export_icon,
    mai_pdf_icon,
    mai_jpg_icon,
    mai_ppt_icon,
    mai_user1,
    mai_user2,
    mai_user3,
    mai_salesVsinvestment_chart,
    mai_roitrends_chart
} from '../../../../../assets/images/index';
import { TabPanel, TabView } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import ChartComponent from "../../../../../shared/components/chart/ChartComponent";
import { Dropdown } from "primereact/dropdown";

const SpendOptimizationInsightsComponent = () => {

    const [customerSegmentChartConfig, setCustomerSegmentChartConfig] = useState<any>({
        type: "pie2d",
        width: "100%",
        height: "25%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                theme: "fusion",
                paletteColors: "#3265AA, #4CB4E6, #776EA9",
                showPlotBorder: "1",
                showValues: "0",
                showLabels: "0",
                showLegend: "0",
                legendPosition: "bottom",
                legendItemFontColor: "#000",
                legendItemFontSize: "13",
                showPercentValues: "0",
                enableSlicing: "0",
                decimals: "2",
                numberScaleValue: "1000,1000,1000",
                numberScaleUnit: "K,M,B",
                formatNumberScale: "1",
                stack100Percent: "1",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartBottomMargin: "0",
                chartTopMargin: "0",
                captionPadding: "0",
                skipOverlapLabels: "1",
                enableSmartLabels: "1",
                labelDistance: "1",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                tooltipborderradius: "5",
                smartLineThickness: "0",
                valuePosition: "Inside",
                labelFontColor: "#fff",
                bgColor: "#f8f8f8",
                pieRadius: "98"
            },
            "data": [{
                "label": "Email",
                "value": "285040"
            }, {
                "label": "Facebook",
                "value": "146330"
            }, {
                "label": "Instagram",
                "value": "105070"
            }]
        },
    });
    const [InvestmentChartConfig, setInvestmentChartConfig] = useState<any>({
        type: "pie2d",
        width: "100%",
        height: "25%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                theme: "fusion",
                paletteColors: "#3265AA, #4CB4E6, #776EA9",
                showPlotBorder: "1",
                showValues: "0",
                showLabels: "0",
                showLegend: "0",
                legendPosition: "bottom",
                legendItemFontColor: "#000",
                legendItemFontSize: "13",
                showPercentValues: "0",
                enableSlicing: "0",
                decimals: "2",
                numberScaleValue: "1000,1000,1000",
                numberScaleUnit: "K,M,B",
                formatNumberScale: "1",
                stack100Percent: "1",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartBottomMargin: "0",
                chartTopMargin: "0",
                captionPadding: "0",
                skipOverlapLabels: "1",
                enableSmartLabels: "1",
                labelDistance: "1",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                tooltipborderradius: "5",
                smartLineThickness: "0",
                valuePosition: "Inside",
                labelFontColor: "#fff",
                bgColor: "#f8f8f8",
                pieRadius: "98"
            },
            "data": [{
                "label": "Email",
                "value": "285040"
            }, {
                "label": "Facebook",
                "value": "146330"
            }, {
                "label": "Instagram",
                "value": "105070"
            }]
        },
    });
    const [RoiChartConfig, setRoiChartConfig] = useState<any>({
        type: "pie2d",
        width: "100%",
        height: "25%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                theme: "fusion",
                paletteColors: "#3265AA, #4CB4E6, #776EA9",
                showPlotBorder: "1",
                showValues: "0",
                showLabels: "0",
                showLegend: "0",
                legendPosition: "bottom",
                legendItemFontColor: "#000",
                legendItemFontSize: "13",
                showPercentValues: "0",
                enableSlicing: "0",
                decimals: "2",
                numberScaleValue: "1000,1000,1000",
                numberScaleUnit: "K,M,B",
                formatNumberScale: "1",
                stack100Percent: "1",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartBottomMargin: "0",
                chartTopMargin: "0",
                captionPadding: "0",
                skipOverlapLabels: "1",
                enableSmartLabels: "1",
                labelDistance: "1",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                tooltipborderradius: "5",
                smartLineThickness: "0",
                valuePosition: "Inside",
                labelFontColor: "#fff",
                bgColor: "#f8f8f8",
                pieRadius: "98"
            },
            "data": [{
                "label": "Email",
                "value": "285040"
            }, {
                "label": "Facebook",
                "value": "146330"
            }, {
                "label": "Instagram",
                "value": "105070"
            }]
        },
    });

    const [dates, setDates] = useState<any>(null);

    const [selectedChannel, setSelectedChannel] = useState<any>(null);
    const channels = [
        { name: 'Email', code: 'Email' },
        { name: 'Instagram', code: 'Instagram' },
        { name: 'Facebook', code: 'Facebook' }
    ];

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper recomdsummary-wrapper customtabs-wrap px-0'>
                <div className='audiencedetails__title mb-2'>
                    <NavLink to="#"> <img src={mai_back_icon} alt="back" className="img-fluid" /></NavLink>
                    <span>Social media marketing - Insights</span>
                    <NavLink to="#"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink>
                </div>
                <div className="audiencedetails__tabs">
                    <TabView>
                        <TabPanel header="insights">
                            <div className="audiencedetails__insight-topheading">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="audiencedetails_calendar">
                                            <label htmlFor="minmax">Date Range</label>
                                            <Calendar className="insightspage-calendar" value={dates} onChange={(e) => setDates(e.value)} dateFormat="dd M yy" selectionMode="range" placeholder="10 June 2023 - 15 July 2023" readOnlyInput />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="heading-button d-flex justify-content-end">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={mai_export_icon} alt="export" className="img-fluid" /> Export
                                                </a>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#"><img src={mai_pdf_icon} alt="pdf" className="img-fluid" /> Export as .pdf</a></li>
                                                    <li><a className="dropdown-item" href="#"><img src={mai_jpg_icon} alt="jpeg" className="img-fluid" /> Export as .jpeg</a></li>
                                                    <li><a className="dropdown-item" href="#"><img src={mai_ppt_icon} alt="ppt" className="img-fluid" /> Export as .pptx</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="box-shadow">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="roilist">
                                                        <div className="roilist-inner insights-card-wrap">
                                                            <div className="row">
                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                    <h4>Overall Sales</h4>
                                                                </div>
                                                                <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                                                    <img src={mai_user1} alt="" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-1">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="roilist">
                                                        <div className="roilist-inner insights-card-wrap">
                                                            <div className="row">
                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                    <h4>Overall Investments</h4>
                                                                </div>
                                                                <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                                                    <img src={mai_user2} alt="" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-1">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="roilist">
                                                        <div className="roilist-inner insights-card-wrap">
                                                            <div className="row">
                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                    <h4>Overall ROI</h4>
                                                                </div>
                                                                <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                                                    <img src={mai_user3} alt="" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-1">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                                    <h5>$14K <span>Current</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="box-shadow">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h3 className="inner__heading">Channel Level Details <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <div className="audiencedetails_calendar">
                                                        <label htmlFor="minmax">Period</label>
                                                        <Calendar className="insightspage-calendar" value={dates} onChange={(e) => setDates(e.value)} dateFormat="dd M yy" selectionMode="range" placeholder="10 June 2023 - 15 July 2023" readOnlyInput />
                                                    </div>
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="channel_leveldetails-chart">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                                <ChartComponent chartConfigs={customerSegmentChartConfig} />
                                                                <h3>Sales</h3>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                                <div className="distribution-indicator">
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidgreen-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>25%</h4>
                                                                            <p>Email</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidblue-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>40%</h4>
                                                                            <p>Facebook</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidred-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>35%</h4>
                                                                            <p>Instagram</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="channel_leveldetails-chart">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                                <ChartComponent chartConfigs={InvestmentChartConfig} />
                                                                <h3>Investments</h3>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                                <div className="distribution-indicator">
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidgreen-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>25%</h4>
                                                                            <p>Email</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidblue-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>40%</h4>
                                                                            <p>Facebook</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidred-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>35%</h4>
                                                                            <p>Instagram</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="channel_leveldetails-chart">
                                                        <div className="row">
                                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                                <ChartComponent chartConfigs={RoiChartConfig} />
                                                                <h3>ROI</h3>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                                <div className="distribution-indicator">
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidgreen-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>25%</h4>
                                                                            <p>Email</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidblue-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>40%</h4>
                                                                            <p>Facebook</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="distribution-indicator-inner">
                                                                        <div><span className="solidred-circle indicator"></span></div>
                                                                        <div>
                                                                            <h4>35%</h4>
                                                                            <p>Instagram</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="selectlist-wrap">
                                            <div className="selectlist">
                                                <label>Channel</label>
                                                <div className="monitoring-dropdownlist">
                                                    <Dropdown className="bg-transparent" value={selectedChannel} onChange={(e) => setSelectedChannel(e.value)} options={channels} optionLabel="name"
                                                        placeholder="Email" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="box-shadow">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h3 className="inner__heading">Sales vs Investments <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <img src={mai_salesVsinvestment_chart} alt="sales" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="box-shadow">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h3 className="inner__heading">ROI Trends <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <img src={mai_roitrends_chart} alt="roi" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

        </Fragment>
    )
}
export default SpendOptimizationInsightsComponent;