import React, { useContext, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SessionStorageKeys, Url } from "../models/constants";
import { StoreActions } from "../store/actions";
import { extractUser } from "../store/currentUser";
import { StoreState } from "../store/store";
import AuthenticationService from './../services/authentication.service';

interface SignInType {
    accessToken: string,
    uid: string
}
interface AuthContextType {
    isAuthenticated: () => boolean;
    signin: (userDetails: SignInType, callback: Function) => void;
    signout: (callback: Function) => void;
}
const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: {children: React.ReactNode}) => {
    const dispatch = useDispatch();
    const navigate  = useNavigate();

    const setCurrentUser = (res: any) => {
        const payload = extractUser(res);
    dispatch({ type: StoreActions.UPDATE_USER, payload });
    // dispatch({ type: StoreActions.SET_PERMISSIONS, payload: payload.permissions })
    }

    const updateUserDetails = (uid: any) => AuthenticationService.fetchUserDetails(uid)
        .then((currentUser: any) => setCurrentUser(currentUser.user));
        

    let signin = (userDetails: SignInType, callback: Function) => {
        if(!!userDetails.accessToken && !!userDetails.uid) {
            AuthenticationService.setSessionStorage(SessionStorageKeys.ACCESSTOKEN, userDetails.accessToken);
            AuthenticationService.setSessionStorage(SessionStorageKeys.UID, userDetails.uid);
        }
        updateUserDetails(userDetails.uid)
        .then(() => callback())
        .catch(() => navigate(`/${Url.LOGIN_FAILED}`));
    };

    let signout = (callback: Function) => {
        sessionStorage.clear();
        callback();
    };
    const isAuthenticated = () => {
        const authenticated = !!AuthenticationService.getSessionStorage(SessionStorageKeys.ACCESSTOKEN) &&
            !!AuthenticationService.getSessionStorage(SessionStorageKeys.UID);
        return authenticated
    }

    let value = { isAuthenticated, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => { 
    return useContext(AuthContext);
}

const AuthenticationHook = {
    useAuth,
    AuthProvider
}
export default AuthenticationHook;