
export enum StoreActions {
    TOAST_ON = 'show',
    TOAST_OFF = 'off',
    UPDATE_USER = 'set_current_user',
    SET_FEATURES = 'set_features',
    SET_ACTIONS = 'set_actions',
    SET_PERMISSIONS = 'set_permissions',
    UPDATE_HEADER_TITLE = 'set_header_title'
}

export interface Action<T> {
    type: StoreActions;
    payload: T;
}