import React, { Fragment, useCallback, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams } from "react-router-dom";
import useHttp from "../../../../../hooks/use-http";
import UtilService from "../../../../../services/util.service";
import { FusionChartCountriesLabels } from "../../../../../models/constants";
import ApiService from "../../../../../services/api.service";
import { SegmentationDataColumn } from "../../../../../models/dataTemplate";
import Loader from "../../../../../shared/components/loader/Loader";
import ChartComponent from "../../../../../shared/components/chart/ChartComponent";
interface OwnProps {
  readonly selectedMatrix: { segment: string; rank: string, color: number | string };
}

const GeographyComponent = (props: OwnProps) => {
  const params: any = useParams();
  const { sendRequest: getCLVGeography, loading: geographyLoader } = useHttp();
  const [customerWorldmapChartConfig, setCustomerWorldmapChartConfig] = useState<any>({
    type: "worldwithcountries",
    width: "100%",
    height: "20%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption: "",
        theme: "fusion",
        formatNumberScale: "0",
        toolTipBgColor: "#444444",
        toolTipColor: "#fff",
        toolTipBorderColor: "#444444",
        toolTipBorderRadius: "5",
        chartLeftMargin: "0",
        chartRightMargin: "0",
        chartTopMargin: "0",
        chartBottomMargin: "0",
        chartLeftPadding: "0",
        chartRightPadding: "0",
        chartBottomPadding: "0",
        chartTopPadding: "0",
        showLegend: "0"
      },
      data: [],
    },
  });
  const [avgCLVWorldmapChartConfig, setAvgCLVWorldmapChartConfig] = useState<any>({
    type: "worldwithcountries",
    width: "100%",
    height: "20%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption: "",
        theme: "fusion",
        formatNumberScale: "0",
        numberPrefix: "$",
        toolTipBgColor: "#444444",
        toolTipColor: "#fff",
        toolTipBorderColor: "#444444",
        toolTipBorderRadius: "5",
        chartLeftMargin: "0",
        chartRightMargin: "0",
        chartTopMargin: "0",
        chartBottomMargin: "0",
        chartLeftPadding: "0",
        chartRightPadding: "0",
        chartBottomPadding: "0",
        chartTopPadding: "0",
        showLegend: "0"
      },
      data: [],
    },
  });

  const getGeographyCLVdata = useCallback(
    (groupBy: any) => {
      const resHandler = (res: any) => {
        const _customerMap = res.response.profile.map((item: any, index: number) => {
          return {
            id:
              FusionChartCountriesLabels[
              UtilService.capitalizeTheFirstLetterOfEachWord(item.label)
              ] ?? "104",
            tooltext: `${item.label}: ${item.customer ?? 0}`,
            color: props.selectedMatrix.color ? props.selectedMatrix.color : UtilService.returnColorSchema()[index >= 10 ? index % 10 : index],
            value: item.customer ?? 0,
          };
        });
        const _avgCLVMap = res.response.profile.map((item: any, index: number) => {
          return {
            id:
              FusionChartCountriesLabels[
              UtilService.capitalizeTheFirstLetterOfEachWord(item.label)
              ] ?? "104",
            tooltext: `${item.label}: $${UtilService.numberFormatter(item.avg_clv ?? 0)}`,
            color: props.selectedMatrix.color ? props.selectedMatrix.color : UtilService.returnColorSchema()[index >= 10 ? index % 10 : index],
            value: item.avg_clv ?? 0,
          };
        });
        let cloneCustomerConfig = { ...customerWorldmapChartConfig };
        let cloneAvgCLVConfig = { ...avgCLVWorldmapChartConfig };
        cloneCustomerConfig.dataSource.data = [..._customerMap];
        cloneAvgCLVConfig.dataSource.data = [..._avgCLVMap];
        setCustomerWorldmapChartConfig(cloneCustomerConfig);
        setAvgCLVWorldmapChartConfig(cloneAvgCLVConfig);
      };
      let filter;
      if (props.selectedMatrix.segment !== '' && props.selectedMatrix.rank !== '') {
        filter = { segment: props.selectedMatrix.segment, rank: props.selectedMatrix.rank };
      }
      getCLVGeography(ApiService.getCLVDistributionProfile(params.clvid!, groupBy, filter), resHandler);
    },
    [getCLVGeography, params.clvid, props.selectedMatrix, props.selectedMatrix.segment, props.selectedMatrix.rank]
  );
  useEffect(() => {
    getGeographyCLVdata(SegmentationDataColumn.GEOGRAPHY);
  }, [getGeographyCLVdata]);

  return (
    <Fragment>

      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
        <div className="insights__innerwrapper customtabs-wrap chartsminheight clv-geography-wrap">
          <Loader isLoading={geographyLoader} style={{ minHeight: '470px' }}>
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <h2 className="inner__heading">
                  <span> Geography </span>
                  {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                </h2>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                <span className="pi pi-ellipsis-v dots3"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="tabs-wrapper customtabs persona-geography-wrap">
                  <TabView>
                    <TabPanel header="Customers">
                      {!geographyLoader ? (
                        <ChartComponent chartConfigs={customerWorldmapChartConfig} />
                      ) : (
                        ""
                      )}
                    </TabPanel>
                    <TabPanel header="Avg. CLV">
                      {!geographyLoader ? (
                        <ChartComponent chartConfigs={avgCLVWorldmapChartConfig} />
                      ) : (
                        ""
                      )}
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </div>

    </Fragment>
  );
};
export default React.memo(GeographyComponent);
