import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
const RecommendationsComponent = () => {

    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}

export default RecommendationsComponent