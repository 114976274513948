export enum AudienceDataColumn {
    KPI = "91c4f91fef23f026e8248b38ecbecb78",
    INDUSTRY = "2e9ce199d8e9e62a463981c34db3c7ff",
    GEOGRAPHY = "bb69510f66aaacc143fcc707fa148e72",
    SEGMENT = "a46fc796ec3439cd56071fe217b65091",
    PRODUCT = "2c2ffdd4ab558294de85140b91c3da46",
    TRANSACTION = "116ce06e0cf2fa0c57ad11ea752aa184",
    YEAR = "099636bbc747aa74f6c00906645e54b7",
    REGION = "9e171f64ce0420b78572f6b3ea6932c6",
  }

export enum SegmentationDataColumn {
    INDUSTRY = "2e9ce199d8e9e62a463981c34db3c7ff",
    GEOGRAPHY = "bb69510f66aaacc143fcc707fa148e72",
    SEGMENT = "a46fc796ec3439cd56071fe217b65091",
    PRODUCT = "2c2ffdd4ab558294de85140b91c3da46",
    TRANSACTION = "116ce06e0cf2fa0c57ad11ea752aa184",
    YEAR = "099636bbc747aa74f6c00906645e54b7",
    REGION = "9e171f64ce0420b78572f6b3ea6932c6",
  }