import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';
import {
  mai_cagrminus,
  mai_cagrplus,
  mai_export_icon,
  mai_search_bar,
  mai_edit_underline_blue
} from '../../../../../../assets/images';
import useHttp from '../../../../../../hooks/use-http';
import { BaseResponse, Columns, SegmentationSummaryResponse } from '../../../../../../models/types';
import ApiService from '../../../../../../services/api.service';
import UtilService from '../../../../../../services/util.service';
import DataTable from '../../../../../../shared/components/datatable/DataTable'
import Loader from '../../../../../../shared/components/loader/Loader';
import { Tooltip } from 'primereact/tooltip';

const SegmentationSegment = () => {
  const params = useParams();
  const { sendRequest: getSegmentationSummary, loading: getSegmentationSummaryLoader } = useHttp();
  const { sendRequest: renameSegmentation, loading: renameSegmentationLoader } = useHttp();
  const [tableData, setTableData] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<Columns[]>([]);
  const [segmentEditData, setSegmentEditData] = useState<any>({});
  const segmentEditHandler = (id: string, current: string) => {
    setSegmentEditData({ id, current });
  }
  const segmentsBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className={`nametooltip${rowData.segment_id}`}>{rowData.segment_name.length > 15 ? `${rowData.segment_name.substring(0, 15)}...` : rowData.segment_name}</span>{rowData.segment_name.length > 15 ? <Tooltip className='list-tooltip-wrap' target={`.nametooltip${rowData.segment_id}`} position='bottom'>{rowData.segment_name}</Tooltip> : ''}&nbsp;<span data-bs-toggle="modal" data-bs-target="#showsegmentation" role="button" onClick={() => segmentEditHandler(rowData.segment_id, rowData.segment_name)}><i className='pi pi-pencil' /></span>
      </>
    );
  };
  const customersBodyTemplate = (rowData: any) => {
    return (
      <>
        {UtilService.numberFormatter(Math.round(Number(rowData.customers_value)))}&nbsp;<span className='text-muted'>({Math.round(rowData.customers_percent)}%)</span>
      </>
    );
  };
  const revenueBodyTemplate = (rowData: any) => {
    return (
      <>
        {UtilService.numberFormatter(Math.round(Number(rowData.total_yearly_revenue_value)))} <span className='text-muted'>({Math.round(rowData.total_yearly_revenue_percent)}%)</span>
      </>
    );
  };
  const mtrBodyTemplate = (rowData: any, key: any) => {
    return (
      <>{((key.field === 'TENURE') || (key.field === 'RECENCY')) ? rowData[key.field] : ((UtilService.numberFormatter(Math.round(Number(rowData[key.field]))) || '')?.length > 10 ? <><span className={`tooltip-${key.field}-${rowData.segment_id}`}>{UtilService.numberFormatter(Math.round(Number(rowData[key.field])))?.substring(0, 10)}...</span><Tooltip className='list-tooltip-wrap' target={`.tooltip-${key.field}-${rowData.segment_id}`} position='bottom'>{UtilService.numberFormatter(Math.round(Number(rowData[key.field])))}</Tooltip></> : (UtilService.numberFormatter((Number(rowData[key.field])))))}</>
    );
  }
  const cagrBodyTemplate = (rowData: any) => {
    let classname: string = "text-success";
    let cagr = String(Math.round(rowData.cagr));
    if (cagr.indexOf('-') > -1) {
      classname = "text-danger";
    }
    return (
      <>
        <span className={classname}>
          {cagr}%
        </span> &nbsp;&nbsp;
        <img src={classname === "text-danger" ? mai_cagrminus : mai_cagrplus} alt="CAGR" />
      </>
    );
  };
  const tableConfig: any = {
    selection: { mode: "single" },
    isDataSelectable: () => false,
  };

  useEffect(() => {
    fetchSegmentSummary();
  }, [params]);

  const colHeaderTitle = (key: any) => {
    if ((key === 'TENURE') || (key === 'RECENCY')) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}(Days)`;
    } else if ((key === 'YOY_GROWTH') || (key === 'Ansys SOW on Actual ACV against SAM (on Mid CAE Spend Range)')) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}(%)`;
    } else if (['MONETARY', 'Revenue $m (2020)', 'Revenue $m (2021)', 'Adjusted R&D Spend, 2021 ($m)', 'CAE Spend (MID) $m', 'Ansys SAM (MID) $m', 'ACV_USD'].includes(key)) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}($)`;
    } else {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}`;
    }
  }
  const fetchSegmentSummary = () => {
    setTableColumns([]);
    setTableData([]);
    const resHandler = (res: SegmentationSummaryResponse) => {
      const columns: Columns[] = [
        {
          field: "segment_name",
          header: "Segments",
          body: segmentsBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "customers_value",
          header: "Customers (%)",
          body: customersBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "total_yearly_revenue_value",
          header: "Total Yearly Revenue ($)",
          body: revenueBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "cagr",
          header: "CAGR (%)",
          body: cagrBodyTemplate,
          sort: true,
          align: "center"
        }
      ];
      let defaultKeys: string[] = ['segment_id', 'segment_index', 'segment_name', 'cagr', 'customers_value', 'customers_percent', 'total_yearly_revenue_value', 'total_yearly_revenue_percent'];
      if (res.response.insights.length > 0) {
        Object.keys(res.response.insights[0]).filter((key: string) => key !== 'segment_id').forEach((key: string) => {
          if (!defaultKeys.includes(key)) {
            columns.push({ field: key, header: colHeaderTitle(key), body: mtrBodyTemplate.bind(key), align: "center", sort: true })
          }
        });
      }
      setTableColumns(columns);
      setTableData(res.response.insights);
    }
    if (params?.sid) {
      getSegmentationSummary(ApiService.getSegmentationSummary(params?.sid), resHandler)
    }
  }
  const renameSegmentationItem = (event: any) => {
    event.preventDefault(); const data: any = {
      data: [{
        segment_id: segmentEditData.id,
        segment_name: segmentEditData.current
      }]
    }
    const resHandler = (res: BaseResponse) => {
      fetchSegmentSummary();
    }
    if (params?.sid) {
      renameSegmentation(ApiService.renameSegmentation(params.sid!, { ...data }), resHandler);
    }
  }
  return (
    <>
      <div className="card list-datatable audiencedetails-table border-0 box-shadow whitebox-height-with-tabs segments-table">
        {/* <div className="row pt-3 px-3">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="row mb-3">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="rules__searchbox">
                                <div className="input-group">
                                    <div className="input-group-prepend border-0">
                                        <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                    </div>
                                    <input type="search" placeholder="Search" aria-describedby="button-addon4" className="form-control border-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="heading-button customer-button d-flex justify-content-end">
                        <NavLink to="#"><img src={mai_export_icon} alt="Export" className="img-fluid" /> Export</NavLink>
                    </div>
                </div>
            </div> */}
        {getSegmentationSummaryLoader ? <Loader style={{ minHeight: '450px' }} /> :
          <DataTable
            data={tableData}
            columns={tableColumns}
            config={tableConfig}
          ></DataTable>
        }
      </div>

      <div className="modal fade filter-popup" id="showsegmentation" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={renameSegmentationItem}>
              <div className="modal-header">
                <h1 className="modal-title popup-title" id="exampleModalLabel">Rename Segmentation</h1>
              </div>
              <div className="modal-body">
                <input className='mai-input segmenteditfield px-2' type="text" name='segment' value={segmentEditData?.current} onChange={(e: any) => setSegmentEditData({ ...segmentEditData, current: e.target.value })} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary mai-next-button px-4" data-bs-dismiss="modal">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(SegmentationSegment);
