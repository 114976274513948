import { TabPanel, TabView } from "primereact/tabview";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mai_question_icon } from "../../../../../../assets/images";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import UtilService from "../../../../../../services/util.service";
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import Loader from "../../../../../../shared/components/loader/Loader";
import { SegmentationDataColumn } from "../../../../../../models/dataTemplate";

const TransactionPersonaComponent = (props: any) => {
    const params: any = useParams();
    console.log(props);
    const [selectedSegments, setSelectedSegments] = useState<any>(props.selectedPersonas);
    const { sendRequest: getSegmentationPersonaTransactions, loading } = useHttp();
    const [transactionYearlyChartConfig, setTransactionYearlyChartConfig] = useState<any>({
        type: "msline",
        width: "100%",
        height: "20%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                yAxisPosition: "right",
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "1",
                plottooltext: "<div style='min-width: 200px;'><div style='display: flex; align-items: center; justify-content: space-between; margin: 2px 0;'><div>$seriesName:</div> <div><b>$dataValue</b></div></div></div>",
                theme: "fusion",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "0",
                canvasBottomPadding: "15"
            }
        }
    });
    const [transactionMonthlyChartConfig, setTransactionMonthlyChartConfig] = useState<any>({
        type: "msline",
        width: "100%",
        height: "20%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                yAxisPosition: "right",
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "1",
                plottooltext: "<div style='min-width: 200px;'><div style='display: flex; align-items: center; justify-content: space-between; margin: 2px 0;'><div>$seriesName:</div> <div><b>$dataValue</b></div></div></div>",
                theme: "fusion",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "0",
                canvasBottomPadding: "15"
            }
        }
    });
    const getPersonaTransactions = useCallback((groupBy: string, segments: any) => {
        const segNameChange = (seg: string) => {
            console.log(seg)
            const segArr = seg.split('_');
            const segId: number = parseInt(segArr[1]);
            const segment = segments.filter((item: any) => item.id === segId);
            return segment[0].name;
        }
        const resHandlerY = (res: any) => {
            const cloneTransactionYearlyChartConfig = { ...transactionYearlyChartConfig };
            const segment1: string = `segment_${segments[0].id}`;
            const yearsList: string[] = res.response[segment1].map((item: any) => item.segment_year.toString());
            cloneTransactionYearlyChartConfig.dataSource.categories = [{
                category: [...yearsList.map((item: any) => ({
                    label: item
                }))]
            }];
            const yearlyDataSet: any = [];
            Object.keys(res.response).forEach((segment: string, index: number) => {
                let tempList: number[] = res.response[segment].map((item: any) => item.sum_acv);
                yearlyDataSet.push({
                    seriesname: segNameChange(segment),
                    color: UtilService.returnColorSchema(true)[index],
                    data: [...tempList.map((item: any) => ({
                        value: item
                    }))]
                });
            })
            cloneTransactionYearlyChartConfig.dataSource.dataSet = [...yearlyDataSet];
            setTransactionYearlyChartConfig(cloneTransactionYearlyChartConfig);
        }
        const resHandlerM = (res: any) => {

            const months: any = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const cloneTransactionMonthlyChartConfig = { ...transactionMonthlyChartConfig };
            const segment1: string = `segment_${segments[0].id}`;
            const monthsList: number[] = res.response[segment1].map((item: any) => item.segment_month);
            const yearsList: string = res.response[segment1].map((item: any) => item.segment_year.toString());
            cloneTransactionMonthlyChartConfig.dataSource.categories = [{
                category: [...monthsList.map((item: any, key: number) => ({
                    label: months[item - 1] + " " + yearsList[key]
                }))]
            }];
            const monthlyDataSet: any = [];
            Object.keys(res.response).forEach((segment: string, index: number) => {
                let tempList: number[] = res.response[segment].map((item: any) => item.sum_acv);
                monthlyDataSet.push({
                    seriesname: segNameChange(segment),
                    color: UtilService.returnColorSchema(true)[index],
                    data: [...tempList.map((item: any) => ({
                        value: item
                    }))]
                });
            })
            cloneTransactionMonthlyChartConfig.dataSource.dataSet = [...monthlyDataSet];
            setTransactionMonthlyChartConfig(cloneTransactionMonthlyChartConfig);
        }
        getSegmentationPersonaTransactions(ApiService.getSegmentationPersonaTransactionDetails(params.sid, groupBy, segments.map((item: any) => item.id), props.start_date, props.end_date, [], "Y"), resHandlerY)
        getSegmentationPersonaTransactions(ApiService.getSegmentationPersonaTransactionDetails(params.sid, groupBy, segments.map((item: any) => item.id), props.start_date, props.end_date, [], "M"), resHandlerM)
    }, [getSegmentationPersonaTransactions, params.sid, props.start_date, props.end_date]);
    useEffect(() => {
        setSelectedSegments(props.selectedPersonas);
    }, [props.selectedPersonas]);
    useEffect(() => {
        if (selectedSegments.length > 0) {
            getPersonaTransactions(SegmentationDataColumn.YEAR, selectedSegments);
        }
    }, [getPersonaTransactions, selectedSegments]);

    return (
        <Fragment>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="insights__innerwrapper customtabs-wrap">
                    <div className="row">
                        <div className="col-lg-10 col-md-10 col-sm-12">
                            <h2 className='inner__heading'><span> Transactions </span>
                                {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                            </h2>
                        </div>
                        {/* <div className="col-lg-8 col-md-8 col-sm-12 text-center">
                            <ul className="list-inline transactions-idicatorslist">
                                <li className="list-inline-item"><span className="products-usage-bar"></span> Avg. Product Usage</li>
                                <li className="list-inline-item"><span className="monthly-payment-bar"></span> Avg. Monthly Payments</li>
                                <li className="list-inline-item"><span className="avg-supply-bar"></span> Avg. Supplies</li>
                            </ul>
                        </div> */}
                        <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                            {/* <span className="pi pi-ellipsis-v dots3"></span> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="tabs-wrapper customtabs">
                                <TabView>
                                    <TabPanel header="M">
                                        {loading ? <Loader style={{ minHeight: '290px' }} /> :
                                            <ChartComponent chartConfigs={transactionMonthlyChartConfig} />}
                                    </TabPanel>
                                    <TabPanel header="Y">
                                        {loading ? <Loader style={{ minHeight: '290px' }} /> :
                                            <ChartComponent chartConfigs={transactionYearlyChartConfig} />}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TransactionPersonaComponent;