import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { StoreActions } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from './store/store';
import { extractUser } from './store/currentUser';
import AuthenticationService from './services/authentication.service';
import { SessionStorageKeys } from './models/constants';
import AuthenticationHook from './hooks/AuthenticationHook';

const App = () => {
  const enable = useSelector((state: StoreState) => state.toastConfig.enable);
  const config = useSelector((state: StoreState) => state.toastConfig.config);
  const isUserDetailsAvailable = useSelector<StoreState, boolean>(state => !!state.currentUser.email.length);
  const accessToken = AuthenticationService.getSessionStorage(SessionStorageKeys.ACCESSTOKEN);
  const uid = AuthenticationService.getSessionStorage(SessionStorageKeys.UID);
  const headerTitle = AuthenticationService.getSessionStorage('title');
  const dispatch = useDispatch()
  const toast = useRef<any>(null!);
  const auth = AuthenticationHook.useAuth();
  const navigate = useNavigate();

  const setCurrentUser = useCallback((res: any) => {
    const payload = extractUser(res);
    dispatch({ type: StoreActions.UPDATE_USER, payload });
    
  }, [dispatch]);

  const updateUserDetails = useCallback((uid: any) => AuthenticationService.fetchUserDetails(uid)
    .then((currentUser: any) => setCurrentUser(currentUser.user)), [setCurrentUser]);
  useEffect(() => {
    if (enable && toast.current && config) {
      toast.current.show(config);
      dispatch({ type: StoreActions.TOAST_OFF })
    }
    if (accessToken && uid && !isUserDetailsAvailable) {
      updateUserDetails(uid)
        .catch(() => auth.signout(() => navigate('')));
        if(headerTitle) {
          const payload = { title: headerTitle}
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload});
        }
        

    }

  }, [enable, config, accessToken, uid, isUserDetailsAvailable, dispatch, updateUserDetails, auth, navigate, headerTitle]);

  return (
    <Fragment>
      <Outlet />
      <Toast ref={toast} position="bottom-right"></Toast>
    </Fragment>
  );
}
export default App;
