import axios from "axios";
import { environment }  from "../environment";

const ssoAuth = () => {
  return axios.create({
    baseURL: `${environment.ssoUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const authApiAuth = () => {
  return axios.create({
    baseURL: `${environment.authApiUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const campaignLensApiAuth = () => {
  return axios.create({
    baseURL: `${environment.campaignUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const apiAuth = () => {
  return axios.create({
    baseURL: `${environment.apiUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const fileUploadApiAuth = () => {
  return axios.create({
    baseURL: `${environment.apiUrl}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export interface UserDetails {
    first_name : string;
    role? : string;
    email : string;
    name? : string;
    username : string;
    loggedinby?: 'NORMAL'| 'SSO';
    token?: string;
    a?: string;
    r?: string;
}
const AuthModel = {
  ssoAuth,
  campaignLensApiAuth,
  authApiAuth,
  apiAuth,
  fileUploadApiAuth
  
};

export default AuthModel;
