import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { AudienceDeatil, createSegmentationGeneralDetailResponse, AudienceListResponse, SegmentationGeneralDetail, segmentationGeneralDetailResponse } from '../../../../../../models/types';
import { AutoComplete } from "primereact/autocomplete";
import './SegmentationGeneralDetails.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import AuthenticationService from '../../../../../../services/authentication.service';
import { Url } from '../../../../../../models/constants';

import { mai_plus_solid_blue, mai_question_icon } from '../../../../../../assets/images/index';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { StoreState } from '../../../../../../store/store';

const SegmentationGeneralDetailsComponent = () => {
  const params = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [segmentation, setSegmentaion] = useState<SegmentationGeneralDetail>({
    name: "",
    description: "",
    is_private: false,
    audience: -1,
    can_be_accessed_by: [],
    is_published: false,
  });
  const { sendRequest: createSegmentation, loading: createSegmentationLoader } = useHttp();
  const { sendRequest: updateSegmentation, loading: updateSegmentationLoader } = useHttp();
  const { sendRequest: getSegmentation } = useHttp();
  const { sendRequest: getAudiences } = useHttp();
  const { sendRequest: getAudienceDetails } = useHttp();
  const { sendRequest: getUsers } = useHttp();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState<any>();
  const [filteredAudienceList, setFilteredAudienceList] = useState<AudienceDeatil[]>([]);
  const [enableNext, setEnableNext] = useState(false);
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const { sendRequest: getAudience, loading: getAudienceLoader } = useHttp();
  
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create Segmentation")
      headerTitleChangeHandler("Create Segmentation",'','');
  })

  const getAudienceList = useCallback((
    searchText = "",
    createdBy = "",
    page = 0,
    sortBy = "modified_on",
    sortOrder = 1,
    published = "True"
  ) => {
    const resHandler = (res: AudienceListResponse) => {
      setFilteredAudienceList(res.audiences);
    };
    getAudiences(
      ApiService.getAudienceList({
        page: page,
        search: searchText,
        created_by: createdBy,
        sort_by: sortBy,
        sort_order: sortOrder,
        published
      }),
      resHandler
    );
  }, [getAudiences]);

  useEffect(() => {
    if (params?.aid) {
      const resHandler = (res: any) => {
        setSelectedAudience(res.audience.name);
        let tempSegmentation: SegmentationGeneralDetail = segmentation;
        tempSegmentation.audience = parseInt(params.aid || '-1');
        setSegmentaion(tempSegmentation);
      };
      getAudience(ApiService.getAudienceGeneraldetail(params?.aid), resHandler);
    }
  }, [getAudience, params?.aid]);

  useEffect(() => {
    if (params?.sid) {
      const resHandler = (res: any) => {
        const audienceResHandler = (audRes: any) => {
          setSelectedAudience(audRes.audience.name);
          setSegmentaion({
            name: res.segmentation.name,
            description: res.segmentation.description,
            is_private: res.segmentation.is_private,
            audience: res.segmentation.audience,
            can_be_accessed_by: [...res.segmentation.can_be_accessed_by],
            is_published:res.segmentation.is_published, 
          });
          setSelectedUsers(res.segmentation.can_be_accessed_by);
        }

        getAudienceDetails(ApiService.getAudienceGeneraldetail(res.segmentation.audience), audienceResHandler);

        // setEnableNext(true);
      }
      getSegmentation(ApiService.getSegmentationGeneraldetail(params?.sid), resHandler)
    }
  }, [getAudienceDetails, getSegmentation, params?.sid])

  useEffect(() => {
    console.log(segmentation);
    if (segmentation.name !== '' && segmentation.description !== '' && segmentation.audience !== -1) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
  }, [segmentation]);

  // useEffect(() => {
  //   setSegmentaion({ ...segmentation, can_be_accessed_by: selectedUsers.map((usr: any) => usr.email) });
  // }, [selectedUsers]);

  const userSelectionHandler = (event: any) => {
    setSelectedUsers(event.value);
    setSegmentaion({ ...segmentation, can_be_accessed_by: [...event.value] });
  }
  const searchUser = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            setUserList(res.users);
          }
        }
        getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
      }
    }, 250);
  };
  const searchAudience = (event: { query: string }) => {
    setTimeout(() => {
      getAudienceList(event.query);
    }, 250);
  }
  const updateSegmentationDetails = (sid: string,action:'save'|'next') => {
    const resHandler = (res: any) => {
        if(action==='save') {
          navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`)
        }
        else{
          navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/${sid}`);
        }
    }
    let segmentationData = { ...segmentation };
    if (segmentationData.is_private) {
      if (segmentationData.can_be_accessed_by.length) {
        segmentationData = { ...segmentation, can_be_accessed_by: [...segmentationData.can_be_accessed_by.map((item: any) => item.id)] }
      }
    } else {
      segmentationData = { ...segmentation, can_be_accessed_by: [] };
    }
    updateSegmentation(ApiService.patchSegmentation(sid, segmentationData), resHandler);
  }
  const createSegmentationHandler = (event: React.FormEvent,action:'save'|'next') => {
    event.preventDefault();
    // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/0`);
    //enable later once api start working
    if (params?.sid) {
      updateSegmentationDetails(params?.sid,action);
    } 
    else{
      const resHandler = (res: createSegmentationGeneralDetailResponse) => {
        if(action==='save'){
          navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`)
        }
        else{
          navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/${res.id}`);
        }
      }
      let segmentationData = { ...segmentation };
      if (segmentationData.can_be_accessed_by.length) {
        segmentationData = { ...segmentation, can_be_accessed_by: [...segmentationData.can_be_accessed_by.map((item: any) => item.id)] }
      }
      createSegmentation(ApiService.createSegmentationGeneraldetail(segmentationData), resHandler)
    }
  }
  const cancelHandler = () => {
    headerTitleChangeHandler('Segmentation List','','');
    navigate(
      `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`
    )
  }
  return (
    <Fragment>
      <div className="audiencecreate__steps sticky-top stickytopRecomd">
        <ul className="list-inline">
          <li className="list-inline-item audiencecreate__steps--active">General details</li>
          <li className="list-inline-item audiencecreate__steps--line steps__active"></li>
          <li className="list-inline-item">Define</li>
          <li className="list-inline-item audiencecreate__steps--line"></li>
          <li className="list-inline-item">Customer attributes</li>
          <li className="list-inline-item audiencecreate__steps--line"></li>
          <li className="list-inline-item">Summary</li>
        </ul>
      </div>
      <Loader isLoading={createSegmentationLoader || updateSegmentationLoader}>
        <div className="audiencecreate__form-wrapper segmentgeneraldetails-wrapper">
          <div className="audiencecreate__form whiteboxheight">
            <form onSubmit={(e:any)=>createSegmentationHandler(e,'next')}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className='boxmin-height'>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label mai-form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control mai-input"
                                id="name"
                                name="name"
                                maxLength={200}
                                value={segmentation.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setSegmentaion({ ...segmentation, name: e.target.value })
                                }
                                aria-describedby="emailHelp"
                                placeholder="Enter segmentation name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12"></div>

                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-3">
                              <label htmlFor="segmentationDesc" className="form-label mai-form-label">
                                Description
                              </label>
                              <textarea
                                id="segmentationDesc"
                                className="form-control mai-textarea custom-Scroll"
                                maxLength={500}
                                value={segmentation.description}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                  setSegmentaion({ ...segmentation, description: e.target.value })
                                }
                                placeholder="Enter description"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12"></div>

                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-3">
                              <div className="form-check form-check-inline ps-0">
                                <label className="form-check-label mai-label-access">Access</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input mai-radio-button"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="public"
                                  value="Public"
                                  checked={segmentation.is_private === false}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setSegmentaion({ ...segmentation, is_private: false })
                                  }
                                />
                                <label className="form-check-label mai-radio-label" htmlFor="public">
                                  Public
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input mai-radio-button"
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="private"
                                  value="Private"
                                  checked={segmentation.is_private === true}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setSegmentaion({ ...segmentation, is_private: true })
                                  }
                                />
                                <label className="form-check-label mai-radio-label" htmlFor="private">
                                  Private
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12"></div>

                          <div className="col-lg-5 col-md-5 col-sm-12">
                            {segmentation.is_private && (
                              <div className="mb-3">
                                <label htmlFor="sharewith" className="form-label mai-label-sharewith">
                                  Share with <span> Optional</span>
                                </label>
                                <AutoComplete
                                  id="Segmentation_general_user"
                                  className="form-control"
                                  placeholder={selectedUsers.length === 0 ? "Search users and add" : ''}
                                  value={selectedUsers}
                                  suggestions={userList}
                                  completeMethod={searchUser}
                                  field="name"
                                  multiple
                                  onChange={userSelectionHandler}
                                  aria-label="User"
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12"></div>

                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-0">
                              <label htmlFor="chooseaudience" className="form-label mai-form-label">
                                Choose audience{" "}
                                {/* <img
                                  src={mai_question_icon}
                                  alt="choose audience"
                                  className="img-fluid ms-1"
                                /> */}
                                {" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                          </div>

                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-0">
                              <AutoComplete
                                className="form-control chooseaudience_input"
                                placeholder="Choose audience"
                                value={selectedAudience}
                                suggestions={filteredAudienceList}
                                completeMethod={searchAudience}
                                appendTo="self"
                                field="name"
                                onChange={(e: any) => {
                                  if (e.value.id !== undefined) {
                                    setSegmentaion({ ...segmentation, audience: e.value.id });
                                    setSelectedAudience(e.value.name);
                                  } else {
                                    setSegmentaion({ ...segmentation, audience: -1 });
                                    setSelectedAudience(e.value);
                                  }
                                }}
                                aria-label="chooseaudience"
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                            <div className="createcampaign-or">
                              <span> OR</span>
                            </div>
                            <div className="campaignbutton">
                              <NavLink
                                to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}`}
                                className="createbutton"
                                onClick={() => headerTitleChangeHandler('Create Audience','','')}
                              >
                                <img src={mai_plus_solid_blue} alt="plus" className="img-fluid" /> Create
                                audience
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className='rules__border-top'>
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <button
                          className="btn btn-primary mai-cancel-button"
                          onClick={cancelHandler}
                        >
                          Cancel
                        </button> 
                        <button className="btn btn-primary mai-cancel-button ms-3 " onClick={(e:any)=>createSegmentationHandler(e,'save')}>{segmentation.is_published?"Save and Close":"Save as Draft"}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                        <button
                          type="submit"
                          className="btn btn-primary mai-next-button"
                          disabled={!enableNext} >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}

export default SegmentationGeneralDetailsComponent;