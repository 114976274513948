import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { mai_pipeline_chart, mai_pipeline_icon, mai_question_icon } from '../../../../../../../assets/images'
import useHttp from '../../../../../../../hooks/use-http';
import ApiService from '../../../../../../../services/api.service';
import UtilService from '../../../../../../../services/util.service';
import Loader from '../../../../../../../shared/components/loader/Loader';
import './KpiComponent.scss';

const KpiPipelineComponent = (props: any) => {
    let params = useParams();
    const [pipelineData, setPipelineData] = useState([]);
    const { sendRequest: getAudienceInsightsPipeline, loading: getAudienceInsightsPipelineLoader } = useHttp();
    const audienceInsightsPipeline = useCallback(() => {
        const resHandler = (res: any) => {
            res.response.sort((a: any, b: any) => b.value - a.value);
            setPipelineData(res.response);
        }
        getAudienceInsightsPipeline(ApiService.getAudienceInsightsPipeline(params.aid, '2021'), resHandler);
    }, [getAudienceInsightsPipeline, params.aid, props.end_date, props.region, props.start_date]);
    useEffect(() => {
        if (props.start_date && props.end_date) {
            audienceInsightsPipeline();
        }
    }, [audienceInsightsPipeline, params.aid, props.end_date, props.region, props.start_date]);

    return (
        <>
            {/* <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
            <div className="insights__innerwrapper">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className='inner__heading'>
                            <img src={mai_pipeline_icon} alt="revenue" className="img-fluid" /> 
                            <span className='kpititle'> {props.item.title}</span> 
                            <img src={mai_question_icon} alt="quest" className='img-fluid' />
                        </h2>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className='innertext__size d-flex justify-content-end'>
                            <h2>{props.item.amount} <sub>{props.item.percent}</sub></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="list-inline insights-data">
                            {
                                props.item.items.map((value: any) => {
                                    return (
                                            <li className="list-inline-item" key={value.title}>
                                                <div className='innertext__size'>
                                                    {value.growth !== undefined && <span className="increase">{value?.growth}</span>}
                                                    {value.regression !== undefined && <span className="decrease">{value?.regression}</span>}
                                                    <h2>
                                                        {value.amount} 
                                                        {value.percent !== undefined && <sub>{value.percent}</sub>}
                                                        {value.increment !== undefined && <span className="increase_horizontal">+{value.increment}</span>}
                                                        {value.decrement !== undefined && <span className="decrease_horizontal">+{value.decrement}</span>}
                                                    </h2>
                                                    <p>{value.title}</p>
                                                </div>
                                            </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div> */}
            <div className="col-lg-4 col-md-4 col-sm-12">
                <Loader isLoading={getAudienceInsightsPipelineLoader}>
                    <div className="insights__innerwrapper">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h2 className='inner__heading'><img src={mai_pipeline_icon} alt="pipeline" className="img-fluid" /> <span> Pipeline </span>
                                    {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                                </h2>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 my-1">
                                <img src={mai_pipeline_chart} alt="pipeline" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <ul className="list-inline insights-data insights-pipeline">
                                    {pipelineData.map((pipeline: any, index: number) => {
                                        return (
                                            <li key={`pipeline_${index}`} className="list-inline-item">
                                                <div className='innertext__size'>
                                                    <h2 className='pipelinevalue' style={{ 'color': '#fff' }}>{UtilService.numberFormatter(pipeline.value)}</h2>
                                                    <p>{UtilService.removeUnderscoreAsSpace(pipeline.lable)}</p>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        </>
    )
}

export default KpiPipelineComponent;