import { Fragment } from "react";
import './SpendOptimizationDefineChannelTheme.scss';
import {
    mai_tick,
    mai_search_bar,
    mai_gray_dots,
    mai_question_icon,
    mai_blue_dots,
    mai_light_red_cross,
    mai_edit_dark_icon,
    mai_delete_dark_icon,
    mai_plus_solid_blue
} from '../../../../../../assets/images/index';
import { NavLink } from "react-router-dom";

const SpendOptimizationDefineChannelThemeComponent = () => {
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Define channel theme</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Spend optimization</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form'>
                    <form>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="rules__wrapper">
                                    <div className="target-variable-wrapper">
                                        <div className="variables-inner">
                                            <h3 className="title16 mb-2"><span>Target variable</span> <img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></h3>
                                            <div className='variables-items-wrap'>
                                                <div className='variables-items-wrap-inner'>
                                                    <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                    <div className='variables-items-fields'><p>Account type</p></div>
                                                </div>
                                                <div className='variables-close'>
                                                    <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="variables-inner">
                                            <h3 className="title16 mb-2"><span>Target variable</span> <img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></h3>
                                            <div className='variables-items-wrap'>
                                                <div className='variables-items-wrap-inner'>
                                                    <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                    <div className='variables-items-fields'><p>Account type</p></div>
                                                </div>
                                                <div className='variables-close'>
                                                    <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="variables-inner">
                                            <h3 className="title16 mb-2"><span>Target variable</span> <img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></h3>
                                            <div className='variables-items-wrap'>
                                                <div className='variables-items-wrap-inner'>
                                                    <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                    <div className='variables-items-fields'><p>Account type</p></div>
                                                </div>
                                                <div className='variables-close'>
                                                    <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="variables-inner">
                                            <button type="submit" className="btn btn-primary mai-cancel-button">Save</button>
                                        </div>
                                    </div>

                                    <h3 className='inner__heading mt-3 mb-2'><span> Configure Channels(1) </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>

                                    <div className="target-variable-wrapper channel-inner-wrap">
                                        <div>
                                            <h3 className="title16 mb-2"><span>Base</span> <img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></h3>
                                        </div>

                                        <div className="var-list">
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Average_purchase</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Account type</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="target-variable-wrapper channel-inner-wrap mt-3">
                                        <div className="heading-titles-wrap">
                                            <div className="row mb-2">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h3 className="title16 m-0"><span>Email</span> <NavLink to=""><img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></NavLink> <NavLink to="" data-bs-target="#editchannelmodal" data-bs-toggle="modal"> <img src={mai_edit_dark_icon} alt="edit" className="img-fluid ms-2" /></NavLink></h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                                    <NavLink to="" className="d-flex justify-content-end"> <img src={mai_delete_dark_icon} alt="delete" className="img-fluid" /></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="var-list">
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Email_DL</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Email_cost</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="target-variable-wrapper channel-inner-wrap mt-3">
                                        <div className="heading-titles-wrap">
                                            <div className="row mb-2">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <h3 className="title16 m-0"><span>Instagram </span> <NavLink to=""><img src={mai_question_icon} alt="quest" className="img-fluid ms-2" /></NavLink> <NavLink to="" data-bs-target="#editchannelmodal" data-bs-toggle="modal"> <img src={mai_edit_dark_icon} alt="edit" className="img-fluid ms-2" /></NavLink></h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                                    <NavLink to="" className="d-flex justify-content-end"> <img src={mai_delete_dark_icon} alt="delete" className="img-fluid" /></NavLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="var-list">
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Instagram_impression</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="variables-inner">
                                                <div className='variables-items-wrap'>
                                                    <div className='variables-items-wrap-inner'>
                                                        <div className='variables-items-bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                        <div className='variables-items-fields'><p>Instagram_click</p></div>
                                                    </div>
                                                    <div className='variables-close'>
                                                        <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <NavLink to="" className="createbutton">
                                            <img src={mai_plus_solid_blue} alt="plus" className="img-fluid" /> Add Channel
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='rules__rightwrapper'>
                                    <h3 className='inner__heading mb-2'><span> List of Variables </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>
                                    <div className="rules__searchbox mb-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon4" className="form-control border-0" />
                                        </div>
                                    </div>
                                    <div className='list-bg'>
                                        <div className="list-of-variables">
                                            <ul className='rules__rightsidelist'>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    SFDC Account ID
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Customer Number
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Account Type
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Product Segment
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Product
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Subscription Type
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Tenure
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Usage Segment
                                                </li>
                                                <li>
                                                    <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                    Contract Type
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                            <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="submit" className="btn btn-primary mai-cancel-button me-3">Save as draft</button>
                                            <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="modal fade audiencedetails__addkpimodal sharewith-modal" id="editchannelmodal" aria-labelledby="sharewithModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title popup-title fs-5" id="sharewithModalLabel">Edit Channel</h1>
                                <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <form action="">
                                            <input type="text" className="form-control mai-input" id="sharewith" name="sharewith" placeholder='Email' />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default SpendOptimizationDefineChannelThemeComponent;