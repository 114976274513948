import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { BaseResponse, ChartConfigType, Columns, SegmentationSummaryResponse } from '../../../../../../models/types';
import './CustomerSegmentationSummaryComponent.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from './../../../../../../services/api.service';
import { ToastSeverity, Url } from '../../../../../../models/constants';
import {
  mai_question_icon,
  mai_segment_star,
  mai_accuracy_check,
  mai_cagrminus,
  mai_cagrplus,
  mai_delete_icon,
  mai_merge_icon,
} from '../../../../../../assets/images/index';
import DataTable from '../../../../../../shared/components/datatable/DataTable';
import ChartComponent from '../../../../../../shared/components/chart/ChartComponent';
import Loader from '../../../../../../shared/components/loader/Loader';
import UtilService from '../../../../../../services/util.service';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { Tooltip } from 'primereact/tooltip';
import Button from '../../../../../../shared/components/button/Button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { StoreState } from '../../../../../../store/store';

const CustomerSegmentationSummaryComponent = () => {
  const params = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { sendRequest: getSegmentationSummary, loading: getSegmentationSummaryLoader } = useHttp();
  const { sendRequest: publishSegmentation } = useHttp();
  const { sendRequest: renameSegmentation } = useHttp();
  const { sendRequest: mergeSegments, loading: mergeSegmentsLoader } = useHttp();
  const { sendRequest: deleteSegments, loading: deleteSegmentsLoader } = useHttp();
  const [tableData, setTableData] = useState<any>([]);
  const [displayMergeModal, setDisplayMergeModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [tableColumns, setTableColumns] = useState<Columns[]>([]);
  const [segmentnumber, setSegmentNumber] = useState<string>('');
  const [accuracy, setAccuracy] = useState<string>('');
  const [selectedSegments, setSelectedSegments] = useState<Array<string>>([]);
  const [chartConfigData, setChartConfigData] = useState<ChartConfigType>({
    type: "spline",
    width: "600",
    height: "350",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        theme: "fusion",
        xAxisName: "No. of Segments",
        YAxisName: "Error",
        showLabels: "1",
        chartLeftMargin: "0",
        chartRightMargin: "0",
        chartTopMargin: "15",
        chartBottomMargin: "0",
        toolTipBgColor: "#444444",
        toolTipColor: "#fff",
        toolTipBorderColor: "#444444",
        toolTipBorderRadius: "5"
      }
    },
  });
  const [segmentEditData, setSegmentEditData] = useState<any>({});
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create Segmentation")
      headerTitleChangeHandler("Create Segmentation",'','');
  })

  const saveHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const resHandler = (res: any) => {
      navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`);
    }
    if (params?.sid) {
      publishSegmentation(ApiService.patchSegmentation(params?.sid, { is_published: true }), resHandler)
    }
  }

  const segmentEditHandler = (id: string, current: string) => {
    setSegmentEditData({ id, current });
  }

  const segmentsBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className={`nametooltip${rowData.segment_id}`}>{rowData.segment_name.length > 20 ? `${rowData.segment_name.substring(0, 20)}...` : rowData.segment_name}</span>{rowData.segment_name.length > 20 ? <Tooltip target={`.nametooltip${rowData.segment_id}`} position='bottom'>{rowData.segment_name}</Tooltip> : ''}&nbsp;<span data-bs-toggle="modal" data-bs-target="#showsegmentation" role="button" onClick={() => segmentEditHandler(rowData.segment_id, rowData.segment_name)}><i className='pi pi-pencil' /></span>
      </>
    );
  };

  const customersBodyTemplate = (rowData: any) => {
    return (
      <>
        {UtilService.numberFormatter(Math.round(Number(rowData.customers_value)))}<span className='text-muted'>({Math.round(rowData.customers_percent)}%)</span>
      </>
    );
  };
  const revenueBodyTemplate = (rowData: any) => {
    return (
      <>
        {UtilService.numberFormatter(Math.round(Number(rowData.total_yearly_revenue_value)))}<span className='text-muted'>({Math.round(rowData.total_yearly_revenue_percent)}%)</span>
      </>
    );
  };
  const mtrBodyTemplate = (rowData: any, key: any) => {
    // {`${key.field === 'MONETARY' ? '$' : ''}`}
    return (
      <>{((key.field === 'TENURE') || (key.field === 'RECENCY')) ? rowData[key.field] : ((UtilService.numberFormatter(Math.round(Number(rowData[key.field]))) || '')?.length > 10 ? <><span className={`tooltip-${key.field}-${rowData.segment_id}`}>{UtilService.numberFormatter(Math.round(Number(rowData[key.field])))?.substring(0, 10)}...</span><Tooltip target={`.tooltip-${key.field}-${rowData.segment_id}`} position='bottom'>{UtilService.numberFormatter(Math.round(Number(rowData[key.field])))}</Tooltip></> : (UtilService.numberFormatter((Number(rowData[key.field])))))}</>
    );
  }
  const cagrBodyTemplate = (rowData: any) => {
    let classname: string = "text-success";
    let cagr = String(Math.round(rowData.cagr));
    if (cagr.indexOf('-') > -1) {
      classname = "text-danger";
    }
    return (
      <>
        <span className={classname}>
          {cagr}%
        </span>
        <img src={classname === "text-danger" ? mai_cagrminus : mai_cagrplus} alt="CAGR" />
      </>
    );
  };
  const tableConfig: any = {
    selection: { mode: "checkbox", handler: (value: any[]) => { setSelectedSegments(value) }, selected: selectedSegments },
    isDataSelectable: () => true,
    dataKey: "segment_id",
    cellSelection: false
  };

  useEffect(() => {
    fetchSegmentSummary();
  }, [params]);

  // const runSegmentSummary = () => {
  //   const resHandler = (res: BaseResponse) => {
  //     fetchSegmentSummary();
  //   }
  //   if (params?.sid) {
  //     runSegmentation(ApiService.runSegmentationSummary(params?.sid), resHandler)
  //   }
  //   // resHandler({detail:"", message:""});
  // }
  const colHeaderTitle = (key: any) => {
    if ((key === 'TENURE') || (key === 'RECENCY')) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}(Months)`;
    } else if ((key === 'YOY_GROWTH') || (key === 'Ansys SOW on Actual ACV against SAM (on Mid CAE Spend Range)')) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}(%)`;
    } else if (['MONETARY', 'Revenue $m (2020)', 'Revenue $m (2021)', 'Adjusted R&D Spend, 2021 ($m)', 'CAE Spend (MID) $m', 'Ansys SAM (MID) $m', 'ACV_USD'].includes(key)) {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}($)`;
    } else {
      return `${UtilService.capitalizeTheFirstLetterOfEachWord(UtilService.removeUnderscoreAsSpace(key))}`;
    }
  }
  const fetchSegmentSummary = () => {
    setTableColumns([]);
    setTableData([]);
    const resHandler = (res: SegmentationSummaryResponse) => {
      // setSegmentNameMapping({ ...res.response.segment_name_mapping });
      const columns: Columns[] = [
        {
          selectionMode: "multiple",
          headerStyle: { width: '3rem' }
        },
        {
          field: "segments",
          header: "Segments",
          body: segmentsBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "customers_value",
          header: "Customers (%)",
          body: customersBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "total_yearly_revenue_value",
          header: "Total Yearly Revenue ($)",
          body: revenueBodyTemplate,
          sort: false,
          align: "center"
        },
        {
          field: "cagr",
          header: "CAGR (%)",
          body: cagrBodyTemplate,
          sort: true,
          align: "center"
        }
      ];
      let defaultKeys: string[] = ['segment_id', 'segment_index', 'segment_name', 'cagr', 'customers_value', 'customers_percent', 'total_yearly_revenue_value', 'total_yearly_revenue_percent'];
      if (res.response.insights.length > 0) {
        Object.keys(res.response.insights[0]).filter((key: string) => key !== 'segment_id').forEach((key: string) => {
          if (!defaultKeys.includes(key)) {
            columns.push({ field: key, header: colHeaderTitle(key), body: mtrBodyTemplate.bind(key), sort: true, align: "center" })
          }
        });
      }
      setTableColumns(columns);
      setTableData(res.response.insights);
      setSegmentNumber(res.response.optimal_seg_no);
      setAccuracy(res.response.accuracy);
      let chartcloneConfig = { ...chartConfigData };
      let chartdata: any = res.response.optimal_seg_graph?.map((chartitem: any, index: number) => {
        if ((Number(res.response.optimal_seg_graph.length) - 1) === index) {
          return {
            label: String(chartitem.cluster),
            value: chartitem.sse,
            vline: "true",
            color: "#4e24e2"
          }
        } else {
          return {
            label: String(chartitem.cluster),
            value: chartitem.sse
          }
        }
      });

      chartcloneConfig.dataSource.data = chartdata;
      setChartConfigData(chartcloneConfig);
    }
    if (params?.sid) {
      getSegmentationSummary(ApiService.getSegmentationSummary(params?.sid), resHandler)
    }

  }
  const refreshChart = () => {
    setTimeout(() => { setChartConfigData({ ...chartConfigData, containerBackgroundOpacity: "1" }) }, 200);
  }

  const renameSegmentationItem = (event: any) => {
    event.preventDefault();
    const data: any = {
      data: [{
        segment_id: segmentEditData.id,
        segment_name: segmentEditData.current
      }]
    }
    const resHandler = (res: BaseResponse) => {
      fetchSegmentSummary();
    }
    if (params?.sid) {
      renameSegmentation(ApiService.renameSegmentation(params.sid!, { ...data }), resHandler);
    }
  }
  const cancelHandler = () => {
    headerTitleChangeHandler('Segmentation List','','');
    navigate(
      `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`
    )
  }
  const backHandler = () => {
    navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_ATTRIBUTE}/${params?.sid}`);
  }
  const mergeSegmentCallback = () => {
    const resHandler = (res: any) => {
      dispatch({
        type: StoreActions.TOAST_ON,
        payload: {
          severity: ToastSeverity.SUCCESS,
          summary: "Information",
          detail: res.detail,
          life: 3000,
        },
      });
      fetchSegmentSummary();
      setSelectedSegments([]);
    }
    if (params.sid) {
      mergeSegments(ApiService.mergeSegmentation(params.sid, selectedSegments.map((value: any) => value.segment_id)), resHandler);
    }
  }
  const deleteSegmentAcceptHandler = () => {
    const resHandler = (res: any) => {
      dispatch({
        type: StoreActions.TOAST_ON,
        payload: {
          severity: ToastSeverity.SUCCESS,
          summary: "Information",
          detail: res.detail,
          life: 3000,
        },
      });
      fetchSegmentSummary();
      setSelectedSegments([]);
    }
    if (params.sid) {
      deleteSegments(ApiService.deleteSegments(params.sid, selectedSegments.map((value: any) => value.segment_id)), resHandler);
    }
  }
  return (
    <Fragment>
      <div className='audiencecreate__steps sticky-top stickytopRecomd'>
        <ul className='list-inline'>
          <li className='list-inline-item audiencecreate__steps--active'>General details</li>
          <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
          <li className='list-inline-item audiencecreate__steps--active'>Define</li>
          <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
          <li className='list-inline-item audiencecreate__steps--active'>Customer attributes</li>
          <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
          <li className='list-inline-item audiencecreate__steps--active'>Summary</li>
        </ul>
      </div>
      <Loader isLoading={getSegmentationSummaryLoader || mergeSegmentsLoader || deleteSegmentsLoader}>
        <div className='audiencecreate__form-wrapper'>
          <div className='audiencecreate__form'>
            <div className='boxmin-height'>
              <div className="card audiencelist-datatable segmentation-segment-table border-0">
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2 className='inner__heading mt-3'><span> Segments List </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="heading-button d-flex justify-content-end segment-button">
                      {selectedSegments.length > 1 ? <button className="me-3 mb-0 btn btn-primary mai-cancel-button" onClick={() => setDisplayMergeModal(true)}><img src={mai_merge_icon} alt="merge" className="img-fluid me-1" /> Merge</button> : ''}
                      {selectedSegments.length > 0 ? <button className='btn btn-primary mai-cancel-button' onClick={() => setDisplayDeleteModal(true)}><img src={mai_delete_icon} alt="delete" className="img-fluid me-1" /> Delete </button> : ''}
                    </div>
                  </div>
                </div>
                <div className="card list-datatable listpage-table border-0 segmentlist-datatable segmentation-summaryTable">
                  <DataTable
                    data={tableData}
                    columns={tableColumns}
                    config={tableConfig}
                  ></DataTable>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className='summary-column'>
                    <div className='optimalnumber'>
                      <div className='me-2'><img src={mai_segment_star} alt="star" className='img-fluid' /></div>
                      <div>
                        <h4> Optimal number of segments</h4>
                        <p>
                          The analysis has calculated the optimal
                          number of segments required
                        </p>
                      </div>

                    </div>
                    <div className='text-center numbersummary'>
                      <h5>{segmentnumber}</h5>
                      <NavLink to="" onClick={refreshChart} data-bs-toggle="modal" data-bs-target="#recommendationpopup">View graph</NavLink>
                      {/* <p>Increasing the number of segments beyond this will not have any significant effect on variance explained metric</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className='summary-column'>
                    <div className='optimalnumber'>
                      <div className='me-2'><img src={mai_accuracy_check} alt="check" className='img-fluid' /></div>
                      <div>
                        <h4>Accuracy</h4>
                        <p>Variance explained by segments </p>
                      </div>
                    </div>
                    <div className='text-center numbersummary'>
                      <h5>{Math.round(Number(accuracy))}%</h5>
                    </div>
                    {/* <div className='text-center numbersummary'>
                    <h5>{segmentnumber}</h5>
                    <NavLink to="" onClick={refreshChart} data-bs-toggle="modal" data-bs-target="#recommendationpopup">View graph</NavLink>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="rules__border-top">
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <button type="submit" className="btn btn-primary mai-cancel-button" onClick={cancelHandler}>Cancel</button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                      <button type="submit" className="btn btn-primary mai-cancel-button me-3" onClick={backHandler}>Back</button>
                      <button type="submit" className="btn btn-primary mai-next-button" onClick={saveHandler}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>


      {/* Recommendation Popup */}
      <div className="modal fade filter-popup" id="recommendationpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Recommendation</h1>
              <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body modal-chart">
              <Loader isLoading={getSegmentationSummaryLoader}>
                <div className='col-md-12'>
                  {chartConfigData.dataSource.data && <ChartComponent chartConfigs={chartConfigData} />}
                </div>
              </Loader>
            </div>
          </div>
        </div>
      </div>

      {/* show segmentation Popup */}
      <div className="modal fade filter-popup" id="showsegmentation" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={renameSegmentationItem}>
              <div className="modal-header">
                <h1 className="modal-title popup-title" id="exampleModalLabel">Edit Segmentation</h1>
              </div>
              <div className="modal-body">
                <input className="mai-input px-2" type="text" name='segment' value={segmentEditData?.current} onChange={(e: any) => setSegmentEditData({ ...segmentEditData, current: e.target.value })} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary mai-next-button" data-bs-dismiss="modal">Save segmentation</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ConfirmDialog
        visible={displayMergeModal}
        onHide={() => {
          setDisplayMergeModal(false);
          setSelectedSegments([]);
        }}
        maskClassName="common-popup-wrap"
        header={`Are you sure, you want to merge ${selectedSegments.length} Segments?`}
        closable={false}
        draggable={false}
        accept={mergeSegmentCallback}
        reject={() => {
          setDisplayMergeModal(false);
          setSelectedSegments([]);
        }} 
      />
      <ConfirmDialog
        visible={displayDeleteModal}
        onHide={() => {
          setDisplayDeleteModal(false);
          setSelectedSegments([]);
        }}
        maskClassName="common-popup-wrap"
        header={`Are you sure, you want to delete ${selectedSegments.length} Segments?`}
        closable={false}
        draggable={false}
        accept={deleteSegmentAcceptHandler}
        reject={() => {
          setDisplayDeleteModal(false);
          setSelectedSegments([]);
        }} 
      />
    </Fragment>
  )
}

export default CustomerSegmentationSummaryComponent;
