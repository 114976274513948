import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip';

import en from '../../../assets/i18n/en.json';
import hi from '../../../assets/i18n/hi.json';
import { LanguageList } from '../../../models/constants';
import './EditLanguageComponent.scss';


const EditLanguageComponent = () => {
    const [language, setLanguage] = useState<string>(LanguageList.EN);
    const [jsonData, setJsonData] = useState<any>(en);

    useEffect(() => {
        if(language === LanguageList.HI){
            setJsonData(hi);
            
        }else{
            setJsonData(en);
        }
    }, [language]);

    const changeHandler = (lang: string) => {
        setLanguage(lang);
    }

    const saveLocaleJson = () => {
        //jsonData.save();
        // console.log('jsonData', jsonData);
        for(let key in jsonData){
            fetch(`http://localhost:3004/${key}`, {
                method: "POST",
                body: new URLSearchParams(jsonData[key])
              });
        }
            
    }

    const updateString = (event: React.ChangeEvent<HTMLInputElement>) => {
        const langstrings = {...jsonData}
        console.log('id', event.target.id);
        let keyValue = event.target.id.split('-');
        let parentkey = keyValue[0];
        let childkey = keyValue[1];
        langstrings[parentkey][childkey] = event.target.value;
        setJsonData(langstrings);
        //jsonData.save();
    }

    const renderLocaleStrings = () => { 
        return Object.keys(jsonData).map((section: string) => 
            (
                <div key={section}>
                <div className='d-inline-flex'>
                    <h5>{section}</h5>
                    <span data-tip data-for={section} className="info">i</span>
                    <ReactTooltip id={section} place="right" offset={{ top: 10, left: 5 }} effect='float'>
                        <img width="600px" height="300px" src={require(`../../../assets/images/${jsonData[section]["keyimgurl"]}.png`)} alt={jsonData[section]["keyimgurl"]} />
                    </ReactTooltip>
                </div>
                {Object.keys(jsonData[section]).map((str: string) => 
                    (
                        <div className="form-group row mb-2" key={`${section}_${str}`}>
                            <label className="col-sm-2 col-form-label">{str}</label>
                            <div className="col-sm-10">
                                <input type="text" onChange={updateString} className={str === 'keyimgurl' ? 'form-control-plaintext' : "form-control"} id={`${section}-${str}`} value={jsonData[section][str]} readOnly={str === 'keyimgurl' ? true : false}/>
                            </div>
                        </div>
                    )
                )}
                </div>
            )
        );
    }
  return (
    <>
        <div className='form ml-2'>
        {/* <select className="form-select form-select mb-3 mx-2" onChange={(event)=> changeHandler(event.target.value)}>
            <option value={LanguageList.EN}>{LanguageList.EN}</option>
            <option value={LanguageList.HI}>{LanguageList.HI}</option>
        </select> */}
            <div className='mx-2'>
                {renderLocaleStrings()}
                <div className='form-group text-center'>
                    <button className='btn btn-secondary' onClick={saveLocaleJson}>Save Changes</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default EditLanguageComponent