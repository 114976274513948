import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  AudienceDeatil,
  createCampaignGeneralDetailResponse,
  AudienceListResponse,
  CampaignGeneralDetail,
  BaseResponse
} from '../../../../../../models/types';
import { AutoComplete } from "primereact/autocomplete";
import './CampaignGeneralDetails.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import AuthenticationService from '../../../../../../services/authentication.service';
import { Url } from '../../../../../../models/constants';

import { mai_plus_solid_blue, mai_question_icon } from '../../../../../../assets/images/index';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { Calendar } from 'primereact/calendar';
import { CampaignLensDropdown } from '../../../../../../store/currentUser';
import { StoreState } from '../../../../../../store/store';
import CampaignLensService from '../../../../../../services/campaignlens.service';
import { Dropdown } from 'primereact/dropdown';


const CampaignGeneralDetailsComponent = () => {
  const params = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [campaign, setCampaign] = useState<CampaignGeneralDetail>({
    name: "",
    description: "",
    is_private: false,
    audience: '',
    start_date: "",
    end_date: "",
    sucess_criteria: "",
    budget: "",
    exclusion: "",
    manager: "",
    can_be_accessed_by: [],
  });
  const { sendRequest: getUsers } = useHttp();
  const { sendRequest: createCampaign, loading: createCampaignLoader } = useHttp();
  const { sendRequest: updateCampaign, loading: updateCampaignLoader } = useHttp();
  const { sendRequest: getCampaign, loading: getCampaignLoader } = useHttp();
  const { sendRequest: getAudiences } = useHttp();
  const { sendRequest: getAudienceDetails } = useHttp();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState<any>();
  const [filteredAudienceList, setFilteredAudienceList] = useState<AudienceDeatil[]>([]);
  const [selectedManager, setSelectedManager] = useState<any>();
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [validDate, setValidDate] = useState<boolean>(false);
  const [fromdate, setFromDate] = useState<Date | undefined>();
  const [todate, setTodate] = useState<Date | undefined>();
  const successCriteriaOptions = useSelector<StoreState, CampaignLensDropdown[]>(state => state.currentUser.features?.campaignLens?.createCampaign.successCriteriaOptions || []);
  const exclusionsOptions = useSelector<StoreState, CampaignLensDropdown[]>(state => state.currentUser.features?.campaignLens?.createCampaign.exclusionsOptions || []);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    AuthenticationService.fetchUsers().then((res: any) => {
      setUserList(res.users);
      setFilteredUsers(res.users);
      setFilteredManagers(res.users);
    });
  }, []);

  useEffect(() => {
    if (fromdate && todate && fromdate?.getTime() < todate?.getTime()) {
      setValidDate(true);
    } else {
      setValidDate(false);
    }
  }, [fromdate, todate])

  const getAudienceList = useCallback((
    searchText = "",
    createdBy = "",
    page = 0,
    sortBy = "modified_on",
    sortOrder = 1
  ) => {
    const resHandler = (res: AudienceListResponse) => {
      setFilteredAudienceList(res.audiences);
    };
    getAudiences(
      ApiService.getAudienceList({
        page: page,
        search: searchText,
        created_by: createdBy,
        sort_by: sortBy,
        sort_order: sortOrder,
        published: 'True'
      }),
      resHandler
    );
  }, [getAudiences]);

  useEffect(() => {
    if (params?.cid) {
      const resHandler = (res: any) => {
        setCampaign({
          name: res.campaign.name,
          description: res.campaign.description,
          is_private: res.campaign.is_private,
          audience: res.campaign.audience,
          start_date: res.campaign.start_date,
          end_date: res.campaign.end_date,
          sucess_criteria: res.campaign.sucess_criteria,
          budget: res.campaign.budget,
          exclusion: res.campaign.exclusion,
          manager: res.campaign.manager,
          can_be_accessed_by: [...res.campaign.can_be_accessed_by.map((user: any) => user.id)],
        });
        setFromDate(new Date(res.campaign.start_date));
        setTodate(new Date(res.campaign.end_date));
        setSelectedUsers(res.campaign.can_be_accessed_by || []);
        if (res.campaign.manager) {
          AuthenticationService.fetchUserDetails(res.campaign.manager).then((ManagerRes: any) => {
            setSelectedManager(ManagerRes.user);
          });
        }
        getAudienceDetails(ApiService.getAudienceGeneraldetail(res.campaign.audience), (audRes) => setSelectedAudience(audRes.audience));
      }
      getCampaign(CampaignLensService.getCampaignGeneraldetail(params?.cid), resHandler)
    }
  }, [getAudienceDetails, getCampaign, params?.cid, userList])

  const headerTitleChangeHandler = (content: any) => {
    const payload = { title: content };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  const searchUser = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            setFilteredUsers(res.users.filter((user: any) => !campaign.can_be_accessed_by.includes(user.id)));
          }
        }
        getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
      }
    }, 250);
  };
  const searchAudience = (event: { query: string }) => {
    setTimeout(() => {
      getAudienceList(event.query);
    }, 250);
  }

  const searchManager = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            setFilteredManagers(res.users);
          }
        }
        getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
      }
    }, 250);
  };
  const createCampaignHandler = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(campaign);
    // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/0`);
    //enable later once api start working
    if (params?.cid) {
      const resHandler = (res: BaseResponse) => {
        setShowModal(true);
      }
      updateCampaign(CampaignLensService.patchCampaign(params?.cid, campaign), resHandler);
    } else {
      const resHandler = (res: createCampaignGeneralDetailResponse) => {
        setShowModal(true);
      }
      createCampaign(CampaignLensService.createCampaignGeneraldetail(campaign), resHandler)
    }
  }
  const cancelHandler = () => {
    headerTitleChangeHandler('Campaign List');
    navigate(
      `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}`
    )
  }
  return (
    <Fragment>
      <div className="audiencecreate__steps sticky-top stickytopRecomd">
        <ul className="list-inline">
          <li className="list-inline-item audiencecreate__steps--active">General details</li>
        </ul>
      </div>
      <Loader isLoading={createCampaignLoader || updateCampaignLoader || getCampaignLoader}>
        <div className="audiencecreate__form-wrapper campaigngeneraldetails-wrapper">
          <div className="audiencecreate__form whiteboxheight">
            <form onSubmit={createCampaignHandler}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-4">
                        <label htmlFor="name" className="form-label mai-form-label">
                          Campaign Name
                        </label>
                        <input
                          type="text"
                          className="form-control mai-input"
                          id="name"
                          name="name"
                          maxLength={200}
                          value={campaign.name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const patt = /[^A-Za-z 0-9]/g;
                            if (!(patt.test(e.target.value))) {
                              setCampaign({ ...campaign, name: e.target.value })
                            }
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Enter campaign name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-4">
                        <label htmlFor="budget" className="form-label mai-form-label">
                          Budget <span className="fadedTitle"> Optional</span>
                        </label>
                        <input
                          type="text"
                          className="form-control mai-input"
                          id="budget"
                          name="budget"
                          maxLength={200}
                          value={campaign.budget}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const patt = /[^A-Za-z 0-9]/g;
                            if (!(patt.test(e.target.value))) {
                              setCampaign({ ...campaign, budget: e.target.value })
                            }
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Enter budget"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="mb-4">
                            <label htmlFor="campaignfrom" className="form-label mai-form-label">
                              Start Date{" "}
                              <img
                                src={mai_question_icon}
                                alt="choose audience"
                                className="img-fluid ms-1"
                              />
                            </label>
                            <div className="defineCalendar">
                              <Calendar
                                inputStyle={{ width: "40%" }}
                                className={!validDate && fromdate && todate ? "invaliddate" : ""}
                                id="fromdate"
                                value={fromdate}
                                onChange={(e: any) => {
                                  setFromDate(e.value);
                                  setCampaign({ ...campaign, start_date: e.value.toISOString() });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="mb-4">
                            <label htmlFor="campaignto" className="form-label mai-form-label">
                              End Date
                            </label>
                            <div className="defineCalendar">
                              <Calendar
                                className={!validDate && fromdate && todate ? "invaliddate" : ""}
                                id="todate"
                                value={todate}
                                onChange={(e: any) => {
                                  setTodate(e.value);
                                  setCampaign({ ...campaign, end_date: e.value.toISOString() });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-4">
                        <label htmlFor="sucess_criteria" className="form-label mai-label-sharewith">
                          Success Criteria <span>Optional</span>
                        </label>
                        <Dropdown
                          className="chooseaudience_input mai-input"
                          id="sucess_criteria"
                          name="sucess_criteria"
                          placeholder="Enter success criteria"
                          options={successCriteriaOptions}
                          value={campaign.sucess_criteria}
                          onChange={(e: any) =>
                            setCampaign({ ...campaign, sucess_criteria: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-4">
                        <label htmlFor="campaignDesc" className="form-label mai-form-label">
                          Description
                        </label>
                        <textarea
                          id="campaignDesc"
                          className="form-control mai-textarea"
                          maxLength={500}
                          value={campaign.description}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            setCampaign({ ...campaign, description: e.target.value })
                          }
                          placeholder="Enter description"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                          <label htmlFor="exclusion" className="form-label mai-label-sharewith">
                            Exclusion{" "}
                            <img
                              src={mai_question_icon}
                              alt="choose exclusion"
                              className="img-fluid ms-1"
                            />
                          </label>
                          <Dropdown
                            className="chooseaudience_input mai-input"
                            id="exclusion"
                            name="exclusion"
                            placeholder="Choose Exclusion"
                            options={exclusionsOptions}
                            value={campaign.exclusion}
                            onChange={(e: any) =>
                              setCampaign({ ...campaign, exclusion: e.target.value })
                            }
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="manager" className="form-label mai-label-sharewith">
                            Campaign Manager{" "}
                            <img
                              src={mai_question_icon}
                              alt="choose manager"
                              className="img-fluid ms-1"
                            />
                          </label>
                          <AutoComplete
                            className="form-control chooseaudience_input"
                            placeholder="Choose Manager"
                            value={selectedManager}
                            suggestions={filteredManagers}
                            completeMethod={searchManager}
                            field="name"
                            onChange={(e: any) => {
                              if (e.value.email !== undefined) {
                                setCampaign({ ...campaign, manager: e.value.email });
                                setSelectedManager(e.value.name);
                              } else {
                                setCampaign({ ...campaign, manager: "" });
                                setSelectedManager(e.value);
                              }
                            }}
                            aria-label="choosemanager"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-4">
                        <div className="form-check form-check-inline ps-0">
                          <label className="form-check-label mai-label-access">Access</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input mai-radio-button"
                            type="radio"
                            name="inlineRadioOptions"
                            id="public"
                            value="Public"
                            checked={campaign.is_private === false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCampaign({ ...campaign, is_private: false });
                              setSelectedUsers([]);
                            }}
                          />
                          <label className="form-check-label mai-radio-label" htmlFor="public">
                            Public
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input mai-radio-button"
                            type="radio"
                            name="inlineRadioOptions"
                            id="private"
                            value="Private"
                            checked={campaign.is_private === true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setCampaign({ ...campaign, is_private: true })
                            }
                          />
                          <label className="form-check-label mai-radio-label" htmlFor="private">
                            Private
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                    <div className="col-lg-5 col-md-5"></div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      {campaign.is_private && (
                        <div className="mb-4">
                          <label htmlFor="sharewith" className="form-label mai-label-sharewith">
                            Share with <span> Optional</span>
                          </label>
                          <AutoComplete
                            id="Campaign_general_user"
                            className="form-control"
                            placeholder="Search users and add"
                            value={selectedUsers}
                            suggestions={filteredUsers}
                            completeMethod={searchUser}
                            field="name"
                            multiple
                            onChange={(e: any) => {
                              setSelectedUsers(e.value);
                              setCampaign({
                                ...campaign,
                                can_be_accessed_by: [...e.value.map((item: any) => item.id)],
                              });
                            }}
                            aria-label="User"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                    <div className="col-lg-5 col-md-5"></div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-0">
                        <label htmlFor="chooseaudience" className="form-label mai-form-label">
                          Choose audience{" "}
                          {" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="mb-0">
                        <AutoComplete
                          className="form-control chooseaudience_input"
                          placeholder="choose audience"
                          value={selectedAudience}
                          suggestions={filteredAudienceList}
                          completeMethod={searchAudience}
                          field="name"
                          onChange={(e: any) => {
                            if (e.value.id !== undefined) {
                              setCampaign({ ...campaign, audience: e.value.id });
                              setSelectedAudience(e.value);
                            } else {
                              setCampaign({ ...campaign, audience: "" });
                              setSelectedAudience(e.value);
                            }
                          }}
                          aria-label="chooseaudience"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                      <div className="createcampaign-or">
                        <span> OR</span>
                      </div>
                      <div className="campaignbutton">
                        <NavLink
                          to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}`}
                          className="createbutton"
                          onClick={() => headerTitleChangeHandler("Create Audience")}
                        >
                          <img src={mai_plus_solid_blue} alt="plus" className="img-fluid" /> Create
                          audience
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="rules__border-top">
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <button
                          className="btn btn-primary mai-cancel-button"
                          onClick={cancelHandler}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                        <button
                          type="submit"
                          className="btn btn-primary mai-next-button"
                          disabled={
                            campaign.name.trim() !== "" &&
                              campaign.description.trim() !== "" &&
                              campaign.audience !== "" &&
                              campaign.exclusion !== "" &&
                              campaign.manager !== "" &&
                              campaign.start_date &&
                              campaign.end_date &&
                              validDate
                              ? false
                              : true
                          }
                          data-backdrop="static"
                          data-keyboard="false"
                          data-bs-toggle="modal"
                          data-bs-target="#campaignsuccesspopup"
                        >
                          {params.cid ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Loader>

      {/* Create Campaign Success Popup */}
      <div
        className={`modal fade filter-popup optimizationPopup ${showModal ? "show" : ""}`}
        style={{ display: `${showModal ? "block" : "none"}` }}
        data-keyboard="false"
        data-backdrop="static"
        id="campaignsuccesspopup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">
                Success!
              </h1>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <p className="title16 mb-0">
                    Your new campaign has been {params.cid ? "Updated" : "Created"}.
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-secondary mai-cancel-button bg-transparent" data-bs-dismiss="modal">Cancel</button> */}
              <button
                type="button"
                onClick={() =>
                  navigate(
                    `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}/${Url.CAMPAIGN_LIST}`
                  )
                }
                className="btn btn-primary mai-next-button"
                data-bs-dismiss="modal"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CampaignGeneralDetailsComponent;