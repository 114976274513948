import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import './CampaignList.scss';

import { mai_search_bar, mai_plus} from '../../../../../assets/images/index';
import { Dropdown } from 'primereact/dropdown';
import { NavLink, useNavigate } from 'react-router-dom';
import { Url } from "../../../../../models/constants";
import Loader from '../../../../../shared/components/loader/Loader';
import { Paginator } from 'primereact/paginator';
import { useDispatch } from 'react-redux';
import useHttp from '../../../../../hooks/use-http';
import { campaignCreatorListResponse, CampaignListResponse, Columns } from '../../../../../models/types';
import { StoreActions } from '../../../../../store/actions';
import AuthenticationService from '../../../../../services/authentication.service';
import CustomTooltip from '../../../../../shared/components/customtooltip/CustomTooltip';
import { UserAvatar } from '../../../../../shared/components/userAvatar/userAvatar';
import { Menu } from 'primereact/menu';
import DataTable from '../../../../../shared/components/datatable/DataTable';
import CampaignLensService from '../../../../../services/campaignlens.service';

const CampaignListComponent = () => {
    const menu = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<any>([]);
    const [first, setFirst] = useState(1);
    const [rows, setRows] = useState(10);
    const [tableDataCount, setTableDataCount] = useState<any>(0);
    const [searchKey, setSearchKey] = useState<string>("");
    const [creatorKey, setCreatorKey] = useState<string>("");
    const [allCreators, setAllCreators] = useState<{ [key: string]: { [key: string]: string | number } }>({});
    const [creatorList, setCreatorList] = useState<{ id: string, name: string }[]>([]);
    const { sendRequest: getCampaigns, loading: getCampaignsLoader } = useHttp();
    const { sendRequest: getCreators} = useHttp();
    
    const [selectedStatus, setSelectedStatus] = useState<any>(null);

    const onStatusChange = (e: { value: any }) => {
        setSelectedStatus(e.value);
        getCampaignList('', '', e.value.code);
    }

    const status = [
        { name: 'Pre-Campaign', code: 'pre campaign' },
        { name: 'On-Going', code: 'on going campaign' },
        { name: 'Post-Campaign', code: 'post campaign' }
    ];
    
    const getCampaignList = useCallback((
        searchText = "",
        createdBy = "",
        status = "",
        page = 0,
        sortBy = "modified_on",
        sortOrder = -1
      ) => {
        const resHandler = (res: CampaignListResponse) => {
          setTableDataCount(res.total);
          setTableData(res.campaigns);
        };
        getCampaigns(
          CampaignLensService.getCampaignList({
            page: page + 1,
            search: searchText,
            status: status,
            created_by: createdBy,
            sort_by: sortBy,
            sort_order: sortOrder,
          }),
          resHandler
        );
      }, [getCampaigns]);
    
      useEffect(() => {
        getCampaignList();
      }, [getCampaignList]);

      useEffect(() => {
        const resHandler = async (res: campaignCreatorListResponse) => {
          const creatorobj: any = {};
          await Promise.allSettled(
            res.creators.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { creatorobj[id] = usr.user; creatorobj[usr.user.email] = usr.user }))
          );
          setAllCreators(creatorobj);
          const creatorsres = res.creators.filter((id: string) => creatorobj[id]).map((id: string) => ({ id: id, name: creatorobj[id].name }));
          setCreatorList([{id: "", name: "All"}, ...creatorsres]);
        };
        getCreators(CampaignLensService.getCampaignCreatorList(), resHandler);
      }, [getCreators]);

      const headerTitleChangeHandler = (content: any) => {
        const payload = { title: content };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
      const pageChangeHandler = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        getCampaignList(searchKey, creatorKey, selectedStatus, event.page);
      };

      const isCellSelectable = (event: any) => {
        const data = event.data;
        return data.field === "actions" ? false : true;
      };

      const statusBodyTemplate = (rowData: any) => {
        let status: string = "Pre-Campaign";
        const startDate: Date | undefined = new Date(rowData.start_date);
        const endDate: Date | undefined = new Date(rowData.end_date);
        if (startDate && endDate && startDate?.getTime() < new Date().getTime() && endDate?.getTime() > new Date().getTime()) {
          status = "On-Going";
        } else if (endDate && endDate?.getTime() < new Date().getTime()) {
          status = "Post-Campaign";
        }

        return (
          <span className={`status-badge status-${status.toLowerCase()}`}>
            {status}
          </span>
        );
      };
      const createdByBodyTemplate = ({ creator, ...row }: any) => {
        const data: { id: string, name?: string } = { id: creator, name: undefined };
        if (allCreators[data.id]) {
          data.name = String(allCreators[creator].name);
        }
    
        return (
          <UserAvatar
            id={data.id}
            name={data.name}
            size={"normal"}
          />
        );
      };
    
      const descriptionBodyTemplate = (rowData: any) => {
        return (
          <>
            {rowData.description.length > 20 ? (
              <CustomTooltip
                position="bottom"
                content={rowData.description}
                containerClass="tooltipStyleChange"
              >
                {`${rowData.description.substring(0, 20)}...`}
              </CustomTooltip>
            ) : (
              rowData.description
            )}
          </>
        );
      };

      const actionBodyTemplate = (rowData: any) => {
        let items = [
          {
            label: "Contact Strategy",
            command: () => console.log("export"),
          },
          {
            label: "A/B Testing",
            command: () => console.log("mail"),
          },
          {
            label: "Campaign Monitoring",
            command: () => console.log("sharewith"),
          },
          {
            label: "Campaign ROI",
            command: () => console.log("view"),
          },
        ];
        return (
          <>
            <Menu model={items} popup ref={menu} id="popup_menu" />{" "}
            <i
              className="pi pi-ellipsis-v"
              // @ts-ignore
              onClick={(event: any) => menu?.current?.toggle(event)}
            ></i>
          </>
        );
      };

      const sharedWithBodyTemplate = (rowData: any) => {
        let returnElem = <></>;
        let sharedElem = rowData.can_be_accessed_by
          .slice(0, 3)
          ?.map((usrId: string) => allCreators[usrId] ? <UserAvatar id={usrId} name={allCreators[usrId].name} size={"normal"} /> : <UserAvatar id={usrId}  size={"normal"} />);
    
        let sharedrestElem = rowData.can_be_accessed_by
          .slice(3, rowData.can_be_accessed_by.length)
          ?.map((usrId: string) => allCreators[usrId] ? <UserAvatar id={usrId} name={allCreators[usrId].name} size={"normal"} /> : <UserAvatar id={usrId}  size={"normal"} />);
    
        let allSharedElem = sharedElem.concat(sharedrestElem);
    
        if (rowData.can_be_accessed_by.length > 3) {
          returnElem = (
            <>
              <CustomTooltip position="bottom" content={allSharedElem} containerClass="tooltipStyleChange">
                {sharedElem} + {rowData.can_be_accessed_by.length - 3}
              </CustomTooltip>
            </>
          );
        } else {
          returnElem = <>{sharedElem}</>;
        }
    
        return returnElem;
      };
    
      const startDateBodyTemplate = (rowData: any) => {
    
        let dateStr = new Date(rowData.start_date);
        return dateStr.toLocaleDateString();
        // "05/08/2020"
      }
      const columns: Columns[] = [
        { field: "name", header: "Campaign Name", sort: true },
        {
          field: "description",
          header: "Description",
          body: descriptionBodyTemplate,
          sort: false,
        },
        {
          field: "creator",
          header: "Created by",
          body: createdByBodyTemplate,
          sort: false,
        },
        {
          field: "can_be_accessed_by",
          header: "Shared with",
          body: sharedWithBodyTemplate,
          sort: false,
        },
        {
          field: "start_date",
          header: "Start date",
          body: startDateBodyTemplate,
          sort: true,
        },
        {
          field: "phase",
          header: "Phase",
          body: statusBodyTemplate,
          sort: false,
        },
        {
          field: "actions",
          header: "Actions",
          body: actionBodyTemplate,
          sort: false,
        },
      ];
    
      const campaignTextSearchHandler = (
        event: React.FormEvent<HTMLInputElement>
      ) => {
        event.preventDefault();
        const patt = /[^A-Za-z 0-9]/g;
        if (!(patt.test(event.currentTarget.value))) {
          setSearchKey(event.currentTarget.value);
          getCampaignList(event.currentTarget.value);
        }
      };
    
      const onSelectionHandler = (event: any) => {
        headerTitleChangeHandler("Create Campaign");
        navigate(
          `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}/${Url.CAMPAIGN_CREATE}/${Url.CAMPAIGN_CREATE_GENERAL}/${event.rowData.id}`
        );
      };
      
      const creatorChangeHandler = (event: any) => {
        setCreatorKey(event.value);
        getCampaignList("", event.value?.id ?? '');
      };
    
      const tableConfig: any = {
        selection: { mode: "single", handler: onSelectionHandler },
        isDataSelectable: isCellSelectable,
      };
    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form audiencelist whiteboxlist-height'>
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input
                                                type="search"
                                                placeholder="Search"
                                                aria-describedby="button-addon4"
                                                className="form-control border-0"
                                                onChange={campaignTextSearchHandler}
                                                value={searchKey}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="selectlist-wrap">
                                        <div className='selectlist'>
                                            <label>Created by</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={creatorKey} options={creatorList} onChange={creatorChangeHandler} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                        <div className='selectlist'>
                                            <label>Status</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                            <div className="welcome__create text-end">
                                <NavLink 
                                    to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}/${Url.CAMPAIGN_CREATE}/${Url.CAMPAIGN_CREATE_GENERAL}`} 
                                    className="welcome__create-anchor" 
                                    onClick={() => headerTitleChangeHandler('Create Campaign')}
                                >
                                    <img
                                        src={mai_plus}
                                        alt="plus-icon"
                                        className="img-fluid welcome__plus-icon"
                                    />{" "}
                                    Create Campaign
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="card list-datatable campaign-list border-0">
                        {getCampaignsLoader ?
                            <Loader></Loader>
                            : <>
                            <DataTable
                                data={tableData}
                                columns={columns}
                                config={tableConfig}
                            ></DataTable>
                            </>
                        }
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={tableDataCount}
                            onPageChange={pageChangeHandler}
                            className="justify-content-end listpagination"
                        ></Paginator>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CampaignListComponent