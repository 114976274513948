import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import './CustomerSegmentationCreate.scss';

const CustomerSegmentationCreateComponent = () => {
    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}

export default CustomerSegmentationCreateComponent;