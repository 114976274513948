import { features } from "process";
import { AudienceBuilderTabs, CampaignLensFeatureNames, CustomerLensFeatureNames, CustomerSegmentationTabs } from "../models/constants";
import { Action, StoreActions } from "./actions";

export interface UserAuthGroup {
    id: number;
    name: string;
    permissions: number[];
}

export interface UserPermission {
  id: number;
  feature_name: string;
  codename: string;
}

export interface DataMapping {
    key: string;
    label: string;
    properties: object;
  }
  
export type DataCategories = 'firmographics' | 'customer_profile' | 'transaction';
export type TemplateTypes = 'customer' | 'transaction' | 'product';
export type DataTemplate = {
    [k in TemplateTypes]: DataMapping[];
  } & {
    categories: { [k in DataCategories]: string[] };
  };
  
export interface PageFilter {
  key: string;
  label: string;
  enable: boolean;
}
export interface CampaignLensDropdown {
  label: string;
  value: string;
}
export interface CustomerAttribute {
  key: string;
  label: string;
  aggFunc?: string;
}

export type CustomerLensFeatures = {
  [CustomerLensFeatureNames.AUDIENCE_BUILDER]: {
    [AudienceBuilderTabs.INSIGHTS]: {
      filters: PageFilter[];
    };
    [AudienceBuilderTabs.CUSTOMERS]: {
      searchFields: string[];
      attributes: CustomerAttribute[];
    };
  };
  [CustomerLensFeatureNames.CUSTOMER_SEGMENTATION]: {
    [CustomerSegmentationTabs.SEGMENT_PERSONAS]: {
      filters: PageFilter[];
    };
  };
};

export type CampaignLensFeatures = {
  [CampaignLensFeatureNames.CREATE_CAMPAIGN]: {
    successCriteriaOptions: CampaignLensDropdown[];
    exclusionsOptions: CampaignLensDropdown[];
  };
  [CampaignLensFeatureNames.CONTACT_STRATEGY]: {
    strategyOptions: CampaignLensDropdown[];
    kpiOptions: CampaignLensDropdown[];
  };
};
  

export interface LoggedInUser {
    id: string;
    email: string;
    username: string;
    name: string;
    leader: boolean;
    seperated: boolean;
    admin: boolean;
    roles: UserAuthGroup[]
    permissions: UserPermission[]
    loginMethod?: 'Local' | 'SSO';
    organization?: string;
    tenant?: string;
    features?: {
        logo?: string;
        showTeanantName?: boolean;
        customerLens?: CustomerLensFeatures;
        campaignLens?: CampaignLensFeatures;
    };
    dataTemplate: DataTemplate;
    
}

export const defaultUser = {
    id: '',
    email: '',
    username: '',
    name: '',
    leader: false,
    seperated: false,
    admin: false,
    tenant: '',
    organization: '',
    roles: [] as UserAuthGroup[],
    permissions: [] as UserPermission[]
} as LoggedInUser;

export const extractUser = (res: any): LoggedInUser => ({
    id: res.id,
    email: res.email,
    username: res.username,
    name: res.name || res.full_name || res.first_name,
    admin: !!(res.is_staff || res.is_superuser),
    seperated: !!res.is_seperated,
    leader: !!res.is_leader,
    roles: res.is_part_of,
    permissions: res.is_authorized_to,
    organization: res.organizationName ,
    tenant: res.tenant,
    features: res.features,
    dataTemplate: res.dataTemplate,
});

export function currentUserReducer(state: LoggedInUser = {...defaultUser}, action: Action<LoggedInUser>) {
    switch(action.type) {
        case StoreActions.UPDATE_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}