import { useCallback, useEffect, useState } from "react";

const useDebounce = (callback: (value: string) => void, delay: number = 300) => {
  const [timer, setTimer] = useState<any>(null);

  const debouncedCallback = useCallback(
    (value: string) => {
      if (timer) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout(() => {
        callback(value);
      }, delay);
      setTimer(newTimer);
    },
    [callback, delay, timer]
  );

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return debouncedCallback;
};

export default useDebounce;
