import { Fragment, useCallback, useRef } from "react";
import React, { useState, useEffect } from "react";
import { Menu } from "primereact/menu"
import {
  mai_search_bar,
  mai_plus,
  mai_share_icon,
  mai_back_icon,
} from "../../../../../assets/images/index";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastSeverity, Url } from "../../../../../models/constants";
import useHttp from "../../../../../hooks/use-http";
import ApiService from "../../../../../services/api.service";
import DataTable from "../../../../../shared/components/datatable/DataTable";
import { SegmentationListResponse, SegmentationDetail, Columns, creatorListResponse } from "../../../../../models/types";
import { Paginator } from "primereact/paginator";
import AuthenticationService from "../../../../../services/authentication.service";
import "./SegmentationListComponent.scss";
import Loader from "../../../../../shared/components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { StoreActions } from "../../../../../store/actions";
import { Dropdown, DropdownChangeEvent as DropdownChangeParams } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import UtilService from "../../../../../services/util.service";
import { Tooltip } from 'primereact/tooltip';
import useDebounce from "../../../../../hooks/DebounceHook";
import { ConfirmDialog } from "primereact/confirmdialog";
import { StoreState } from "../../../../../store/store";

const SegmentationListComponent = () => {
  const menu = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayModal, setDisplayModal] = useState(false);
  const [deleteSegmentationID, setDeleteSegmentationID] = useState<string>("");
  const [tableData, setTableData] = useState<any>([]);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [tableDataCount, setTableDataCount] = useState<any>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [creatorKey, setCreatorKey] = useState<{ code: string, name: string }>({ name: 'All', code: '' });
  const [allCreators, setAllCreators] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [sharedByUsers, setSharedByUsers] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [creatorList, setCreatorList] = useState<{ code: string, name: string }[]>([]);
  const { sendRequest: getSegmentations, loading: getSegmentationsLoader } = useHttp();
  const { sendRequest: getCreators } = useHttp();
  const { sendRequest: deleteSegmentation, loading: deleteSegmentationLoader } = useHttp();
  const [selectedStatus, setSelectedStatus] = useState<{ code: string, name: string }>({ code: '', name: 'All' });
  const [menuItems, setMenuItems] = useState<any>([]);
  const status = [
    { name: 'All', code: '' },
    { name: 'Published', code: 'True' },
    { name: 'Draft', code: 'False' }
  ];
  const getSegmentationList = useCallback((
    searchText = "",
    createdBy = "",
    published = '',
    page = 0,
    sortBy = "modified_on",
    sortOrder = -1
  ) => {
    const resHandler = async (res: SegmentationListResponse) => {
      setTableDataCount(res.total);
      setTableData(res.segmentation);
      const sharedUsers: string[] = [];
      res.segmentation.forEach((item: SegmentationDetail) => sharedUsers.push(...item.can_be_accessed_by));
      const uniqueSharedUsers = [...Array.from(new Set(sharedUsers))];
      const sharedUsersObj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        uniqueSharedUsers.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { sharedUsersObj[id] = usr.user }).catch((err: any) => console.error(err)))
      );
      setSharedByUsers(sharedUsersObj);
    };
    getSegmentations(
      ApiService.getSegmentationList({
        page: page + 1,
        search: searchText,
        created_by: createdBy,
        sort_by: sortBy,
        sort_order: sortOrder,
        published
      }),
      resHandler
    );
  }, [getSegmentations]);
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Segmentation List")
      headerTitleChangeHandler("Segmentation List",'','');
  })
  useEffect(() => {
    getSegmentationList();
  }, [getSegmentationList]);

  useEffect(() => {
    const resHandler = async (res: creatorListResponse) => {
      const creatorobj: { [key: string]: { [key: string]: string } } = {};
      await Promise.all(
        res.creator.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { creatorobj[id] = usr.user }).catch((err: any) => console.error(err)))
      );
      setAllCreators(creatorobj);
      const creatorsres: { code: string, name: string }[] = res.creator.filter((id: string) => !!creatorobj[id]).map((id: string) => ({ code: id, name: creatorobj[id].name }));
      creatorsres.unshift({ name: 'All', code: '' });
      setCreatorList(creatorsres);
    };
    getCreators(ApiService.getSegmentationCreatorList(), resHandler);
  }, [getCreators]);

  const onSelectionHandler = (event: any) => {
    if (event.rowData.is_published === true) {
      headerTitleChangeHandler(event?.rowData?.name,`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`,'#');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_DETAILS}/${event.rowData.id}`
      );
    } else {
      headerTitleChangeHandler('Create Segmentation','','');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/${event.rowData.id}`
      );
    }
  };

  const isCellSelectable = (event: any) => {
    const data = event.data;
    return data.field === "actions" ? false : true;
  };
  const statusBodyTemplate = (rowData: any) => {
    let status: string = "Draft";
    if (rowData.is_published) {
      status = "Published";
    }
    return (
      <span className={`customer-badge status-${status.toLowerCase()}`}>
        {status}
      </span>
    );
  };

  const createdByBodyTemplate = ({ creator, ...row }: any) => {
    return renderAvatar(creator, allCreators);
  };
  const nameBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.name.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listtooltip${rowData.id}`} position="bottom">
              {rowData.name}
            </Tooltip>
            <div className={`listtooltip${rowData.id}`}>
              {`${rowData.name.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.name
        )}
      </>
    );
  };
  const descriptionBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.description.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listdesctooltip${rowData.id}`} position="bottom">
              {rowData.description}
            </Tooltip>
            <div className={`listdesctooltip${rowData.id}`}>
              {`${rowData.description.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.description
        )}
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    let items = [{}];
    const updateMenuItemsHandler = (rowData: any) => {
      if (rowData.is_published === true) {
        items.push({
          label: "Create CLV",
          icon: "pi pi-plus-circle",
          command: () => {
            headerTitleChangeHandler('Create CLV','','');
            navigate(
              `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.SEGMENTATION}/${rowData.id}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}`
            );
          },
        });
        items.push({
          label: "Create recommendation",
          icon: "pi pi-plus-circle",
          command: () => {
            headerTitleChangeHandler('Create Recommendation','','');
            navigate(
              `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.RECOMMENDATIONS_CREATE_GENERAL}/${Url.SEGMENTATION}/${rowData.id}`
            );
          },
        });
      }
      items.push(
        {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            headerTitleChangeHandler('Create Segmentation','','');
            navigate(
              `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}/${rowData.id}`
            );
          }
        })
      items.push(
        {
          label: "Export",
          icon: "pi pi-plus-circle",
          command: () => { },
        })
      items.push(
        {
          label: "Mail .csv",
          icon: "pi pi-envelope",
          command: () => { },
        })
      items.push(
        {
          label: "Share With",
          icon: "pi pi-share-alt",
          command: () => { },
        })
      items.push(
        {
          label: "View history",
          icon: "pi pi-history",
          command: () => { },
        })
      items.push(
        {
          label: "Delete",
          icon: "pi pi-trash",
          command: () => { setDisplayModal(true);},
        })
      setMenuItems(items);
    }
    return (
      <>
        <Menu model={menuItems} popup ref={menu} id="popup_menu" />{" "}
        <i
          className="pi pi-ellipsis-v"
          // @ts-ignore
          onClick={(event: any) => { menu?.current?.toggle(event); setDeleteSegmentationID(rowData.id); updateMenuItemsHandler(rowData) }}
        ></i>
      </>
    );
  };

  const sharedWithBodyTemplate = (rowData: any) => {
    let returnElem = <></>;
    let sharedElem = rowData.can_be_accessed_by
      .slice(0, 3)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let sharedrestElem = rowData.can_be_accessed_by
      .slice(3, rowData.can_be_accessed_by.length)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let allSharedElem = sharedElem.concat(sharedrestElem);

    if (rowData.can_be_accessed_by.length > 3) {
      returnElem = (
        <>
          {/* <CustomTooltip position="bottom" content={allSharedElem} containerClass="tooltipStyleChange">
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </CustomTooltip> */}


          <Tooltip className="list-tooltip-wrap" target={`.sharewithtooltip${rowData.id}`} position="bottom">
            {allSharedElem}
          </Tooltip>
          <div className={`sharewithtooltip${rowData.id}`}>
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </div>
        </>
      );
    } else {
      returnElem = <>{sharedElem}</>;
    }

    return returnElem;
  };

  const lastupdatedBodyTemplate = (rowData: any) => {

    let dateStr = new Date(rowData.modified_on);
    return dateStr.toLocaleDateString()
    // "05/08/2020"
  }
  const columns: Columns[] = [
    {
      field: "name",
      header: "Name",
      body: nameBodyTemplate,
      sort: true
    },
    {
      field: "description",
      header: "Description",
      body: descriptionBodyTemplate,
      sort: false,
    },
    { field: "customers", header: "Customers", sort: true },
    {
      field: "creator",
      header: "Created By",
      body: createdByBodyTemplate,
      sort: false,
    },
    {
      field: "can_be_accessed_by",
      header: "Shared With",
      body: sharedWithBodyTemplate,
      sort: false,
    },
    {
      field: "modified_on",
      header: "Last Updated",
      body: lastupdatedBodyTemplate,
      sort: true,
    },
    {
      field: "is_published",
      header: "Status",
      body: statusBodyTemplate,
      sort: true,
    },
    {
      field: "actions",
      header: "Actions",
      body: actionBodyTemplate,
      sort: false,
    },
  ];
  const renderAvatar = (userId: string, userData: { [key: string]: { [key: string]: string } }) => {
    const userDetails: { id: string, name: string, label: string, image: string } = { id: userId, name: '', label: '', image: '' };
    if (userData[userId]) {
      userDetails.label = UtilService.getShortName(String(userData[userId].name));
      userDetails.name = String(userData[userId].name);
    }
    return (
      <Avatar key={userId} className={`user-avatar ${UtilService.classes()[userDetails.name.length % 10]}`} label={userDetails.label} image={userDetails.image} shape="circle" size={"normal"} />
    );
  }
  const debouncedCallback = useDebounce((value: string) => {
    // Make your backend API call here using the value
    getSegmentationList(value, creatorKey.code, selectedStatus.code);
  }, 500); // You can customize the delay time here
  const segmentationTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const patt = /[^A-Za-z 0-9]/g;
    if (!(patt.test(event.currentTarget.value))) {
      setSearchKey(event.currentTarget.value);
      debouncedCallback(event.currentTarget.value);
    }
  };
  const creatorChangeHandler = (event: DropdownChangeParams) => {
    event.preventDefault();
    setCreatorKey(event.value);
    getSegmentationList(searchKey, event.value.code, selectedStatus.code);
  };
  const onStatusChange = (event: DropdownChangeParams) => {
    setSelectedStatus(event.value);
    getSegmentationList(searchKey, creatorKey.code, event.value.code);
  }
  const pageChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    getSegmentationList(searchKey, creatorKey.code, selectedStatus.code, event.page);
  };
  const tableConfig: any = {
    selection: { mode: "single", handler: onSelectionHandler },
    isDataSelectable: isCellSelectable,
  };
  const deleteSegmentationCallback = () => {
    const resHandler = (res: any) => {
      dispatch({
        type: StoreActions.TOAST_ON,
        payload: {
          severity: ToastSeverity.SUCCESS,
          summary: "Information",
          detail: res.detail,
          life: 3000,
        },
      });
      getSegmentationList(searchKey, creatorKey.code, selectedStatus.code, 0);
    }
    if (deleteSegmentationID) {
      deleteSegmentation(ApiService.deleteSegmentation(deleteSegmentationID), resHandler);
    }
  }
  return (
    <Fragment>
      <div className="segmentationcreate__form-wrapper">
        <div className="segmentationcreate__form segmentationlist whiteboxlist-height">
          <div className="row mb-3">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="rules__searchbox">
                    <div className="input-group">
                      <div className="input-group-prepend border-0">
                        <button
                          id="button-addon4"
                          type="button"
                          className="btn btn-link text-info"
                        >
                          <img
                            src={mai_search_bar}
                            alt="search"
                            className="img-fluid"
                          />
                        </button>
                      </div>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-describedby="button-addon4"
                        className="form-control border-0"
                        onChange={segmentationTextSearchHandler}
                        value={searchKey}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="selectlist-wrap">
                    <div className='selectlist'>
                      <label>Created by</label>
                      <div className='monitoring-dropdownlist'>
                        <Dropdown value={creatorKey} options={creatorList} onChange={creatorChangeHandler} optionLabel="name" placeholder="All" />
                      </div>
                    </div>
                    <div className='selectlist'>
                      <label>Status</label>
                      <div className='monitoring-dropdownlist'>
                        <Dropdown value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="All" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="welcome__create text-end">
                <NavLink
                  to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}`}
                  onClick={() => headerTitleChangeHandler('Create Segmentation','','')}
                  className="welcome__create-anchor"
                >
                  <img
                    src={mai_plus}
                    alt="plus-icon"
                    className="img-fluid welcome__plus-icon"
                  />{" "}
                  Create Segmentation
                </NavLink>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card list-datatable segmentationlist-datatable listpage-table border-0">
              {getSegmentationsLoader || deleteSegmentationLoader ?
                <Loader style={{ minHeight: "445px" }}></Loader>
                : <>
                  <DataTable
                    data={tableData}
                    columns={columns}
                    config={tableConfig}
                  ></DataTable>
                </>
              }
              <Paginator
                first={first}
                rows={rows}
                totalRecords={tableDataCount}
                onPageChange={pageChangeHandler}
                className="justify-content-end listpagination"
              ></Paginator>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={displayModal}
        onHide={() => {
          setDisplayModal(false);
          setDeleteSegmentationID("");
        }}
        maskClassName="common-popup-wrap"
        header="Are you sure, you want to delete audience?"
        message="Dependent clv, recommendation will get deleted."
        closable={false}
        draggable={false}
        accept={deleteSegmentationCallback}
        reject={() => {
          setDisplayModal(false);
          setDeleteSegmentationID("");
        }} />
    </Fragment>
  );
};

export default SegmentationListComponent;
