import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import './ContactStartegyList.scss';

import { mai_search_bar, mai_plus} from '../../../../../assets/images/index';
import { Dropdown } from 'primereact/dropdown';
import { NavLink, useNavigate } from 'react-router-dom';
import { Url } from "../../../../../models/constants";
import { UserAvatar } from '../../../../../shared/components/userAvatar/userAvatar';
import { useDispatch } from 'react-redux';
import AuthenticationService from '../../../../../services/authentication.service';
import useHttp from '../../../../../hooks/use-http';
import { Columns, ContactStrategyListResponse, contactStrategyCreatorListResponse } from '../../../../../models/types';
import CampaignLensService from '../../../../../services/campaignlens.service';
import CustomTooltip from '../../../../../shared/components/customtooltip/CustomTooltip';
import { Menu } from 'primereact/menu';
import Loader from '../../../../../shared/components/loader/Loader';
import { StoreActions } from '../../../../../store/actions';
import { Paginator } from 'primereact/paginator';
import DataTable from '../../../../../shared/components/datatable/DataTable';
const ContactStartegyListComponent = () => {
    const menu = useRef(null);
    const dispatch = useDispatch();
    // const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<any>([]);
    const [first, setFirst] = useState(1);
    const [rows, setRows] = useState(10);
    const [tableDataCount, setTableDataCount] = useState<any>(0);
    const [searchKey, setSearchKey] = useState<string>("");
    const [creatorKey, setCreatorKey] = useState<string>("");
    const [allCreators, setAllCreators] = useState<{ [key: string]: { [key: string]: string | number } }>({});
    const [creatorList, setCreatorList] = useState<{ id: string, name: string }[]>([]);
    const { sendRequest: getContactStrategy, loading: getContactStrategyLoader } = useHttp();
    const { sendRequest: getCreators} = useHttp();


    
    const getContactStrategyList = useCallback((
        searchText = "",
        createdBy = "",
        page = 0,
        sortBy = "modified_on",
        sortOrder = -1
      ) => {
        const resHandler = (res: ContactStrategyListResponse) => {
          setTableDataCount(res.total);
          setTableData(res.contact_strategy);
        };
        getContactStrategy(
          CampaignLensService.getContactStrategyList({
            page: page + 1,
            search: searchText,
            created_by: createdBy,
            sort_by: sortBy,
            sort_order: sortOrder,
          }),
          resHandler
        );
      }, [getContactStrategy]);
    
      useEffect(() => {
        getContactStrategyList();
      }, [getContactStrategyList]);

      useEffect(() => {
        const resHandler = async (res: contactStrategyCreatorListResponse) => {
          const creatorobj: any = {};
          await Promise.allSettled(
            res.creators.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { creatorobj[id] = usr.user; creatorobj[usr.user.email] = usr.user }))
          );
          setAllCreators(creatorobj);
          const creatorsres = res.creators.filter((id: string) => creatorobj[id]).map((id: string) => ({ id: id, name: creatorobj[id].name }));
          setCreatorList([{id: "", name: "All"}, ...creatorsres]);
        };
        getCreators(CampaignLensService.getContactStrategyCreatorList(), resHandler);
      }, [getCreators]);

      const headerTitleChangeHandler = (content: any) => {
        const payload = { title: content };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
      const pageChangeHandler = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        getContactStrategyList(searchKey, creatorKey, event.page);
      };

      const isCellSelectable = (event: any) => {
        const data = event.data;
        return data.field === "actions" ? false : true;
      };

      const createdByBodyTemplate = ({ creator, ...row }: any) => {
        const data: { id: string, name?: string } = { id: creator, name: undefined };
        if (allCreators[data.id]) {
          data.name = String(allCreators[creator].name);
        }
    
        return (
          <UserAvatar
            id={data.id}
            name={data.name}
            size={"normal"}
          />
        );
      };
    
      const objectiveBodyTemplate = (rowData: any) => {
        return (
          <>
            {rowData.objective.length > 20 ? (
              <CustomTooltip
                position="bottom"
                content={rowData.objective}
                containerClass="tooltipStyleChange"
              >
                {`${rowData.objective.substring(0, 20)}...`}
              </CustomTooltip>
            ) : (
              rowData.objective
            )}
          </>
        );
      };

      const actionBodyTemplate = (rowData: any) => {
        let items = [
          {
            label: "Contact Strategy",
            command: () => console.log("export"),
          },
          {
            label: "A/B Testing",
            command: () => console.log("mail"),
          },
          {
            label: "Campaign Monitoring",
            command: () => console.log("sharewith"),
          },
          {
            label: "Campaign ROI",
            command: () => console.log("view"),
          },
        ];
        return (
          <>
            <Menu model={items} popup ref={menu} id="popup_menu" />{" "}
            <i
              className="pi pi-ellipsis-v"
              // @ts-ignore
              onClick={(event: any) => menu?.current?.toggle(event)}
            ></i>
          </>
        );
      };

      const sharedWithBodyTemplate = (rowData: any) => {
        let returnElem = <></>;
        let sharedElem = rowData.shared_with
          .slice(0, 3)
          ?.map((usrId: string) => allCreators[usrId] ? <UserAvatar id={usrId} name={allCreators[usrId].name} size={"normal"} /> : <UserAvatar id={usrId} size={"normal"} />);
    
        let sharedrestElem = rowData.shared_with
          .slice(3, rowData.shared_with.length)
          ?.map((usrId: string) => allCreators[usrId] ? <UserAvatar id={usrId} name={allCreators[usrId].name} size={"normal"} /> : <UserAvatar id={usrId} size={"normal"} />);
    
        let allSharedElem = sharedElem.concat(sharedrestElem);
    
        if (rowData.shared_with.length > 3) {
          returnElem = (
            <>
              <CustomTooltip position="bottom" content={allSharedElem} containerClass="tooltipStyleChange">
                {sharedElem} + {rowData.shared_with.length - 3}
              </CustomTooltip>
            </>
          );
        } else {
          returnElem = <>{sharedElem}</>;
        }
    
        return returnElem;
      };
    
      const startDateBodyTemplate = (rowData: any) => {
    
        let dateStr = new Date(rowData.start_date);
        return dateStr.toLocaleDateString();
        // "05/08/2020"
      }
      const columns: Columns[] = [
        { field: "campaign_name", header: "Campaign Name", sort: true },
        {
          field: "objective",
          header: "Objective",
          body: objectiveBodyTemplate,
          sort: false,
        },
        {
          field: "creator",
          header: "Created by",
          body: createdByBodyTemplate,
          sort: false,
        },
        {
          field: "shared_with",
          header: "Shared with",
          body: sharedWithBodyTemplate,
          sort: false,
        },
        {
          field: "start_date",
          header: "Start date",
          body: startDateBodyTemplate,
          sort: true,
        },
        {
          field: "actions",
          header: "Actions",
          body: actionBodyTemplate,
          sort: false,
        },
      ];
    
      const textSearchHandler = (
        event: React.FormEvent<HTMLInputElement>
      ) => {
        event.preventDefault();
        const patt = /[^A-Za-z 0-9]/g;
        if (!(patt.test(event.currentTarget.value))) {
          setSearchKey(event.currentTarget.value);
          getContactStrategyList(event.currentTarget.value);
        }
      };
    
      const onSelectionHandler = (event: any) => {
        headerTitleChangeHandler("Create Contact Strategy");
        navigate(
          `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CONTACT_STRATEGY}/${Url.CONTACT_STRATEGY_CREATE}/${Url.CONTACT_STRATEGY_ROADMAP}/${event.rowData.id}`
        );
      };
      
      const creatorChangeHandler = (event: any) => {
        setCreatorKey(event.value);
        getContactStrategyList("", event.value?.id ?? '');
      };
    
      const tableConfig: any = {
        selection: { mode: "single", handler: onSelectionHandler },
        isDataSelectable: isCellSelectable,
      };

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form audiencelist whiteboxlist-height'>
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input
                                                type="search"
                                                placeholder="Search"
                                                aria-describedby="button-addon4"
                                                className="form-control border-0"
                                                onChange={textSearchHandler}
                                                value={searchKey}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="selectlist-wrap">
                                        <div className='selectlist'>
                                            <label>Created by</label>
                                            <div className='monitoring-dropdownlist'>
                                            <Dropdown value={creatorKey} options={creatorList} onChange={creatorChangeHandler} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                            <div className="welcome__create text-end">
                                <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CONTACT_STRATEGY}/${Url.CONTACT_STRATEGY_CREATE}/${Url.CONTACT_STRATEGY_CREATE_GENERAL}`} className="welcome__create-anchor" >
                                    <img
                                        src={mai_plus}
                                        alt="plus-icon"
                                        className="img-fluid welcome__plus-icon"
                                    />{" "}
                                    Create Contact Strategy
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="card list-datatable contactstrategylist-datatable border-0">
                            {getContactStrategyLoader ?
                                <Loader></Loader>
                                : <>
                                <DataTable
                                    data={tableData}
                                    columns={columns}
                                    config={tableConfig}
                                ></DataTable>
                                </>
                            }
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={tableDataCount}
                                onPageChange={pageChangeHandler}
                                className="justify-content-end listpagination"
                            ></Paginator>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ContactStartegyListComponent