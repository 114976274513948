import { Fragment } from 'react';
import './RecommendationSummary.scss';
import {
    mai_web_engagement_icon,
    mai_email_blue_icon,
    mai_revenue_opportunity_icon,
    mai_product_icon,
    mai_growth_icon,
    mai_revenue_icon
} from '../../../../../../assets/images/index';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const RecommendationSummaryComponents = () => {

    const data = [
        { "existingrevenue": "$ 25k", "product": "AJ", "opportunity": "$ 40k", "growth": "13%", "totalcustomer": "134", "conversionrate": "21% +7%", "cac": "$123", "avgchurn": "21% +7%", "avgnps": "4" },
        { "existingrevenue": "$ 120k", "product": "GJ", "opportunity": "$ 10k", "growth": "6%", "totalcustomer": "89", "conversionrate": "13% -2%", "cac": "$ 165", "avgchurn": "8% -2%", "avgnps": "5" },
        { "existingrevenue": "$ 50k", "product": "GH", "opportunity": "$ 110k", "growth": "74%", "totalcustomer": "325", "conversionrate": "67% +7%", "cac": "$ 146", "avgchurn": "6% +7%", "avgnps": "7" },
        { "existingrevenue": "$ 40k", "product": "AJ", "opportunity": "$ 50k", "growth": "34%", "totalcustomer": "125", "conversionrate": "25% -2%", "cac": "$ 178", "avgchurn": "9% +7%", "avgnps": "3" },
        { "existingrevenue": "$ 15k", "product": "AJ", "opportunity": "$ 10k", "growth": "3%", "totalcustomer": "46", "price": 15, "conversionrate": "12% +7%", "cac": "$ 157", "avgchurn": "2% +7%", "avgnps": "4" },
    ]

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Attributes</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active '>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdsummary-wrapper'>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="insights__innerwrapper">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_product_icon} alt="web" className="img-fluid" /> <span> Products</span> </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='insights-data'>
                                        <div><h3>5</h3></div>
                                        <div>
                                            <h2>55,533</h2>
                                            <p>Cohort</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="insights__innerwrapper dropdownmain-wrapper-end">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_revenue_icon} alt="email" className="img-fluid" /> <span> Existing Revenue</span> </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='insights-data'>
                                        <div>
                                            <div className='innertext__size'>
                                                <h2 className='blueshade'>$ 7.8M <span className="increase_horizontal">+7%</span></h2>
                                                <p>2020-2022FY</p>
                                            </div>
                                        </div>
                                        <div></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="insights__innerwrapper dropdownmain-wrapper-end">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_revenue_opportunity_icon} alt="revenue" className="img-fluid" /> <span> Revenue Opportunity</span> </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='insights-data'>
                                        <div>
                                            <div className='innertext__size'>
                                                <h2 className='greenshade mb-2'>$ 2.34M </h2>
                                                <p>Total</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='text-end mb-2'>
                                                <h4 className=''>$ 7.8M </h4>
                                                <p>Upsell</p>
                                            </div>
                                            <div className='text-end'>
                                                <h4 className=''>$ 7.8M </h4>
                                                <p>Cross sell</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="insights__innerwrapper dropdownmain-wrapper-end">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_growth_icon} alt="growth" className="img-fluid" /> <span> Growth Opportunity </span> </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='insights-data'>
                                        <div>
                                            <div className='innertext__size'>
                                                <h2 className='greenshade mb-2'>30%</h2>
                                                <p>Total</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='text-end mb-2'>
                                                <h4 className=''>22%</h4>
                                                <p>Upsell</p>
                                            </div>
                                            <div className='text-end'>
                                                <h4 className=''>20%</h4>
                                                <p>Cross sell</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='audiencecreate__form'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h1 className='titles fs-5'>Products</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card audiencelist-datatable border-0">
                                <DataTable value={data} responsiveLayout="scroll">
                                    <Column field="product" header="Product"></Column>
                                    <Column field="existingrevenue" header="Existing Revenue ($)"></Column>
                                    <Column field="opportunity" header="Opportunity ($)"></Column>
                                    <Column field="growth" header="Growth ($)"></Column>
                                    <Column field="totalcustomer" header="Total Customers (#)"></Column>
                                    <Column field="conversionrate" header="Conversion Rate (%)"></Column>
                                    <Column field="cac" header="CAC ($)"></Column>
                                    <Column field="avgchurn" header="Avg. Churn Rate (%)"></Column>
                                    <Column field="avgnps" header="Avg. NPS (#)"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Back</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Publish</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}

export default RecommendationSummaryComponents;