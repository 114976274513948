import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import * as d3 from 'd3';
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup
} from 'react-simple-maps';
import './MapChart.scss';
import { geoData } from "./GeoData";
import ReactTooltip from "react-tooltip";
import MapChartService from "./MapChartService";
import UtilService from "../../../../../services/util.service";

export type HighlightConfigType = {
    stroke: string;
    stroke_width: string;
    opacity: string;
}

const MapChartComponent = forwardRef((props: any, ref) => {
    const [tooltipContent, setTooltipContent] = useState('');
    const [position, setPosition] = useState<any>({ coordinates: [0, 0], zoom: 1 });
    const countryRegionMappings = [...MapChartService.countryRegionMappings]
    const highlighterConfig: HighlightConfigType = {
        stroke: 'red',
        stroke_width: '1',
        opacity: '1'
    };
    let data: any = [];
    if (props.data) {
        let response = [...props.data];
        response.forEach((res: any) => {
            let mappedData: any = [];
            if (typeof (res.gmov_country) === 'object') {
                mappedData = countryRegionMappings.filter((mapping: any) => res.gmov_country.includes(mapping.gmov_country));
            } else {
                mappedData = countryRegionMappings.filter((mapping: any) => mapping.gmov_country === res.gmov_country);
            }
            // const mappedData = countryRegionMappings.find((mapping: any) => mapping.gmov_country === res.gmov_country);
            if (mappedData.length) {
                mappedData.forEach((mData: any) => {
                    if (mData.countries.length) {
                        const fullData = mData.countries.map((item: any) => ({ ...item, ...res }));
                        data.push(...fullData);
                    }
                })

            }

        });
    }
    let colorScale: any = d3.scaleLinear();
    const dataValues: any = [...data.map((item: any) => item.value)];
    colorScale.domain([Math.min(...dataValues), Math.max(...dataValues)]).range(["#a38eab", "#2C1336"]);
    const handleMoveEnd = (position: any) => {
        setPosition(position);
    }
    useImperativeHandle(ref, () => ({
        handleZoomIn() {
            if (position.zoom >= 7) return;
            setPosition((pos: any) => ({ ...pos, zoom: pos.zoom * 1.5 }));
        },
        handleZoomOut() {
            if (position.zoom <= 1) return;
            setPosition((pos: any) => ({ ...pos, zoom: pos.zoom / 1.5 }));
        },
        handleCountryHighlight(country: string | Array<string>) {
            resetMapCountryHighlights();
            let mappedData: any = [];
            if (typeof (country) === 'object') {
                mappedData = countryRegionMappings.filter((mapping: any) => country.includes(mapping.gmov_country));
            } else {
                mappedData = countryRegionMappings.filter((mapping: any) => mapping.gmov_country === country);
            }
            console.log(country, mappedData);
            groupSelectGeographyHighlights(mappedData);
        },
        resetMap() {
            setPosition((pos: any) => ({ coordinates: [0, 0], zoom: 1 }));
            resetMapCountryHighlights(1);
        }
    }))
    const geographyClickHandler = (event: any, selectedData: any, geoId: string) => {
        console.log('entered', selectedData);
        console.log(event, event.ctrlKey);
        if (!selectedData) {
          return;
        }
        resetMapCountryHighlights();
        selectGeographyHighlights(geoId, highlighterConfig);
        if (selectedData) {
            let _mappedData = [];
            if (props.mappingTabIndex) {
                _mappedData = countryRegionMappings.filter((mapping: any) => mapping.gmov_country === selectedData.gmov_country);
            } else {
                _mappedData = countryRegionMappings.filter((mapping: any) => mapping.gmov_region === selectedData.gmov_region);
            }
            groupSelectGeographyHighlights(_mappedData);
            props.tileSelectionHandler(selectedData, geoId);
        }
    }
    const selectGeographyHighlights = (id: string, config: HighlightConfigType) => {
        console.log(id, config);
        const elementId: any = document.getElementById(`geo_${id}`);
        if (elementId) {
            elementId.attributes.getNamedItem('stroke').value = config.stroke;
            elementId.attributes.getNamedItem('stroke-width').value = config.stroke_width;
            elementId.attributes.getNamedItem('opacity').value = config.opacity;
        }
    }
    const resetMapCountryHighlights = (key = 0) => {
        const config: HighlightConfigType = {
            stroke: '#fff',
            stroke_width: '1',
            opacity: key ? '1' : '0.3'
        };
        geoData.objects.world.geometries.forEach((item: any) => {
            selectGeographyHighlights(item.id, config);
        });
    }
    const groupSelectGeographyHighlights = (mappedData: any) => {
        if (mappedData.length) {
            mappedData.forEach((mData: any) => {
                if (mData.countries.length) {
                    console.log(mData.countries);
                    mData.countries.forEach((country: any) => {
                        selectGeographyHighlights(country.id, highlighterConfig);
                    });
                }
            })

        }
    }
    useEffect(()=> {
        props.onPositionChange(position);
    }, [position]);

    return (
        <>
            <div data-tip="">
                <ComposableMap className="mapheight" projection="geoMercator" projectionConfig={{ scale: 100 }}>
                    <ZoomableGroup
                        zoom={position.zoom}
                        center={position.coordinates}
                        onMoveEnd={handleMoveEnd}
                    >
                        <Geographies geography={geoData}>
                            {({ geographies }) =>
                                geographies.map((geo: any, index: number) => {
                                    const d = data.find((s: any) => s.id === geo.id);
                                    const tooltipData = d
                                        ? props.mappingTabIndex ? `${d.gmov_country}, $${UtilService.numberFormatter(Math.round(d.value), 'M')}` : `${d.gmov_region}, $${UtilService.numberFormatter(Math.round(d.value), 'M')}`
                                        : ''   // geo.properties.name 
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            id={`geo_${geo.id}`}
                                            fill={d ? `${colorScale(d.value)}` : "#F6F6F6"}
                                            stroke="#fff"
                                            strokeWidth="0.3"
                                            opacity="1"
                                            onMouseOver={() => setTooltipContent(tooltipData)}
                                            onMouseLeave={() => setTooltipContent('')}
                                            onClick={(event: any) => geographyClickHandler(event, d, geo.id)}

                                            style={{
                                                default: { outline: "none" },
                                                hover: { outline: "none" },
                                                pressed: { outline: "none" }
                                            }}
                                        />
                                    )
                                }
                                )
                            }
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
            </div>
            <ReactTooltip multiline={true}>{tooltipContent}</ReactTooltip>
        </>
    );
});

export default MapChartComponent;