import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import './Rules.scss';
import { MultiSelect } from 'primereact/multiselect';
import {
    mai_cross,
    mai_tick,
    mai_plus_solid_blue,
    mai_white_cross,
    mai_light_red_cross,
    mai_gray_dots,
    mai_blue_dots,
    mai_arrow_right,
    mai_search_bar
} from '../../../../../../assets/images/index'
import { useNavigate, useParams } from 'react-router-dom';
import { Url } from '../../../../../../models/constants';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import Loader from '../../../../../../shared/components/loader/Loader';
import UtilService from '../../../../../../services/util.service';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { StoreState } from '../../../../../../store/store';

const RulesComponent = () => {
    let navigate = useNavigate();
    let params = useParams();
    let audienceId: string | undefined = params.aid;
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();
    let operatorValueRef = useRef<HTMLInputElement>(null!);
    let searchRef = useRef<HTMLInputElement>(null!);
    const [disableCheck, setDisableCheck] = useState(false);
    const { sendRequest: getRulesColumns } = useHttp();
    const { sendRequest: getExistingRules, loading: fetchRulesLoader } = useHttp();
    const { sendRequest: getVariablePopupDataset } = useHttp();
    const { sendRequest: onCreateRule, loading: createRuleLoader } = useHttp();
    const [columnDataSet, setColumnDataset] = useState<any>();
    const [filteredCcolumnDataSet, setFilteredColumnDataset] = useState<any>();
    const [includeRuleSet, setIncludeRuleSet] = useState([
        [

            {
                field: "",
                label: '',
                value: {},
                operator: "null"
            }

        ]
    ]);
    const [excludeRuleSet, setExcludeRuleSet] = useState([
        [
            {
                field: "",
                label: '',
                value: {},
                operator: "null"
            }
        ]
    ]);
    const [popupDataSet, setPopupDataSet] = useState<any>({
        variable: {
            field: '',
            label: '',
            value: {},
            operator: ''
        },
        field: '',
        gindex: '',
        vindex: '',
        available_operators: [
            { label: 'Less Than', value: 'lt' },
            { label: 'Greater Than', value: 'gt' },
            { label: 'Less Than Equal', value: 'lte' },
            { label: 'Equals', value: 'eq' }
        ],
        value_set: []
    });
    const [selectedOperator, setSelectedOperator] = useState<any>({});
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const [multipleSelectedValues, setMultipleSelectedValues] = useState<any>([]);
    const getColumns = useCallback(() => {
        const resHandler = (res: any) => {
            const dataSet: any = {
                customer_profile: [],
                firmographics: [],
                transactions: []
            };
            dataSet.customer_profile = res.columns.filter((item: any) => item.category === 'customer_profile').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            dataSet.firmographics = res.columns.filter((item: any) => item.category === 'firmographics').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            dataSet.transactions = res.columns.filter((item: any) => item.category === 'transaction').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            setColumnDataset(dataSet);
            setFilteredColumnDataset(dataSet);
        }
        getRulesColumns(ApiService.getRulesColumnsLlist(), resHandler);
    }, [getRulesColumns]);
    const dispatch=useDispatch();
    const header = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const fetchExistingRules = useCallback(() => {
        const resHandler = (res: any) => {
            // If not a valid audience id
            if (Object.keys(res?.rules).length > 0 && res?.rules?.include.length > 0) {
                setIncludeRuleSet(res?.rules?.include);
            }
            if (Object.keys(res?.rules).length > 0 && res?.rules?.exclude.length > 0) {
                setExcludeRuleSet(res?.rules?.exclude);
            }
        }
        getExistingRules(ApiService.getAudienceRules(audienceId), resHandler);
    }, [audienceId, getExistingRules]);
    useEffect(() => {
        getColumns();
        if (audienceId) {
            fetchExistingRules();
        }
    }, [audienceId, fetchExistingRules, getColumns]);

    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
        const payload = { title,backUrl,share };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
      useEffect(()=>{   
        if(header.title!=="Create Audience")
            headerTitleChangeHandler("Create Audience",'','');
      })

    const dragStartMessage = (event: any, key: 'customer_profile' | 'firmographics' | 'transactions', index: any) => {
        const element: any = document.getElementById(event.target.id);
        element.classList.add("on-drag");
        dragItem.current = { ...columnDataSet[key][index] };
    };
    const dragEnterMessage = (e: any, key: 'customer_profile' | 'firmographics' | 'transactions', position: any) => {
        dragOverItem.current = { ...columnDataSet[key][position] };
    };
    const dragEndMessage = (event: any) => {
        const element: any = document.getElementById(event.target.id);
        element.classList.remove("on-drag");
    };
    const onDropCompleted = (event: any, key: 'include' | 'exclude', gindex: number,) => {
        event.preventDefault();
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        if (dataSet[gindex][0].field.length !== 0) {
            if (dataSet[gindex].length < 5 && !(dataSet[gindex].map((item: any) => item.field).includes(dragItem.current.key))) {
                dataSet[gindex].push({
                    field: dragItem.current.key,
                    label: dragItem.current.label,
                    value: {},
                    operator: "or"
                });
            }
        } else {
            dataSet[gindex][0].field = dragItem.current.key;
            dataSet[gindex][0].label = dragItem.current.label;
        }
        updateDataSet(key, dataSet);
        dragItem.current = null;
        dragOverItem.current = null;
        validatePage(key, dataSet);

    }
    // const operatorSelectionHandler = (key: 'include' | 'exclude', op: string, gindex: number, sindex: number, vindex: number) => {
    //     const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
    //     dataSet[gindex][sindex][vindex].operator = op;
    //     updateDataSet(key, dataSet);
    // }
    // const operatorChangeHandler = (key: 'include' | 'exclude', gindex: number, sindex: number, vindex: number) => {
    //     const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
    //     dataSet[gindex][sindex][vindex].operator = '';
    //     updateDataSet(key, dataSet);
    // }
    const removeVariableHandler = (key: 'include' | 'exclude', gindex: number, vindex: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        if (gindex === 0) {
            if (vindex === 0) {
                dataSet[gindex][vindex].field = '';
                dataSet[gindex][vindex].label = '';
                dataSet[gindex][vindex].value = {};
                if (dataSet[gindex].length > 1) {
                    dataSet[gindex].splice(vindex, 1);
                    dataSet[gindex][0].operator = 'null';
                }
            } else {
                dataSet[gindex].splice(vindex, 1);
            }
        } else {
            dataSet[gindex].splice(vindex, 1);
            if (dataSet[gindex].length === 0) {
                dataSet.splice(gindex, 1);
            } else if (dataSet[gindex].length === 1) {
                dataSet[gindex][0].operator = 'and';
            }
        }
        updateDataSet(key, dataSet);
        validatePage(key, dataSet);
    }
    const variableClickHandler = (key: 'include' | 'exclude', gindex: number, vindex: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        const variable: any = dataSet[gindex][vindex];
        setSelectedOperator('');
        setPopupDataSet({
            variable,
            key,
            gindex,
            vindex,
            available_operators: [],
            value_set: []
        })
        // Get popupvalues from api
        const resHandler = (res: any) => {
            const popupData: any = {
                variable,
                key,
                gindex,
                vindex,
                available_operators: [...res.available_operators],
                value_set: [...res.value_set.map((item: any) => { return { name: item, code: item } })]
            };
            const varKeys = Object.keys(variable.value);
            if (varKeys.length === 0) {
                setSelectedOperator(popupData.available_operators[0].value);
                setSelectedValue(popupData.value_set.length > 0 ? popupData.value_set[0].code : '');
                setMultipleSelectedValues([]);
            } else {
                setSelectedOperator(varKeys[0]);
                if (['in', 'nin'].includes(varKeys[0])) {
                    setMultipleSelectedValues(variable.value[varKeys[0]].map((item: any) => { return { name: item, code: item } }));
                } else {
                    setSelectedValue(['string', 'number'].includes(typeof (variable.value[varKeys[0]])) ? variable.value[varKeys[0]] : variable.value[varKeys[0]].map((item: any) => { return { name: item, code: item } }));
                    // setSelectedValue(typeof (variable.value[varKeys[0]]) !== 'string' ? variable.value[varKeys[0]].map((item: any) => { return { name: item, code: item } }) : variable.value[varKeys[0]]);
                }

            }

            setPopupDataSet(popupData);
        }
        getVariablePopupDataset(ApiService.getVariablePopupDetails({ field: variable.field }), resHandler);
    }
    const operatorChangeHandler = (e: any) => {
        setSelectedOperator(e.target.value)
        if (['in', 'nin'].indexOf(e.target.value) > -1) {
            setMultipleSelectedValues([]);
        } else {
            setSelectedValue(popupDataSet.value_set.length > 0 ? popupDataSet.value_set[0].code : '');
        }
    }
    const variableValueSelectionHandler = () => {
        const dataSet = popupDataSet.key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        const data: any = {};
        data[selectedOperator] = ['lt', 'gt', 'lte', 'gte', 'regex'].indexOf(selectedOperator) > -1
            ? operatorValueRef.current!.value
            : ['eq', 'neq'].indexOf(selectedOperator) > -1 ? selectedValue : multipleSelectedValues.map((item: any) => item.code);
        dataSet[popupDataSet.gindex][popupDataSet.vindex].value = { ...data };
        updateDataSet(popupDataSet.key, dataSet);
        validatePage(popupDataSet.key, dataSet);
    }
    const enableDisableAddGroup = (dataSet: any) => {
        return dataSet[dataSet.length - 1].length > 0 && dataSet[dataSet.length - 1][0].field.length > 0
    }
    const addingGroupHandler = (key: 'include' | 'exclude') => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        dataSet.push(
            [
                {
                    field: "",
                    label: '',
                    value: {},
                    operator: "and"
                }
            ]);
        updateDataSet(key, dataSet);
        validatePage(key, dataSet);
    };
    const deletingGroupHandler = (key: 'include' | 'exclude', index: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        if (dataSet.length > 1) {
            dataSet.splice(index, 1);
            updateDataSet(key, dataSet);
            validatePage(key, dataSet);
        }
    }
    const updateDataSet = (key: 'include' | 'exclude', data: any) => {
        if (key === 'include') {
            setIncludeRuleSet(data);
        } else {
            setExcludeRuleSet(data);
        }
    }
    const validatePage = (key: 'include' | 'exclude', dataSet1: any) => {
        let state1Check: any = [];
        dataSet1.forEach((group: any, gindex: number) => {
            group.forEach((variable: any, vindex: number) => {
                const data: any = Object.entries(variable.value);
                if (data.length > 0 && variable.field.length > 0) {
                    state1Check.push(!(data[0][0].length > 0 && data[0][1].toString().length > 0))
                } else {
                    if (variable.field.length > 0 || variable.operator !== 'null') {
                        state1Check.push(true);
                    } else if (dataSet1.length > 1 && dataSet1[0][0].field.length === 0) {
                        state1Check.push(true);
                    }
                }
            })
        });
        setDisableCheck(state1Check.includes(true));
    }
    const onCancelHandler = () => {
        navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`);
    }
    const onBackHandler = () => {
        navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}/${audienceId}`);
    }
    const onNextHandler = (event: any, key: 'save' | 'next') => {
        event.preventDefault();
        const data: any = {
            include: validateRuleSet(includeRuleSet) ? [] : [...includeRuleSet],
            exclude: validateRuleSet(excludeRuleSet) ? [] : [...excludeRuleSet]
        }
        const resHandler = (res: any) => {
            if (key === 'next') {
                navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_SUMMARY}/${audienceId}`);
            }
        }
        onCreateRule(ApiService.createRule(data, audienceId), resHandler);
    }
    const validateRuleSet = (data: any) => {
        return data.length === 1 && data[0].length === 1 && data[0][0].operator === 'null' && data[0][0].field.length === 0;
    }
    const searchHandler = () => {
        const searchKey = searchRef.current.value.toLowerCase();
        if (searchKey.length > 1) {
            const dataSet: any = {
                customer_profile: [],
                firmographics: [],
                transactions: []
            };
            dataSet.customer_profile = columnDataSet.customer_profile.filter((item: any) => item.label.toLowerCase().match(`${searchKey}`) !== null && item.label.toLowerCase().match(`${searchKey}`).length > 0);
            dataSet.firmographics = columnDataSet.firmographics.filter((item: any) => item.label.toLowerCase().match(`${searchKey}`) !== null && item.label.toLowerCase().match(`${searchKey}`).length > 0)
            dataSet.transactions = columnDataSet.transactions.filter((item: any) => item.label.toLowerCase().match(`${searchKey}`) !== null && item.label.toLowerCase().match(`${searchKey}`).length > 0)
            setFilteredColumnDataset(dataSet);
        } else {
            setFilteredColumnDataset(columnDataSet);
        }
    }
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Rules</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form whiteboxheight'>
                    <Loader isLoading={createRuleLoader || fetchRulesLoader}>
                        <form>
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="rules__wrapper">
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            <div className="accordion-item pb-0 mt-2 border-none">
                                                <h2 className="accordion-header" id="flush-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                        <img src={mai_tick} alt="tick" className='img-fluid me-2' /> Include
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body p-0">
                                                        {includeRuleSet.map((group: any, gindex: number) => {
                                                            return (
                                                                <div key={`inc_group_${gindex}`} id={`inc_group_${gindex}`} className="rules__includeexclude-body"
                                                                    onDrop={(e) => onDropCompleted(e, 'include', gindex)}
                                                                    onDragOver={(e) => e.preventDefault()}>
                                                                    {gindex !== 0 &&
                                                                        <div className='rules__or'>
                                                                            {group[0].operator}
                                                                        </div>
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12 text-end">
                                                                            <div className='rules__group' onClick={() => deletingGroupHandler('include', gindex)}>
                                                                                <span>Group {gindex + 1}</span>
                                                                                <img src={mai_white_cross} alt="cross" className='img-fluid' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {(group.length === 1 && group[0].field.length === 0) &&
                                                                            <p className='rules__deagdrops'>Drag and drop attribute here</p>
                                                                        }
                                                                        <div className='rules_group_wrapper col-lg-6'>
                                                                            <div className={`rules__includes-data`}>
                                                                                {group.map((variable: any, vindex: number) => {
                                                                                    return (
                                                                                        <div key={`inc_group_${gindex}_sec_value_${vindex}`} id={`inc_group_${gindex}_sec_value_${vindex}`}>
                                                                                            {(variable.field.length > 0 &&
                                                                                                <>
                                                                                                    <div className='rules__inner-body'>
                                                                                                        <div className='rules__includes-inner invalid-variable' data-bs-toggle="modal" data-bs-target="#annualsalesModal" onClick={() => variableClickHandler('include', gindex, vindex)}>
                                                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                                                            <div className='rules__varfield'><p>{variable.label}</p></div>
                                                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                                                        </div>
                                                                                                        <div className='rules__crosslight' onClick={() => removeVariableHandler('include', gindex, vindex)}>
                                                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {(vindex !== 0 && group.length >= 2) &&
                                                                                                        <div className='rules__and'>
                                                                                                            <div className='rules__and-border'></div>
                                                                                                            <span> {variable.operator}</span>
                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    {enableDisableAddGroup(includeRuleSet) &&
                                                        <div className='rules__orgroup mt-2' onClick={() => addingGroupHandler('include')}>
                                                            <img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> AND group
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="accordion-item pb-2 mt-4">
                                                <h2 className="accordion-header" id="flush-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                        <img src={mai_cross} alt="cross" className='img-fluid me-2' />  Exclude
                                                    </button>
                                                </h2>
                                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                    <div className="accordion-body p-0">
                                                        {excludeRuleSet.map((group: any, gindex: number) => {
                                                            return (
                                                                <div key={`exc_group_${gindex}`} id={`exc_group_${gindex}`} className="rules__includeexclude-body"
                                                                    onDrop={(e) => onDropCompleted(e, 'exclude', gindex)}
                                                                    onDragOver={(e) => e.preventDefault()}>
                                                                    {gindex !== 0 &&
                                                                        <div className='rules__or'>
                                                                            {group[0].operator}
                                                                        </div>
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12 text-end">
                                                                            <div className='rules__group' onClick={() => deletingGroupHandler('exclude', gindex)}>
                                                                                <span>Group {gindex + 1}</span>
                                                                                <img src={mai_white_cross} alt="cross" className='img-fluid' />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {(group.length === 1 && group[0].field.length === 0) &&
                                                                            <p className='rules__deagdrops'>Drag and drop attribute here</p>
                                                                        }
                                                                        <div className='rules_group_wrapper col-lg-6'>
                                                                            <div className={`rules__includes-data`}>
                                                                                {group.map((variable: any, vindex: number) => {
                                                                                    return (
                                                                                        <div key={`exc_group_${gindex}_sec_value_${vindex}`} id={`exc_group_${gindex}_sec_value_${vindex}`}>
                                                                                            {(variable.field.length > 0 &&
                                                                                                <>
                                                                                                    <div className='rules__inner-body'>
                                                                                                        <div className='rules__includes-inner invalid-variable' data-bs-toggle="modal" data-bs-target="#annualsalesModal" onClick={() => variableClickHandler('exclude', gindex, vindex)}>
                                                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                                                            <div className='rules__varfield'><p>{variable.label}</p></div>
                                                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                                                        </div>
                                                                                                        <div className='rules__crosslight' onClick={() => removeVariableHandler('exclude', gindex, vindex)}>
                                                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {(vindex !== 0 && group.length >= 2) &&
                                                                                                        <div className='rules__and'>
                                                                                                            <div className='rules__and-border'></div>
                                                                                                            <span>{variable.operator}</span>
                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    {enableDisableAddGroup(excludeRuleSet) &&
                                                        <div className='rules__orgroup mt-2' onClick={() => addingGroupHandler('exclude')}>
                                                            <img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> AND group
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className='rules__rightwrapper'>
                                        <div className="rules__searchbox">
                                            <div className="input-group">
                                                <div className="input-group-prepend border-0">
                                                    <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                                </div>
                                                <input type="search" placeholder="Search" aria-describedby="button-addon4" className="form-control border-0" ref={searchRef} onChange={searchHandler} />
                                            </div>
                                        </div>
                                        <div className='rules__custprofile'>
                                            <div className="accordion" id="mai-accordian-rules">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading1">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                            Customer Profile
                                                        </button>
                                                    </h2>
                                                    <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll h-auto">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.customer_profile?.map((profile: any, index: number) => {
                                                                    return (
                                                                        <li key={`${profile.key}_${index}`} id={`${profile.key}_${index}`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'customer_profile', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'customer_profile', index)}
                                                                            onDragOver={(e) => { e.preventDefault() }}
                                                                            draggable
                                                                        >
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span> {profile.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                            Firmographics
                                                        </button>
                                                    </h2>
                                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll h-auto">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.firmographics?.map((item: any, index: number) => {
                                                                    return (
                                                                        <li key={`${item.key}_${index}`} id={`${item.key}_${index}`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'firmographics', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'firmographics', index)}
                                                                            onDragOver={(e) => { e.preventDefault() }}
                                                                            draggable>
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span>{item.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading3">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                            Transaction
                                                        </button>
                                                    </h2>
                                                    <div id="collapse3" className="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.transactions?.map((item: any, index: number) => {
                                                                    return (
                                                                        <li key={`${item.key}_${index}_trans`} id={`${item.key}_${index}_trans`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'transactions', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'transactions', index)}
                                                                            onDragOver={(e) => e.preventDefault()}
                                                                            draggable>
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span>{item.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="rules__border-top">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                                <button type="submit" className="btn btn-primary mai-cancel-button" onClick={onCancelHandler}>Cancel</button>
                                                <button className="btn btn-primary mai-cancel-button ms-3" onClick={(e: any) => onNextHandler(e, 'save')}>Save as Draft</button>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                                <button type="submit" className="btn btn-primary mai-cancel-button me-3" onClick={onBackHandler}>Back</button>
                                                <button type="submit" className="btn btn-primary mai-next-button" disabled={disableCheck} onClick={(e: any) => onNextHandler(e, 'next')}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Loader>
                </div>
            </div>

            <div className="modal fade subscription-modalpopup annualsales" id="annualsalesModal" aria-labelledby="annualsalesModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-1">
                            <h1 className="modal-title" id="annualsalesModalLabel">{popupDataSet?.variable?.label}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {selectedOperator.length === 0
                            ? <Loader style={{minHeight: '181px'}}></Loader>
                            : <>
                                <div className="modal-body pb-0">
                                    <div className="row mb-4">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <label htmlFor="operator">Operator</label>
                                            <select className="form-select" id="operator" value={selectedOperator} onChange={(e: any) => { operatorChangeHandler(e) }}>
                                                {popupDataSet.available_operators.map((operator: any, i: number) => {
                                                    return (
                                                        <option key={`operator_${i}`} value={operator.value}> {operator.label}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        {['lt', 'gt', 'lte', 'gte', 'regex'].indexOf(selectedOperator) > -1 &&
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label htmlFor="operator">From</label>
                                                <input type="text" name="operatorValueRef" className='form-control' placeholder='Type Here' ref={operatorValueRef} value={selectedValue}
                                                    onChange={(e: any) => { setSelectedValue(e.target.value) }} />
                                            </div>
                                        }
                                        {['eq', 'neq'].indexOf(selectedOperator) > -1 &&
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label htmlFor="operator">Select</label>
                                                <select className="form-select" id="operator" value={selectedValue} onChange={(e: any) => { setSelectedValue(e.target.value) }}>
                                                    {popupDataSet.value_set.map((item: any, i: number) => {
                                                        return (
                                                            <option key={`op_drop_val_${i}`} value={item.code}> {item.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        }
                                        {['in', 'nin'].indexOf(selectedOperator) > -1 &&
                                            <div className="col-lg-6 col-md-6 col-sm-12 audiencelistmultiselect">
                                                <label htmlFor="operator">Select</label>
                                                <MultiSelect appendTo={'self'} panelClassName="filter-multiselect-pannel selectoperators"
                                                    className="filter-multiselect-input" value={multipleSelectedValues} options={popupDataSet.value_set} onChange={(e) => setMultipleSelectedValues(e.value)} optionLabel="name" placeholder="Select Value" display="chip" />
                                            </div>
                                        }
                                        {/* <div className='rulesCalendar'>
                                        <Calendar id="calendar" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} showIcon></Calendar>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end border-0">
                                    <button type="button" className="btn btn-primary popup-button" data-bs-dismiss="modal" onClick={variableValueSelectionHandler}>Done</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default RulesComponent;