import { Dropdown } from "primereact/dropdown";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { mai_plus_solid_blue, mai_tick_blue_lg } from '../../../../../../assets/images/index';
import './AbTestTestDetails.scss';

const AbTestTestDetailsComponent = () => {
    const [selectTime, setSelectedTime] = useState<any>(null);
    const testtime = [
        { name: 'Hrs', code: 'hours' },
        { name: 'Mins', code: 'Minutes' },
        { name: 'Sec', code: 'Second' },
    ];
    const onTimeChange = (e: { value: any }) => {
        setSelectedTime(e.value);
    }

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Test Targets</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Test Details</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper testtargetwrapper'>
                <div className='audiencecreate__form box-shadow whiteboxheight'>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='box-maxheight custom-Scroll'>
                                <div className="row mb-4">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="form-check ps-0">
                                            <label className="form-check-label mai-label-access">Audience Split</label>
                                        </div>
                                        <div className='customcheckwrap'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="randomsampling" value="Randomsampling" checked />
                                                <label className="form-check-label mai-radio-label" htmlFor="randomsampling">Random Sampling</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="stratifiedsampling" value="Stratifiedsampling" />
                                                <label className="form-check-label mai-radio-label" htmlFor="stratifiedsampling">Stratified Sampling</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <label className="form-label mai-label-sharewith">Strata’s (0) <span>Click the button to select stratas </span></label>
                                        <div className='campaignbutton m-0'>
                                            <NavLink to="" className="createbutton"><img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> Select Stratas</NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="splitbox">
                                            <div className="splitcol split-1st">
                                                <p>Split A</p>
                                                <div className="splitcol-inner splitA">
                                                    20%
                                                </div>
                                            </div>
                                            <div className="splitcol split-2nd">
                                                <p>Split B</p>
                                                <div className="splitcol-inner splitB">
                                                    80%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12"></div>
                                </div>

                                <div className="row my-3 mt-4">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <hr className="hrline" />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className="form-label mai-form-label">
                                            Primary KPI to be Tested
                                        </label>
                                        <div className='kpitobetested'>
                                            {/* {
                                                kpiOptions && kpiOptions.map((item) => <>
                                                    <div className="kpi" key={item.value}>
                                                        <input type="checkbox" id={item.value} value={item.value} onClick={handleCheckboxChange} checked={contactStrategy.kpi.includes(item.value)} />
                                                        <label htmlFor={item.value}><span> {item.label}</span></label>
                                                    </div>
                                                </>)
                                            } */}
                                            <div>
                                                <NavLink to=""><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Open Rate</NavLink>
                                                <NavLink to="" className='grayBtn'><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Click Rate</NavLink>
                                                <NavLink to=""><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Conversion Rate</NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12"></div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <label className="form-label mai-form-label">
                                            Select Test Runtime
                                        </label>
                                        <div className="runtimebox">
                                            <div className="timeselectcol timeInput">
                                                <input type="text" className="form-control" placeholder="24" />
                                            </div>
                                            <div className="timeselectcol timeselect">
                                                <Dropdown value={selectTime} options={testtime} onChange={onTimeChange} optionLabel="name" placeholder="Hrs" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top mt-0">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Prev</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default AbTestTestDetailsComponent;