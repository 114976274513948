import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AudienceGeneralDetail } from '../../../../../../models/types';
import { AutoComplete } from "primereact/autocomplete";
import './GeneralDetails.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from './../../../../../../services/api.service';
import AuthenticationService from '../../../../../../services/authentication.service';
import { Url } from '../../../../../../models/constants';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from '../../../../../../store/store';
import { StoreActions } from '../../../../../../store/actions';
const GeneralDetailsComponent = () => {
    const { aid } = useParams();
    let navigate = useNavigate();
    const dispatch=useDispatch();
    const header = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const currentUserDetails = useSelector<StoreState, any>(state => state.currentUser);
    const [audience, setAudience] = useState<AudienceGeneralDetail>({
        name: "",
        description: "",
        is_private: false,
        can_be_accessed_by: [],
    });
    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
        const payload = { title,backUrl,share };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
      useEffect(()=>{
        if(header.title!=="Create Audience")
        headerTitleChangeHandler("Create Audience",'','');
      })
    const { sendRequest: getUsers } = useHttp();
    const { sendRequest: createAudience, loading: createAudienceLoader } = useHttp();
    const { sendRequest: updateAudience } = useHttp();
    const { sendRequest: getAudience } = useHttp();
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        if (aid) {
            const resHandler = (res: any) => {
                setAudience(res.audience);
                setSelectedUsers(res?.audience.can_be_accessed_by);
            }
            getAudience(ApiService.getAudienceGeneraldetail(aid), resHandler)
        } 
    }, [getAudience, aid])

    const searchUser = (event: any) => {
        setTimeout(() => {
            if (event.query.trim().length >= 3) {
                const resHandler = (res: any) => {
                    if (res?.users) {
                      let allUsersList = res.users;
                      const userListWithoutLoginUser = allUsersList.filter(
                        (user: any) => user.id != currentUserDetails.id
                      );
                      const finallist = userListWithoutLoginUser.filter(
                        (user: any) =>
                          !selectedUsers
                            .map((selUsr: any) => selUsr.id)
                            .includes(user.id)
                      );
                      setUserList(finallist);
                    }
                }
                getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
            }
        }, 250);
    };
    const userSelectionHandler = (event: any) => {
        setSelectedUsers(event.value);
        setAudience({ ...audience, can_be_accessed_by: [...event.value] });
    }
    const updateAudienceDetails = () => {
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_RULES}/${aid}`);
        }
        let audienceData = { ...audience};
        if(audienceData.is_private) {
            console.log(audienceData);
            if(audienceData.can_be_accessed_by.length) {
                audienceData = {...audience, can_be_accessed_by: [...audienceData.can_be_accessed_by.map((item: any) => item.id)]}
            }
        } else {
            audienceData = { ...audience, can_be_accessed_by: []};
        }
        updateAudience(ApiService.updateAudience(audienceData, aid), resHandler);
    }
    const createAudienceHandler = (event: React.FormEvent) => {
        event.preventDefault();
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_RULES}/${res.id}`);
        }
        if (aid) {
            updateAudienceDetails();
        } else {
            let audienceData = { ...audience};
            if(audienceData.can_be_accessed_by.length) {
                audienceData = {...audience, can_be_accessed_by: [...audienceData.can_be_accessed_by.map((item: any) => item.id)]}
            }
            createAudience(ApiService.createAudienceGeneraldetail(audienceData), resHandler)
        }
    }
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Rules</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form whiteboxheight'>
                    <Loader isLoading={createAudienceLoader}>
                        <form onSubmit={createAudienceHandler}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='boxmin-height'>
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="mb-3">
                                                    <label htmlFor="name" className="form-label mai-form-label">Name</label>
                                                    <input type="text" className="form-control mai-input" id="name" name="name" maxLength={100} value={audience.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAudience({ ...audience, name: e.target.value })} aria-describedby="emailHelp" placeholder='Enter audience name' />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="audienceDesc" className="form-label mai-form-label">Description</label>
                                                    <textarea id="audienceDesc" className='form-control mai-textarea custom-Scroll' maxLength={500} value={audience.description} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setAudience({ ...audience, description: e.target.value })} placeholder='Enter description'></textarea>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="form-check form-check-inline ps-0">
                                                        <label className="form-check-label mai-label-access">Access</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="public" value="Public" checked={audience.is_private === false} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setAudience({ ...audience, can_be_accessed_by: [], is_private: false }); setSelectedUsers([])}} />
                                                        <label className="form-check-label mai-radio-label" htmlFor="public">Public</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="private" value="Private" checked={audience.is_private === true} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAudience({ ...audience, is_private: true })} />
                                                        <label className="form-check-label mai-radio-label" htmlFor="private">Private</label>
                                                    </div>
                                                </div>
                                                {audience.is_private &&
                                                    <div className="mb-3">
                                                        <label htmlFor="sharewith" className="form-label mai-label-sharewith">Share with
                                                            <span> Optional </span>
                                                        </label>
                                                        <AutoComplete
                                                            id="aud_general_det"
                                                            className="form-control sharewithData"
                                                            placeholder={selectedUsers.length === 0 ? "Search users and add" : ''}
                                                            value={selectedUsers}
                                                            suggestions={userList}
                                                            completeMethod={searchUser}
                                                            field="name"
                                                            multiple
                                                            onChange={userSelectionHandler}
                                                            aria-label="User"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-12"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="rules__border-top">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                                <button className="btn btn-primary mai-cancel-button" onClick={() => navigate("/main/workflow/customerlens-workflow/audience-builder/list")}>Cancel</button>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                                <button type="submit" className="btn btn-primary mai-next-button" disabled={audience.name.trim() === '' || audience.description.trim() === ''}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Loader>
                </div>
            </div>
        </Fragment>
    )
}

export default GeneralDetailsComponent;