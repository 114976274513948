import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import AdminComponent from "./components/admin/AdminComponent";
import EditLanguageComponent from "./components/admin/language/EditLanguageComponent";
import { Url } from "./models/constants";
import LoginComponent from "./components/login/LoginComponent";
import MainComponent from "./components/main/MainComponent";
import PageNotFoundComponent from "./components/pagenotfound/PageNotFoundComponent";
import HomeComponent from "./components/home/HomeComponent";
import AuthenticationHook from "./hooks/AuthenticationHook";
import ChartExampleComponent from "./components/admin/ChartExampleComponent";
import CustomerLensHomeComponent from "./components/home/customerlens/CustomerLensHomeComponent";
import CampaignLensHomeComponent from "./components/home/campaignlens/CampaignLensHomeComponent";
import MarketingLensHomeComponent from "./components/home/marketinglens/MarketingLensHomeComponent";
import CustomerLensDashboardComponent from "./components/dashboard/customerlens/CustomerLensDashboardComponent";
import CampaignLensDashboardComponent from "./components/dashboard/campaignlens/CampaignLensDashboardComponent";
import MarketingLensDashboardComponent from "./components/dashboard/marketinglens/MarketingLensDashboardComponent";
import DashboardComponent from "./components/dashboard/DashboardComponent";
import WorkflowComponent from "./components/workflow/WorkflowComponent";
import CustomerLensWorkflowComponent from "./components/workflow/customerlens/CustomerLensWorkflowComponent";
import CampaignLensWorkflowComponent from "./components/workflow/campaignlens/CampaignLensWorkflowComponent";
import MarketingLensWorkflowComponent from "./components/workflow/marketinglens/MarketingLensWorkflowComponent";
import AudienceBuilderComponent from "./components/workflow/customerlens/audiencebuilder/AudienceBuilderComponent";
import AudienceListComponent from "./components/workflow/customerlens/audiencebuilder/list/AudienceListComponent";
import AudienceCreateComponent from "./components/workflow/customerlens/audiencebuilder/create/AudienceCreateComponent";
import CustomerSegmentationComponent from "./components/workflow/customerlens/customersegmentation/CustomerSegmentationComponent";
import RecommendationsComponent from "./components/workflow/customerlens/recommendations/RecommendationsComponent";
import CustomerLifeTimeValueComponent from "./components/workflow/customerlens/customer lifetime value/CustomeLifetimeValueComponent";
import GeneralDetailsComponent from "./components/workflow/customerlens/audiencebuilder/create/generaldetails/GeneralDetailsComponent";
import RulesComponent from "./components/workflow/customerlens/audiencebuilder/create/rules/RulesComponent";
import SummaryComponent from "./components/workflow/customerlens/audiencebuilder/create/summary/SummaryComponent";
import RequireAuth from "./auth/RequireAuth";
import AudienceDetailsComponent from "./components/workflow/customerlens/audiencebuilder/details/AudienceDetailsComponent";
import CustomerSegmentationDetailsComponent from "./components/workflow/customerlens/customersegmentation/details/CustomerSegmentationDetailsComponent";
import VeryActiveCustomersComponent from "./components/dashboard/customerlens/veryactive/VeryActiveCustomersComponent";
import AudienceDashboardComponent from "./components/dashboard/customerlens/veryactive/audiencedashboard/AudienceDashboardComponent";
import CustomerSegmentationCreateComponent from "./components/workflow/customerlens/customersegmentation/create/CustomerSegmentationCreateComponent";
import SegmentationListComponent from "./components/workflow/customerlens/customersegmentation/list/SegmentationListComponent";
import SegmentationDefineComponent from "./components/workflow/customerlens/customersegmentation/create/define/SegmentationDefineComponent";
import SegmentationCustomerAttributesComponent from "./components/workflow/customerlens/customersegmentation/create/customerattributes/SegmentationCustomerAttributesComponent";
import CustomerSegmentationSummaryComponent from "./components/workflow/customerlens/customersegmentation/create/summary/CustomerSegmentationSummaryComponent";
import RecommendationCreateComponent from "./components/workflow/customerlens/recommendations/create/RecommendationCreateComponent";
import RecommendationListComponent from "./components/workflow/customerlens/recommendations/list/RecommendationListComponent";
import RecommendationGeneralDetailsComponents from "./components/workflow/customerlens/recommendations/create/generaldetails/RecommendationGeneralDetailsComponents";
import RecommendationAttributeComponent from "./components/workflow/customerlens/recommendations/create/attributes/RecommendationAttributeComponent";
import RecommendationSummaryComponents from "./components/workflow/customerlens/recommendations/create/summary/RecommendationSummaryComponents";
import RecommendationDetailsComponent from "./components/workflow/customerlens/recommendations/details/RecommendationDetailsComponent";
import SegmentationGeneralDetailsComponent from "./components/workflow/customerlens/customersegmentation/create/generaldetails/SegmentationGeneralDetailsComponent";
import CustomerLifetimeValueListComponent from "./components/workflow/customerlens/customer lifetime value/list/CustomerLifetimeValueListComponent";
import ClvCreateComponent from "./components/workflow/customerlens/customer lifetime value/create/ClvCreateComponent";
import ClvGeneralDetailsComponents from "./components/workflow/customerlens/customer lifetime value/create/generaldetails/ClvGeneralDetailsComponents";
import ClvSummaryComponents from "./components/workflow/customerlens/customer lifetime value/create/summary/ClvSummaryComponents";
import ClvDetailsComponent from "./components/workflow/customerlens/customer lifetime value/details/ClvDetailsComponent";
import CampaignMonitoringComponent from "./components/workflow/campaignlens/campaignmonitoring/CampaignMonitoringComponent";
import CampaignMonitoringListComponent from "./components/workflow/campaignlens/campaignmonitoring/list/CampaignMonitoringListComponent";
import CampaignMonitoringDetailsComponent from "./components/workflow/campaignlens/campaignmonitoring/details/CampaignMonitoringDetailsComponent";
import CampaignOptimizationComponent from "./components/workflow/campaignlens/campaignoptimization/CampaignOptimizationComponent";
import CampaignOptimizationDetailsComponent from "./components/workflow/campaignlens/campaignoptimization/details/CampaignOptimizationDetailsComponent";
import CampaignOptimizationListComponent from "./components/workflow/campaignlens/campaignoptimization/list/CampaignOptimizationListComponent";
import CampaignComponent from "./components/workflow/campaignlens/campaign/CampaignComponent";
import CampaignCreateComponent from "./components/workflow/campaignlens/campaign/create/CampaignCreateComponent";
import CampaignGeneralDetailsComponent from "./components/workflow/campaignlens/campaign/create/generaldetails/CampaignGeneralDetailsComponent";
import CampaignListComponent from "./components/workflow/campaignlens/campaign/list/CampaignListComponent";
import ContactStartegyComponent from "./components/workflow/campaignlens/contactstrategy/ContactStartegyComponent";
import ContactStartegyListComponent from "./components/workflow/campaignlens/contactstrategy/list/ContactStartegyListComponent";
import CreateContactStrategyComponent from "./components/workflow/campaignlens/contactstrategy/create/CreateContactStrategyComponent";
import ContactStrategyGeneralDetailsComponent from "./components/workflow/campaignlens/contactstrategy/create/generaldetails/ContactStrategyGeneralDetailsComponent";
import RoadmapComponent from "./components/workflow/campaignlens/contactstrategy/create/roadmap/RoadmapComponent";
import InputParametersComponent from "./components/workflow/campaignlens/campaignoptimization/details/inputparameters/InputParametersComponent";
import CampaignOptimizationSummaryComponent from "./components/workflow/campaignlens/campaignoptimization/details/summary/CampaignOptimizationSummaryComponent";
import AbTestComponent from "./components/workflow/campaignlens/abtest/AbTestComponent";
import AbTestListComponent from "./components/workflow/campaignlens/abtest/list/AbTestListComponent";
import AbTestDetailsComponent from "./components/workflow/campaignlens/abtest/details/AbTestDetailsComponent";
import AbTestCreateComponent from "./components/workflow/campaignlens/abtest/create/AbTestCreateComponent";
import AbTestGenDetailsComponent from "./components/workflow/campaignlens/abtest/create/generaldetails/AbTestGenDetailsComponent";
import AbTestTargetComponent from "./components/workflow/campaignlens/abtest/create/testtarget/AbTestTargetComponent";
import AbTestTestDetailsComponent from "./components/workflow/campaignlens/abtest/create/testdetails/AbTestTestDetailsComponent";
import StatisticalSignificantCalculatorComponent from "./components/workflow/campaignlens/abtest/details/statisticalsignificantcalculator/StatisticalSignificantCalculatorComponent";
import AbTestGeneralDetailsComponent from "./components/workflow/campaignlens/abtest/details/generaldetails/AbTestGeneralDetailsComponent";
import SpendOptimizationComponent from "./components/workflow/marketinglens/Spend Optimization/SpendOptimizationComponent";
import SpendOptimizationInsightsComponent from "./components/workflow/marketinglens/Spend Optimization/details/SpendOptimizationInsightsComponent";
import SpendOptimizationListComponent from "./components/workflow/marketinglens/Spend Optimization/list/SpendOptimizationListComponent";
import SpendOptimizationCreateComponent from "./components/workflow/marketinglens/Spend Optimization/create/SpendOptimizationCreateComponent";
import SpendOptimizationGeneralDetailsComponent from "./components/workflow/marketinglens/Spend Optimization/create/generaldetails/SpendOptimizationGeneralDetailsComponent";
import SpendOptimizationDefineChannelThemeComponent from "./components/workflow/marketinglens/Spend Optimization/create/definechanneltheme/SpendOptimizationDefineChannelThemeComponent";
import CreateSpendOptimizationComponent from "./components/workflow/marketinglens/Spend Optimization/create/spendoptimization/CreateSpendOptimizationComponent";
import SpendOptimizationSummaryComponent from "./components/workflow/marketinglens/Spend Optimization/create/summary/SpendOptimizationSummaryComponent";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <AuthenticationHook.AuthProvider>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<LoginComponent />} />
                        <Route path={`/:${Url.TENANT}`} element={<LoginComponent />} />
                        <Route path={Url.LOGIN} element={<LoginComponent />} />
                        <Route path={Url.LOGIN_FAILED} element={<LoginComponent />} />
                        <Route path={Url.MAIN} element={<RequireAuth><MainComponent /></RequireAuth>}>
                            <Route index element={<RequireAuth><CustomerLensHomeComponent /></RequireAuth>} />
                            <Route path={Url.HOME} element={<RequireAuth><HomeComponent /></RequireAuth>} >
                                <Route index element={<RequireAuth><CustomerLensHomeComponent /></RequireAuth>} />
                                <Route path={Url.CUSTOMERLENS_HOME} element={<RequireAuth><CustomerLensHomeComponent /></RequireAuth>} />
                                <Route path={Url.CAMPAIGNLENS_HOME} element={<RequireAuth><CampaignLensHomeComponent /></RequireAuth>} />
                                <Route path={Url.MARKETINGLENS_HOME} element={<RequireAuth><MarketingLensHomeComponent /></RequireAuth>} />
                            </Route>
                            <Route path={Url.DASHBOARD} element={<RequireAuth><DashboardComponent /></RequireAuth>} >
                                <Route index element={<RequireAuth><CustomerLensDashboardComponent /></RequireAuth>} />
                                <Route path={Url.CUSTOMERLENS_DASHBOARD} element={<RequireAuth><CustomerLensDashboardComponent /></RequireAuth>} >
                                    <Route index element={<RequireAuth>< VeryActiveCustomersComponent /></RequireAuth>} />
                                    <Route path='veryactive' element={<RequireAuth>< VeryActiveCustomersComponent /></RequireAuth>} />
                                    <Route path='audiencedashboard' element={<RequireAuth>< AudienceDashboardComponent /></RequireAuth>} />
                                </Route>
                                <Route path={Url.CAMPAIGNLENS_DASHBOARD} element={<RequireAuth><CampaignLensDashboardComponent /></RequireAuth>} />
                                <Route path={Url.MARKETINGLENS_DASHBOARD} element={<RequireAuth><MarketingLensDashboardComponent /></RequireAuth>} />
                            </Route>
                            <Route path={Url.WORKFLOW} element={<RequireAuth><WorkflowComponent /></RequireAuth>} >
                                <Route index element={<RequireAuth><CustomerLensWorkflowComponent /></RequireAuth>} />
                                <Route path={Url.CUSTOMERLENS_WORKFLOW} element={<RequireAuth><CustomerLensWorkflowComponent /></RequireAuth>} >
                                    <Route index element={<RequireAuth><AudienceBuilderComponent /></RequireAuth>} />
                                    <Route path={Url.AUDIENCE_BUILDER} element={<RequireAuth><AudienceBuilderComponent /></RequireAuth>} >
                                        <Route index element={<RequireAuth><AudienceListComponent /></RequireAuth>} />
                                        <Route path={Url.AUDIENCE_LIST} element={<RequireAuth><AudienceListComponent /></RequireAuth>} />
                                        <Route path={`${Url.AUDIENCE_DETAILS}/:${Url.AUDIENCE_ID}`} element={<RequireAuth><AudienceDetailsComponent /></RequireAuth>} />
                                        <Route path={Url.AUDIENCE_CREATE} element={<RequireAuth><AudienceCreateComponent /></RequireAuth>} >
                                            <Route index element={<RequireAuth><GeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={Url.AUDIENCE_CREATE_GENERAL} element={<RequireAuth><GeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.AUDIENCE_CREATE_GENERAL}/:${Url.AUDIENCE_ID}`} element={<RequireAuth><GeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.AUDIENCE_CREATE_RULES}/:${Url.AUDIENCE_ID}`} element={<RequireAuth><RulesComponent /></RequireAuth>} />
                                            <Route path={`${Url.AUDIENCE_CREATE_SUMMARY}/:${Url.AUDIENCE_ID}`} element={<RequireAuth><SummaryComponent /></RequireAuth>} />
                                        </Route>
                                    </Route>
                                    <Route path={Url.CUSTOMER_SEGMENTATION} element={<RequireAuth><CustomerSegmentationComponent /></RequireAuth>} >
                                        <Route index element={<RequireAuth><SegmentationListComponent /></RequireAuth>} />
                                        <Route path={Url.CUSTOMER_SEGMENTATION_LIST} element={<RequireAuth><SegmentationListComponent /></RequireAuth>} />
                                        <Route path={Url.CUSTOMER_SEGMENTATION_CREATE} element={<RequireAuth><CustomerSegmentationCreateComponent /></RequireAuth>} >
                                            <Route index element={<RequireAuth><SegmentationGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL} element={<RequireAuth><SegmentationGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><SegmentationGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`:${Url.AUDIENCE_ID}/${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}`} element={<RequireAuth><SegmentationGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><SegmentationDefineComponent /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_SEGMENTATION_CREATE_ATTRIBUTE}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><SegmentationCustomerAttributesComponent /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_SEGMENTATION_CREATE_SUMMARY}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><CustomerSegmentationSummaryComponent /></RequireAuth>} />
                                        </Route>
                                        <Route path={`${Url.CUSTOMER_SEGMENTATION_DETAILS}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><CustomerSegmentationDetailsComponent /></RequireAuth>} />
                                    </Route>                                    
                                    <Route path={`${Url.RECOMMENDATIONS}/:${Url.RECOMMENDATIONS_DETAILS}`} element={<RequireAuth><RecommendationDetailsComponent /> </RequireAuth>} />
                                    <Route path={Url.RECOMMENDATIONS} element={<RequireAuth><RecommendationsComponent /></RequireAuth>} />
                                    <Route path={Url.RECOMMENDATIONS} element={<RequireAuth><RecommendationsComponent /> </RequireAuth>} >
                                        <Route index element={<RequireAuth><RecommendationListComponent /></RequireAuth>} />
                                        <Route path={Url.RECOMMENDATIONS_LIST} element={<RequireAuth><RecommendationListComponent /> </RequireAuth>} />
                                        <Route path={Url.RECOMMENDATIONS_CREATE} element={<RequireAuth><RecommendationCreateComponent /></RequireAuth>} >
                                            <Route index element={<RequireAuth><RecommendationGeneralDetailsComponents />  </RequireAuth>} />
                                            <Route path={Url.RECOMMENDATIONS_CREATE_GENERAL} element={<RequireAuth><RecommendationGeneralDetailsComponents /> </RequireAuth>} />
                                            <Route path={`${Url.RECOMMENDATIONS_CREATE_GENERAL}/${Url.AUDIENCE_BUILDER}/:${Url.AUDIENCE_ID}`} element={<RequireAuth><RecommendationGeneralDetailsComponents /> </RequireAuth>} />
                                            <Route path={`${Url.RECOMMENDATIONS_CREATE_GENERAL}/${Url.SEGMENTATION}/:${Url.CUSTOMER_SEGMENTATION_ID}`} element={<RequireAuth><RecommendationGeneralDetailsComponents /> </RequireAuth>} />
                                            <Route path={`${Url.RECOMMENDATIONS_CREATE_GENERAL}/:${Url.RECOMMENDATIONS_ID}`} element={<RequireAuth><RecommendationGeneralDetailsComponents /> </RequireAuth>} />
                                            <Route path={`${Url.RECOMMENDATIONS_CREATE_ATTRIBUTE}/:${Url.RECOMMENDATIONS_ID}`} element={<RequireAuth><RecommendationAttributeComponent /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_SEGMENTATION_CREATE_SUMMARY}/:${Url.RECOMMENDATIONS_ID}`} element={<RequireAuth><RecommendationSummaryComponents /></RequireAuth>} />

                                        </Route>
                                    </Route>
                                    <Route path={Url.CUSTOMER_LIFETIME_VALUE} element={<RequireAuth><CustomerLifeTimeValueComponent /> </RequireAuth>}>
                                        <Route index element={<RequireAuth><CustomerLifetimeValueListComponent /></RequireAuth>} />
                                        <Route path={Url.CUSTOMER_LIFETIME_VALUE_LIST} element={<RequireAuth><CustomerLifetimeValueListComponent /></RequireAuth>} />
                                        <Route path={Url.CUSTOMER_LIFETIME_VALUE} element={<RequireAuth><CustomerLifeTimeValueComponent /></RequireAuth>} />
                                        <Route path={`${Url.CUSTOMER_LIFETIME_VALUE_DETAILS}/:${Url.CUSTOMER_LIFETIME_VALUE_ID}`} element={<RequireAuth><ClvDetailsComponent /></RequireAuth>} />
                                        <Route path={Url.CUSTOMER_LIFETIME_VALUE_CREAT} element={<RequireAuth><ClvCreateComponent /> </RequireAuth>}>
                                            <Route index element={<RequireAuth><ClvGeneralDetailsComponents /></RequireAuth>} />
                                            <Route path={Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL} element={<RequireAuth><ClvGeneralDetailsComponents /> </RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}/:${Url.CUSTOMER_LIFETIME_VALUE_ID}`} element={<RequireAuth><ClvGeneralDetailsComponents /></RequireAuth>} />
                                            <Route path={`:${Url.AUDIENCE_ID}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}`} element={<RequireAuth><ClvGeneralDetailsComponents /></RequireAuth>} />
                                            <Route path={`${Url.SEGMENTATION}/:${Url.CUSTOMER_SEGMENTATION_ID}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}`} element={<RequireAuth><ClvGeneralDetailsComponents /></RequireAuth>} />
                                            <Route path={`${Url.CUSTOMER_LIFETIME_VALUE_CREATE_SUMMARY}/:${Url.CUSTOMER_LIFETIME_VALUE_ID}`} element={<RequireAuth><ClvSummaryComponents /> </RequireAuth>} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path={Url.CAMPAIGNLENS_WORKFLOW} element={<RequireAuth><CampaignLensWorkflowComponent /></RequireAuth>} >
                                    <Route path={Url.CAMPAIGN} element={<RequireAuth><CampaignComponent /></RequireAuth>} >
                                        <Route index element={<RequireAuth><CampaignListComponent /> </RequireAuth>} />
                                        <Route path={Url.CAMPAIGN_LIST} element={<RequireAuth><CampaignListComponent /> </RequireAuth>} />

                                        <Route path={Url.CAMPAIGN_CREATE} element={<RequireAuth><CampaignCreateComponent /></RequireAuth>} >
                                            <Route index element={<RequireAuth><CampaignCreateComponent /></RequireAuth>} />
                                            <Route path={Url.CAMPAIGN_CREATE_GENERAL} element={<RequireAuth><CampaignGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.CAMPAIGN_CREATE_GENERAL}/:${Url.CAMPAIGN_ID}`} element={<RequireAuth><CampaignGeneralDetailsComponent /></RequireAuth>} />

                                        </Route>
                                    </Route>
                                    <Route path={Url.CONTACT_STRATEGY} element={<RequireAuth><ContactStartegyComponent /> </RequireAuth>} >
                                        <Route index element={<RequireAuth><ContactStartegyListComponent /> </RequireAuth>} />
                                        <Route path={Url.CONTACT_STRATEGY_LIST} element={<RequireAuth><ContactStartegyListComponent /> </RequireAuth>} />

                                        <Route path={Url.CONTACT_STRATEGY_CREATE} element={<RequireAuth><CreateContactStrategyComponent /> </RequireAuth>} >
                                            <Route index element={<RequireAuth><CreateContactStrategyComponent /></RequireAuth>} />
                                            <Route path={Url.CONTACT_STRATEGY_CREATE_GENERAL} element={<RequireAuth><ContactStrategyGeneralDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.CONTACT_STRATEGY_CREATE_GENERAL}/:${Url.CONTACT_STRATEGY_ID}`} element={<RequireAuth><ContactStrategyGeneralDetailsComponent /></RequireAuth>} />
                                            <Route path={`${Url.CONTACT_STRATEGY_ROADMAP}/:${Url.CONTACT_STRATEGY_ID}`} element={<RequireAuth><RoadmapComponent /> </RequireAuth>} />
                                        </Route>
                                    </Route>
                                    <Route path={Url.CAMPAIGN_MONITORING} element={<RequireAuth><CampaignMonitoringComponent /> </RequireAuth>} >
                                        <Route index element={<RequireAuth><CampaignMonitoringListComponent /> </RequireAuth>} />
                                        <Route path={Url.CAMPAIGN_MONITORING_LIST} element={<RequireAuth><CampaignMonitoringListComponent /> </RequireAuth>} />
                                        <Route path={`${Url.CAMPAIGN_MONITORING_DETAILS}/:${Url.CAMPAIGN_ID}`} element={<RequireAuth><CampaignMonitoringDetailsComponent /> </RequireAuth>} />
                                    </Route>
                                    <Route path={Url.CAMPAIGN_OPTIMIZATION} element={<RequireAuth><CampaignOptimizationComponent /> </RequireAuth>} />
                                    <Route path={Url.CAMPAIGN_OPTIMIZATION} element={<RequireAuth><CampaignOptimizationComponent /> </RequireAuth>} >
                                        <Route index element={<RequireAuth><CampaignOptimizationListComponent /> </RequireAuth>} />
                                        <Route path={Url.CAMPAIGN_OPTIMIZATION_LIST} element={<RequireAuth><CampaignOptimizationListComponent /> </RequireAuth>} />
                                        <Route path={Url.CAMPAIGN_OPTIMIZATION_DETAILS} element={<RequireAuth><CampaignOptimizationDetailsComponent /> </RequireAuth>} >
                                            <Route index element={<RequireAuth><CampaignOptimizationDetailsComponent /> </RequireAuth>} />
                                            <Route path={Url.CAMPAIGN_OPTIMIZATION_INPUT_PARAMETERS} element={<RequireAuth><InputParametersComponent /> </RequireAuth>} />
                                            <Route path={`${Url.CAMPAIGN_OPTIMIZATION_INPUT_PARAMETERS}/:${Url.CAMPAIGN_OPTIMIZATION_ID}`} element={<RequireAuth><InputParametersComponent /> </RequireAuth>} />
                                            <Route path={Url.CAMPAIGN_OPTIMIZATION_SUMMARY} element={<RequireAuth><CampaignOptimizationSummaryComponent /> </RequireAuth>} />
                                        </Route>
                                    </Route>
                                    <Route path={Url.AB_TEST} element={<RequireAuth><AbTestComponent /> </RequireAuth>} >
                                        <Route index element={<RequireAuth><AbTestListComponent /> </RequireAuth>} />
                                        <Route path={Url.AB_TEST_LIST} element={<RequireAuth><AbTestListComponent /> </RequireAuth>} />
                                        <Route path={Url.AB_TEST_CREATE} element={<RequireAuth><AbTestCreateComponent /> </RequireAuth>} >
                                            <Route index element={<RequireAuth><AbTestGenDetailsComponent /> </RequireAuth>} />
                                            <Route path={Url.AB_TEST_CREATE_GENERAL} element={<RequireAuth><AbTestGenDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.AB_TEST_CREATE_GENERAL}/:${Url.AB_TEST_ID}`} element={<RequireAuth><AbTestGenDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.AB_TEST_CREATE_TEST_TARGET}/:${Url.AB_TEST_ID}`} element={<RequireAuth><AbTestTargetComponent /> </RequireAuth>} />
                                            <Route path={`${Url.AB_TEST_CREATE_TEST_DETAILS}/:${Url.AB_TEST_ID}`} element={<RequireAuth><AbTestTestDetailsComponent /> </RequireAuth>} />
                                        </Route>
                                        <Route path={Url.AB_TEST_DETAILS} element={<RequireAuth><AbTestDetailsComponent /> </RequireAuth>} >
                                            <Route path={`${Url.AB_TEST_GENERAL_DETAILS}/:${Url.AB_TEST_ID}`} element={<RequireAuth><AbTestGeneralDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.AB_TEST_STATISTICAL_SIGNIFICANCE_CALCULATOR}/:${Url.AB_TEST_ID}`} element={<RequireAuth><StatisticalSignificantCalculatorComponent /> </RequireAuth>} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path={Url.MARKETINGLENS_WORKFLOW} element={<RequireAuth><MarketingLensWorkflowComponent /></RequireAuth>} >
                                    <Route path={Url.SPEND_OPTIMIZATION} element={<RequireAuth><SpendOptimizationComponent /> </RequireAuth>}>
                                        <Route path={`${Url.SPEND_OPTIMIZATION_DETAILS}/:${Url.SPEND_OPTIMIZATION_ID}`} element={<RequireAuth><SpendOptimizationInsightsComponent /> </RequireAuth>} />
                                        <Route index element={<RequireAuth><SpendOptimizationListComponent /> </RequireAuth>} />
                                        <Route path={Url.SPEND_OPTIMIZATION_LIST} element={<RequireAuth><SpendOptimizationListComponent /> </RequireAuth>} />
                                        <Route path={Url.SPEND_OPTIMIZATION_CREATE} element={<RequireAuth><SpendOptimizationCreateComponent /> </RequireAuth>}>
                                            <Route index element={<RequireAuth><SpendOptimizationGeneralDetailsComponent /> </RequireAuth>} />
                                            <Route path={Url.SPEND_OPTIMIZATION_CREATE_GENERAL} element={<RequireAuth><SpendOptimizationGeneralDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.SPEND_OPTIMIZATION_CREATE_GENERAL}/:${Url.SPEND_OPTIMIZATION_ID}`} element={<RequireAuth><SpendOptimizationGeneralDetailsComponent /> </RequireAuth>} />
                                            <Route path={`${Url.SPEND_OPTIMIZATION_CREATE__DEFINE_CHANNEL_THEME}/:${Url.SPEND_OPTIMIZATION_ID}`} element={<RequireAuth><SpendOptimizationDefineChannelThemeComponent /> </RequireAuth>} />
                                            <Route path={`${Url.SPEND_OPTIMIZATION_CREATE_SPEND_OPTIMIZATION}/:${Url.SPEND_OPTIMIZATION_ID}`} element={<RequireAuth><CreateSpendOptimizationComponent /> </RequireAuth>} />
                                            <Route path={`${Url.SPEND_OPTIMIZATION_CREATE_SUMMARY}/:${Url.SPEND_OPTIMIZATION_ID}`} element={<RequireAuth><SpendOptimizationSummaryComponent /> </RequireAuth>} />
                                        </Route>

                                    </Route>
                                </Route>
                            </Route>
                            <Route
                                path={`${Url.ADMIN}`}
                                element={
                                    // <RequireAuth>
                                    <AdminComponent />
                                    // </RequireAuth>
                                }
                            >
                                <Route index element={
                                    // <RequireAuth>
                                    <EditLanguageComponent />
                                    // </RequireAuth>
                                } />
                                <Route
                                    path={Url.LOCALE}
                                    element={
                                        // <RequireAuth>
                                        <EditLanguageComponent />
                                        // </RequireAuth>
                                    }
                                >
                                </Route>
                                <Route
                                    path={Url.CHART}
                                    element={
                                        // <RequireAuth>
                                        <ChartExampleComponent />
                                        // </RequireAuth>
                                    }
                                >
                                </Route>
                            </Route>


                        </Route>
                        <Route path='*' element={<PageNotFoundComponent />} />
                    </Route>
                </Routes>
            </AuthenticationHook.AuthProvider >
        </BrowserRouter >
    );
}

export default AppRoutes;