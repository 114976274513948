import { Dropdown } from "primereact/dropdown";
import { TabPanel, TabView } from "primereact/tabview";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    mai_trends_chart,
    mai_indicator_orange,
    mai_indicator_blue,
    mai_configure_icon,
    mai_download_icon
} from '../../../../../../assets/images/index';
import './StatisticalSignificantCalculator.scss';

const StatisticalSignificantCalculatorComponent = () => {
    const [segmentsList, setSelectedSegmentsList] = useState<any>(null);
    const onCreatedByChange = (e: { value: any }) => {
        setSelectedSegmentsList(e.value);
    }
    const segmentsdropdownlist = [
        { name: 'Open', code: 'open' },
        { name: 'Click', code: 'click' },
        { name: 'Bounce', code: 'bounce' },
        { name: 'Conversion', code: 'conversion' }
    ];
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Statistical Significance Calculator</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form box-shadow whiteboxheight'>
                    <div className='boxmin-height'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <h2 className="title16 m-0"><span> New Product Adaptation </span> </h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <hr className="hrline" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="subjectlinetabs">
                                    <TabView>
                                        <TabPanel header="Subject Line">
                                            <table className="table subjectlinetable">
                                                <tr>
                                                    <th>VARIANT A</th>
                                                    <th>VARIANT B</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        North-East Sales Renewal Test, East Sales Renewal Test, North-East Sales Renewal Test
                                                    </td>
                                                    <td>
                                                        Increase New Product Adoptation, Increase Product Adoptation
                                                    </td>
                                                </tr>
                                            </table>
                                        </TabPanel>
                                        <TabPanel header="Open Rate">
                                            <table className="table subjectlinetable openratetable">
                                                <tr>
                                                    <th>VARIANT A</th>
                                                    <th>VARIANT B</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <table className="table varianta-table">
                                                            <tr>
                                                                <th>Customers</th>
                                                                <th>Response</th>
                                                                <th>Result</th>
                                                            </tr>
                                                            <tr>
                                                                <td>100</td>
                                                                <td>20</td>
                                                                <td>20%</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <table className="table variantb-table">
                                                            <tr>
                                                                <th>Customers</th>
                                                                <th>Response</th>
                                                                <th>Result</th>
                                                            </tr>
                                                            <tr>
                                                                <td>100</td>
                                                                <td>40</td>
                                                                <td>40%</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </TabPanel>
                                    </TabView>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="subjectbox testresult">
                                    <h2 className="mb-3">Your test results</h2>

                                    <p>
                                        <span> Test "A" converted 58% better than Test “B”</span><br />
                                        I am 100% certain that the changes in Test "A" will improve your conversion rate. <br /><br />

                                        Your A/B test is statistically significant!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <hr className="hrline" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="trendswrapper">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <h2 className="inner__heading">
                                                Trends
                                            </h2>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                            <div className="indicatorlist">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item"><img src={mai_indicator_orange} alt="click rate" className="img-fluid" /> Variant A</li>
                                                    <li className="list-inline-item"><img src={mai_indicator_blue} alt="conversion rate" className="img-fluid" /> Variant B</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <div className="selectlist-wrap justify-content-end p-0">
                                                <div className='selectlist me-0'>
                                                    <div className='monitoring-dropdownlist'>
                                                        <Dropdown value={segmentsList} options={segmentsdropdownlist} onChange={onCreatedByChange} optionLabel="name" placeholder="Click Through Rate" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-1 col-md-1 col-sm-12 text-end">
                                            <div className="dropdown dot3-dropdownwrap">
                                                <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="pi pi-ellipsis-v dots3"></span>
                                                </NavLink>
                                                <ul className="dropdown-menu">
                                                    <li><NavLink className="dropdown-item" to="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</NavLink></li>
                                                    <li><NavLink className="dropdown-item" to="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-center px-5">
                                            <img src={mai_trends_chart} alt="" className="img-fluid" />
                                            {/* <ChartComponent chartConfigs={mailTrendLineChartConfig} /> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Cancel</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Prev</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default StatisticalSignificantCalculatorComponent;