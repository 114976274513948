import { Fragment, useCallback, useRef } from "react";
import React, { useState, useEffect } from "react";
import { Menu } from "primereact/menu";
import "./AudienceList.scss";
import {
  mai_search_bar,
  mai_plus,
  mai_back_icon,
  mai_share_icon,
} from "../../../../../assets/images/index";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastSeverity, Url } from "../../../../../models/constants";
import useHttp from "../../../../../hooks/use-http";
import ApiService from "../../../../../services/api.service";
import DataTable from "../../../../../shared/components/datatable/DataTable";
import { AudienceDeatil, AudienceListResponse, Columns, creatorListResponse } from "../../../../../models/types";
import { Paginator } from "primereact/paginator";
import AuthenticationService from "../../../../../services/authentication.service";
import Loader from "../../../../../shared/components/loader/Loader";
import { StoreActions } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownChangeEvent as DropdownChangeParams } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import UtilService from "../../../../../services/util.service";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AutoComplete } from "primereact/autocomplete";
import { AudienceGeneralDetail } from '../../../../../models/types';
import { Tooltip } from 'primereact/tooltip';
import { useSelector } from "react-redux";
import { StoreState } from "../../../../../store/store";
import AudienceWorldMapComponent from "../worldmap/AudienceWorldMapComponent";
import useDebounce from "../../../../../hooks/DebounceHook";


const AudienceListComponent = () => {
  
  const menu = useRef<any>(null!);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayShareWithModal, setDisplayShareWithModal] = useState(false);
  const [deleteAudienceID, setDeleteAudienceID] = useState<string>("");
  const [tableData, setTableData] = useState<any>([]);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [tableDataCount, setTableDataCount] = useState<any>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [creatorKey, setCreatorKey] = useState<{ code: string, name: string }>({ name: 'All', code: '' });
  const [selectedStatus, setSelectedStatus] = useState<{ code: string, name: string }>({ code: '', name: 'All' });
  const [allCreators, setAllCreators] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [sharedByUsers, setSharedByUsers] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [creatorList, setCreatorList] = useState<{ code: string, name: string }[]>([]);
  const { sendRequest: getAudiences, loading: getAudiencesLoader } = useHttp();
  const { sendRequest: getCreators } = useHttp();
  const { sendRequest: deleteAudience } = useHttp();
  const { sendRequest: updateAudience } = useHttp();
  const { sendRequest: getAudience } = useHttp();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [userList, setUserList] = useState([]);
  const { sendRequest: getUsers } = useHttp();
  const currentUserDetails = useSelector<StoreState, any>(state => state.currentUser);

  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    headerTitleChangeHandler("Audience List",'','');
  })
  const [audience, setAudience] = useState<AudienceGeneralDetail>({
    name: "",
    description: "",
    is_private: false,
    can_be_accessed_by: [],
  });

  const status = [
    { name: 'All', code: '' },
    { name: 'Published', code: 'True' },
    { name: 'Draft', code: 'False' }
  ];
  const getAudienceList = useCallback((
    searchText = "",
    createdBy = "",
    published = '',
    page = 0,
    sortBy = "modified_on",
    sortOrder = -1,

  ) => {
    const resHandler = async (res: AudienceListResponse) => {
      setTableDataCount(res.total);
      setTableData(res.audiences);
      const sharedUsers: string[] = [];
      res.audiences.forEach((item: AudienceDeatil) => sharedUsers.push(...item.can_be_accessed_by));
      const uniqueSharedUsers = [...Array.from(new Set(sharedUsers))];
      const sharedUsersObj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        uniqueSharedUsers.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { try { sharedUsersObj[id] = usr.user } catch (error) { console.log(error) } }))
      );
      setSharedByUsers(sharedUsersObj);
    };
    getAudiences(
      ApiService.getAudienceList({
        page: page + 1,
        search: searchText,
        created_by: createdBy,
        sort_by: sortBy,
        sort_order: sortOrder,
        published
      }),
      resHandler
    );
  }, [getAudiences]);

  useEffect(() => {
    getAudienceList();
  }, [getAudienceList]);

  useEffect(() => {
    const resHandler = async (res: creatorListResponse) => {
      const creatorobj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        res.creator.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { creatorobj[id] = usr.user }).catch((err: any) => console.error(err)))
      );
      setAllCreators(creatorobj);
      const creatorsres: { code: string, name: string }[] = res.creator.filter((id: string) => !!creatorobj[id]).map((id: string) => ({ code: id, name: creatorobj[id].name }));
      creatorsres.unshift({ name: 'All', code: '' });
      setCreatorList(creatorsres);
    };
    getCreators(ApiService.getAudienceCreatorList(), resHandler);
  }, [getCreators]);

  const userSelectionHandler = (event: any) => {
    setFlag(true);
    setSelectedUsers(event.value);
    setAudience({ ...audience, can_be_accessed_by: [...event.value] });
  }

  const searchUser = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            let allUsersList = res.users;
            const userListWithoutLoginUser = allUsersList.filter(
              (user: any) => user.id != currentUserDetails.id
            );
            const finallist = userListWithoutLoginUser.filter(
              (user: any) =>
                !selectedUsers
                  .map((selUsr: any) => selUsr.id)
                  .includes(user.id)
            );
            setUserList(finallist);
          }
        }
        getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
      }
    }, 250);
  };
  

  const onSelectionHandler = (event: any) => {
    if (event.rowData.is_published) {
      headerTitleChangeHandler(event?.rowData?.name,`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`,'share');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_DETAILS}/${event.rowData.id}`
      );
    } else {
      headerTitleChangeHandler('Create Audience','','');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_RULES}/${event.rowData.id}`
      );
    }
  };
  const isCellSelectable = (event: any) => {
    const data = event.data;
    return data.field === "actions" ? false : true;
  };
  const statusBodyTemplate = (rowData: any) => {
    let status: string = "Draft";
    if (rowData.is_published) {
      status = "Published";
    }
    return (
      <span className={`customer-badge status-${status.toLowerCase()}`}>
        {status}
      </span>
    );
  };
  const createdByBodyTemplate = ({ creator, ...row }: any) => {
    return renderAvatar(creator, allCreators);
  };
  const nameBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.name.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listtooltip${rowData.id}`} position="bottom">
              {rowData.name}
            </Tooltip>
            <div className={`listtooltip${rowData.id}`}>
              {`${rowData.name.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.name
        )}
      </>
    );
  };
  const descriptionBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.description.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listdesctooltip${rowData.id}`} position="bottom">
              {rowData.description}
            </Tooltip>
            <div className={`listdesctooltip${rowData.id}`}>
              {`${rowData.description.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.description
        )}
      </>
    );
  };
  const actionBodyTemplate = (rowData: any) => {
    let items = [{}];
    const updateMenuItemsHandler = (audience: any) => {
      if (audience.is_published === true) {
        items.push({
          label: "Create Segment",
          icon: "pi pi-fw pi-plus-circle",
          command: () => {
            headerTitleChangeHandler('Create Segmentation','','');
            navigate(
              `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${rowData.id}/${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}`
            );
          },
        });

        items.push({
          label: "Create CLV",
          icon: "pi pi-plus-circle",
          command: () => {
            headerTitleChangeHandler('Create CLV','','');
            navigate(
              `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${rowData.id}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}`
            );
          },
        });
        items.push({
          label: "Create recommendation",
          icon: "pi pi-plus-circle",
          command: () => { navigate(
            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}/${Url.AUDIENCE_BUILDER}/${rowData.id}`
          );},
        });
      }
      items.push({
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => { },
      });
      items.push({
        label: "Export",
        icon: "pi pi-plus-circle",
        command: () => { },
      });
      items.push({
        label: "Mail .csv",
        icon: "pi pi-envelope",
        command: () => { },
      });
      if (audience.is_private === true) {
        items.push({
          label: "Share With",
          icon: "pi pi-share-alt",
          command: () => {
            setDisplayShareWithModal(true);
          },
        });
      }
      items.push({
        label: "View history",
        icon: "pi pi-history",
        command: () => { },
      });
      items.push({
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          setDisplayModal(true);
        },
      });
      setMenuItems(items);
    };

    const fetchAudienceDetails = async (audience: any) => {
      setFlag(false);
      setAudience(audience);
      const allSelectedUsers: string[] = [];
      await Promise.allSettled(
        audience.can_be_accessed_by.map(
          async (id: any) =>
            await AuthenticationService.fetchUserDetails(id)
              .then((usr: any) => {
                allSelectedUsers.push(...allSelectedUsers, usr.user);
              })
              .catch((err: any) => console.error(err))
        )
      );
      const uniqueSharedUsers = [...Array.from(new Set(allSelectedUsers))];
      setSelectedUsers(uniqueSharedUsers);
    };

    return (
      <>
        <Menu model={menuItems} popup ref={menu} id="popup_menu" />{" "}
        <i
          className="pi pi-ellipsis-v"
          onClick={(event: any) => { menu?.current?.toggle(event); setDeleteAudienceID(rowData.id); updateMenuItemsHandler(rowData); fetchAudienceDetails(rowData) }}
        ></i>
      </>
    );
  };

  const sharedWithBodyTemplate = (rowData: any) => {
    let returnElem = <></>;
    let sharedElem = rowData.can_be_accessed_by
      .slice(0, 3)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let sharedrestElem = rowData.can_be_accessed_by
      .slice(3, rowData.can_be_accessed_by.length)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let allSharedElem = sharedElem.concat(sharedrestElem);

    if (rowData.can_be_accessed_by.length > 3) {
      returnElem = (
        <>
          {/* <CustomTooltip position="bottom" content={allSharedElem} containerClass="tooltipStyleChange">
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </CustomTooltip> */}

          <Tooltip className="list-tooltip-wrap" target={`.sharewithtooltip${rowData.id}`} position="bottom">
            {allSharedElem}
          </Tooltip>
          <div className={`sharewithtooltip${rowData.id}`}>
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </div>
        </>
      );
    } else {
      returnElem = <>{sharedElem}</>;
    }
    return returnElem;
  };
  const lastupdatedBodyTemplate = (rowData: any) => {

    let dateStr = new Date(rowData.modified_on);
    return dateStr.toLocaleDateString()
    // "05/08/2020"
  }
  const columns: Columns[] = [
    { field: "name", header: "Name", body: nameBodyTemplate, sort: true },
    {
      field: "description",
      header: "Description",
      body: descriptionBodyTemplate,
      sort: false,
    },
    { field: "customers", header: "Customers", sort: true },
    {
      field: "creator",
      header: "Created By",
      body: createdByBodyTemplate,
      sort: false,
    },
    {
      field: "can_be_accessed_by",
      header: "Shared With",
      body: sharedWithBodyTemplate,
      sort: false,
    },
    {
      field: "modified_on",
      header: "Last Updated",
      body: lastupdatedBodyTemplate,
      sort: true,
    },
    {
      field: "is_published",
      header: "Status",
      body: statusBodyTemplate,
      sort: true,
    },
    {
      field: "actions",
      header: "Actions",
      body: actionBodyTemplate,
      sort: false,
    },
  ];
  const renderAvatar = (userId: string, userData: { [key: string]: { [key: string]: string } }) => {
    const userDetails: { id: string, name: string, label: string, image: string } = { id: userId, name: '', label: '', image: '' };
    if (userData[userId]) {
      userDetails.label = UtilService.getShortName(String(userData[userId].name));
      userDetails.name = String(userData[userId].name);
    }
    return (
      <Avatar key={userId} className={`user-avatar ${UtilService.classes()[userDetails.name.length % 10]}`} label={userDetails.label} image={userDetails.image} shape="circle" size={"normal"} />
    );
  }
  const debouncedCallback = useDebounce((value: string) => {
    // Make your backend API call here using the value
    getAudienceList(value, creatorKey.code, selectedStatus.code);
  }, 500); // You can customize the delay time here

  const audienceTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const patt = /[^A-Za-z 0-9]/g;
    if (!(patt.test(event.currentTarget.value))) {
      setSearchKey(event.currentTarget.value);
      debouncedCallback(event.currentTarget.value);
    }
  };
  const creatorChangeHandler = (event: DropdownChangeParams) => {
    event.preventDefault();
    setCreatorKey(event.value);
    getAudienceList(searchKey, event.value.code, selectedStatus.code);
  };
  const onStatusChange = (event: DropdownChangeParams) => {
    setSelectedStatus(event.value);
    getAudienceList(searchKey, creatorKey.code, event.value.code);

  }
  const pageChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    getAudienceList(searchKey, creatorKey.code, selectedStatus.code, event.page);
  };
  const tableConfig: any = {
    selection: { mode: "single", handler: onSelectionHandler },
    isDataSelectable: isCellSelectable,
  };
  const deleteAudienceCallback = () => {
    const resHandler = (res: any) => {
      dispatch({
        type: StoreActions.TOAST_ON,
        payload: {
          severity: ToastSeverity.SUCCESS,
          summary: "Information",
          detail: res.detail,
          life: 3000,
        },
      });
      getAudienceList(searchKey, creatorKey.code, selectedStatus.code, 0);
    }
    if (deleteAudienceID) {
      deleteAudience(ApiService.deleteAudience(deleteAudienceID), resHandler);
    }
  }
  const footerContent = (
    <div>
      <Button label="Update" onClick={() => updateSharedUsersDetailsCallback()} autoFocus />
    </div>
  );
  const updateSharedUsersDetailsCallback = () => {
    const resAudienceHandler = (res: any) => {
      setAudience(res.audience);
      let audienceData = { ...audience };
      if (flag === true) {
        if (audienceData.is_private) {
          if (audienceData.can_be_accessed_by.length) {
            audienceData = {
              ...audience,
              can_be_accessed_by: [
                ...audienceData.can_be_accessed_by.map((item: any) => item.id),
              ],
            };
          }
        } else {
          audienceData = { ...audience, can_be_accessed_by: [] };
        }
        updateAudience(
          ApiService.updateAudience(audienceData, deleteAudienceID),
          resHandler
        );
      }
    };
    getAudience(
      ApiService.getAudienceGeneraldetail(deleteAudienceID),
      resAudienceHandler
    );
    const resHandler = (res: any) => {
      getAudienceList(
        searchKey,
        creatorKey.code,
        selectedStatus.code,
        0
      );
    };
    setDisplayShareWithModal(false);
  };
  return (
    <Fragment>
      <AudienceWorldMapComponent />
      <div className="audiencecreate__form-wrapper">
        <div className="audiencecreate__form audiencelist whiteboxlist-height">
          <div className="row mb-3">
            <div className="col-lg-9 col-md-9 col-sm-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="rules__searchbox">
                    <div className="input-group">
                      <div className="input-group-prepend border-0">
                        <button id="button-addon4" type="button" className="btn btn-link text-info">
                          <img src={mai_search_bar} alt="search" className="img-fluid" />
                        </button>
                      </div>
                      <input
                        type="search"
                        placeholder="Search"
                        // pattern="[A-Za-z\s\^]+"
                        aria-describedby="button-addon4"
                        className="form-control border-0"
                        onChange={audienceTextSearchHandler}
                        value={searchKey} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="selectlist-wrap">
                    <div className="selectlist">
                      <label>Created by</label>
                      <div className="monitoring-dropdownlist">
                        <Dropdown
                          value={creatorKey}
                          options={creatorList}
                          onChange={creatorChangeHandler}
                          optionLabel="name"
                          placeholder="All" />
                      </div>
                    </div>
                    <div className="selectlist">
                      <label>Status</label>
                      <div className="monitoring-dropdownlist">
                        <Dropdown
                          value={selectedStatus}
                          options={status}
                          onChange={onStatusChange}
                          optionLabel="name"
                          placeholder="All" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="welcome__create text-end">
                <NavLink
                  to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_GENERAL}`}
                  className="welcome__create-anchor"
                  onClick={() => headerTitleChangeHandler("Create Audience",'','')}
                >
                  <img src={mai_plus} alt="plus-icon" className="img-fluid welcome__plus-icon" />{" "}
                  Create Audience
                </NavLink>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card list-datatable audiencelist-datatable listpage-table border-0">
              {getAudiencesLoader ? (
                <Loader style={{ minHeight: "445px" }}></Loader>
              ) : (
                <>
                  <DataTable data={tableData} columns={columns} config={tableConfig}></DataTable>
                </>
              )}
              <Paginator
                first={first}
                rows={rows}
                totalRecords={tableDataCount}
                onPageChange={pageChangeHandler}
                className="justify-content-end listpagination"
              ></Paginator>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={displayModal}
        onHide={() => {
          setDisplayModal(false);
          setDeleteAudienceID("");
        }}
        maskClassName="common-popup-wrap"
        header="Are you sure, you want to delete audience?"
        message="Dependent Segmentation, clv, recommendation will get deleted."
        closable={false}
        draggable={false}
        accept={deleteAudienceCallback}
        reject={() => {
          setDisplayModal(false);
          setDeleteAudienceID("");
        }} />

      <Dialog
        visible={displayShareWithModal}
        onHide={() => {
          setDisplayShareWithModal(false);
          setDeleteAudienceID("");
        }}
        maskClassName="common-popup-wrap sharewith-modalpopup"
        draggable={false}
        header="Share with"
        footer={footerContent}
      >
        <AutoComplete
          id="aud_general_det"
          className="form-control sharewithData"
          placeholder={selectedUsers.length === 0 ? "Search users and add" : ''}
          value={selectedUsers}
          suggestions={userList}
          completeMethod={searchUser}
          field="name"
          multiple
          onChange={userSelectionHandler}
          aria-label="User"
        />

      </Dialog>
    </Fragment>
  );
};

export default AudienceListComponent;
