import React, { useRef, useState } from 'react';
import './CustomTooltip.scss';
type PropType = {
    content?: string;
    children?: React.ReactNode; 
    position?: 'top' | 'left' | 'right' | 'bottom';
    containerClass?: string;
    theme?: 'darktooltip' | 'lighttooltip';
}
const CustomTooltip = ({
  content,
  children,
  position,
  containerClass,
  theme,
}: PropType) => {
    const [elementPosition, setElementPosition] = useState<{top: number, left: number}>({top: 0, left: 0});
    
    const focusElem = (event: any) => {
        let currentPosition = event.target.getBoundingClientRect();
        let alterPosition = currentPosition.width < 100 ? 0 : 50;
        setElementPosition({ top: currentPosition.top + currentPosition.height, left: currentPosition.left - (currentPosition.width / 2) - alterPosition})
    }
  return (
    <div className={`customtooltip ${containerClass}`} onMouseEnter={focusElem}>
      {children}
      <div
        className={`customtooltiptext ${
          theme === "lighttooltip" ? "lighttooltip" : "darktooltip"
        } customtooltip-${position}`}
        style={elementPosition}
      >
        {content}
        <span className="arrow"></span>
      </div>
    </div>
  );
};

export default CustomTooltip;
