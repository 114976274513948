import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import AuthenticationHook from "../hooks/AuthenticationHook";

const RequireAuth = (props: any) => {
    const location = useLocation();
    const auth = AuthenticationHook.useAuth();
    if(!auth.isAuthenticated()) {
        return <Navigate to="/" state={{ path: location.pathname }} replace />
    } 
    return props.children;
}

export default React.memo(RequireAuth);