import { Fragment, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import ApiService from "../../../../../services/api.service";
import ChartComponent from "../../../../../shared/components/chart/ChartComponent";
import useHttp from "../../../../../hooks/use-http";
import { useParams } from "react-router-dom";
import Loader from "../../../../../shared/components/loader/Loader";
import { clvSummaryDataType } from "../../../../../models/types";
import {
  mai_question_icon,
  mai_product_icon,
  mai_revenue_icon,
  mai_revenue_opportunity_icon,
  mai_existingrevenue_indicate,
  mai_predictedCLV,
  mai_refresh,
} from "../../../../../assets/images/index";
import UtilService from "../../../../../services/util.service";
interface OwnProps {
  readonly selectedMatrix: { segment: string; rank: string, color: number | string };
  matrixHandler(segment: string, rank: string, colorIndex: number): void;
}
interface matrixDataStructure {
  segment: string;
  items: {
    rank: string;
    value: number;
    percentage: number;
  }[];
}
const PredictedClvChart = (props: OwnProps) => {
  const { sendRequest: getClvSummary, loading: getClvSummaryLoader } = useHttp();
  const { sendRequest: getMatrix, loading: getMatrixLoader } = useHttp();
  const { sendRequest: getMatrixPredictionData, loading: getMatrixPredictionDataLoader } = useHttp();
  const params = useParams();
  const [revenueTimeframe, setRevenueTimeframe] = useState<string>("");
  const [predictedTimeframe, setPredictedTimeframe] = useState<string>("");
  const [avgCLVRevenue, setAvgCLVRevenue] = useState({avg_revenue: 0, avg_predicted_clv: 0});
  const [matrixSegment, setMatrixSegment] = useState<matrixDataStructure[]>([
    {
      segment: "",
      items: [
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
      ],
    },
    {
      segment: "",
      items: [
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
      ],
    },
    {
      segment: "",
      items: [
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
        { rank: "", value: 0, percentage: 0 },
      ],
    },
  ]);
  const [clvSummaryData, setClvSummaryData] = useState<clvSummaryDataType>({
    total_customer: 0,
    total_revenue: 0,
    projected_revenue: 0,
    avg_clv: 0,
    incremental_revenue: 0,
    growth: 0,
    avg_revenue: 0,
    avg_predicted_clv: 0,
  });
  const [clvPredictionChartConfig, setClvPredictionChartConfig] = useState<any>({
    type: "line",
    width: "100%",
    height: "11%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
      chart: {
        yAxisPosition: "left",
        rotateLabels: 0,
        showhovereffect: "1",
        numberprefix: "$",
        drawcrossline: "0",
        plottooltext:
          "<div style='min-width: 200px;'><div style='display: flex; align-items: center; justify-content: space-between; margin: 2px 0;'> <div><b>$dataValue</b></div></div></div>",
        theme: "fusion",
        toolTipBgColor: "#444444",
        toolTipColor: "#fff",
        toolTipBorderColor: "#444444",
        toolTipBorderRadius: "5",
        chartLeftMargin: "0",
        chartRightMargin: "0",
        chartTopMargin: "20",
        chartBottomMargin: "0",
        canvasBottomPadding: "15",
      },
    },
  });

  const getMatrixClass = useCallback((segment: string, rank: string) => {
    if (props.selectedMatrix.rank === "" && props.selectedMatrix.segment === "") {
      return `default-matrix`;
    } else if (props.selectedMatrix.rank === rank && props.selectedMatrix.segment === segment) {
      return "selected-matrix";
    } else {
      return "unselected-matrix";
    }
  },[props.selectedMatrix.rank, props.selectedMatrix.segment]);

  useEffect(() => {
    const resHandler = (res: any) => {
      let clvSummaryDataClone: clvSummaryDataType = {...clvSummaryData};

      clvSummaryDataClone.total_customer = res.clv.total_customer ?? 0;
      clvSummaryDataClone.total_revenue = res.clv.total_revenue ?? 0;
      clvSummaryDataClone.projected_revenue = res.clv.projected_revenue ?? 0;
      clvSummaryDataClone.avg_clv = res.clv.avg_clv ?? 0;

      clvSummaryDataClone.incremental_revenue = res.clv.incremental_revenue ?? 0;
      clvSummaryDataClone.growth = res.clv.growth ?? 0;
      setClvSummaryData(clvSummaryDataClone);
    };
    getClvSummary(ApiService.clvSummary(params?.clvid), resHandler);
  }, [getClvSummary, params?.clvid]);

  const getPredictCLVChart = useCallback(() => {
    const resHandler = (res: any) => {
      if (!Array.isArray(res.predict) && res.predict.prediction !== null ) {
        let revenueMinYear = 3000;
        let revenueMaxYear = 0;
        let predictedMinMonth = 13;
        let predictedMaxMonth = 0;
        let predictedYear = 0;
        const months: string[] = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const cloneClvPredictionChartConfig = { ...clvPredictionChartConfig };
        cloneClvPredictionChartConfig.dataSource = {
          ...cloneClvPredictionChartConfig.dataSource,
          data: res.predict.prediction.map((item: any) => {
            if (item.predicted) {
              if (predictedMinMonth > item.month) {
                predictedYear = item.year;
              }
              predictedMinMonth = predictedMinMonth > item.month ? item.month : predictedMinMonth;
              predictedMaxMonth = predictedMaxMonth < item.month ? item.month : predictedMaxMonth;
              return {
                label: months[item.month - 1] + " " + item.year.toString(),
                value: item.value,
                color: props.selectedMatrix.color ? props.selectedMatrix.color : "#69efb5",
              };
            } else {
              const tempYear = item.month < 3 ? item.year - 1 : item.year;
              revenueMinYear = revenueMinYear > tempYear ? tempYear : revenueMinYear;
              revenueMaxYear = revenueMaxYear < tempYear ? tempYear : revenueMaxYear;
              return {
                label: months[item.month - 1] + " " + item.year.toString(),
                value: item.value,
              };
            }
          }),
        };
        setRevenueTimeframe("FY" + revenueMinYear + "-" + revenueMaxYear);
        setPredictedTimeframe(
          months[predictedMinMonth - 1] + "-" + months[predictedMaxMonth - 1] + " " + predictedYear
        );
        setClvPredictionChartConfig(cloneClvPredictionChartConfig);
      } else {
        setClvPredictionChartConfig((prevClvPredictionChartConfig: any) => ({...prevClvPredictionChartConfig, dataSource: {...prevClvPredictionChartConfig.dataSource, data: []}}));
      }
      setAvgCLVRevenue(oldAvgCLVRevenue => ({...oldAvgCLVRevenue, avg_revenue: (res.predict.avg_revenue ?? 0), avg_predicted_clv: (res.predict.avg_predicted_clv ?? 0)}));
    };
    let filter = { segment: props.selectedMatrix.segment, rank: props.selectedMatrix.rank };
    getMatrixPredictionData(ApiService.getClvInsightPredict(params?.clvid!, filter), resHandler);
  }, [getMatrixPredictionData, params?.clvid, props.selectedMatrix]);
  
  useEffect(() => {
    getPredictCLVChart();
  }, [getPredictCLVChart]);
  
  useEffect(() => {
    if (params?.clvid) {
      const resHander = (res: any) => {
        setMatrixSegment(res.matrix);
      };
      getMatrix(ApiService.getClvInsightMatrix(params?.clvid), resHander);
    }
  }, [getMatrix, params?.clvid]);

  return (
    <Fragment>
      {/* <Loader isLoading={getClvSummaryLoader}> */}
      {/* <div className="row"> */}
      <div className="col-lg-3 col-md-3 col-sm-12">
        <div className="insights__innerwrapper matrixitemwrap">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="inner__heading">
                <img src={mai_product_icon} alt="web" className="img-fluid" />{" "}
                <span> Customers</span>{" "}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="insights-data">
                <div>
                  <div className="innertext__size">
                    <h2 className="blueshade titleblack mb-2">{clvSummaryData.total_customer}</h2>
                    <p>Total Customer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3 className="inner__heading mb-1">
                <span>Action item matrix </span>{" "}
                <img src={mai_question_icon} alt="quest" className="img-fluid" />
                {props.selectedMatrix.segment !== "" && props.selectedMatrix.rank !== "" && (
                  <img
                    src={mai_refresh}
                    alt="quest"
                    className="img-fluid"
                    role="button"
                    onClick={() => props.matrixHandler("", "", 0)}
                  />
                )}
              </h3>
              <p className="subtitle m-0">Click on metrix to view specific insights</p>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
              {/* <img src={mai_actionitemmatrix_chart} alt="matrix" className="img-fluid" /> */}
              <Loader isLoading={getMatrixLoader}>
                <div className="matrix-header">
                  <div className="matrix-header-inner">
                    <p className="subtitle">{matrixSegment[0].segment}</p>
                  </div>
                  <div className="matrix-header-inner">
                    <p className="subtitle">{matrixSegment[1].segment}</p>
                  </div>
                  <div className="matrix-header-inner">
                    <p className="subtitle">{matrixSegment[2].segment}</p>
                  </div>
                </div>

                <div className="matrix-content-wrap">
                  <div className="matrix-content-lheading">
                    <div className="matrix-content-innerlheading">
                      <p className="subtitle">High</p>
                    </div>
                    <div className="matrix-content-innerlheading">
                      <p className="subtitle">Medium</p>
                    </div>
                    <div className="matrix-content-innerlheading">
                      <p className="subtitle">Low</p>
                    </div>
                  </div>
                  <div className="matrix-content-box my-1">
                    <div
                      className={`matrix-content-box__inner matrix1-clr ${getMatrixClass(
                        matrixSegment[0].segment,
                        matrixSegment[0].items[0].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[0].segment,
                          matrixSegment[0].items[0].rank,
                          1
                        )
                      }
                    >
                      <p>{matrixSegment[0].items[0].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[0].items[0].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix2-clr ${getMatrixClass(
                        matrixSegment[1].segment,
                        matrixSegment[1].items[0].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[1].segment,
                          matrixSegment[1].items[0].rank,
                          2
                        )
                      }
                    >
                      <p>{matrixSegment[1].items[0].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[1].items[0].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix3-clr ${getMatrixClass(
                        matrixSegment[2].segment,
                        matrixSegment[2].items[0].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[2].segment,
                          matrixSegment[2].items[0].rank,
                          3
                        )
                      }
                    >
                      <p>{matrixSegment[2].items[0].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[2].items[0].percentage)}%
                      </span>
                    </div>

                    <div
                      className={`matrix-content-box__inner matrix4-clr ${getMatrixClass(
                        matrixSegment[0].segment,
                        matrixSegment[0].items[1].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[0].segment,
                          matrixSegment[0].items[1].rank,
                          4
                        )
                      }
                    >
                      <p>{matrixSegment[0].items[1].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[0].items[1].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix5-clr ${getMatrixClass(
                        matrixSegment[1].segment,
                        matrixSegment[1].items[1].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[1].segment,
                          matrixSegment[1].items[1].rank,
                          5
                        )
                      }
                    >
                      <p>{matrixSegment[1].items[1].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[1].items[1].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix6-clr ${getMatrixClass(
                        matrixSegment[2].segment,
                        matrixSegment[2].items[1].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[2].segment,
                          matrixSegment[2].items[1].rank,
                          6
                        )
                      }
                    >
                      <p>{matrixSegment[2].items[1].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[2].items[1].percentage)}%
                      </span>
                    </div>

                    <div
                      className={`matrix-content-box__inner matrix7-clr ${getMatrixClass(
                        matrixSegment[0].segment,
                        matrixSegment[0].items[2].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[0].segment,
                          matrixSegment[0].items[2].rank,
                          7
                        )
                      }
                    >
                      <p>{matrixSegment[0].items[2].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[0].items[2].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix8-clr ${getMatrixClass(
                        matrixSegment[1].segment,
                        matrixSegment[1].items[2].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[1].segment,
                          matrixSegment[1].items[2].rank,
                          8
                        )
                      }
                    >
                      <p>{matrixSegment[1].items[2].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[1].items[2].percentage)}%
                      </span>
                    </div>
                    <div
                      className={`matrix-content-box__inner matrix9-clr ${getMatrixClass(
                        matrixSegment[2].segment,
                        matrixSegment[2].items[2].rank
                      )}`}
                      onClick={() =>
                        props.matrixHandler(
                          matrixSegment[2].segment,
                          matrixSegment[2].items[2].rank,
                          9
                        )
                      }
                    >
                      <p>{matrixSegment[2].items[2].value}</p>
                      <span>
                        {UtilService.numberFormatter(matrixSegment[2].items[2].percentage)}%
                      </span>
                    </div>
                  </div>
                  <div className="matrix-content-lheading rheading">
                    <div className="matrix-header-inner">
                      <p className="subtitle"> CLV</p>
                    </div>
                  </div>
                </div>
                <div className="matrix-header">
                  <div className="matrix-header-inner w-100">
                    <p className="subtitle"> Conversion Rate</p>
                  </div>
                </div>
              </Loader>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-9 col-md-9 col-sm-12">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="insights__innerwrapper">
              {getClvSummaryLoader ? (
                <Loader style={{ minHeight: "106px" }} />
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h2 className="inner__heading">
                        <img src={mai_revenue_icon} alt="email" className="img-fluid" />{" "}
                        <span> Existing Revenue</span>{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="insights-data">
                        <div>
                          <div className="innertext__size">
                            <h2 className="blueshade titleblack">
                              ${UtilService.numberFormatter(clvSummaryData.total_revenue)}
                              <span className="increase_horizontal">+4%</span>
                            </h2>
                            <p>{revenueTimeframe}</p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="insights__innerwrapper clvrevenuegrowth-wrapper">
              {getClvSummaryLoader ? (
                <Loader style={{ minHeight: "106px" }} />
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h2 className="inner__heading">
                        <img
                          src={mai_revenue_opportunity_icon}
                          alt="revenue"
                          className="img-fluid"
                        />{" "}
                        <span> CLV</span>{" "}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="insights-data align-items-center">
                        <div>
                          <div className="innertext__size">
                            <h2 className="greenshade mb-1 titleblack">
                              ${UtilService.numberFormatter(clvSummaryData.projected_revenue)}{" "}
                            </h2>
                            <p>Projected Revenue</p>
                          </div>
                        </div>
                        <div>
                          <div className="text-end">
                            <h4 className="avgclv mb-1">
                              ${UtilService.numberFormatter(clvSummaryData.avg_clv)}
                            </h4>
                            <p>Avg. CLV</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <h4 className="monthsrange">{predictedTimeframe}</h4>
                      <div className="insights-data clvrevenue-growth">
                        <div>
                          <div className="innertext__size">
                            <h2 className="titleblack">
                              ${UtilService.numberFormatter(clvSummaryData.incremental_revenue)}{" "}
                            </h2>
                            <p>Incremental Revenue</p>
                          </div>
                        </div>
                        <div>
                          <div className="text-end">
                            <h2 className="titleblack">
                              {UtilService.numberFormatter(clvSummaryData.growth)}{" "}
                            </h2>
                            <p>% Growth</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="insights__innerwrapper">
              <div className="predictedclv p-1 bg-transparent">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <h3 className="inner__heading f-18 mb-3">
                      <span> Predicted CLV </span>{" "}
                      <img src={mai_question_icon} alt="quest" className="img-fluid" />
                    </h3>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <div className="custprofile-chartindicator">
                      <ul className="list-inline m-0">
                        <li className="list-inline-item me-3">
                          <img
                            src={mai_existingrevenue_indicate}
                            alt="existing revenue"
                            className="img-fluid"
                          />
                          <span> Revenue</span>
                        </li>
                        <li className="list-inline-item">
                          <img src={mai_predictedCLV} alt="product clv" className="img-fluid" />
                          <span> Predicted CLV</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    {getMatrixPredictionDataLoader ? (
                      ''
                    ) : (
                      <div className="avgrevenuenpredictedclv">
                        <div>
                          <p>Avg. Revenue</p>
                          <h3>$ {UtilService.numberFormatter(avgCLVRevenue.avg_revenue)} </h3>
                        </div>
                        <div className="ms-3">
                          <p>Avg. Predicted CLV</p>
                          <h3 className="greencolor" style={{color: props.selectedMatrix.color ? props.selectedMatrix.color.toString() : "#69efb5"}}>
                            ${UtilService.numberFormatter(avgCLVRevenue.avg_predicted_clv)}{" "}
                          </h3>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {getMatrixPredictionDataLoader ? (
                      <Loader style={{ minHeight: "210px" }} />
                    ) : (
                      <ChartComponent chartConfigs={clvPredictionChartConfig} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </Loader> */}
    </Fragment>
  );
};
export default PredictedClvChart;
