import { Fragment } from "react"
import { Outlet } from "react-router-dom";

const AbTestCreateComponent = () => {
    return (
        <Fragment>
           <Outlet />
        </Fragment>
    )
}
export default AbTestCreateComponent;