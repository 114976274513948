import { Outlet } from "react-router-dom";
import VeryActiveCustomersComponent from "./veryactive/VeryActiveCustomersComponent";


const CustomerLensDashboardComponent = () => {
    return (
        <Outlet/>
    )
}

export default CustomerLensDashboardComponent;