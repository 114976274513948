import { Fragment } from "react";
import {
    mai_key_icon,
    mai_audience_icon,
    mai_duration_icon,
    mai_calendar_blue_icon
} from "../../../../../../assets/images";
import './AbTestGeneralDetails.scss';

const AbTestGeneralDetailsComponent = () => {
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Statistical Significance Calculator</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper abtestdetailswrap'>
                <div className='audiencecreate__form box-shadow whiteboxheight'>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='boxmin-height'>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-11 col-md-11 col-sm-12">
                                                <div className="row mb-3">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <h2 className="title16 m-0"><span> New Product Adaptation </span> </h2>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="productadoptbox">
                                                            <img src={mai_calendar_blue_icon} alt="calendar" className="img-fluid" />
                                                            <p>Start Date</p>
                                                            <h3>24  Nov 2021</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="productadoptbox">
                                                            <img src={mai_duration_icon} alt="duration" className="img-fluid" />
                                                            <p>Duration of The Test</p>
                                                            <h3> &lt;= 1 Week</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="productadoptbox">
                                                            <img src={mai_audience_icon} alt="audience" className="img-fluid" />
                                                            <p>Audience</p>
                                                            <h3>North-East Region</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="productadoptbox">
                                                            <img src={mai_key_icon} alt="key" className="img-fluid" />
                                                            <p>Primary KPI</p>
                                                            <h3> CTR</h3>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-5">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <div className="stakeholderbox">
                                                            <h2>Stakeholder</h2>
                                                            <p>John Dalton</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-12"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="adapthistorybox pe-5">
                                            <div className="adapthistorybox__inner adapthistory-desc">
                                                <h4>Description</h4>
                                                <p>We want to test whether a new product design (UX, messaging, feature, etc.) can bring about an improvement in a metric that translates into product success.</p>
                                            </div>
                                            <div className="adapthistorybox__inner adapthistory-hypothesis">
                                                <h4>Hypothesis</h4>
                                                <p>Highlighting the product features might help increase sales & revenue during the  period.</p>
                                            </div>
                                            <div className="adapthistorybox__inner adapthistory-result">
                                                <h4>Result</h4>
                                                <p>The message was not able to garner much attention and generate more revenue during the Black Friday period.</p>
                                            </div>
                                            <div className="adapthistorybox__inner adapthistory-learning">
                                                <h4>Learning Outcome</h4>
                                                <p>Many clients did not notice the banner and received few clicks.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3"></div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Prev</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default AbTestGeneralDetailsComponent;