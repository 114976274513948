import { Fragment } from "react";
import './SpendOptimizationGeneralDetails.scss';


const SpendOptimizationGeneralDetailsComponent = () => {

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Define channel theme</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Spend optimization</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper'>
                <div className='audiencecreate__form whiteboxheight'>
                    <form>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label mai-form-label">Name</label>
                                            <input type="text" className="form-control mai-input" id="name" name="name" maxLength={200} aria-describedby="emailHelp" placeholder='Enter recommendation name' />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="">
                                            <label htmlFor="audienceDesc" className="form-label mai-form-label">Description</label>
                                            <textarea id="audienceDesc" className='form-control mai-textarea' maxLength={500} placeholder='Enter description'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="my-4">
                                            <div className="form-check form-check-inline ps-0">
                                                <label className="form-check-label mai-label-access">Access</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="public" value="Public" checked />
                                                <label className="form-check-label mai-radio-label" htmlFor="public">Public</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="private" value="Private" />
                                                <label className="form-check-label mai-radio-label" htmlFor="private">Private</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="">
                                            <label htmlFor="sharewith" className="form-label mai-label-sharewith">Share With <span>Optional</span></label>
                                            <input type="text" className="form-control mai-input" id="sharewith" name="sharewith" placeholder='Search Users' data-bs-target="#sharewithmodal" data-bs-toggle="modal" readOnly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                            <button type="button" className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="button" className="btn btn-primary mai-cancel-button me-3">Save as draft</button>
                                            {/* <button type="submit" className="btn btn-primary mai-next-button" disabled={!enableNext}>Next</button> */}
                                            <button type="button" className="btn btn-primary mai-next-button" disabled>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                <div className="modal fade audiencedetails__addkpimodal sharewith-modal" id="sharewithmodal" aria-labelledby="sharewithModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title popup-title fs-5" id="sharewithModalLabel">Share access with</h1>
                                <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <form action="">
                                            <input type="text" className="form-control mai-input" id="sharewith" name="sharewith" placeholder='Search and add members' />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default SpendOptimizationGeneralDetailsComponent;