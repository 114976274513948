import { Fragment } from "react";
import { NavLink } from "react-router-dom";

const PageNotFoundComponent = () => {
    return (
        <Fragment>
            Invalid Url. Please enter valid Url or <NavLink to="/">Click Here</NavLink> to Login.
        </Fragment>
    )
}

export default PageNotFoundComponent;