import { Avatar } from "primereact/avatar";
import { useEffect, useState, useCallback } from "react";
import AuthenticationService from "../../../services/authentication.service";
import UtilService from "../../../services/util.service";
import './userAvatar.scss';

interface Props {
    id: any
    name?: any
    size?: 'normal' | 'large' | 'xlarge'
}
export function UserAvatar(props: Props) {
    const [avatar, setAvatar] = useState('');
    const [label, setLabel] = useState< string | undefined>(undefined);
    const [name, setName] = useState< string >('');
    const onAvatarFetchComplete = useCallback((res: any) => setAvatar(res.image), []);
    const onAvatarFetchError = useCallback(() => setAvatar(''), []);
    const onUserFetchComplete = useCallback((res: any) => {
        setLabel(UtilService.getShortName(res.user.name));
        setName(String(res.user.name));
    }, []);
    const onUserFetchError = useCallback(() => setLabel(undefined), []);
    const classes: string[] = ['avatar1','avatar2','avatar3','avatar4','avatar5','avatar6','avatar7','avatar8','avatar9','avatar0'];
    useEffect(() => {
        if(props.id){
            // Fetch USer Avatar
            // AuthenticationService.getUserAvatar(props.id).then(onAvatarFetchComplete).catch(onAvatarFetchError);
        }
    }, [props.id]);

    useEffect(() => {
        if (props.name === undefined) {
            AuthenticationService.fetchUserDetails(props.id).then(onUserFetchComplete).catch(onUserFetchError);
        } else {
            setLabel(UtilService.getShortName(props.name))
            setName(String(props.name))
        }
    }, [props.id, props.name]);

    const size = props.size ?? 'normal'
    return (
        <>
            {label !== undefined && <Avatar className={`user-avatar ${classes[name.length % 10]}`} label={label} image={avatar} shape="circle" size={size} />}
        </>
    )
}