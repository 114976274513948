import { Action, StoreActions } from "./actions";

export interface ToasterType {
    severity: string,
    summary: string,
    detail: string,
    life: number
}
export interface ToasterConfig {
    enable: boolean,
    config: ToasterType
}
export const defaultToastConfig = {
    enable: false,
    config:
    {
        severity: '', summary: '', detail: '', life: 3000
    }
} as ToasterConfig;

export function toastConfigReducer(state: ToasterConfig = defaultToastConfig, action: Action<ToasterConfig['config']>): ToasterConfig {
    switch(action.type) {
        case StoreActions.TOAST_OFF : {
            return {
                ...state,
                enable: false,
                config: {...defaultToastConfig.config}
            };
        }
        
        case StoreActions.TOAST_ON: {
            return {
                ...state,
                enable: true,
                config: { ...action.payload}
            };
        }
        default:
            return state;
    }
}
