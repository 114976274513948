import { defaultUser, LoggedInUser, currentUserReducer } from "./currentUser";
import { defaultToastConfig, toastConfigReducer, ToasterConfig } from "./toast";
import { defaultTitle, headerTitleReducer, HeaderTitle} from "./headerTitle";
// import { defaultFeatureActions, FeatureActionsType, permissionsReducer } from './permissions';

export type StoreState = {toastConfig: ToasterConfig, currentUser: LoggedInUser, headerContent: HeaderTitle};

const initialState: StoreState = {
    toastConfig: defaultToastConfig,
    currentUser: defaultUser,
    headerContent: defaultTitle
    // userPermissions: defaultFeatureActions
};

export const reducers = {
    toastConfig: toastConfigReducer,
    currentUser: currentUserReducer,
    headerContent: headerTitleReducer
    // userPermissions: permissionsReducer
};
