import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { StoreActions } from "../store/actions";
import { ToastSeverity, HttpMethods } from "../models/constants";
import { HttpConfig } from "../models/types";
const useHttp = () => {
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const showToast = useDispatch();
    const sendRequest = useCallback(async (config: HttpConfig | any, responseAction: (data: any) => any, failureAction?: (data: any) => any) => {
        setLoading(true);
        try {
            const method: HttpMethods = config?.method ? config?.method : HttpMethods.GET;
            const body: {} | undefined = config?.body ? config?.body : undefined;
            const res: {} = await config.instance[method](config.url, body);
            responseAction(res);
            setError(false);
        } catch (err: any) {
            setLoading(false);
            setError(true);
            if (failureAction) {
                failureAction(err);
            } else {
                showToast({
                    type: StoreActions.TOAST_ON,
                    payload: {
                        severity: ToastSeverity.WARN,
                        summary: "Information",
                        detail: `${err.response.data.error ? err.response.data.error : err.response.data.detail}`,
                        life: 3000,
                    },
                });
            }
        } finally {
            setLoading(false);
        }
    }, [showToast]);
    return { sendRequest, loading, error };
};


export default useHttp;
