import React, { Fragment, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { NavLink } from 'react-router-dom';

import './RecommendationDetails.scss';
import {
    mai_share_icon,
    mai_back_icon,
    mai_gray_dots,
    mai_question_icon,
    mai_growth_icon,
    mai_product_icon,
    mai_revenue_icon,
    mai_revenue_opportunity_icon,
    mai_BG_primary,
    mai_start_blue_icon,
    mai_customer_churn_icon,
    mai_conversion_rate_icon,
    mai_cac_icon,
    mai_bypersona_chart,
    mai_geographychart_distributionchart,
    mai_customersbg_icon,
    mai_revenuebg_icon,
    mai_opportunitybg_icon,
    mai_growthbg_icon,
    mai_search_bar,
    mai_save_audience_icon,
    mai_export_icon,
    mai_filter_wbg_icon,
    mai_customer_profile_pic,
    mai_contract_expiry_date,
    mai_lease_icon,
    mai_payment_method_icon,
    mai_tenure_icon,
    mai_industry_icon,
    mai_region_icon,
    mai_products_icon,
    mai_prod_adoption_path_chart,
    mai_existing_revenue_chart,
    mai_currentprodforcast_indicate,
    mai_productforcast_indicate,
    mai_existingrevenue_indicate
} from '../../../../../assets/images/index';

const RecommendationDetailsComponent = () => {

    const recomendationlist = [
        { "customers": "1000", "createdby": "AJ", "name": "Recommendations", "description": "Product Description", "sharedwith": "user.png", "lastupdated": "26/06/22", "status": "Published", "actions": 5 },
        { "customers": "1001", "createdby": "GJ", "name": "Recommendations 1", "description": "Product Description", "sharedwith": "black-watch.jpg", "lastupdated": "24/06/22", "status": "Draft", "actions": 4 },
        { "customers": "1002", "createdby": "GH", "name": "Recommendations 2", "description": "Product Description", "sharedwith": "blue-band.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 },
        { "customers": "1003", "createdby": "AJ", "name": "Recommendations 3", "description": "Product Description", "sharedwith": "blue-t-shirt.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 5 },
        { "customers": "1004", "createdby": "AJ", "name": "Recommendations 4", "description": "Product Description", "sharedwith": "bracelet.jpg", "price": 15, "lastupdated": "12/06/22", "status": "Published", "actions": 4 },
        { "customers": "1005", "createdby": "PJ", "name": "Recommendations 5", "description": "Product Description", "sharedwith": "brown-purse.jpg", "lastupdated": "19/06/22", "status": "Draft", "actions": 4 },
        { "customers": "1006", "createdby": "DK", "name": "Recommendations 6", "description": "Product Description", "sharedwith": "chakra-bracelet.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 },
        { "customers": "1007", "createdby": "MM", "name": "Recommendations 7", "description": "Product Description", "sharedwith": "galaxy-earrings.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 5 },
        { "customers": "1008", "createdby": "VM", "name": "Recommendations 8", "description": "Product Description", "sharedwith": "game-controller.jpg", "lastupdated": "22/06/22", "status": "Published", "actions": 4 },
        { "customers": "1009", "createdby": "CM", "name": "Recommendations 9", "description": "Product Description", "sharedwith": "gaming-set.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 }
    ]


    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper audiencedetails recomdsummary-wrapper customtabs-wrap px-0'>
                <div className='audiencedetails__title mb-2'>
                    <NavLink to="#"> <img src={mai_back_icon} alt="back" className="img-fluid" /></NavLink>
                    <span>Recommendations Renewal Campaign</span>
                    <NavLink to="#"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink>
                </div>
                <div className="audiencedetails__tabs">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" id="segments-tab" data-bs-toggle="tab" data-bs-target="#segments" href="#">Insights</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="segmentpersonas-tab" data-bs-toggle="tab" data-bs-target="#segmentpersonas" href="#">Customers</a>
                        </li>
                    </ul>

                    <div className="tab-content" id="tabContent">

                        <div className="tab-pane fade show active" id="segments" role="tabpanel" aria-labelledby="segments-tab">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="insights__innerwrapper">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h2 className='inner__heading'><img src={mai_product_icon} alt="web" className="img-fluid" /> <span> Products</span> </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className='insights-data'>
                                                    <div><h3>5</h3></div>
                                                    <div>
                                                        <h2 className="mb-2">55,533</h2>
                                                        <p>Cohort</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="insights__innerwrapper dropdownmain-wrapper-end">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h2 className='inner__heading'><img src={mai_revenue_icon} alt="email" className="img-fluid" /> <span> Existing Revenue</span> </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className='insights-data'>
                                                    <div>
                                                        <div className='innertext__size'>
                                                            <h2 className='blueshade mb-2'>$ 7.8M <span className="increase_horizontal">+7%</span></h2>
                                                            <p>2020-2022FY</p>
                                                        </div>
                                                    </div>
                                                    <div></div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="insights__innerwrapper dropdownmain-wrapper-end">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h2 className='inner__heading'><img src={mai_revenue_opportunity_icon} alt="revenue" className="img-fluid" /> <span> Revenue Opportunity</span> </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className='insights-data'>
                                                    <div>
                                                        <div className='innertext__size'>
                                                            <h2 className='greenshade mb-2'>$ 2.34M </h2>
                                                            <p>Total</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='text-end mb-2'>
                                                            <h4 className=''>$ 7.8M </h4>
                                                            <p>Upsell</p>
                                                        </div>
                                                        <div className='text-end'>
                                                            <h4 className=''>$ 7.8M </h4>
                                                            <p>Cross sell</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="insights__innerwrapper dropdownmain-wrapper-end">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h2 className='inner__heading'><img src={mai_growth_icon} alt="growth" className="img-fluid" /> <span> Growth Opportunity </span> </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className='insights-data'>
                                                    <div>
                                                        <div className='innertext__size'>
                                                            <h2 className='greenshade mb-2'>30%</h2>
                                                            <p>Total</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='text-end mb-2'>
                                                            <h4 className=''>22%</h4>
                                                            <p>Upsell</p>
                                                        </div>
                                                        <div className='text-end'>
                                                            <h4 className=''>20%</h4>
                                                            <p>Cross sell</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="box-shadow-wrapper recomd-leftcolmn">
                                        <div className="recomdetails-lefttop">
                                            <div>
                                                <h4>Products</h4>
                                            </div>
                                            <div>
                                                <ul>
                                                    <li><i className="pi pi-circle-fill"></i> Existing revenue</li>
                                                    <li><i className="pi pi-circle-fill"></i> Opportunity</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="recom-prodlist">
                                            <ul>
                                                <li className="active">
                                                    <span> Product Ar</span>
                                                    <img src={mai_BG_primary} alt="" className="img-fluid" />
                                                </li>
                                                <li>
                                                    <span> Product Sg</span>
                                                    <img src={mai_BG_primary} alt="" className="img-fluid" />
                                                </li>
                                                <li>
                                                    <span> Product Ri</span>
                                                    <img src={mai_BG_primary} alt="" className="img-fluid" />
                                                </li>
                                                <li>
                                                    <span> Product Fd</span>
                                                    <img src={mai_BG_primary} alt="" className="img-fluid" />
                                                </li>
                                                <li>
                                                    <span> Product Cr</span>
                                                    <img src={mai_BG_primary} alt="" className="img-fluid" />
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="box-shadow-wrapper recomd-cacconversion">
                                                <div className="recomd-left recomd-innerwrap">
                                                    <div><img src={mai_cac_icon} alt="cac" /> </div>
                                                    <div>
                                                        <h3>$ 324</h3>
                                                        <p>CAC</p>
                                                    </div>
                                                </div>
                                                <div className="recomd-right recomd-innerwrap">
                                                    <div><img src={mai_conversion_rate_icon} alt="conversion rate" /> </div>
                                                    <div>
                                                        <h3>$ 12</h3>
                                                        <p>Conversion Rate</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="box-shadow-wrapper recomd-cacconversion">
                                                <div className="recomd-left recomd-innerwrap">
                                                    <div><img src={mai_customer_churn_icon} alt="shurn" /> </div>
                                                    <div>
                                                        <h3>6%</h3>
                                                        <p>Customer Churn Rate</p>
                                                    </div>
                                                </div>
                                                <div className="recomd-right recomd-innerwrap">
                                                    <div><img src={mai_start_blue_icon} alt="star" /> </div>
                                                    <div>
                                                        <h3>3</h3>
                                                        <p>Avg. NPS</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="box-shadow-wrapper p-2">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="insights__innerwrapper shadow-none recomdinsights">
                                                            <div className="row">
                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                    <h2 className='inner__heading'><span> By persona </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                                                    <div className="tabs-wrapper customtabs">
                                                                        <TabView>
                                                                            <TabPanel header="Revenue">
                                                                                <img src={mai_bypersona_chart} alt="chart" className="img-fluid" />
                                                                            </TabPanel>
                                                                            <TabPanel header="Opportunity">
                                                                                <img src={mai_bypersona_chart} alt="chart" className="img-fluid" />
                                                                            </TabPanel>
                                                                        </TabView>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="insights__innerwrapper shadow-none d-block">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <h2 className='inner__heading'><span> Geographical distribution</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                                                </div>

                                                            </div>

                                                            <div className="customer_chart">
                                                                <img src={mai_geographychart_distributionchart} alt="revenue" className="img-fluid w-100" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="recomd-cacconversion py-0">
                                                            <div className="recomd-right recomd-innerwrap w-100 px-0">
                                                                <div><img src={mai_customersbg_icon} alt="customers" className="img-fluid me-2" /> </div>
                                                                <div>
                                                                    <h3>200</h3>
                                                                    <p>Customers</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="recomd-cacconversion py-0">
                                                            <div className="recomd-right recomd-innerwrap w-100 px-0">
                                                                <div><img src={mai_customersbg_icon} alt="customers" className="img-fluid me-2" /> </div>
                                                                <div>
                                                                    <h3>$90k <span className="increase_horizontal">+4%</span></h3>
                                                                    <p>Existing Revenue</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="recomd-cacconversion py-0">
                                                            <div className="recomd-right recomd-innerwrap w-100 px-0">
                                                                <div><img src={mai_opportunitybg_icon} alt="opportunity" className="img-fluid me-2" /> </div>
                                                                <div>
                                                                    <h3>$40k</h3>
                                                                    <p>Opportunity</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="recomd-cacconversion py-0">
                                                            <div className="recomd-right recomd-innerwrap w-100 px-0">
                                                                <div><img src={mai_growthbg_icon} alt="growth" className="img-fluid me-2" /> </div>
                                                                <div>
                                                                    <h3>45%</h3>
                                                                    <p>Growth Opportunity</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tab-pane fade" id="segmentpersonas" role="tabpanel" aria-labelledby="segmentpersonas-tab">
                            <div className="audiencecreate__form audiencelist boxshadow">
                                <div className="row mb-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="rules__searchbox">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend border-0">
                                                            <button
                                                                id="button-addon4"
                                                                type="button"
                                                                className="btn btn-link text-info"
                                                            >
                                                                <img
                                                                    src={mai_search_bar}
                                                                    alt="search"
                                                                    className="img-fluid"
                                                                />
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-describedby="button-addon4"
                                                            className="form-control border-0"
                                                        // onChange={audienceTextSearchHandler}
                                                        // value={searchKey}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="audiencelist__select">
                    <label htmlFor="createdby">Created by</label>
                    <select
                      className="form-select"
                      id="createdby"
                      defaultValue={"All"}
                      value={creatorKey}
                      onChange={creatorChangeHandler}
                    >
                      <option value={''}>All</option>
                      {!getCreatorsLoader &&
                        creatorList.map((item: { id: string, name: string }) => <option key={item.id} value={item.id}>{item.name}</option>)
                      }
                    </select>
                  </div>
                </div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="heading-button d-flex justify-content-end segment-button">
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#filterpopup"><img src={mai_filter_wbg_icon} alt="filter" className="img-fluid" /> Filters</NavLink>
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#saveasaudience"><img src={mai_save_audience_icon} alt="save audience" className="img-fluid" /> Save as audience</NavLink>
                                            <NavLink to="#" data-bs-toggle="modal" data-bs-target="#customerprofile"><img src={mai_export_icon} alt="export" className="img-fluid" /> Export</NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="card list-datatable audiencelist-datatable audiencedetails-table top20customerwrap border-0">
                                        <DataTable value={recomendationlist} responsiveLayout="scroll">
                                            <Column field="name" header="Name"></Column>
                                            <Column field="customers" header="customers"></Column>
                                            <Column field="description" header="description"></Column>
                                            <Column field="createdby" header="createdby"></Column>
                                            <Column field="sharedwith" header="sharedwith"></Column>
                                            <Column field="lastupdated" header="lastupdated"></Column>
                                            <Column field="status" header="status"></Column>
                                            <Column field="actions" header="actions"></Column>
                                        </DataTable>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Save as Audience Modal pop up  */}
            <div className="modal fade audiencedetails__addkpimodal saveasaudiencePopup" id="saveasaudience" aria-labelledby="addkpiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Save as audience</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked1">
                                                Customers
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked2">
                                                Industry  Segment
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked3" />
                                            <label className="form-check-label" htmlFor="checked3">
                                                Payment Frequency
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked4" />
                                            <label className="form-check-label" htmlFor="checked4">
                                                Contact ID
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked5">
                                                Product number
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked6" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked6">
                                                BPN
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked7" />
                                            <label className="form-check-label" htmlFor="checked7">
                                                PBI Segment
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked8" />
                                            <label className="form-check-label" htmlFor="checked8">
                                                Contact Email
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked9" />
                                            <label className="form-check-label" htmlFor="checked9">
                                                Annual Sales
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked10" />
                                            <label className="form-check-label" htmlFor="checked10">
                                                Contract No.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked11" />
                                            <label className="form-check-label" htmlFor="checked11">
                                                No. of Employess
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked12" />
                                            <label className="form-check-label" htmlFor="checked12">
                                                Rep ID
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked13" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked13">
                                                Industry
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked14" />
                                            <label className="form-check-label" htmlFor="checked14">
                                                Duns No.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked15" />
                                            <label className="form-check-label" htmlFor="checked15">
                                                Avg. email open rate
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked16" />
                                            <label className="form-check-label" htmlFor="checked16">
                                                Rep Email
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filter Popup */}
            <div className="modal fade filter-popup recomd-filter" id="filterpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Filters</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="countries" className="form-label">Recommended products</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>Personas</h2>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                    <label htmlFor="countries" className="form-label">Industry</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="countries" className="form-label">Segment</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mai-next-button">Save</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Customer Profile Popup */}
            <div className="modal fade customerprofilepop-wrap" id="customerprofile" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Customer profile <NavLink to="#" className="ms-2"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink></h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-4">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="customerprofilepic text-center">
                                        <img src={mai_customer_profile_pic} alt="profile pic" className="img-fluid" />
                                        <h3>Jack Wills Corp.</h3>
                                        <p>Onboarded in 2015</p>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12">
                                    <div className="customerprofiledesc">
                                        <div className="row mb-3">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_products_icon} alt="products" className="img-fluid profilesicons" />
                                                    <div className="profiletitle profiletitlewidth">
                                                        <h4>Primary Products</h4>
                                                    </div>
                                                    <div className="profilesname">
                                                        <span className="color1">Ar</span>
                                                        <span className="color2">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_revenue_icon} alt="" className="img-fluid profilesicons" />
                                                    <div className="profiletitle profiletitlewidth">
                                                        <h4>Annual Revenue</h4>
                                                    </div>
                                                    <div className="profilesname">
                                                        <h2 className="totalrevenuenumber">$ 6k <p className="increase_horizontal">+7%</p></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_region_icon} alt="region" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>North-East, USA</h4>
                                                        <p className="subtitle">Region</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_industry_icon} alt="industry" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Manufacturing</h4>
                                                        <p className="subtitle">Industry</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_tenure_icon} alt="tenure" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>4 years</h4>
                                                        <p className="subtitle">Tenure</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_payment_method_icon} alt="payment method" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Monthly</h4>
                                                        <p className="subtitle">Payment method</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_lease_icon} alt="lease" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Lease</h4>
                                                        <p className="subtitle">Contract type</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_contract_expiry_date} alt="expiry" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>23/11/2023</h4>
                                                        <p className="subtitle">Contract expiry date</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <h2 className="inner__heading mb-2">
                                        <span>Product adoption path</span>
                                        <img src={mai_question_icon} alt="quest" className="img-fluid" />
                                    </h2>
                                    <div className="productsadoppathwrap custprofilebox">
                                        <img src={mai_prod_adoption_path_chart} alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className="inner__heading mb-2">
                                        <span>Existing revenue vs forecast</span>
                                        <img src={mai_question_icon} alt="quest" className="img-fluid" />
                                    </h2>
                                    <div className="productsadoppathwrap custprofilebox">
                                        <img src={mai_existing_revenue_chart} alt="" className="img-fluid" />
                                        <div className="custprofile-chartindicator">
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <img src={mai_existingrevenue_indicate} alt="existing revenue" className="img-fluid" />
                                                    <span> Existing revenue</span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <img src={mai_productforcast_indicate} alt="product" className="img-fluid" />
                                                    <span> Recommended product forecast</span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <img src={mai_currentprodforcast_indicate} alt="current product" className="img-fluid" />
                                                    <span> Current products forecast</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mai-next-button">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RecommendationDetailsComponent;