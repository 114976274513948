import React, { useCallback, useEffect, useState } from 'react'
import { mai_question_icon } from '../../../../../../assets/images';
import { Columns } from '../../../../../../models/types';
import UtilService from '../../../../../../services/util.service';
import DataTable from '../../../../../../shared/components/datatable/DataTable'
import { ProgressBar } from 'primereact/progressbar'
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../shared/components/loader/Loader';
import { AudienceDataColumn } from '../../../../../../models/dataTemplate';

const IndustryComponent = (props: any) => {
    const [tableData, setTableData] = useState<any>([]);
    let params = useParams();
    const { sendRequest: getAudienceInsightsProfile, loading: getAudienceInsightsProfileLoader } = useHttp();
    const audienceInsightsProfile = useCallback((groupBy: any) => {
        const filter: any = [];
        const group_by: any = [groupBy];
        const resHandler = (res: any) => {
            const updatedIndustrySet: any = res.response.profile.map((profile: any) => {
                return {
                    name: profile.label,
                    customers: {
                        value: profile.customer,
                        percent: UtilService.percentageCalculator(profile.customer, props?.cohortUniverse?.cohort?.customer)
                    },
                    revenue: {
                        value: profile.revenue,
                        percent: UtilService.percentageCalculator(profile.revenue, props?.cohortUniverse?.cohort?.revenue)
                    }
                }
            });
            setTableData(updatedIndustrySet);
        }
        if (props.region) {
            filter.push({ field: AudienceDataColumn.REGION, label: "Region", value: { in: [props.region] }, operator: "null" });
        }
        if (props.start_date && props.end_date) {
            getAudienceInsightsProfile(ApiService.getAudienceInsightsProfile({ aid: params.aid, group_by, start_date: props.start_date, end_date: props.end_date, filter }), resHandler)
        }
    }, [getAudienceInsightsProfile, params.aid, props?.cohortUniverse?.cohort?.customer, props?.cohortUniverse?.cohort?.revenue, props.end_date, props.region, props.start_date]);
    useEffect(() => {
        audienceInsightsProfile(AudienceDataColumn.INDUSTRY);
    }, [audienceInsightsProfile, props]);

    const customersBodyTemplate = (rowData: any) => {
        return <div className="progressbar">
            <h4>{rowData?.customers?.value}</h4>
            <ProgressBar value={rowData?.customers?.percent}></ProgressBar>
        </div>
    }
    const revenueBodyTemplate = (rowData: any) => {
        return <h3 className="data-per">${UtilService.numberFormatter(Number(rowData?.revenue?.value))}<span>{rowData?.revenue?.percent?.toFixed(0)}%</span></h3>
    }

    const columns: Columns[] = [
        { field: "name", header: "", sort: false },
        {
            field: "customers.value",
            header: "Customers",
            body: customersBodyTemplate,
            sort: true,
        },
        {
            field: "revenue.value",
            header: "Revenue",
            body: revenueBodyTemplate,
            sort: true,
        },
    ];
    return (
        <>
            <div className="insights__innerwrapper industries-customerRevenue">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className='inner__heading mb-2'><span> Industries</span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                            <div className="dropdown industries-dropdown">
                                <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="pi pi-ellipsis-v dots3"></span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                    <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                </ul>
                            </div>
                        </div> */}
                </div>
                <div className='industries_datatable'>
                    {(getAudienceInsightsProfileLoader || !props?.cohortUniverse?.cohort) ? <Loader style={{ minHeight: "320px" }} /> :
                        <DataTable
                            data={tableData}
                            columns={columns}
                        ></DataTable>
                    }
                </div>
            </div>
        </>
    )
}

export default React.memo(IndustryComponent)