import { SessionStorageKeys } from "../models/constants";
import AuthenticationService from "../services/authentication.service";

const REFRESH_URL = 'auth/token/refresh';
const JwtInterceptor = (instance: any) => {
    // Add a request interceptor
    instance.interceptors.request.use((request: any) => {
        // add auth header with jwt if account is logged in and request is to the api url
        const token = AuthenticationService.getSessionStorage(SessionStorageKeys.ACCESSTOKEN);
        if (!!token) {
            if (!request.url.includes(REFRESH_URL)) {
                request.headers.Authorization = `Bearer ${token}`;
                request.headers.common.Authorization = `Bearer ${token}`;
            }
            
        }
        return request;
    }, (error: any) => {
        return Promise.reject(error);
    });
    // Add a response Interceptor
    instance.interceptors.response.use((response: any) => {
        // Send only the response data back to the api calls
        if(response.status === 200) {
            return response?.data ? response?.data : response
        } else {
            return response;
        }
    }, async (error: any) => {
        const originalConfig = error.config;
        if (error.response) {
        // Access Token was expired
            // if (error.response.status === 401 && !originalConfig._retry) {
            //     originalConfig._retry = true;
            //     try {
            //         if(error.response.data.detail === 'Token is invalid or expired' && error.config.url.includes(REFRESH_URL)) {
            //             sessionStorage.clear();
            //         }
            //         const rs = await instance.post(REFRESH_URL, {
            //         // refresh: AuthenticationService.getSessionStorage('refreshtoken'),
            //         });
        
            //         const { access } = rs;
            //         // AuthenticationService.setSessionStorage('accesstoken', access);
        
            //         return instance(originalConfig);
            //     } catch (_error) {
            //         return Promise.reject(_error);
            //     }
            // }
        }
        return Promise.reject(error);
    });
}
export default JwtInterceptor;