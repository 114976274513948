import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './SegmentationDefineComponent.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { Url } from '../../../../../../models/constants';
import { BaseResponse, getSegmentationDeineResponse, getSegmentationTimeframeResponse, SegmentationDefineAttrDetail } from '../../../../../../models/types';
import { Calendar } from 'primereact/calendar';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { StoreState } from '../../../../../../store/store';

const SegmentationDefineComponent = () => {
    const params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [segmentationDefine, setSegmentationDefine] = useState<SegmentationDefineAttrDetail>({
        methodology: "AI Powered",
        min_date: "",
        max_date: "",
        no_of_segments: "2",
    });
    const { sendRequest: createSegmentDefine, loading: createSegmentDefineLoader } = useHttp();
    const { sendRequest: runSegmentationRollup, loading: runSegmentationRollupLoader } = useHttp();
    const { sendRequest: getSegmentDefine } = useHttp();
    const { sendRequest: getSegmentTimeframe } = useHttp();
    const [enableNext, setEnableNext] = useState<boolean>(false);
    const [fromdate, setFromDate] = useState<Date | undefined>();
    const [todate, setTodate] = useState<Date | undefined>();
    const [timeframe, setTimeframe] = useState<{ mindate?: Date, maxdate?: Date }>({ mindate: undefined, maxdate: undefined });
    const [validDate, setValidDate] = useState<boolean>(false);
    const header = useSelector<StoreState, any>(
      (state) => state.headerContent
    );
    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
      const payload = { title,backUrl,share };
      dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    }
    useEffect(()=>{
      if(header.title!=="Create Segmentation")
        headerTitleChangeHandler("Create Segmentation",'','');
    })
    useEffect(() => {
        if (params?.sid) {
            const resHandler = (res: getSegmentationTimeframeResponse) => {
                let mindate:Date=convertToDate(res.min_date)!;
                mindate?.setFullYear(mindate.getFullYear() +1);
                setTimeframe({ mindate, maxdate: convertToDate(res.max_date) });
                // mindate code to be removed once data is changed from backend
                setFromDate(mindate);
                setTodate(convertToDate(res.max_date));
                setSegmentationDefine({ ...segmentationDefine, min_date: convertToStirng(mindate?.toLocaleDateString()), max_date: res.max_date });
                const resHandler1 = (res: any) => {
                    if(res.segmentation.min_date !== '') {
                        setFromDate(convertToDate(res.segmentation.min_date))
                    }
                    if(res.segmentation.max_date !== '') {
                        setTodate(convertToDate(res.segmentation.max_date));
                    }
                    if(res.segmentation.min_date !== '') {
                        setSegmentationDefine({ ...segmentationDefine,min_date: res.segmentation.min_date, max_date: res.segmentation.max_date, no_of_segments: res.segmentation.no_of_segments});
                    }
                    
                }
                if(params?.sid) {
                    getSegmentDefine(ApiService.getSegmentationGeneraldetail(params?.sid), resHandler1)
                }
            }
            getSegmentTimeframe(ApiService.getSegmentationDefineTimeFrame(params?.sid), resHandler)
        }
    }, [])

    useEffect(() => {
        if (segmentationDefine.min_date !== '' && segmentationDefine.max_date !== '' && segmentationDefine.no_of_segments !== '' && Number(segmentationDefine.no_of_segments) >= 2 && Number(segmentationDefine.no_of_segments) < 9 && validDate === true) {
            setEnableNext(true);
        } else {
            setEnableNext(true);
        }
    }, [segmentationDefine, validDate]);


    const padTo2Digits = (date: number) => {
        return date.toString().padStart(2, '0');
    }

    const formatDate = (date?: Date | null) => {
        return date !== undefined && date !== null ? [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') : '';
    }

    const convertToDate = (dateString?: string) => {
        //  Convert a "yyyy-mm-dd" string into a Date object
        if (dateString !== undefined && dateString !== '') {
            let d = dateString.split("-");
            let dat = new Date(d[0] + '/' + d[1] + '/' + d[2]);
            return dat;
        } else {
            return undefined;
        }
    }
    const convertToStirng=(date:string)=>{
        let d=date?.split('/');
        return `${d[2]}-${d[0]}-${d[1]}`;
    }

    useEffect(() => {
        if (fromdate && todate && fromdate?.getTime() < todate?.getTime()) {
            setValidDate(true);
        } else {
            setValidDate(false);
        }
    }, [fromdate, todate])

    const createSegmentationDefineHandler = (event: React.FormEvent) => {
        event.preventDefault();
        // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_ATTRIBUTE}/0`);

        const resHandler = (res: BaseResponse) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_ATTRIBUTE}/${params?.sid}`);
        }
        if (params?.sid) {
            const resDefineHandler = (res: BaseResponse) => {
                runSegmentationRollup(ApiService.runSegmentationInvokeRollup(params?.sid!), resHandler);
            }
            createSegmentDefine(ApiService.patchSegmentation(params?.sid, segmentationDefine), resDefineHandler);
        }

    }
    const cancelHandler = () => {
        headerTitleChangeHandler('Segmentation List','','');
        navigate(
            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`
        )
    }
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Define</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Customer attributes</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>
            <Loader isLoading={createSegmentDefineLoader || runSegmentationRollupLoader}>
                <div className='audiencecreate__form-wrapper'>
                    <div className='audiencecreate__form whiteboxheight'>
                        <form onSubmit={createSegmentationDefineHandler}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='boxmin-height'>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-12 definewrapper">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                        <h2>Segmentation methodology</h2>
                                                        <h3>Select methodology based on the use case</h3>
                                                    </div>
                                                    <div className="col-lg-6 col-lg-6 col-sm-12 mb-4">
                                                        <div className="form-check form-check-inline formcheck-segment">
                                                            <input className="form-check-input mai-radio-button" type="radio" name="segmentmethdology" id="aipower" value="AI Powered" checked={segmentationDefine.methodology === 'AI Powered'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSegmentationDefine({ ...segmentationDefine, methodology: 'AI Powered' })} />
                                                            <label className="form-check-label mai-radio-label" htmlFor="aipower">AI powered <span>Enable dynamic segments with the power of AI</span> </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                                        <div className="form-check form-check-inline formcheck-segment">
                                                            <input className="form-check-input mai-radio-button" type="radio" name="segmentmethdology" id="advance" value="Advance" checked={segmentationDefine.methodology !== 'AI Powered'} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSegmentationDefine({ ...segmentationDefine, methodology: 'Advance' })} disabled />
                                                            <label className="form-check-label mai-radio-label" htmlFor="advance">Advanced <span>Based upon the principles of RFM/MDT</span> </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                        <h2>Segmentation timeframe</h2>
                                                    </div>

                                                    <div className="col-lg-6 col-lg-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="segmentfrom" className="form-label mai-form-label">From</label>
                                                            <div className='defineCalendar'>
                                                                <Calendar panelClassName='range-calendar-pannel' inputStyle={{ width: '50%' }} className={!validDate ? 'invaliddate' : ''} minDate={timeframe.mindate} maxDate={timeframe.maxdate} id="fromdate" value={fromdate} onChange={(e: any) => { setFromDate(e.value); setSegmentationDefine({ ...segmentationDefine, min_date: formatDate(e.value) }) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="segmentto" className="form-label mai-form-label">To</label>
                                                            <div className='defineCalendar'>
                                                                <Calendar panelClassName='range-calendar-pannel' className={!validDate ? 'invaliddate' : ''} minDate={timeframe.mindate} maxDate={timeframe.maxdate} id="todate" value={todate} onChange={(e: any) => { setTodate(e.value); setSegmentationDefine({ ...segmentationDefine, max_date: formatDate(e.value) }) }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-7 col-lg-7 col-sm-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="numberofsegment" className="form-label mai-label-sharewith">Number of segments</label>
                                                            <input type="number" className="form-control mai-input" id="numberofsegment" name="segmentnumber" placeholder='Enter Number' min="2" max="9" value={segmentationDefine.no_of_segments} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSegmentationDefine({ ...segmentationDefine, no_of_segments: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12"></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='rules__border-top'>
                                        <div className="row mt-3">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <button
                                                    className="btn btn-primary mai-cancel-button"
                                                    onClick={cancelHandler}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                <button
                                                    className="btn btn-primary mai-cancel-button"
                                                    onClick={() =>
                                                        navigate(
                                                            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_GENERAL}/${params.sid}`
                                                        )
                                                    }
                                                >
                                                    Back
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary mai-next-button ms-3"
                                                    disabled={!enableNext}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </Loader>
        </Fragment>
    )
}

export default SegmentationDefineComponent;
