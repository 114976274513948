import { Fragment, useEffect, useRef, useState } from "react";
import './Roadmap.scss';
import {
  mai_cross_gray_icon,
  mai_edit_icon,
  mai_start_date_icon,
  mai_email_blue,
  mai_plus_solid_blue,
  mai_uploadImg,
} from '../../../../../../assets/images/index';
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { BaseResponse, CampaignGeneralDetail, ContactStrategyEmailDetail, ContactStrategyGeneralDetail, createContactStrategyEmailResponse, getContactStrategyGeneralDetailResponse } from "../../../../../../models/types";
import useHttp from "../../../../../../hooks/use-http";
import CampaignLensService from "../../../../../../services/campaignlens.service";
import Loader from "../../../../../../shared/components/loader/Loader";
import { Url } from "../../../../../../models/constants";

const RoadmapComponent = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [date1, setDate1] = useState<Date | Date[] | undefined>(undefined);
  const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
  const [contactStrategy, setContactStrategy] = useState<ContactStrategyGeneralDetail>({
    campaign: "",
    objective: "",
    kpi: [],
    emails: [],
    roles: [{}],
  });
  const [campaign, setCampaign] = useState<CampaignGeneralDetail>();
  const [updateEmailId, setUpdateEmailId] = useState<string | undefined>();
  const [contactStrategyEmail, setContactStrategyEmail] = useState<ContactStrategyEmailDetail>({
    send_on: "",
    subject: "",
    headline: "",
    url: "",
    content: "",
    image: []
  });
  const [contactStrategyEmailList, setContactStrategyEmailList] = useState<{ [key in string]: ContactStrategyEmailDetail }>({});
  const [openRoleChooser, setOpenRoleChooser] = useState<boolean>(false);
  const [roleNodes, setRoleNodes] = useState<{ [key in string]: number }>({
    buyer_signer: 0,
    primary_user: 0,
    business_user: 0,
    shipping_contact: 0,
    collection_contact: 0,
    account_payable: 0
  });
  const { sendRequest: getContactStrategy, loading: getContactStrategyLoader } = useHttp();
  const { sendRequest: updateContactStrategy } = useHttp();
  const { sendRequest: getCampaignDetails } = useHttp();
  const { sendRequest: createContactStrategyEmail, loading: createContactStrategyEmailLoader } = useHttp();
  const { sendRequest: deleteContactStrategyEmail, loading: deleteContactStrategyEmailLoader } = useHttp();
  const roleChooserRef = useRef<HTMLDivElement>(null);

  const defaultRolesLabels: any = {
    buyer_signer: "Buyer/Signor",
    primary_user: "Primary user",
    business_user: "Officer/Business User",
    shipping_contact: "Shipping Contact",
    collection_contact: "Collection Contact",
    account_payable: "Account Payable"
  };
  const fetchContactStrategyDetail = () => {
    if (params?.csid) {
      const resHandler = (res: getContactStrategyGeneralDetailResponse) => {
        setContactStrategy(res.contact_strategy);
        getCampaignDetails(CampaignLensService.getCampaignGeneraldetail(res.contact_strategy.campaign), (campRes) => setCampaign(campRes.campaign));
        // setEnableNext(true);
        let allEmails: { [key in string]: ContactStrategyEmailDetail } = {} as {
          [key in string]: ContactStrategyEmailDetail;
        };
        allEmails =
          res.contact_strategy.emails?.reduce(
            (
              acc: { [key in string]: ContactStrategyEmailDetail },
              email: ContactStrategyEmailDetail
            ) => {
              if (email.id) {
                acc[email.id] = email;
              }
              return acc;
            },
            { ...contactStrategyEmailList }
          ) || {};
        setContactStrategyEmailList(allEmails);
        let _cloeRoleNodes = { ...roleNodes };
        Object.keys(res.contact_strategy.roles[0])?.forEach((element) => {
          if (Object.hasOwn(_cloeRoleNodes, element)) {
            _cloeRoleNodes[element] = res.contact_strategy.roles[0][element];
          }
        });
        setRoleNodes(_cloeRoleNodes);
      }
      getContactStrategy(CampaignLensService.getContactStrategyGeneraldetail(params?.csid), resHandler)
    }
  }

  useEffect(() => {
    fetchContactStrategyDetail();
    const handleClickOutside = (event: any) => {
      if (roleChooserRef.current && !roleChooserRef.current.contains(event.target)) {
        setOpenRoleChooser(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [])


  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (event: any) => {
    const file = event.target.files[0];
    const base64: any = await convertBase64(file);
    setContactStrategyEmail({ ...contactStrategyEmail, image: [base64] });
  };

  const createEmailHandler = (event: any) => {
    if (params?.csid) {
      if (updateEmailId) {
        const resHandler = (res: createContactStrategyEmailResponse) => {
          fetchContactStrategyDetail();
          // setEnableNext(true);
        }
        createContactStrategyEmail(CampaignLensService.patchContactStrategyEmail(params?.csid, updateEmailId, contactStrategyEmail), resHandler);
      } else {
        const resHandler = (res: createContactStrategyEmailResponse) => {
          fetchContactStrategyDetail();
          // setEnableNext(true);
        }
        createContactStrategyEmail(CampaignLensService.createContactStrategyEmail(params?.csid, contactStrategyEmail), resHandler);
      }
    }
  }

  const nodeHandler = (roleKey: string, direction: number) => {
    const _cloneRoleNodes = { ...roleNodes };
    _cloneRoleNodes[roleKey] = direction ? _cloneRoleNodes[roleKey] + 1 || 1 : (_cloneRoleNodes[roleKey] ? _cloneRoleNodes[roleKey] - 1 : 0);
    setRoleNodes(_cloneRoleNodes);

    let _cloneContactStrategy = { ...contactStrategy };
    if (_cloneContactStrategy.roles && Object.hasOwn(_cloneContactStrategy.roles[0], roleKey)) {
      _cloneContactStrategy.roles[0][roleKey] = _cloneRoleNodes[roleKey];
      setContactStrategy(_cloneContactStrategy);
    }

  }

  const roleChangeHandler = (event: any) => {
    let _cloneContactStrategy = { ...contactStrategy };
    if (event.target.checked) {
      _cloneContactStrategy.roles = _cloneContactStrategy.roles
        ? [
          {
            ..._cloneContactStrategy.roles[0],
            [event.target.value]: roleNodes[event.target.value],
          },
        ]
        : [
          {
            [event.target.value]: roleNodes[event.target.value],
          },
        ];
    } else {
      if (_cloneContactStrategy.roles) delete _cloneContactStrategy.roles[0][event.target.value];
    }
    setContactStrategy(_cloneContactStrategy);
  };

  /*
  const roleHandler = (roleKey: string, direction: number) => {
    let _cloneContactStrategy = { ...contactStrategy };
    _cloneContactStrategy.roles = _cloneContactStrategy.roles
      ? [
          {
            ..._cloneContactStrategy.roles[0],
            [roleKey]: direction ? _cloneContactStrategy.roles[0][roleKey] + 1 || 1 : (_cloneContactStrategy.roles[0][roleKey] ? _cloneContactStrategy.roles[0][roleKey] - 1 : 0),
          },
        ]
      : [
          {
            [roleKey]: direction ? 1 : 0,
          },
        ];
        
    setContactStrategy(_cloneContactStrategy);
  };
  */

  const updateContactStrategyHandler = (event: React.FormEvent) => {
    event.preventDefault();
    // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/0`);
    //enable later once api start working
    const resHandler = (res: any) => {
      navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CONTACT_STRATEGY}/${Url.CONTACT_STRATEGY_LIST}`);
    }
    if (params?.csid) {
      updateContactStrategy(CampaignLensService.patchContactStrategy(params?.csid, contactStrategy), resHandler);
    }
  }

  const deleteContactStrategyEmailHandler = (email_id: string) => {
    const resHandler = (res: BaseResponse) => {
      fetchContactStrategyDetail();
    }
    if (params?.csid) {
      deleteContactStrategyEmail(CampaignLensService.deleteContactStrategyEmail(params?.csid, email_id), resHandler);
    }
  }
  return (
    <Fragment>
      <div className="audiencecreate__steps sticky-top stickytopRecomd">
        <ul className="list-inline">
          <li className="list-inline-item audiencecreate__steps--active">General details</li>
          <li className="list-inline-item audiencecreate__steps--line steps__active-rules"></li>
          <li className="list-inline-item audiencecreate__steps--active">Campaign Roadmap</li>
        </ul>
      </div>

      <div className="audiencecreate__form-wrapper campaigngeneraldetails-wrapper roadmap-wrapper">
        <div className="audiencecreate__form whiteboxheight">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="boxmin-height">
                <div className="row">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label htmlFor="roles" className="form-label mai-label-sharewith">
                          Choose Roles
                        </label>
                        <div className="rolechooser" ref={roleChooserRef}>
                          <div
                            className="role-area"
                            onClick={() => setOpenRoleChooser(!openRoleChooser)}>
                            {contactStrategy.roles[0] && Object.keys(contactStrategy.roles[0])?.filter((item: string) => Object.hasOwn(defaultRolesLabels, item))?.map((roleItem: string) =>
                              <div className="rolefield m-1" key={roleItem}>
                                <p className="roleitem px-1">
                                  <span className="role-label">
                                    {defaultRolesLabels[roleItem]} -
                                  </span>
                                  <span className="role-value">
                                    {contactStrategy.roles[0][roleItem]}
                                  </span>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className={`chooserole-design ${openRoleChooser && "openRoleModal"}`}>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                {Object.keys(roleNodes)?.map((rnode: string) => (
                                  <div className="plusminusmainwrap" key={rnode}>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="listof-kpi py-1">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input checkboxfield"
                                              type="checkbox"
                                              value={rnode}
                                              id={rnode}
                                              onChange={roleChangeHandler}
                                              checked={
                                                contactStrategy.roles &&
                                                  Object.hasOwn(contactStrategy.roles[0], rnode)
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <label className="form-check-label" htmlFor="checked1">
                                              {defaultRolesLabels[rnode]}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                        <div className="plusminuswrap">
                                          <div className="plusminuswrap__inner">
                                            <span
                                              className="pi pi-minus"
                                              role="button"
                                              onClick={() => nodeHandler(rnode, 0)}
                                            ></span>
                                          </div>
                                          <div className="plusminuswrap__inner plusminuswrap__inner-middle">
                                            {roleNodes[rnode] || 0}
                                          </div>
                                          <div className="plusminuswrap__inner">
                                            <span
                                              className="pi pi-plus"
                                              role="button"
                                              onClick={() => nodeHandler(rnode, 1)}
                                            ></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12"></div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12"></div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="rules__border-top my-4"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <div className="roadmapDate roadmapDateGreen roadmapDot">
                      <img src={mai_start_date_icon} alt="date" className="img-fluid" />
                      <div>
                        <p>
                          Start Campaign{" "}
                          <span>{new Date(campaign?.start_date || "").toLocaleDateString()}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12"></div>
                </div>

                {
                  <Loader isLoading={getContactStrategyLoader || createContactStrategyEmailLoader || deleteContactStrategyEmailLoader}>
                    {Object.keys(contactStrategyEmailList)?.map((email_id: string, index: number) => (
                      <div className="row" key={email_id}>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                          <div className="editDate">
                            <div>
                              <p>
                                {new Date(
                                  contactStrategyEmailList[email_id].send_on
                                ).toLocaleDateString()}
                              </p>
                            </div>
                            <img src={mai_edit_icon}
                              alt="edit"
                              role="button"
                              className="img-fluid" onClick={() => {
                                setUpdateEmailId(email_id);
                                setContactStrategyEmail(contactStrategyEmailList[email_id]);
                                setDate1(new Date(contactStrategyEmailList[email_id].send_on));
                                setDate2(new Date(contactStrategyEmailList[email_id].send_on));
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#selectDatepopup"
                            />
                          </div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12">
                          <div className="emailEditSec">
                            <div className="emailEditInner">
                              <div className="flexProperty">
                                <img src={mai_email_blue} alt="email" className="img-fluid" />
                                <p
                                  role="button"
                                  onClick={() => {
                                    setUpdateEmailId(email_id);
                                    setContactStrategyEmail(contactStrategyEmailList[email_id]);
                                    setDate1(new Date(contactStrategyEmailList[email_id].send_on));
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#contactstrategyEmailpopup"
                                >
                                  <strong> {`Email ${index + 1}`}:</strong>{" "}
                                  {contactStrategyEmailList[email_id].headline}
                                </p>
                                <img
                                  src={mai_cross_gray_icon}
                                  role="button"
                                  alt="cross"
                                  className="img-fluid crossImg"
                                  onClick={() => deleteContactStrategyEmailHandler(email_id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Loader>
                }

                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <div className="editDate addEmailBorder"></div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <div className="campaignbutton addEmailBtn">
                      <span
                        className="createbutton"
                        role="button"
                        onClick={() => {
                          setUpdateEmailId(undefined);
                          setContactStrategyEmail({
                            send_on: "",
                            subject: "",
                            headline: "",
                            url: "",
                            content: "",
                            image: [],
                          });
                          setDate1(undefined);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#contactstrategyEmailpopup"
                      >
                        <img src={mai_plus_solid_blue} alt="plus" className="img-fluid" /> Add Email
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12">
                    <div className="roadmapDate roadmapDateRed roadmapDot">
                      <img src={mai_start_date_icon} alt="date" className="img-fluid" />
                      <div>
                        <p>
                          End Campaign{" "}
                          <span>{new Date(campaign?.end_date || "").toLocaleDateString()}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-12"></div>
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="rules__border-top">
                <div className="row mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <button className="btn btn-primary mai-cancel-button">Cancel</button>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                    <button
                      type="button"
                      className="btn btn-primary mai-next-button"
                      onClick={updateContactStrategyHandler}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Strategy Email Popup */}
      <div
        className="modal fade filter-popup campaignEmailPopup"
        id="contactstrategyEmailpopup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">
                Email
              </h1>
              <button
                type="button"
                className="btn-close popup-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="audiencedetails__tabs tabswrap">
                <TabView>
                  <TabPanel header="Step1">
                    <div className="row emailpopupform">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                          <label htmlFor="countries" className="form-label">
                            Send on
                          </label>
                          <Calendar
                            id="basic"
                            value={date1}
                            minDate={new Date(campaign?.start_date || "")}
                            maxDate={new Date(campaign?.end_date || "")}
                            onChange={(e: any) => {
                              setDate1(e.value);
                              setContactStrategyEmail({
                                ...contactStrategyEmail,
                                send_on: e.value.toISOString(),
                              });
                            }}
                            placeholder="Choose Date"
                            readOnlyInput
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                          <label htmlFor="countries" className="form-label">
                            Enter subject
                          </label>
                          <input
                            type="text"
                            className="form-control mai-input"
                            placeholder="Enter subject"
                            value={contactStrategyEmail?.subject}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setContactStrategyEmail({
                                ...contactStrategyEmail,
                                subject: event.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <label htmlFor="countries" className="form-label">
                            Add headline
                          </label>
                          <input
                            type="text"
                            className="form-control mai-input"
                            placeholder="Add headline"
                            value={contactStrategyEmail?.headline}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setContactStrategyEmail({
                                ...contactStrategyEmail,
                                headline: event.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <label htmlFor="countries" className="form-label">
                            Add URL
                          </label>
                          <input
                            type="text"
                            className="form-control mai-input"
                            placeholder="Add URL"
                            value={contactStrategyEmail?.url}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setContactStrategyEmail({
                                ...contactStrategyEmail,
                                url: event.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Step2">
                    <div className="row emailpopupform">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                          <label htmlFor="countries" className="form-label">
                            Content
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Enter content"
                            maxLength={500}
                            value={contactStrategyEmail.content}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                              setContactStrategyEmail({
                                ...contactStrategyEmail,
                                content: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <label htmlFor="countries" className="form-label">
                            Insert picture
                          </label>
                          <input
                            type="file"
                            className="form-control mai-input"
                            placeholder="Insert picture"
                            onChange={uploadImage}
                          />
                          <div>
                            {contactStrategyEmail.image[0] ? <img src={contactStrategyEmail.image[0]} alt="" className="img-fluid" /> : <img src={mai_uploadImg} alt="" className="img-fluid" />}

                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary mai-next-button"
                data-bs-dismiss="modal"
                onClick={createEmailHandler}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Strategy Select Date Popup */}
      <div
        className="modal fade filter-popup selectDatepopWrap"
        id="selectDatepopup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title popup-title" id="exampleModalLabel">
                Select Date
              </h1>
            </div>
            <div className="modal-body">
              <form action="">
                <Calendar
                  className="mai-input"
                  id="basic"
                  value={date2}
                  minDate={new Date(campaign?.start_date || "")}
                  maxDate={new Date(campaign?.end_date || "")}
                  onChange={(e: any) => {
                    setDate2(e.value)
                    setDate1(e.value);
                    setContactStrategyEmail({
                      ...contactStrategyEmail,
                      send_on: e.value.toISOString(),
                    });
                  }}
                  placeholder="Choose Date"
                  readOnlyInput
                />
              </form>
            </div>
            <div className="modal-footer justify-content-center">
              <button type="button"
                className="btn btn-primary mai-next-button"
                data-bs-dismiss="modal"
                onClick={createEmailHandler}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Campaign Roadmap Success Popup */}
      <div
        className="modal fade filter-popup optimizationPopup"
        id="roadmapsuccesspopup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">
                Success!
              </h1>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <p className="title16 mb-0">
                    Your new campaign has been created with the Campaign ID{" "}
                    <strong> - Camp123</strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary mai-cancel-button bg-transparent"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary mai-next-button">
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default RoadmapComponent;