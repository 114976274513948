import AuthModel from "./../models/authmodel";
import JwtInterceptor from "../interceptors/jwt-interceptor";
import { CampaignGeneralDetail, ContactStrategyEmailDetail, ContactStrategyGeneralDetail } from "../models/types";
import { HttpMethods } from "../models/constants";

const apiInstance = AuthModel.campaignLensApiAuth();

// Interceptor to inspect HTTP calls
JwtInterceptor(apiInstance);


const createCampaignGeneraldetail = (data: CampaignGeneralDetail) => {
    return {
      instance: apiInstance,
      method: HttpMethods.POST,
      url: "/api/campaign_lens/v1.0/campaign/",
      body: data,
    };
  }
  
  const getCampaignList =  (Inputs: {
    page?: number;
    search?: string;
    created_by?: string;
    status?: string;
    sort_by?: string;
    sort_order?: number;
  }) => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/campaign/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&status=${Inputs.status}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}`,
    };
  };
  
  const getCampaignGeneraldetail = (id: string) => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/campaign/fetch/${id}/general_details`,
    };
  };
  
  const patchCampaign = (id: string, data: any) => {
    return {
      instance: apiInstance,
      method: HttpMethods.PATCH,
      url: `/api/campaign_lens/v1.0/campaign/${id}`,
      body: data
    };
  };

  const getCampaignCreatorList = () => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/campaign/creators`,
    };
  }
  
  const getContactStrategyCreatorList = () => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/contact_strategy/creators`,
    };
  }

  const getContactStrategyList = (Inputs: {
    page?: number;
    search?: string;
    created_by?: string;
    status?: string;
    sort_by?: string;
    sort_order?: number;
  }) => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/contact_strategy/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}`,
    };
  };

  const createContactStrategyGeneraldetail = (data: ContactStrategyGeneralDetail) => {
    return {
      instance: apiInstance,
      method: HttpMethods.POST,
      url: "/api/campaign_lens/v1.0/contact_strategy/",
      body: data,
    };
  }
  const getContactStrategyGeneraldetail = (id: string) => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/contact_strategy/fetch/${id}/general_details`,
    };
  };
  
  const patchContactStrategy = (id: string, data: any) => {
    return {
      instance: apiInstance,
      method: HttpMethods.PATCH,
      url: `/api/campaign_lens/v1.0/contact_strategy/${id}`,
      body: data
    };
  };

  const createContactStrategyEmail = (contact_strategy_id: string, data: ContactStrategyEmailDetail) => {
    return {
      instance: apiInstance,
      method: HttpMethods.POST,
      url: `/api/campaign_lens/v1.0/contact_strategy/${contact_strategy_id}/email`,
      body: data,
    };
  }

  const getContactStrategyEmail = (contact_strategy_id: string, email_id: string) => {
    return {
      instance: apiInstance,
      method: HttpMethods.GET,
      url: `/api/campaign_lens/v1.0/contact_strategy/fetch/${contact_strategy_id}/email/${email_id}`,
    };
  };

  const patchContactStrategyEmail = (contact_strategy_id: string, email_id: string, data: ContactStrategyEmailDetail) => {
    return {
      instance: apiInstance,
      method: HttpMethods.PATCH,
      url: `/api/campaign_lens/v1.0/contact_strategy/${contact_strategy_id}/email/${email_id}`,
      body: data,
    };
  };

  const deleteContactStrategyEmail = (contact_strategy_id: string, email_id: string) => {
    return {
      instance: apiInstance,
      method: HttpMethods.DELETE,
      url: `/api/campaign_lens/v1.0/contact_strategy/${contact_strategy_id}/email/${email_id}`,
    };
  };

const CampaignLensService = {
  createCampaignGeneraldetail,
  getCampaignList,
  getCampaignGeneraldetail,
  patchCampaign,
  getCampaignCreatorList,
  getContactStrategyCreatorList,
  getContactStrategyList,
  createContactStrategyGeneraldetail,
  getContactStrategyGeneraldetail,
  patchContactStrategy,
  createContactStrategyEmail,
  getContactStrategyEmail,
  patchContactStrategyEmail,
  deleteContactStrategyEmail
}

export default CampaignLensService;