import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
    // createContactStrategyGeneralDetailResponse, 
    CampaignListResponse,
    CampaignGeneralDetail,
    ContactStrategyGeneralDetail,
    getContactStrategyGeneralDetailResponse,
    // campaignGeneralDetailResponse
} from '../../../../../../models/types';
import { AutoComplete } from "primereact/autocomplete";
import './ContactStrategyGeneralDetails.scss';
import useHttp from '../../../../../../hooks/use-http';
import { Url } from '../../../../../../models/constants';

import {
    mai_plus_solid_blue,
    mai_question_icon,
} from '../../../../../../assets/images/index';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import CampaignLensService from '../../../../../../services/campaignlens.service';
import { StoreState } from '../../../../../../store/store';
import { CampaignLensDropdown } from '../../../../../../store/currentUser';


const ContactStrategyGeneralDetailsComponent = () => {
    const params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [contactStrategy, setContactStrategy] = useState<ContactStrategyGeneralDetail>({
        campaign: "",
        objective: "",
        kpi: [],
        roles: [{}],
    });
    const { sendRequest: createContactStrategy, loading: createContactStrategyLoader } = useHttp();
    const { sendRequest: updateContactStrategy, loading: updateContactStrategyLoader } = useHttp();
    const { sendRequest: getContactStrategy, loading: getContactStrategyLoader } = useHttp();
    const { sendRequest: getCampaigns } = useHttp();
    const { sendRequest: getCampaignDetails } = useHttp();
    const [selectedCampaign, setSelectedCampaign] = useState<any>();
    const [filteredCampaignList, setFilteredCampaignList] = useState<CampaignGeneralDetail[]>([]);
    const [enableNext, setEnableNext] = useState<boolean>(false);
    const kpiOptions = useSelector<StoreState, CampaignLensDropdown[]>(state => state.currentUser.features?.campaignLens?.contactStrategy.kpiOptions || []);

    const getCampaignList = useCallback((
        searchText = "",
        createdBy = "",
        page = 0,
        sortBy = "modified_on",
        sortOrder = 1
    ) => {
        const resHandler = (res: CampaignListResponse) => {
            setFilteredCampaignList(res.campaigns);
        };
        getCampaigns(
            CampaignLensService.getCampaignList({
                page: page,
                search: searchText,
                created_by: createdBy,
                sort_by: sortBy,
                sort_order: sortOrder,
                status: ''
            }),
            resHandler
        );
    }, [getCampaigns]);

    useEffect(() => {
        if (params?.csid) {
            const resHandler = (res: getContactStrategyGeneralDetailResponse) => {
                setContactStrategy(res.contact_strategy);
                getCampaignDetails(CampaignLensService.getCampaignGeneraldetail(res.contact_strategy.campaign), (campRes) => setSelectedCampaign(campRes.campaign));
                // setEnableNext(true);
            }
            getContactStrategy(CampaignLensService.getContactStrategyGeneraldetail(params?.csid), resHandler)
        }
    }, [getCampaignDetails, getContactStrategy, params?.csid])

    useEffect(() => {
        console.log("contactStrategy", contactStrategy);
        if (contactStrategy.campaign !== '' && contactStrategy.objective !== '' && contactStrategy.kpi && contactStrategy.kpi.length > 0) {
            setEnableNext(true);
        } else {
            setEnableNext(false);
        }
    }, [contactStrategy]);

    const headerTitleChangeHandler = (content: any) => {
        const payload = { title: content };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    }
    const searchCampaign = (event: { query: string }) => {
        setTimeout(() => {
            getCampaignList(event.query);
        }, 250);
    }

    const handleCheckboxChange = (event: any) => {
        if (event.target.checked) {
            if (!contactStrategy.kpi.includes(event.target.value)) {
                setContactStrategy({ ...contactStrategy, kpi: [...contactStrategy.kpi, event.target.value] });
            }
        } else {
            setContactStrategy({ ...contactStrategy, kpi: contactStrategy.kpi.filter(item => item !== event.target.value) });
        }
    }

    const createContactStrategyHandler = (event: React.FormEvent) => {
        event.preventDefault();
        // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/0`);
        //enable later once api start working
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CONTACT_STRATEGY}/${Url.CONTACT_STRATEGY_CREATE}/${Url.CONTACT_STRATEGY_ROADMAP}/${res.id ?? params?.csid}`);
        }
        if (params?.csid) {
            updateContactStrategy(CampaignLensService.patchContactStrategy(params?.csid, contactStrategy), resHandler);
        } else {
            createContactStrategy(CampaignLensService.createContactStrategyGeneraldetail(contactStrategy), resHandler)
        }
    }
    const cancelHandler = () => {
        headerTitleChangeHandler('Campaign List');
        navigate(
            `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`
        )
    }
    return (
        <Fragment>
            <div className="audiencecreate__steps sticky-top stickytopRecomd">
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Campaign Roadmap</li>
                </ul>
            </div>
            <Loader isLoading={createContactStrategyLoader || updateContactStrategyLoader || getContactStrategyLoader}>
                <div className="audiencecreate__form-wrapper campaigngeneraldetails-wrapper">
                    <div className="audiencecreate__form whiteboxheight">
                        <form onSubmit={createContactStrategyHandler}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="minheightWhiteBox">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="mb-0">
                                                    <label htmlFor="chooseaudience" className="form-label mai-form-label">
                                                    Campaign Name{" "}
                                                        {" "}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                {/* <label htmlFor="campaignname" className="form-label mai-form-label">
                                                    Campaign Name
                                                    <img
                                                        src={mai_question_icon}
                                                        alt="Choose campaign Name"
                                                        className="img-fluid ms-1"
                                                    />
                                                </label> */}
                                                <AutoComplete
                                                    className="form-control chooseaudience_input"
                                                    placeholder="Choose campaign name"
                                                    value={selectedCampaign}
                                                    suggestions={filteredCampaignList}
                                                    completeMethod={searchCampaign}
                                                    field="name"
                                                    onChange={(e: any) => {
                                                        if (e.value.id !== undefined) {
                                                            setContactStrategy({ ...contactStrategy, campaign: e.value.id });
                                                            setSelectedCampaign(e.value);
                                                        } else {
                                                            setContactStrategy({ ...contactStrategy, campaign: '' });
                                                            setSelectedCampaign(e.value);
                                                        }
                                                    }}
                                                    aria-label="chooseaudience"
                                                />
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                                                <div className="createcampaign-or">
                                                    <span> OR</span>
                                                </div>
                                                <div className="campaignbutton">
                                                    <NavLink
                                                        to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}/${Url.CAMPAIGN_CREATE}/${Url.CAMPAIGN_CREATE_GENERAL}`}
                                                        className="createbutton"
                                                        onClick={() => headerTitleChangeHandler('Create Campaign')}
                                                    >
                                                        <img src={mai_plus_solid_blue} alt="plus" className="img-fluid" /> Create Campaign
                                                    </NavLink>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-sm-12 mt-4">
                                                <label htmlFor="objective" className="form-label mai-form-label">
                                                    Objective
                                                </label>
                                                <textarea
                                                    id="objective"
                                                    className="form-control mai-textarea"
                                                    maxLength={500}
                                                    value={contactStrategy.objective}
                                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                                        setContactStrategy({ ...contactStrategy, objective: e.target.value })
                                                    }
                                                    placeholder="Enter objective"
                                                ></textarea>
                                            </div>
                                            <div className="col-lg-5 col-md-5">
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 mt-4">
                                                <label htmlFor="campaignDesc" className="form-label mai-form-label">
                                                    Primary KPI to be Tested
                                                </label>
                                                <div className='kpitobetested'>
                                                    {
                                                        kpiOptions && kpiOptions.map((item) => <>
                                                            <div className="kpi" key={item.value}>
                                                                <input type="checkbox" id={item.value} value={item.value} onClick={handleCheckboxChange} checked={contactStrategy.kpi.includes(item.value)} />
                                                                <label htmlFor={item.value}><span> {item.label}</span></label>
                                                            </div>
                                                        </>)
                                                    }
                                                    {/* <div>
                                                        <a href=""><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Open Rate</a>
                                                        <a href="" className='grayBtn'><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Click Rate</a>
                                                        <a href=""><img src={mai_tick_blue_lg} alt="tick" className='img-fluid' /> Conversion Rate</a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className='rules__border-top'>
                                        <div className="row mt-3">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <button
                                                    className="btn btn-primary mai-cancel-button"
                                                    onClick={cancelHandler}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                                <button type="submit" className="btn btn-primary mai-next-button" disabled={!enableNext} >
                                                    {params.csid ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </Loader>

            {/* Create Campaign Success Popup */}
            <div className="modal fade filter-popup optimizationPopup" id="campaignsuccesspopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Success!</h1>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <p className='title16 mb-0'>
                                        Your new campaign has been created with the Campaign ID <strong> - Camp123</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button bg-transparent" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary mai-next-button">Okay</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ContactStrategyGeneralDetailsComponent;