import React, { useContext, createContext, useState } from "react";
import { ThemesList } from './../models/constants';
type SingleTheme = ThemesList.DEFAULT | ThemesList.LIGHT | ThemesList.DARK;
// type ThemesType = Record<ThemesList, string>;
// interface ThemesTypeInterface extends ThemesType {};
interface ThemesTypeInterface {
    default: SingleTheme,
    light: SingleTheme,
    dark: SingleTheme
}
export const themes: ThemesTypeInterface = {
    default: ThemesList.DEFAULT,
    light: ThemesList.LIGHT,
    dark: ThemesList.DARK
}

interface ThemeContextType {
    theme: SingleTheme;
    changeTheme: (theme: SingleTheme, callback?: Function) => void;
}
const ThemeContext = createContext<ThemeContextType>(null!);

export const ThemeWrapperProvider = ({ children }: {children: React.ReactNode}) => {
    const [theme, setTheme] = useState<SingleTheme>(themes.default);
    const changeTheme = (theme: SingleTheme, callback?: Function) => {
        setTheme(theme);
        console.log(theme);
        document.body.classList.value = '';
        document.body.classList.add(theme);
        // switch (theme) {
        //     case themes.light:
        //       document.body.classList.add(themes.light);
        //       break;
        //     case themes.dark:
        //     default:
        //       document.body.classList.remove('white-content');
        //       break;
        //   }
        if(callback){
            callback();
        }
    }
    const value = { theme, changeTheme };
    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
export const useTheme = () => { 
    return useContext(ThemeContext);
}