import { Fragment } from "react"
import './ClvSummary.scss';
import {
    mai_growth_icon,
    mai_product_icon,
    mai_revenue_icon,
    mai_revenue_opportunity_icon,
    mai_question_icon,
    mai_existingrevenue_indicate,
    mai_predictedCLV,
    mai_clvdistribution,
    mai_predictedclv
} from '../../../../../../assets/images/index';
import { useEffect, useState } from "react";
import { StoreActions } from '../../../../../../store/actions';
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../../../../services/api.service";
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import useHttp from "../../../../../../hooks/use-http";
import { useDispatch, useSelector } from "react-redux";
import { Url } from "../../../../../../models/constants";
import { ToastSeverity } from "../../../../../../models/constants";

import Loader from "../../../../../../shared/components/loader/Loader";
import { clvSummaryDataType } from "../../../../../../models/types";
import UtilService from "../../../../../../services/util.service";
import { StoreState } from "../../../../../../store/store";
const ClvSummaryComponents = () => {
    const { sendRequest: getClvSummary, loading:loading  } = useHttp();
    const { sendRequest: updateCLV, loading: updateClvLoader } = useHttp();
    const params=useParams();
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const [revenueTimeframe, setRevenueTimeframe] = useState<string>('');
    const [predictedTimeframe, setPredictedTimeframe] = useState<string>('');
    const [clvSummaryData,setClvSummaryData]=useState<clvSummaryDataType>({
        total_customer:0,
        total_revenue:0,
        projected_revenue:0,
        avg_clv:0,
        incremental_revenue:0,
        growth:0,
        avg_revenue:0,
        avg_predicted_clv:0
    })
    const [clvPredictionChartConfig, setClvPredictionChartConfig] = useState<any>({
        type: "line",
        width: "100%",
        height: "25%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                yAxisPosition: "left",
                rotateLabels:0,
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "0",
                plottooltext: "<div style='min-width: 200px;'><div style='display: flex; align-items: center; justify-content: space-between; margin: 2px 0;'> <div><b>$dataValue</b></div></div></div>",
                theme: "fusion",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "0",
            }
        }
    });
    const [clvDistributionChartConfig, setClvDistributionChartConfig] = useState<any>({
        type: "bar2d",
        width: "100%",
        height: "25%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                yAxisPosition: "left",
                rotateLabels:0,
                showhovereffect: "1",
                numberprefix: "$",
                drawcrossline: "0",
                plottooltext: "<div style='min-width: 200px;'><div style='display: flex; align-items: center; justify-content: space-between; margin: 2px 0;'> <div><b>$dataValue</b></div></div></div>",
                theme: "fusion",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                toolTipBorderColor: "#444444",
                toolTipBorderRadius: "5",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartTopMargin: "20",
                chartBottomMargin: "0",
                showValues:"1",
                placeValuesInside:"1",
            }
        }
    });
    const header = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    }
    useEffect(()=>{
    if(header.title!=="Create CLV")
        headerTitleChangeHandler("Create CLV",'','');
    })
    useEffect(() => {
        const resHandler=(res:any)=>{
            let clvSummaryDataClone:clvSummaryDataType=clvSummaryData

            clvSummaryDataClone.total_customer=res.clv.total_customer??0;
            clvSummaryDataClone.total_revenue=res.clv.total_revenue??0;
            clvSummaryDataClone.projected_revenue=res.clv.projected_revenue??0;
            clvSummaryDataClone.avg_clv=res.clv.avg_clv??0;

            clvSummaryDataClone.incremental_revenue=res.clv.incremental_revenue??0;    
            clvSummaryDataClone.growth=res.clv.growth??0;
            clvSummaryDataClone.avg_revenue=res.clv.avg_revenue??0;
            clvSummaryDataClone.avg_predicted_clv=res.clv.avg_predicted_clv??0;
            setClvSummaryData(clvSummaryDataClone);
            if(res.clv.prediction!==null)
            {
                let revenueMinYear=3000;
                let revenueMaxYear=0;
                let predictedMinMonth=13;
                let predictedMaxMonth=0;
                let predictedYear=0;
                const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const cloneClvPredictionChartConfig = { ...clvPredictionChartConfig };
                cloneClvPredictionChartConfig.dataSource = {
                    ...cloneClvPredictionChartConfig.dataSource,
                    data: res.clv.prediction.map((item: any) => {
                        if(item.predicted)
                        {
                            if(predictedMinMonth>item.month)
                            {
                                predictedYear=item.year;
                            }
                            predictedMinMonth=predictedMinMonth>item.month?item.month:predictedMinMonth;
                            predictedMaxMonth=predictedMaxMonth<item.month?item.month:predictedMaxMonth;
                            return {
                                label: months[item.month-1] + " " + item.year.toString(),
                                value:item.value,
                                color:"#69efb5"
                            }
                        }
                        else{
                            const tempYear=item.month<3?item.year-1:item.year;
                            revenueMinYear=revenueMinYear>tempYear?tempYear:revenueMinYear;
                            revenueMaxYear=revenueMaxYear<tempYear?tempYear:revenueMaxYear;
                            return {
                                label: months[item.month-1] + " " + item.year.toString(),
                                value:item.value,
                            }
                        }
                    })
                };
                setRevenueTimeframe('FY'+revenueMinYear+'-'+revenueMaxYear);
                setPredictedTimeframe(months[predictedMinMonth-1]+'-'+months[predictedMaxMonth-1]+' '+predictedYear);
                setClvPredictionChartConfig(cloneClvPredictionChartConfig);
            }
            if(res.clv.rank!==null)
            {
                const cloneClvDistributionChartConfig=clvDistributionChartConfig;
                const colorsList=[	"#00cccc" , "#00cc99" ,"#00cc66"]
                cloneClvDistributionChartConfig.dataSource={...cloneClvDistributionChartConfig.dataSource,
                    data:res.rank.map((item:any, key:number)=>{
                        return{
                            label:item.monetary,
                            value:item.customer_count,
                            displayValue:UtilService.capitalizeTheFirstLetterOfEachWord(item.clv_rank)+' '+UtilService.numberFormatter(item.revenue),
                            color: colorsList[key],
                        }})
                }
                setClvDistributionChartConfig(cloneClvDistributionChartConfig);
            }
        }
        getClvSummary(ApiService.clvSummary(params?.clvid),resHandler);
    }, [params?.clvid])

    const cancelHandler = () => {
        headerTitleChangeHandler("CLV List",'','');
        navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_LIST}`);
    }
    const onBackHandler = () => {
    navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}/${params?.clvid}`);
    }
    const publishHandler = (event: React.FormEvent) => {
        const resHandler = (res: any) => {
            dispatch({
                type: StoreActions.TOAST_ON,
                payload: {
                  severity: ToastSeverity.SUCCESS,
                  summary: "Information",
                  detail: "CLV Published Successfully",
                  life: 3000,
                },
              });
            cancelHandler();
        }
        if (params?.clvid) {
            updateCLV(ApiService.updateClvDetails({ is_published: true }, params.clvid), resHandler);
        }
      }
    return (
        <Fragment>
            <Loader isLoading={loading}>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    {/* <li className='list-inline-item audiencecreate__steps--active'>Variables</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li> */}
                    <li className='list-inline-item audiencecreate__steps--active'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper audiencedetails recomdsummary-wrapper px-0 pt-2'>
                {/* <div className='audiencedetails__title mb-3'>
                    <span>Create CLV</span>
                </div> */}
                <div className="audiencedetails__tabs">
                    <div className="tab-content py-0" id="tabContent">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="insights__innerwrapper mb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h2 className='inner__heading'><img src={mai_product_icon} alt="web" className="img-fluid" /> <span> Total Customers</span> </h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className='insights-data'>
                                                <div><h3>{clvSummaryData.total_customer}</h3></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <div className="insights__innerwrapper dropdownmain-wrapper-end mb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h2 className='inner__heading'><img src={mai_revenue_icon} alt="email" className="img-fluid" /> <span> Total Revenue</span> </h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className='insights-data'>
                                                <div>
                                                    <div className='innertext__size'>
                                                        <h2 className='blueshade titleblack'>$ {UtilService.numberFormatter(clvSummaryData.total_revenue)} <span className="increase_horizontal">+4%</span></h2>
                                                        <p>{revenueTimeframe}</p>
                                                    </div>
                                                </div>
                                                <div></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="insights__innerwrapper dropdownmain-wrapper-end clvrevenuegrowth-wrapper mb-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h2 className='inner__heading'><img src={mai_revenue_opportunity_icon} alt="revenue" className="img-fluid" /> <span> CLV</span> </h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className='insights-data align-items-center'>
                                                <div>
                                                    <div className='innertext__size'>
                                                        <h2 className='greenshade mb-1 titleblack'>$ {UtilService.numberFormatter(clvSummaryData.projected_revenue)}</h2>
                                                        <p>Projected Revenue</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='text-end'>
                                                        <h4 className='avgclv mb-1'>$ {Number((clvSummaryData.avg_clv).toFixed(1))} </h4>
                                                        <p>Avg. CLV</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <h4 className="monthsrange">{predictedTimeframe}</h4>
                                            <div className='insights-data clvrevenue-growth'>
                                                <div>
                                                    <div className='innertext__size'>
                                                        <h2 className='titleblack'>$ {UtilService.numberFormatter(clvSummaryData.incremental_revenue)} </h2>
                                                        <p>Incremental Revenue</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='text-end'>
                                                        <h2 className='titleblack'>{UtilService.numberFormatter(clvSummaryData.growth)} %</h2>
                                                        <p>% Growth</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="insights__innerwrapper mb-0">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-sm-12">
                                            <div className="predictedclv">
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <h3 className='inner__heading mb-3'><span> Predicted CLV </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="custprofile-chartindicator">
                                                            <ul className="list-inline m-0">
                                                                <li className="list-inline-item me-3">
                                                                    <img src={mai_existingrevenue_indicate} alt="existing revenue" className="img-fluid" />
                                                                    <span> Revenue</span>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <img src={mai_predictedCLV} alt="product clv" className="img-fluid" />
                                                                    <span> Predicted CLV</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="avgrevenuenpredictedclv">
                                                            <div>
                                                                <p>Avg. Revenue</p>
                                                                <h3>{UtilService.numberFormatter(clvSummaryData.avg_revenue)}</h3>
                                                            </div>
                                                            <div className="ms-3">
                                                                <p> Avg. Predicted CLV </p>
                                                                <h3 className="greencolor">$ {UtilService.numberFormatter(clvSummaryData.avg_predicted_clv)}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <ChartComponent chartConfigs={clvPredictionChartConfig} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="predictedclv">
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <h3 className='inner__heading mb-3'><span> CLV distribution </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                        <ChartComponent chartConfigs={clvDistributionChartConfig} />
                                                        {/* <img src={mai_clvdistribution} alt="clv distribution" className="img-fluid clvchart" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row mt-5">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="rules__border-top">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                                        <button type="submit" className="btn btn-primary mai-cancel-button" onClick={cancelHandler}>Cancel</button>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3" onClick={onBackHandler}>Back</button>
                                                        <button type="submit" className="btn btn-primary mai-next-button" data-bs-toggle="modal" onClick={publishHandler}>Publish</button>
                                                        {/* data-bs-target="#showCLV" */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Merge segmentation Popup
            <div className="modal fade filter-popup smallpopup" id="showCLV" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title" id="exampleModalLabel">New CLV has been published successfully.</h1>
                        </div>
                        <div className="modal-footer mt-4">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary mai-next-button" onClick={}>Show CLV</button>
                        </div>
                    </div>
                </div>
            </div> */}
            </Loader>   
        </Fragment>
    )
}

export default ClvSummaryComponents;