import { Dropdown } from 'primereact/dropdown';
import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { mai_plus_solid_blue, mai_question_icon } from '../../../../../../assets/images/index';
import './AbTestGenDetails.scss';

const AbTestGenDetailsComponent = () => {
    const [selectAudience, setSelectedAudience] = useState<any>(null);
    const audiences = [
        { name: 'North-east Region Renewal', code: 'NER' },
        { name: 'Bottom of the line', code: 'BOL' },
        { name: 'Middle of the line leads', code: 'MLL' },
    ];
    const onCityChange = (e: { value: any }) => {
        setSelectedAudience(e.value);
    }

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Test Targets</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Test Details</li>
                </ul>
            </div>
            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper gendetailswrap'>
                <div className='audiencecreate__form box-shadow'>
                    <form>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-4">
                                            <label htmlFor="name" className="form-label mai-form-label">Test Name</label>
                                            <input type="text" className="form-control mai-input" name="name" maxLength={200} placeholder='Enter test name' />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-4">
                                            <label htmlFor="audienceDesc" className="form-label mai-form-label">Test Description</label>
                                            <textarea className='form-control mai-textarea' maxLength={500} placeholder='Enter description'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="">
                                            <label htmlFor="audienceDesc" className="form-label mai-form-label">Hypothesis</label>
                                            <textarea className='form-control mai-textarea' maxLength={500} placeholder='Enter hypothesis'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="my-4">
                                            <div className="form-check ps-0">
                                                <label className="form-check-label mai-label-access">Access</label>
                                            </div>
                                            <div className='customcheckwrap'>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="audience" value="Audience" checked />
                                                    <label className="form-check-label mai-radio-label" htmlFor="audience">Audience</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="campaign" value="Campaign" />
                                                    <label className="form-check-label mai-radio-label" htmlFor="campaign">Campaign</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        {/* <div className="">
                                            <label htmlFor="choosedata" className="form-label mai-form-label choosedata-label">
                                                <div>
                                                    <div className="form-check form-check-inline ps-0">
                                                        <label className="form-check-label mai-label-access">Select Audience </label>
                                                    </div>
                                                </div>
                                            </label>
                                            <select name="choosedata" id="choosedata" className='form-select mai-input'>
                                                <option value="Search for audience and segments " selected disabled>Select your audience </option>
                                                <option value="North-east Region Renewal" >North-east Region Renewal</option>
                                                <option value="Bottom of the line">Bottom of the line</option>
                                                <option value="Middle of the line leads">Middle of the line leads</option>
                                            </select>
                                        </div> */}

                                        <div>
                                            <label htmlFor="choosedata" className="form-label mai-form-label choosedata-label">
                                                <div>
                                                    <div className="form-check form-check-inline ps-0">
                                                        <label className="form-check-label mai-label-access">Select Audience </label>
                                                    </div>
                                                </div>
                                            </label>
                                            <Dropdown className='mai-input' value={selectAudience} options={audiences} onChange={onCityChange} optionLabel="name" placeholder="Select Your Audience" />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                                        <div className='createcampaign-or'>
                                            <span> OR</span>
                                        </div>
                                        <div className='campaignbutton'>
                                            <NavLink to="" className="createbutton"><img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> Create audience</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                            <button className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="submit" className="btn btn-primary mai-next-button" disabled>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    )
}
export default AbTestGenDetailsComponent;