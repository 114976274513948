export enum ThemesList {
  DEFAULT = "default",
  LIGHT = "light",
  DARK = "dark",
}
export enum LanguageList {
  EN = "en",
  HI = "hi",
}
export enum ToastSeverity {
  WARN = "warn",
  SUCCESS = "success",
  INFO = "info",
  ERROR = "error",
  LIFETIME = 3000,
}
export enum HttpMethods {
  GET = "get",
  PUT = "put",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
}
export enum TenantProviders {
  LOCAL = "local",
  ADFS = "adfs",
  AZURE = "azure",
}
export enum Url {
  DEFAULT = "/",
  TENANT = "tenant",
  MAIN = "main",
  LOGIN = "login",
  LOGIN_FAILED = "login-failed",
  HOME = "home",
  CUSTOMERLENS_HOME = "customerlens-home",
  CAMPAIGNLENS_HOME = "campaignlens-home",
  MARKETINGLENS_HOME = "marketinglens-home",
  DASHBOARD = "dashboard",
  CUSTOMERLENS_DASHBOARD = "customerlens-dashboard",
  CAMPAIGNLENS_DASHBOARD = "campaignlens-dashboard",
  MARKETINGLENS_DASHBOARD = "marketinglens-dashboard",
  WORKFLOW = "workflow",
  CUSTOMERLENS_WORKFLOW = "customerlens-workflow",
  CAMPAIGNLENS_WORKFLOW = "campaignlens-workflow",
  MARKETINGLENS_WORKFLOW = "marketinglens-workflow",

  AUDIENCE_BUILDER = "audience-builder",
  AUDIENCE_LIST = "list",
  AUDIENCE_DETAILS = "details",
  AUDIENCE_ID = "aid",
  AUDIENCE_CREATE = "create",
  AUDIENCE_CREATE_GENERAL = "general",
  AUDIENCE_CREATE_RULES = "rules",
  AUDIENCE_CREATE_SUMMARY = "summary",
  CUSTOMER_SEGMENTATION_ID = "sid",
  CUSTOMER_SEGMENTATION = "customer-segmentation",
  CUSTOMER_SEGMENTATION_DETAILS = "details",

  CUSTOMER_SEGMENTATION_LIST = "list",
  CUSTOMER_SEGMENTATION_CREATE = "create",
  CUSTOMER_SEGMENTATION_CREATE_GENERAL = "general",
  CUSTOMER_SEGMENTATION_CREATE_DEFINE = "define",
  CUSTOMER_SEGMENTATION_CREATE_ATTRIBUTE = "attribute",
  CUSTOMER_SEGMENTATION_CREATE_SUMMARY = "summary",
  SEGMENTATION="segmentation",

  CUSTOMER_LIFETIME_VALUE = "clv",
  RECOMMENDATIONS = "recommendations",

  RECOMMENDATIONS_ID = "rid",
  RECOMMENDATIONS_LIST = "list",
  RECOMMENDATIONS_CREATE = "create",
  RECOMMENDATIONS_CREATE_GENERAL = "general",
  RECOMMENDATIONS_CREATE_ATTRIBUTE = "attribute",
  RECOMMENDATIONS_CREATE_SUMMARY = "summary",
  RECOMMENDATIONS_DETAILS = "recommendations-details",

  CUSTOMER_LIFETIME_VALUE_ID = "clvid",
  CUSTOMER_LIFETIME_VALUE_LIST = "list",
  CUSTOMER_LIFETIME_VALUE_CREAT = "create",
  CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL = "general",
  CUSTOMER_LIFETIME_VALUE_CREATE_VARIABLES = "variables",
  CUSTOMER_LIFETIME_VALUE_CREATE_SUMMARY = "summary",
  CUSTOMER_LIFETIME_VALUE_DETAILS = "clv-details",

  CAMPAIGN = "campaign",
  CAMPAIGN_ID = "cid",
  CAMPAIGN_CREATE = "create",
  CAMPAIGN_CREATE_GENERAL = "general",
  CAMPAIGN_LIST = "list",

  CONTACT_STRATEGY = "contactstrategy",
  CONTACT_STRATEGY_ID = "csid",
  CONTACT_STRATEGY_CREATE = "create",
  CONTACT_STRATEGY_CREATE_GENERAL = "general",
  CONTACT_STRATEGY_LIST = "list",
  CONTACT_STRATEGY_ROADMAP = "roadmap",

  CAMPAIGN_MONITORING = "campaign-monitoring",
  CAPMAIGN_MONITORING_ID = "cmid",
  CAMPAIGN_MONITORING_LIST = "list",
  CAMPAIGN_MONITORING_DETAILS = "details",

  CAMPAIGN_OPTIMIZATION = "campaign-optimization",
  CAMPAIGN_OPTIMIZATION_ID = "cmid",
  CAMPAIGN_OPTIMIZATION_LIST = "list",
  CAMPAIGN_OPTIMIZATION_DETAILS = "details",
  CAMPAIGN_OPTIMIZATION_INPUT_PARAMETERS = "input-parameters",
  CAMPAIGN_OPTIMIZATION_SUMMARY = "summary",

  AB_TEST = "abtest",
  AB_TEST_ID = 'abid',
  AB_TEST_LIST = "list",
  AB_TEST_CREATE = "create",
  AB_TEST_CREATE_GENERAL = "gen-details",
  AB_TEST_CREATE_TEST_TARGET = "test-target",
  AB_TEST_CREATE_TEST_DETAILS = "test-details",

  AB_TEST_DETAILS = "details",
  AB_TEST_GENERAL_DETAILS = "general-details",
  AB_TEST_STATISTICAL_SIGNIFICANCE_CALCULATOR = "statistical-significance-calculator",


  SPEND_OPTIMIZATION_ID = 'spendid',
  SPEND_OPTIMIZATION = "spend-optimization",

  SPEND_OPTIMIZATION_LIST = "list",

  SPEND_OPTIMIZATION_CREATE = "create",
  SPEND_OPTIMIZATION_CREATE_GENERAL = "general",
  SPEND_OPTIMIZATION_CREATE__DEFINE_CHANNEL_THEME = "define-channel-theme",
  SPEND_OPTIMIZATION_CREATE_SPEND_OPTIMIZATION = 'spend-optimization',
  SPEND_OPTIMIZATION_CREATE_SUMMARY = "summary",

  SPEND_OPTIMIZATION_DETAILS = "details",
  SPEND_OPTIMIZATION_INSIGHTS = "insights",
 

  ADMIN = "admin",
  LOCALE = "locale",
  CHART = "chart",
}
export enum SessionStorageKeys {
  ACCESSTOKEN = "accesstoken",
  UID = "uid",
}
export enum ChartThemeList {
  FUSION = "fusion",
  UMBER = "umber",
  CANDY = "candy",
  GAMMEL = "gammel",
  ZUNE = "zune",
  OCEAN = "ocean",
  CARBON = "carbon",
}
export enum MagnifaiLens {
  CUSTOMER_LENS = "customerLens",
  CAMPAIGN_LENS = "campaignLens",
  MARKETING_LENS = "marketingLens",
  USECASEHUB_LENS = "usecasehubLens",
}

export enum CustomerLensFeatureNames {
  AUDIENCE_BUILDER = "audienceBuilder",
  CUSTOMER_SEGMENTATION = "customerSegmentation",
  CLV = "clv",
  RECOMMENDATION = "recommendation",
}

export enum AudienceBuilderTabs {
  INSIGHTS = "insights",
  CUSTOMERS = "customers",
}

export enum CustomerSegmentationTabs {
  SEGMENT_PERSONAS = "segmentPersonas",
}

export enum CampaignLensFeatureNames {
  CREATE_CAMPAIGN = "createCampaign",
  CONTACT_STRATEGY = "contactStrategy",
  AB_TEST = "abTest",
  CAMPAIGN_MONITORING = "campaignMonitoring",
  CAMPAIGN_OPTIMIZATION = "campaignOptimization",
}

export const FusionChartCountriesLabels: any = {
  "Antigua and Barbuda": "01",
  Bahamas: "02",
  Barbados: "03",
  Belize: "04",
  Canada: "05",
  "Costa Rica": "06",
  Cuba: "07",
  Dominica: "08",
  "Dominican Republic": "09",
  "El Salvador": "10",
  Grenada: "11",
  Guatemala: "12",
  Haiti: "13",
  Honduras: "14",
  Jamaica: "15",
  Mexico: "16",
  Nicaragua: "17",
  Panama: "18",
  "St. Kitts & Nevis": "19",
  "St. Lucia": "20",
  "St. Vincent & the Grenadines": "21",
  "Trinidad & Tobago": "22",
  "US": "23",
  "USA": "23",
  "United States": "23",
  Greenland: "24",
  Argentina: "25",
  Bolivia: "26",
  Brazil: "27",
  Chile: "28",
  Colombia: "29",
  Ecuador: "30",
  "Falkland Is lands": "31",
  "French Guiana": "32",
  Guyana: "33",
  Paraguay: "34",
  Peru: "35",
  Suriname: "36",
  Uruguay: "37",
  Venezuela: "38",
  Algeria: "39",
  Angola: "40",
  Benin: "41",
  Botswana: "42",
  "Burkina Faso": "43",
  Burundi: "44",
  Cameroon: "45",
  "Cape Verde": "46",
  "Central African Republic": "47",
  Chad: "48",
  Comoros: "49",
  "Ivory Coast": "50",
  "DR Congo": "51",
  Djibouti: "52",
  Egypt: "53",
  "Equatorial Guinea": "54",
  Eritrea: "55",
  Ethiopia: "56",
  Gabon: "57",
  Ghana: "58",
  Guinea: "59",
  "Guinea-Bissau": "60",
  Kenya: "61",
  Lesotho: "62",
  Liberia: "63",
  Libya: "64",
  Madagascar: "65",
  Malawi: "66",
  Mali: "67",
  Mauritania: "68",
  Morocco: "69",
  Mozambique: "70",
  Namibia: "71",
  Niger: "72",
  Nigeria: "73",
  Rwanda: "74",
  "Sao Tome and Principe": "75",
  Senegal: "76",
  Seychelles: "77",
  "Sierra Leone": "78",
  Somalia: "79",
  "South Africa": "80",
  "Republic of Sudan": "81",
  Swaziland: "82",
  Tanzania: "83",
  Togo: "84",
  Tunisia: "85",
  Uganda: "86",
  "Western Sahara": "87",
  Zambia: "88",
  Zimbabwe: "89",
  Gambia: "90",
  Congo: "91",
  Mauritius: "92",
  Afghanistan: "93",
  Armenia: "94",
  Azerbaijan: "95",
  Bangladesh: "96",
  Bhutan: "97",
  Brunei: "98",
  "Burma (Myanmar)": "99",
  Cambodia: "100",
  China: "101",
  "Hong Kong": "101",
  "Timor-Leste": "102",
  Georgia: "103",
  India: "104",
  Indonesia: "105",
  Iran: "106",
  Japan: "107",
  Kazakhstan: "108",
  "Korea (north)": "109",
  "Korea (south)": "110",
  Kyrgyzstan: "111",
  Laos: "112",
  Malaysia: "113",
  Mongolia: "114",
  Nepal: "115",
  Pakistan: "116",
  Philippines: "117",
  Russia: "118",
  Singapore: "119",
  "Sri Lanka": "120",
  Tajikistan: "121",
  Thailand: "122",
  Turkmenistan: "123",
  Uzbekistan: "124",
  Vietnam: "125",
  Albania: "129",
  Andorra: "130",
  Austria: "131",
  Belarus: "132",
  Belgium: "133",
  "Bosnia and Herzegovina": "134",
  Bulgaria: "135",
  Croatia: "136",
  "Czech Republic": "137",
  "Czechia": "137" ,
  Denmark: "138",
  Estonia: "139",
  Finland: "140",
  France: "141",
  Germany: "142",
  Greece: "143",
  Hungary: "144",
  Iceland: "145",
  Ireland: "146",
  Italy: "147",
  Latvia: "148",
  Liechtenstein: "149",
  Lithuania: "150",
  Luxembourg: "151",
  Macedonia: "152",
  Malta: "153",
  Moldova: "154",
  Monaco: "155",
  Montenegro: "156",
  Netherlands: "157",
  Norway: "158",
  Poland: "159",
  Portugal: "160",
  Romania: "161",
  "San Marino": "162",
  Serbia: "163",
  Slovakia: "164",
  Slovenia: "165",
  Spain: "166",
  Sweden: "167",
  Switzerland: "168",
  Ukraine: "169",
  "United Kingdom": "170",
  "Vatican City": "171",
  Cyprus: "172",
  Turkey: "173",
  Australia: "175",
  Fiji: "176",
  Kiribati: "177",
  "Marshall Islands": "178",
  Micronesia: "179",
  Nauru: "180",
  "New Zealand": "181",
  "Republic of Palau": "182",
  "Papua New Guinea": "183",
  Samoa: "184",
  "Solomon Islands": "185",
  Tonga: "186",
  Tuvalu: "187",
  Vanuatu: "188",
  "New Caledonia": "189",
  Bahrain: "190",
  Iraq: "191",
  Israel: "192",
  Jordan: "193",
  Kuwait: "194",
  Lebanon: "195",
  Oman: "196",
  Qatar: "197",
  "Saudi Arabia": "198",
  Syria: "199",
  UnitedArabEmirates: "200",
  Yemen: "201",
  "Puerto Rico": "202",
  "Cayman Islands": "203",
  "South Sudan": "204",
  Kosovo: "205",
  Aruba: "206",
  Anguilla: "207",
  "American Samoa": "208",
  Bermuda: "209",
  "BES Islands": "210",
  "Cocos (Keeling) Islands": "211",
  "Cook Islands": "212",
  "Christmas Island": "213",
  Curacao: "214",
  "Faroe Islands": "215",
  "French Polynesia": "216",
  Gibraltar: "217",
  Guam: "218",
  Guadeloupe: "219",
  "Gaza Strip": "220",
  Guernsey: "221",
  "Isle of Man": "222",
  Jersey: "223",
  "Kingman Reef": "224",
  Maldives: "225",
  Montserrat: "226",
  Mayotte: "227",
  Martinique: "228",
  "Norfolk Island": "229",
  "Northern Mariana Islands": "230",
  Niue: "231",
  "Pitcairn Islands": "232",
  "La Réunion": "233",
  "Sint Maarten": "234",
  "Saint Helena": "235",
  "Saint Pierre and Miquelon": "236",
  "Turks and Caicos Islands": "237",
  "Virgin Islands (UK)": "238",
  "Virgin Islands (US)": "239",
  Palestine: "240",
  "Wallis and Futuna": "241",
  "Cape Town": "242",
  "La Paz": "243",
  Abkhazia: "244",
  "Netherlands Antilles": "245",
  "Northern Cyprus": "246",
  Svalbard: "247",
  Tokelau: "248",
};
