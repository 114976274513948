import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import reportWebVitals from './reportWebVitals';
import { Environment }from './models/types';
import './assets/styles/styles.scss';
import { InternationalizationProvider } from './hooks/LanguageHook';
import { ThemeWrapperProvider } from './hooks/ThemeHook';
import AppRoutes from './AppRoutes';
import { StoreState, reducers } from './store/store';

declare global {
  interface Window {
    env: Environment;
    dataLayer?: any[];
  }
}

const AppStore = configureStore<StoreState, AnyAction>({
  reducer: combineReducers<StoreState>(reducers),
  middleware: [thunk]
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeWrapperProvider>
    <InternationalizationProvider>
      <React.StrictMode>
      <Provider store={AppStore}>
        <AppRoutes />
      </Provider>
      </React.StrictMode>
    </InternationalizationProvider>
  </ThemeWrapperProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
