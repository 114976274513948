import { Fragment, memo, useCallback, useEffect, useState } from "react"
import { TabView, TabPanel } from 'primereact/tabview';
import { useParams } from "react-router-dom";
import useHttp from "../../../../../hooks/use-http";
import UtilService from "../../../../../services/util.service";
import ApiService from "../../../../../services/api.service";
import ChartComponent from "../../../../../shared/components/chart/ChartComponent";
import Loader from "../../../../../shared/components/loader/Loader";
import { AudienceDataColumn } from "../../../../../models/dataTemplate";

interface OwnProps {
  readonly selectedMatrix: { segment: string; rank: string, color: number | string };
}
const SegmentsComponent = (props: OwnProps) => {
  const params = useParams();
  const { sendRequest: getCLVInsightsProfile, loading: getCLVInsightsProfileLoader } = useHttp();
  const [customerSegmentChartConfig, setCustomerSegmentChartConfig] = useState<any>({
    type: 'treemap',
    containerBackgroundOpacity: "0",
    width: "100%",
    height: "16%",
    dataFormat: 'json',
    dataSource: {
      "chart": {
        "animation": "0",
        "hideTitle": "1",
        "plotToolText": "<div style='min-width: 150px'><div style='display: flex; justify-content: space-between'><b>$label</b></div> <div style='display: flex; justify-content: space-between; margin-top: 5px'> <div>Count:</div> <div> <b>$value</b></div> </div>",
        "spacex": "0",
        "spacey": "0",
        "horizontalPadding": "1",
        "verticalPadding": "1",
        "hoveronallsiblingleaves": "1",
        "plotborderthickness": "0.5",
        "plotbordercolor": "666666",
        "legendpadding": "0",
        "legendItemFontSize": "10",
        "legendItemFontBold": "1",
        "showLegend": "1",
        "legendPointerWidth": "8",
        "legenditemfontcolor": "3d5c5c",
        "algorithm": "squarified",
        "caption": "",
        "legendScaleLineThickness": "0",
        "legendCaptionFontSize": "10",
        "legendaxisbordercolor": "bfbfbf",
        "subcaption": "",
        "legendCaption": "",
        "showchildLabels": "1",
        "showchildValues": "1",
        "showParent": "0",
        "theme": "fusion",
        "labelFontColor": "000",
        "labelShadow": "0",
        "toolTipBgColor": "#444444",
        "toolTipColor": "#fff",
        "toolTipBorderColor": "#444444",
        "toolTipBorderRadius": "5",
        "chartLeftMargin": "0",
        "chartRightMargin": "0",
        "chartTopMargin": "0",
        "chartBottomMargin": "0",
      },
      "data": [],
      "colorrange": {
        "mapbypercent": "1",
        "gradient": 0,
        "minvalue": "-25",
        "code": "e24b1a",
        "startlabel": "Decline",
        "endlabel": "Rise",
        "color": [{
          "code": "ffffff",
          "maxvalue": "0",
          "label": "Static"
        }, {
          "code": "6da81e",
          "maxvalue": "25",
          "label": "AVERAGE"
        }]
      }
    }
  });
  const [avgClVSegmentChartConfig, setRevenueSegmentChartConfig] = useState<any>({
    type: 'treemap',
    containerBackgroundOpacity: "0",
    width: "100%",
    height: "16%",
    dataFormat: 'json',
    dataSource: {
      "chart": {
        "animation": "0",
        "hideTitle": "1",
        "plotToolText": "<div style='min-width: 150px'><div style='display: flex; justify-content: space-between'><b>$label</b></div> <div style='display: flex; justify-content: space-between; margin-top: 5px'> <div>Avg. CLV:</div> <div> <b>$$svalue K</b></div> </div> ",
        "spacex": "0",
        "spacey": "0",
        "horizontalPadding": "1",
        "verticalPadding": "1",
        "hoveronallsiblingleaves": "1",
        "plotborderthickness": "0.5",
        "plotbordercolor": "666666",
        "legendpadding": "0",
        "legendItemFontSize": "10",
        "legendItemFontBold": "1",
        "showLegend": "1",
        "legendPointerWidth": "8",
        "legenditemfontcolor": "3d5c5c",
        "algorithm": "squarified",
        "caption": "",
        "legendScaleLineThickness": "0",
        "legendCaptionFontSize": "10",
        "legendaxisbordercolor": "bfbfbf",
        "subcaption": "",
        "legendCaption": "",
        "showchildLabels": "1",
        "showParent": "0",
        "theme": "fusion",
        "toolTipBgColor": "#444444",
        "toolTipColor": "#fff",
        "toolTipBorderColor": "#444444",
        "toolTipBorderRadius": "5",
        "chartLeftMargin": "0",
        "chartRightMargin": "0",
        "chartTopMargin": "0",
        "chartBottomMargin": "0",
      },
      "data": [],
      "colorrange": {
        "mapbypercent": "1",
        "gradient": 0,
        "minvalue": "-25",
        "code": "e24b1a",
        "startlabel": "Decline",
        "endlabel": "Rise",
        "color": [{
          "code": "ffffff",
          "maxvalue": "0",
          "label": "Static"
        }, {
          "code": "6da81e",
          "maxvalue": "25",
          "label": "AVERAGE"
        }]
      }
    }
  });
  const audienceInsightsProfile = useCallback((groupBy: any) => {
    const resHandler = (res: any) => {
      const totalCustomers = res.response.profile.reduce((acc: number, profile: any) => acc + profile.customer, 0);
      const totalRevenue = res.response.profile.reduce((acc: number, profile: any) => acc + profile.avg_clv, 0);
      const _customerSegmentSet: any = res.response.profile.map((profile: any, index: number) => {
        return {
          label: `${profile.label}<br/>${UtilService.percentageCalculator(profile.customer, totalCustomers).toFixed(0)}%`,
          fillcolor: props.selectedMatrix.color ? props.selectedMatrix.color : UtilService.returnColorSchema()[index],
          value: profile.customer,
          svalue: UtilService.percentageCalculator(profile.customer, totalCustomers).toFixed(2)
        }
      });
      const _avgClVSegmentSet: any = res.response.profile.map((profile: any, index: number) => {
        return {
          label: `${profile.label}<br/>${UtilService.percentageCalculator(profile.avg_clv, totalRevenue).toFixed(0)}%`,
          fillcolor: UtilService.returnColorSchema()[index],
          value: profile.avg_clv,
          svalue: UtilService.numberFormatter(Number(profile.avg_clv),'K')
        }
      });
      const customerSegmentSet: any = { ...customerSegmentChartConfig };
      customerSegmentSet.dataSource.data = [{
        label: "Total",
        value: _customerSegmentSet.reduce((acc: number, item: any) => acc + item.value, 0), // totalCustomers,
        svalue: '100',
        data: [..._customerSegmentSet]
      }];
      const avgClVSegmentSet: any = { ...avgClVSegmentChartConfig };
      avgClVSegmentSet.dataSource.data = [{
        label: "Total",
        value: _avgClVSegmentSet.reduce((acc: number, item: any) => acc + item.value, 0), // totalRevenue,
        svalue: '100',
        data: [..._avgClVSegmentSet]
      }]
      setCustomerSegmentChartConfig(customerSegmentSet);
      setRevenueSegmentChartConfig(avgClVSegmentSet);
    }
    let filter;
    if (props.selectedMatrix.segment !== '' && props.selectedMatrix.rank !== '') {
        filter = { segment: props.selectedMatrix.segment, rank: props.selectedMatrix.rank };
    }
      getCLVInsightsProfile(ApiService.getCLVDistributionProfile(params.clvid!, groupBy, filter), resHandler)
  }, [getCLVInsightsProfile, params.clvid, props.selectedMatrix]);
  useEffect(() => {
      audienceInsightsProfile(AudienceDataColumn.SEGMENT);
  }, [audienceInsightsProfile]);

  return (
    <Fragment>
      <div className="insights__innerwrapper customerRevenue">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <h2 className="inner__heading">
              <span> Segments </span>
              {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
            </h2>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 text-end">
            {/* <span className="pi pi-ellipsis-v dots3"></span> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="tabs-wrapper customtabs maptabview-wrap">
              <TabView>
                <TabPanel header="Customers">
                  {getCLVInsightsProfileLoader ||
                    !customerSegmentChartConfig?.dataSource?.data?.[0]?.data?.length ? (
                    <Loader style={{ minHeight: "320px" }} />
                  ) : (
                    <ChartComponent chartConfigs={customerSegmentChartConfig} />
                  )}
                </TabPanel>
                <TabPanel header="Avg CLV">
                  <Loader
                    isLoading={
                      getCLVInsightsProfileLoader ||
                      !avgClVSegmentChartConfig?.dataSource?.data?.[0]?.data
                    }
                    style={{ minHeight: "320px" }}
                  >
                    <ChartComponent chartConfigs={avgClVSegmentChartConfig} />
                  </Loader>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default memo(SegmentsComponent);