import { Fragment } from "react";
import {
    mai_question_icon,
    mai_content_icon,
    mai_offer_icon,
    mai_formname_icon,
    mai_sendtime_icon,
    mai_delete_icon_gray
} from "../../../../../../assets/images";
import './AbTestTarget.scss';

const AbTestTargetComponent = () => {
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Test Targets</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Test Details</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper testtargetwrapper'>
                <div className='audiencecreate__form box-shadow whiteboxheight'>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className='boxmin-height'>
                                <div className="row mb-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <h2 className="title16 m-0"><span> What would you like to test? </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="subjectlinebox">
                                            <h2>Subject Line</h2>
                                            <div className="plusminuswrap">
                                                <div className="plusminuswrap__inner"><span className="pi pi-minus" role="button"></span></div>
                                                <div className="plusminuswrap__inner plusminuswrap__inner-middle">0</div>
                                                <div className="plusminuswrap__inner"><span className="pi pi-plus" role="button"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="testdescbox">
                                            <img src={mai_formname_icon} alt="form name" className="img-fluid" />
                                            <h2>Form Name</h2>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="testdescbox">
                                            <img src={mai_sendtime_icon} alt="send time" className="img-fluid" />
                                            <h2>Send Time</h2>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="testdescbox">
                                            <img src={mai_offer_icon} alt="offer" className="img-fluid" />
                                            <h2>Offer</h2>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="testdescbox">
                                            <img src={mai_content_icon} alt="content" className="img-fluid" />
                                            <h2>Content</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row my-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <hr className="hrline" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <p className="normalpara">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas nihil blanditiis eius voluptatum consequatur, atque tenetur esse sed recusandae reiciendis.</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="subjectbox">
                                            <div className="deleteiconbox text-end">
                                                <img src={mai_delete_icon_gray} alt="delete" className="img-fluid deleteimg" />
                                            </div>
                                            <div>
                                                <label htmlFor="subject1" className="form-label font16">Subject 1</label>
                                                <input type="text" className="form-control normalinputfield" id="subject1" placeholder="Increase New Product Adoptation" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="subjectbox">
                                            <div className="deleteiconbox text-end">
                                                <img src={mai_delete_icon_gray} alt="delete" className="img-fluid deleteimg" />
                                            </div>
                                            <div>
                                                <label htmlFor="subject2" className="form-label font16">Subject 1</label>
                                                <input type="text" className="form-control normalinputfield" id="subject2" placeholder="North-East Sales Renewal Test" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Prev</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default AbTestTargetComponent;