import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const CustomerLifeTimeValueComponent = () => {
    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}

export default CustomerLifeTimeValueComponent;