import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

const AdminComponent = () => {
  return (
    <Fragment>
      <div>Admin Component</div>
    <Outlet />
    </Fragment>
    
  )
}

export default AdminComponent