import { Fragment } from "react"
import { Outlet } from "react-router-dom"

const ClvCreateComponent = () =>{
    return(
        <Fragment>
            <Outlet />
        </Fragment>
    )
}
export default ClvCreateComponent;