import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import './InputParameters.scss';

import {
    mai_plus_solid_blue,
    mai_question_icon,
    mai_light_red_cross,
    mai_gray_dots,
    mai_blue_dots,
    mai_arrow_right,
    mai_search_bar
} from '../../../../../../assets/images/index';
import { Url } from "../../../../../../models/constants";
import ApiService from "../../../../../../services/api.service";

import useHttp from '../../../../../../hooks/use-http';
import AuthenticationService from '../../../../../../services/authentication.service';
import { AudienceGeneralDetail } from '../../../../../../models/types';

const InputParametersComponent = () => {
    const params = useParams();
    let navigate = useNavigate();
    const [audience, setAudience] = useState<AudienceGeneralDetail>({
        name: "",
        description: "",
        is_private: false,
        can_be_accessed_by: [],
    });
    const { sendRequest: createAudience, loading: createAudienceLoader, error } = useHttp();
    const { sendRequest: getAudience, loading: getAudienceLoader } = useHttp();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const [enableNext, setEnableNext] = useState<boolean>(false);

    useEffect(() => {
        AuthenticationService.fetchUsers().then((res: any) => {
            let allUsers = res.users.map((usr: any) => ({
                id: usr.name, ...usr
            }));
            setUserList(allUsers);
            setFilteredUsers(allUsers);
        });
    }, [])

    useEffect(() => {
        if (params?.aid) {
            const resHandler = (res: any) => {
                setAudience(res.audience);
            }
            getAudience(ApiService.getAudienceGeneraldetail(params?.aid), resHandler)
        }
    }, [params])

    useEffect(() => {
        if (audience.name !== '' && audience.description !== '' && ((audience.is_private === true && audience.can_be_accessed_by.length > 0) || audience.is_private === false)) {
            setEnableNext(true);
        } else {
            setEnableNext(false);
        }
    }, [audience]);

    useEffect(() => {
        setAudience({ ...audience, can_be_accessed_by: selectedUsers.map((usr: any) => usr.email) });
    }, [selectedUsers]);

    const searchUser = (event: any) => {
        setTimeout(() => {
            let _filteredUsers;
            if (!event.query.trim().length) {
                _filteredUsers = [...userList];
            } else {
                _filteredUsers = userList.filter((usr: any) => !selectedUsers.map((selusr: any) => selusr.id).includes(usr.id)).filter((usr: any) => {
                    return usr.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUsers(_filteredUsers);
        }, 250);
    };

    const createAudienceHandler = (event: React.FormEvent) => {
        event.preventDefault();
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_RULES}/${res.id}`);
        }
        createAudience(ApiService.createAudienceGeneraldetail(audience), resHandler)
    }

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>Input Parameters</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper optimizationdetailswrap1'>
                <div className='audiencecreate__form'>
                    <form onSubmit={createAudienceHandler}>
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="objectivefunction" className="form-label mai-form-label">Objective Funtion</label>
                                            <input type="text" className="form-control mai-input" id="objectivefunction" name="objectivefunction" maxLength={200} value={audience.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAudience({ ...audience, name: e.target.value })} aria-describedby="emailHelp" placeholder='maximize revenue' />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12"></div>

                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="mb-4">
                                            <label htmlFor="constraints" className="form-label mai-label-sharewith">Constraints</label>
                                            <input type="text" className="form-control mai-input" id="constraints" name="constraints" placeholder='Max. number of customers in a campaign' />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className='segmentbutton d-none'>
                                            <NavLink to="" className="createbutton"><img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> Add Constraint</NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="mb-4">
                                            <input type="text" className="form-control mai-input" id="newacquisitions" name="newacquisitions" placeholder='Exclude new acquisitions' />
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className='segmentbutton mt-0'>
                                            <NavLink to="" className="createbutton"><img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> Add Constraint</NavLink>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12"></div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <h3 className='titlefont16 mb-3'><span> Choose Variables </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>
                                <div className="rules__wrapper">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">

                                        <div className="accordion-item pb-0 mt-2 border-none">                                          
                                            <div id="flush-collapsetwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">

                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p className='mt-0'>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p className='mt-0'>Product Segment</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p className='mt-0'>Usage Segment</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='rules__rightwrapper'>
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon4" className="form-control border-0" />
                                        </div>
                                    </div>
                                    <div className='rules__custprofile'>
                                        <div className="accordion" id="mai-accordian-rules">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                        Customer Profile
                                                    </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                SFDC Account ID
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Customer Number
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Account Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Tenure
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Usage Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Contract Type
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                        Firmographics
                                                    </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Region
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Annual sales
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                No. of employees
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Multi/single location
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading3">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                        Transaction
                                                    </button>
                                                </h2>
                                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <p className='coming-soon m-0'> Coming Soon</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row mt-3">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <button className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                            {/* <button type="submit" className="btn btn-primary mai-next-button" disabled={!enableNext}>Next</button> */}
                                            <button type="submit" className="btn btn-primary mai-next-button" disabled>Next</button>
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    )
}
export default InputParametersComponent;