import { Fragment, useCallback } from "react";
import React, { useState, useEffect, useRef } from "react";
import DataTable from "../../../../../shared/components/datatable/DataTable";
import "./CustomerLifetimeValueList.scss";
import {
  mai_search_bar,
  mai_plus,
  mai_users,
  mai_share_icon,
  mai_back_icon,
} from "../../../../../assets/images/index";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastSeverity, Url } from "../../../../../models/constants";
import { Dropdown, DropdownChangeEvent as DropdownChangeParams } from "primereact/dropdown";
import {
  CLVDetail,
  CLVListResponse,
  Columns,
  creatorListResponse,
} from "../../../../../models/types";
import ApiService from "../../../../../services/api.service";
import AuthenticationService from "../../../../../services/authentication.service";
import useHttp from "../../../../../hooks/use-http";
import { Paginator } from "primereact/paginator";
import Loader from "../../../../../shared/components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { StoreActions } from "../../../../../store/actions";
import { Tooltip } from "primereact/tooltip";
import { Avatar } from "primereact/avatar";
import UtilService from "../../../../../services/util.service";
import { Menu } from "primereact/menu";
import CustomTooltip from "../../../../../shared/components/customtooltip/CustomTooltip";
import { StoreState } from "../../../../../store/store";
import { ConfirmDialog } from "primereact/confirmdialog";

const CustomerLifetimeValueListComponent = () => {

  const menu = useRef<any>(null!);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [deleteCLVID, setDeleteCLVID] = useState<string>("");
  const [tableDataCount, setTableDataCount] = useState<any>(0);
  const [tableData, setTableData] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [creatorKey, setCreatorKey] = useState<{ code: string, name: string }>({ name: 'All', code: '' });
  const [sharedByUsers, setSharedByUsers] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [menuItems, setMenuItems] = useState<any>([]);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [allCreators, setAllCreators] = useState<{
    [key: string]: { [key: string]: string };
  }>({});
  const [creatorList, setCreatorList] = useState<
    { code: string; name: string }[]
  >([]);
  const { sendRequest: getClvs, loading: getClvsLoader } = useHttp();
  const { sendRequest: deleteCLV, loading: deleteCLVLoader  } = useHttp();
  const { sendRequest: getCreators } = useHttp();
  const getClvList = useCallback(
    (
      searchText = "",
      createdBy = "",
      published = "",
      page = 0,
      sortBy = "modified_on",
      sortOrder = -1
    ) => {
      const resHandler = async (res: CLVListResponse) => {
        setTableDataCount(res.total);
        setTableData(res.clv);
        const sharedUsers: string[] = [];
        res.clv.forEach((item: CLVDetail) => sharedUsers.push(...item.can_be_accessed_by));
        const uniqueSharedUsers = [...Array.from(new Set(sharedUsers))];
        const sharedUsersObj: { [key: string]: { [key: string]: string } } = {};
        await Promise.allSettled(
          uniqueSharedUsers.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { sharedUsersObj[id] = usr.user }).catch((err: any) => console.error(err)))
        );
        setSharedByUsers(sharedUsersObj);
      };
      getClvs(
        ApiService.getCLVList({
          page: page + 1,
          search: searchText,
          created_by: createdBy,
          sort_by: sortBy,
          sort_order: sortOrder,
          published,
        }),
        resHandler
      );
    },
    [getClvs]
  );
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create CLV")
      headerTitleChangeHandler("Create CLV",'','');
  })
  useEffect(() => {
    getClvList();
  }, [getClvList]);

  useEffect(() => {
    const resHandler = async (res: creatorListResponse) => {
      const creatorobj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        res.creator.map(
          async (id) =>
            await AuthenticationService.fetchUserDetails(id)
              .then((usr: any) => {
                creatorobj[id] = usr.user;
              })
              .catch((err: any) => console.error(err))
        )
      );
      setAllCreators(creatorobj);
      const creatorsres: { code: string; name: string }[] = res.creator
        .filter((id: string) => !!creatorobj[id])
        .map((id: string) => ({ code: id, name: creatorobj[id].name }));
      creatorsres.unshift({ name: "All", code: "" });
      setCreatorList(creatorsres);
    };
    getCreators(ApiService.getClvCreatorList(), resHandler);
  }, [getCreators]);

  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const status = [
    { name: 'All', code: '' },
    { name: 'Published', code: 'True' },
    { name: 'Draft', code: 'False' }
  ];
  const [createdBy, setSelectedCreatedBy] = useState<any>(null);

  const statusBodyTemplate = (rowData: any) => {
    let status: string = "Draft";
    if (rowData.is_published) {
      status = "Published";
    }
    return (
      <span className={`customer-badge status-${status.toLowerCase()}`}>
        {status}
      </span>
    );
  };
  const createdByBodyTemplate = ({ creator, ...row }: any) => {
    return renderAvatar(creator, allCreators);
  };
  const nameBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.name.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listtooltip${rowData.id}`} position="bottom">
              {rowData.name}
            </Tooltip>
            <div className={`listtooltip${rowData.id}`}>
              {`${rowData.name.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.name
        )}
      </>
    );
  };
  const descriptionBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.description.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listdesctooltip${rowData.id}`} position="bottom">
              {rowData.description}
            </Tooltip>
            <div className={`listdesctooltip${rowData.id}`}>
              {`${rowData.description.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.description
        )}
      </>
    );
  };
  const sharedWithBodyTemplate = (rowData: any) => {
    let returnElem = <></>;
    let sharedElem = rowData.can_be_accessed_by?.slice(0, 3)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let sharedrestElem = rowData.can_be_accessed_by?.slice(3, rowData.can_be_accessed_by.length)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let allSharedElem = sharedElem?.concat(sharedrestElem);

    if (rowData.can_be_accessed_by?.length > 3) {
      returnElem = (
        <>
          <Tooltip className="list-tooltip-wrap" target={`.sharewithtooltip${rowData.id}`} position="bottom">
            {allSharedElem}
          </Tooltip>
          <div className={`sharewithtooltip${rowData.id}`}>
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </div>
        </>
      );
    } else {
      returnElem = <>{sharedElem}</>;
    }
    return returnElem;
  };
  const lastupdatedBodyTemplate = (rowData: any) => {
    let dateStr = new Date(rowData.modified_on);
    return dateStr.toLocaleDateString();
  }
  const actionBodyTemplate = (rowData: any) => {
    let items = [{}];
    const updateMenuItemsHandler = (clv: any) => {
      items.push({
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => { },
      });
      items.push({
        label: "Delete",
        icon: "pi pi-trash",
        command: () => { 
          setDisplayModal(true);
        },
      });
      setMenuItems(items);
    }
    return (
      <>
        <Menu model={menuItems} popup ref={menu} id="popup_menu" />{" "}
        <i
          className="pi pi-ellipsis-v"
          onClick={(event: any) => { menu?.current?.toggle(event); setDeleteCLVID(rowData.id); updateMenuItemsHandler(rowData); }}
        ></i>
      </>
    );
  };

  const columns: Columns[] = [
    { field: "name", header: "Name", body: nameBodyTemplate, sort: true },
    {
      field: "description",
      header: "Description",
      body: descriptionBodyTemplate,
      sort: false,
    },
    { field: "customers", header: "Customers", sort: false },
    {
      field: "creator",
      header: "Created By",
      body: createdByBodyTemplate,
      sort: false,
    },
    {
      field: "can_be_accessed_by",
      header: "Shared With",
      body: sharedWithBodyTemplate,
      sort: false,
    },
    {
      field: "modified_on",
      header: "Last Updated",
      body: lastupdatedBodyTemplate,
      sort: true,
    },
    {
      field: "is_published",
      header: "Status",
      body: statusBodyTemplate,
      sort: false,
    },
    {
      field: "actions",
      header: "Actions",
      body: actionBodyTemplate,
      sort: false,
    },
  ];
  const renderAvatar = (userId: string, userData: { [key: string]: { [key: string]: string } }) => {
    const userDetails: { id: string, name: string, label: string, image: string } = { id: userId, name: '', label: '', image: '' };
    if (userData[userId]) {
      userDetails.label = UtilService.getShortName(String(userData[userId].name));
      userDetails.name = String(userData[userId].name);
    }
    return (
      <Avatar key={userId} className={`user-avatar ${UtilService.classes()[userDetails.name.length % 10]}`} label={userDetails.label} image={userDetails.image} shape="circle" size={"normal"} />
    );
  }
  const onSelectionHandler = (event: any) => {
    if (event.rowData.is_published) {
      headerTitleChangeHandler(event?.rowData?.name,`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_LIST}`,'#');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_DETAILS}/${event.rowData.id}`
      );
    } else {
      headerTitleChangeHandler('Create CLV','','');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}/${event.rowData.id}`
      );
    }
  };
  const isCellSelectable = (event: any) => {
    const data = event.data;
    return data.field === "actions" ? false : true;
  };
  const tableConfig: any = {
    selection: { mode: "single", handler: onSelectionHandler },
    isDataSelectable: isCellSelectable,
  };

  const onStatusChange = (e: { value: any }) => {
    setSelectedStatus(e.value);
    getClvList("", creatorKey ? creatorKey.code : '', e.value.code);
  };

  const onCreatedByChange = (event: DropdownChangeParams) => {
    event.preventDefault();
    setCreatorKey(event.value);
    getClvList("", event.value.code, selectedStatus ? selectedStatus.code : '');
  };
  const pageChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    getClvList(searchKey, creatorKey ? creatorKey.code : '', selectedStatus ? selectedStatus.code : '', event.page);
  };

  const clvTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const patt = /[^A-Za-z 0-9]/g;
    if (!(patt.test(event.currentTarget.value))) {
      setSearchKey(event.currentTarget.value);
      getClvList(event.currentTarget.value);
    }
  };
  const deleteCLVCallback = () => {
    const resHandler = (res: any) => {
      dispatch({
        type: StoreActions.TOAST_ON,
        payload: {
          severity: ToastSeverity.SUCCESS,
          summary: "Information",
          detail: res.detail,
          life: 3000,
        },
      });
      getClvList(searchKey, creatorKey ? creatorKey.code : '', selectedStatus ? selectedStatus.code : '', 0);
    }
    if (deleteCLVID) {
      deleteCLV(ApiService.deleteCLV(deleteCLVID), resHandler);
    }
  }

  return (
    <Fragment>
      <div className="audiencecreate__form-wrapper">
        <div className="audiencecreate__form audiencelist whiteboxlist-height">
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="titles">Customer Lifetime Value</h1>
            </div>
          </div> */}
          <div className="row mb-3">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="rules__searchbox">
                    <div className="input-group">
                      <div className="input-group-prepend border-0">
                        <button
                          id="button-addon4"
                          type="button"
                          className="btn btn-link text-info"
                        >
                          <img
                            src={mai_search_bar}
                            alt="search"
                            className="img-fluid"
                          />
                        </button>
                      </div>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-describedby="button-addon4"
                        className="form-control border-0"
                        onChange={clvTextSearchHandler}
                        value={searchKey}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="selectlist-wrap">
                    <div className="selectlist">
                      <label>Created by</label>
                      <div className="monitoring-dropdownlist">
                        <Dropdown
                          value={creatorKey}
                          options={creatorList}
                          onChange={onCreatedByChange}
                          optionLabel="name"
                          placeholder="All"
                        />
                      </div>
                    </div>
                    <div className="selectlist">
                      <label>Status</label>
                      <div className="monitoring-dropdownlist">
                        <Dropdown
                          value={selectedStatus}
                          options={status}
                          onChange={onStatusChange}
                          optionLabel="name"
                          placeholder="All"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="welcome__create text-end">
                <NavLink
                  to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_CREAT}/${Url.CUSTOMER_LIFETIME_VALUE_CREATE_GENERAL}`}
                  onClick={() => headerTitleChangeHandler('Create CLV','','')}
                  className="welcome__create-anchor"
                >
                  <img
                    src={mai_plus}
                    alt="plus-icon"
                    className="img-fluid welcome__plus-icon"
                  />{" "}
                  Create CLV
                </NavLink>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card list-datatable audiencelist-datatable listpage-table border-0">
              {getClvsLoader || deleteCLVLoader ? (
                <Loader style={{ minHeight: "445px" }}></Loader>
              ) : (
                <>
                  <DataTable
                    data={tableData}
                    columns={columns}
                    config={tableConfig}
                  ></DataTable>
                </>
              )}
              <Paginator
                first={first}
                rows={rows}
                totalRecords={tableDataCount}
                onPageChange={pageChangeHandler}
                className="justify-content-end listpagination"
              ></Paginator>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={displayModal}
        onHide={() => {
          setDisplayModal(false);
          setDeleteCLVID("");
        }}
        maskClassName="common-popup-wrap"
        header="Are you sure, you want to delete CLV?"
        message=""
        closable={false}
        draggable={false}
        accept={deleteCLVCallback}
        reject={() => {
          setDisplayModal(false);
          setDeleteCLVID("");
        }} />
    </Fragment>
  );
};

export default CustomerLifetimeValueListComponent;
