import React, { Fragment, useState } from "react";
import './SpendOptimizationSummary.scss';
import {
    mai_question_icon,
    mai_channel_roi_chart,
    mai_spendpercentage_chart
} from '../../../../../../assets/images/index';
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


const SpendOptimizationSummaryComponent = () => {
    const [customerSegmentChartConfig, setCustomerSegmentChartConfig] = useState<any>({
        type: "pie2d",
        width: "100%",
        containerBackgroundOpacity: "0",
        dataFormat: "json",
        dataSource: {
            chart: {
                theme: "fusion",
                paletteColors: "#3265AA, #4CB4E6, #776EA9",
                showPlotBorder: "1",
                showValues: "0",
                showLabels: "0",
                showLegend: "1",
                legendPosition: "bottom",
                legendItemFontColor: "#000",
                legendItemFontSize: "13",
                showPercentValues: "0",
                enableSlicing: "0",
                decimals: "2",
                numberScaleValue: "1000,1000,1000",
                numberScaleUnit: "K,M,B",
                formatNumberScale: "1",
                stack100Percent: "1",
                chartLeftMargin: "0",
                chartRightMargin: "0",
                chartBottomMargin: "10",
                chartTopMargin: "0",
                captionPadding: "0",
                skipOverlapLabels: "1",
                enableSmartLabels: "1",
                labelDistance: "1",
                toolTipBgColor: "#444444",
                toolTipColor: "#fff",
                tooltipborderradius: "5",
                smartLineThickness: "0",
                valuePosition: "Inside",
                labelFontColor: "#fff",
                bgColor: "#f8f8f8",
                pieRadius: "100"
            },
            "data": [{
                "label": "Email",
                "value": "285040"
            }, {
                "label": "Facebook",
                "value": "146330"
            }, {
                "label": "Instagram",
                "value": "105070"
            }]
        },
    });

    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const onStatusChange = (e: { value: any }) => {
        setSelectedStatus(e.value);
    }
    const status = [
        { name: 'Email', code: 'Email' },
        { name: 'Facebook', code: 'Facebook' },
        { name: 'Instagram', code: 'Instagram' }
    ];

    const metricdata = [
        { Channels: 'Email', currentsales: '$14000', scenariossales: '$16000', percentageincrement: '+14.28%' },
        { Channels: 'Facebook', currentsales: '$10000', scenariossales: '$11000', percentageincrement: '+0.10%' },
        { Channels: 'Instagram', currentsales: '$6000', scenariossales: '$5000', percentageincrement: '-0.16%' }
    ]

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Define channel theme</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Spend optimization</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper'>
                <form>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="box-shadow">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="inner__heading">Channel Distribution <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                        <span className="pi pi-ellipsis-v dots3"></span>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                <ChartComponent chartConfigs={customerSegmentChartConfig} />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                <div className="distribution-indicator">
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidgreen-circle indicator"></span></div>
                                                        <div>
                                                            <h4>25%</h4>
                                                            <p>Email</p>
                                                        </div>
                                                    </div>
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidblue-circle indicator"></span></div>
                                                        <div>
                                                            <h4>40%</h4>
                                                            <p>Facebook</p>
                                                        </div>
                                                    </div>
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidred-circle indicator"></span></div>
                                                        <div>
                                                            <h4>35%</h4>
                                                            <p>Instagram</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-12">
                                                <ChartComponent chartConfigs={customerSegmentChartConfig} />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                                <div className="distribution-indicator">
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidgreen-circle indicator"></span></div>
                                                        <div>
                                                            <h4>25%</h4>
                                                            <p>Email</p>
                                                        </div>
                                                    </div>
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidblue-circle indicator"></span></div>
                                                        <div>
                                                            <h4>40%</h4>
                                                            <p>Facebook</p>
                                                        </div>
                                                    </div>
                                                    <div className="distribution-indicator-inner">
                                                        <div><span className="solidred-circle indicator"></span></div>
                                                        <div>
                                                            <h4>35%</h4>
                                                            <p>Instagram</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="box-shadow">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <h3 className="inner__heading">Channel level ROI <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <ul className="channellist">
                                            <li><span className="solidgreen-circle indicator"></span> Investments</li>
                                            <li><span className="solidblue-circle indicator"></span> Sales</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center justify-content-end">
                                        <div className='selectlist d-flex me-5'>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown className="channelroi-list" panelClassName="channelroi-list-pannel" value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="Email" />
                                            </div>
                                        </div>

                                        <span className="pi pi-ellipsis-v dots3"></span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <div className="roilist">
                                            <div className="roilist-inner">
                                                <h4>Sales</h4>
                                                <h5>$14K</h5>
                                            </div>
                                            <div className="roilist-inner">
                                                <h4>Investments</h4>
                                                <h5>$7.5K</h5>
                                            </div>
                                            <div className="roilist-inner">
                                                <h4>ROI</h4>
                                                <h5>86.66%</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-12">
                                        <img src={mai_channel_roi_chart} alt="" className="img-fluid" />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="box-shadow">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="inner__heading">Change in spend (%) <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                        <span className="pi pi-ellipsis-v dots3"></span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <img src={mai_spendpercentage_chart} alt="spend" className="img-fluid" />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="box-shadow">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="inner__heading">Performance Metrics <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                        <span className="pi pi-ellipsis-v dots3"></span>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <DataTable value={metricdata} tableClassName="metricsdatatable">
                                            <Column field="Channels" header="Channels"></Column>
                                            <Column field="currentsales" header="Current Sales"></Column>
                                            <Column field="scenariossales" header="Scenarios Sales"></Column>
                                            <Column field="percentageincrement" header="Percentage Increment"></Column>
                                        </DataTable>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <button className="btn btn-primary mai-cancel-button">Cancel</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                        <button className="btn btn-primary mai-cancel-button me-3">Save as draft</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Publish</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </Fragment>
    )
}
export default SpendOptimizationSummaryComponent;