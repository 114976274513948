import React, { useCallback, useEffect, useState } from "react"
import { Fragment } from "react"
import { useParams } from "react-router-dom";
import { mai_question_icon } from "../../../../../../assets/images";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import UtilService from "../../../../../../services/util.service";
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import Loader from "../../../../../../shared/components/loader/Loader";
import { AudienceDataColumn } from "../../../../../../models/dataTemplate";

const prodChartData: any = {
    type: "bubble",
    width: "100%",
    height: "15.5%",
    containerBackgroundOpacity: "0",
    dataFormat: "json",
    dataSource: {
        chart: {
            theme: "fusion",
            paletteColors: "#4e24e2, #0372AB, #FF5904",
            xAxisMinValue: "0",
            xNumberPrefix: "$",
            yAxisMinValue: "0",
            showPlotBorder: "1",
            showValues: "1",
            xAxisName: "Revenue",
            yAxisName: "Customers",
            showverticalline: "0",
            divLineDashed: "1",
            vDivLineDashed: "1",
            chartLeftMargin: "0",
            chartRightMargin: "0",
            chartTopMargin: "0",
            chartBottomMargin: "0",
            canvasTopPadding: "40",
            canvasRightPadding: "40",
            toolTipBgColor: "#444444",
            toolTipColor: "#fff",
            toolTipBorderColor: "#444444",
            toolTipBorderRadius: "5",
            // xFormatNumberScale: "1"
            // numberScaleUnit: "K, M", 
            // numberScaleValue: "1000, 1000"

        },
        dataset: [{ data: [{ x: 0, y: 0, z: 1, name: "", label: "", color: "#FFFFFF" }] }]
    },
}
const ProductsComponent = (props: any) => {
    const params = useParams();
    const { sendRequest: getAudienceInsightsProfile, loading: getAudienceInsightsProfileLoader } = useHttp();
    const [productsChartConfig, setProductsChartConfig] = useState<any>();
    const audienceInsightsProfile = useCallback((groupBy: any) => {
        const filter: any = [];
        const group_by: any = [groupBy];
        const resHandler = (res: any) => {
            let cloneConfig = { ...prodChartData };
            const slicedProducts: any = [...res.response.profile].slice(0, 5);
            cloneConfig.dataSource.dataset = [{
                data: slicedProducts.map((item: any, index: number) => ({
                    x: item.revenue,
                    y: item.customer,
                    z: 1,
                    label: UtilService.getShortName(item.label),
                    displayValue: item.label.length > 3 ? UtilService.getShortName(item.label) : item.label,
                    name: item.label,
                    color: UtilService.returnColorSchema()[index >= 10 ? (index % 10) : index],
                    toolText: `<div style='min-width: 150px'><b>Product: ${item.label} </b><div style='display: flex; justify-content: space-between; margin-top: 5px'> <div>Customers:</div> <div><b>${item.customer}</b></div></div> <div style='display: flex; justify-content: space-between; margin-top: 3px'> <div>Revenue:</div> <div><b>$${UtilService.numberFormatter(Number(item.revenue))}</b></div></div></div>`,

                }))
            }];
            setProductsChartConfig(cloneConfig);
        }
        if (props.region) {
            filter.push({ field: AudienceDataColumn.REGION, label: "Region", value: { in: [props.region] }, operator: "null" });
        }
        if (props.start_date && props.end_date) {
            getAudienceInsightsProfile(ApiService.getAudienceInsightsProfile({ aid: params.aid, group_by, start_date: props.start_date, end_date: props.end_date, filter }), resHandler)
        }
    }, [getAudienceInsightsProfile, params.aid, props.end_date, props.region, props.start_date]);

    useEffect(() => {
        if (props.end_date !== undefined && props.region !== undefined && props.start_date !== undefined) {
            audienceInsightsProfile(AudienceDataColumn.PRODUCT);
        }
    }, [audienceInsightsProfile, props]);

    return (
        <Fragment>
            <div className="insights__innerwrapper productsChart">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <h2 className='inner__heading'><span> Products <>(Top-5)</> </span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                        {/* <span className="pi pi-ellipsis-v dots3"></span> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="map-wrapper">
                            {(getAudienceInsightsProfileLoader || !productsChartConfig?.dataSource?.dataset?.[0]?.data?.length) ? <Loader style={{ minHeight: "320px" }} /> :
                                (!getAudienceInsightsProfileLoader && productsChartConfig?.dataSource?.dataset?.[0]?.data?.length ? <ChartComponent chartConfigs={productsChartConfig} /> : '')}
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default React.memo(ProductsComponent);