import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mai_question_icon } from '../../../../../../assets/images';
import useHttp from '../../../../../../hooks/use-http';
import { Columns } from '../../../../../../models/types';
import ApiService from '../../../../../../services/api.service';
import UtilService from '../../../../../../services/util.service';
import DataTable from '../../../../../../shared/components/datatable/DataTable';
import Loader from '../../../../../../shared/components/loader/Loader';
import './TopCustomersComponent.scss';

const TopCustomersComponent = (props: any) => {
    let params = useParams();
    const [tableData, setTableData] = useState<any>([]);
    const [productColorSet, setProductColorSet] = useState<any>([]);
    const { sendRequest: getTopCustomersList, loading: getcustomersLoader } = useHttp();
    const getTopCustomers = useCallback(() => {
        const data = {
            customers: 20,
            products: 3
        };
        const resHandler = (res: any) => {
            let productSet: any = [];
            res.top_customers.forEach((item: any) => productSet.push(...item.products));
            const newSet: any = new Set(productSet);
            setProductColorSet([...newSet]);
            const dataSet = res.top_customers.map((cust: any) => {
                const change_perc = cust.current_acv > 0 && cust.previous_acv > 0 ? (cust.change / cust.previous_acv) * 100 : 0;
                return {
                    ...cust,
                    change_perc
                }
            });
            setTableData(dataSet);
        }
        getTopCustomersList(ApiService.audienceInsightsTopCustomers(params.aid, data), resHandler);
    }, [getTopCustomersList, params.aid]);
    useEffect(() => {
        getTopCustomers();
    }, [getTopCustomers]);
    const customerNameTemplate = (rowData: any) => {
        return <>{UtilService.capitalizeTheFirstLetterOfEachWord(rowData.customer_name)}</>
    }
    const amountBodyTemplate = (rowData: any) => {
        return <>${UtilService.numberFormatter(Number(rowData.change))}</>
    }

    const currentrevenueBodyTemplate = (rowData: any) => {
        return <>${UtilService.numberFormatter(Number(rowData.current_acv))}</>
    }

    const prevrevenueBodyTemplate = (rowData: any) => {
        return <>${UtilService.numberFormatter(Number(rowData.previous_acv))}</>
    }

    const percentBodyTemplate = (rowData: any) => {
        return <div className={`${rowData.change_perc > 0 ? 'raise' : 'fall'}`}>{`${rowData.change_perc > 0 ? '+' : ''}`}{Math.round(rowData.change_perc.toFixed(2))}%</div>
    }

    const topproductsBodyTemplate = (rowData: any) => {
        return <>
            {rowData.products.map((product: string, index: number) =>
                <span key={`cust_prod_${index}`} className={`product-badge`} style={{ 'background': `${productColorCode(product)}` }}>
                    {UtilService.capitalizeTheFirstLetterOfEachWord(product)}
                </span>
            )}
        </>
    }
    const productColorCode = (product: any) => {
        return UtilService.returnColorSchema()[productColorSet.indexOf(product)];
    }
    const columns: Columns[] = [
        {
            field: "customer_name",
            header: "Customer Name",
            sort: true,
            body: customerNameTemplate,
            width: '20%'
        },
        {
            field: "current_acv",
            header: `Total Revenue (CY) $`,
            body: currentrevenueBodyTemplate,
            sort: true,
            align: "center",
            width: '10%'
        },
        {
            field: "previous_acv",
            header: `Total Revenue (PY) $`,
            body: prevrevenueBodyTemplate,
            sort: true,
            align: "center",
            width: '10%'
        },
        {
            field: "change",
            header: "Absolute Change ($)",
            body: amountBodyTemplate,
            sort: true,
            align: "center",
            width: '10%'
        },
        {
            field: "change_perc",
            header: "Change (%)",
            body: percentBodyTemplate,
            sort: true,
            align: "center",
            width: '10%'
        },
        {
            field: "products",
            header: "Top 3 Products",
            body: topproductsBodyTemplate,
            sort: false,
            align: "center",
            width: '40%'
        },
    ];
    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="insights__innerwrapper">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <h2 className='inner__heading'>
                                <span> Top 20 customers </span>
                                {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="tabs-wrapper">
                                <div className="card list-datatable audiencelist-datatable audiencedetails-table top20customerwrap border-0">
                                    {getcustomersLoader ? <Loader style={{minHeight: '27rem'}} /> :
                                        <DataTable
                                            data={tableData}
                                            columns={columns}
                                        ></DataTable>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TopCustomersComponent);