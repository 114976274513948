import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { mai_search_bar, mai_plus, mai_users } from '../../../../../assets/images/index';
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastSeverity, Url } from '../../../../../models/constants';
import { Dropdown, DropdownChangeEvent as DropdownChangeParams  } from 'primereact/dropdown';
import ApiService from '../../../../../services/api.service';
import { StoreActions } from '../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import useHttp from '../../../../../hooks/use-http';
import { StoreState } from '../../../../../store/store';
import { Columns, RecommendationDetail, RecommendationGeneralDetail, RecommendationListResponse, creatorListResponse } from '../../../../../models/types';
import AuthenticationService from '../../../../../services/authentication.service';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';
import useDebounce from '../../../../../hooks/DebounceHook';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import UtilService from '../../../../../services/util.service';
import Loader from '../../../../../shared/components/loader/Loader';
import { Paginator } from 'primereact/paginator';
import DataTable from '../../../../../shared/components/datatable/DataTable';

const RecommendationListComponent = () => {
  const menu = useRef<any>(null!);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayShareWithModal, setDisplayShareWithModal] = useState(false);
  const [deleteRecommendationID, setDeleteRecommendationID] = useState<string>("");
  const [tableData, setTableData] = useState<any>([]);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [tableDataCount, setTableDataCount] = useState<any>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const [creatorKey, setCreatorKey] = useState<{ code: string, name: string }>({ name: 'All', code: '' });
  const [selectedStatus, setSelectedStatus] = useState<{ code: string, name: string }>({ code: '', name: 'All' });
  const [allCreators, setAllCreators] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [sharedByUsers, setSharedByUsers] = useState<{ [key: string]: { [key: string]: string } }>({});
  const [creatorList, setCreatorList] = useState<{ code: string, name: string }[]>([]);
  const { sendRequest: getRecommendations, loading: getRecommendationsLoader } = useHttp();
  const { sendRequest: getCreators } = useHttp();
  const { sendRequest: deleteRecommendation } = useHttp();
  const { sendRequest: updateRecommendation } = useHttp();
  const { sendRequest: getRecommendation } = useHttp();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [userList, setUserList] = useState([]);
  const { sendRequest: getUsers } = useHttp();
  const currentUserDetails = useSelector<StoreState, any>(state => state.currentUser);

  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    headerTitleChangeHandler("Saved Recommendations",'','');
  })
  const [recommendation, setRecommendation] = useState<RecommendationGeneralDetail>({
    name: "",
    description: "",
    is_private: false,
    can_be_accessed_by: [],
    source: '',
    source_id: []
  });

  const status = [
    { name: 'All', code: '' },
    { name: 'Published', code: 'True' },
    { name: 'Draft', code: 'False' }
  ];
  const getRecommendationList = useCallback((
    searchText = "",
    createdBy = "",
    published = '',
    page = 0,
    sortBy = "modified_on",
    sortOrder = -1,

  ) => {
    const resHandler = async (res: RecommendationListResponse) => {
      setTableDataCount(res.total);
      setTableData(res.recommendation);
      const sharedUsers: string[] = [];
      res.recommendation.forEach((item: RecommendationDetail) => sharedUsers.push(...item.can_be_accessed_by));
      const uniqueSharedUsers = [...Array.from(new Set(sharedUsers))];
      const sharedUsersObj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        uniqueSharedUsers.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { try { sharedUsersObj[id] = usr.user } catch (error) { console.log(error) } }))
      );
      setSharedByUsers(sharedUsersObj);
    };
    getRecommendations(
      ApiService.getRecommendationList({
        page: page + 1,
        search: searchText,
        created_by: createdBy,
        sort_by: sortBy,
        sort_order: sortOrder,
        published
      }),
      resHandler
    );
  }, [getRecommendations]);

  useEffect(() => {
    getRecommendationList();
  }, [getRecommendationList]);

  useEffect(() => {
    const resHandler = async (res: creatorListResponse) => {
      const creatorobj: { [key: string]: { [key: string]: string } } = {};
      await Promise.allSettled(
        res.creator.map(async (id) => await AuthenticationService.fetchUserDetails(id).then((usr: any) => { creatorobj[id] = usr.user }).catch((err: any) => console.error(err)))
      );
      setAllCreators(creatorobj);
      const creatorsres: { code: string, name: string }[] = res.creator.filter((id: string) => !!creatorobj[id]).map((id: string) => ({ code: id, name: creatorobj[id].name }));
      creatorsres.unshift({ name: 'All', code: '' });
      setCreatorList(creatorsres);
    };
    getCreators(ApiService.getRecommendationCreatorList(), resHandler);
  }, [getCreators]);

  const userSelectionHandler = (event: any) => {
    setFlag(true);
    setSelectedUsers(event.value);
    setRecommendation({ ...recommendation, can_be_accessed_by: [...event.value] });
  }

  const searchUser = (event: any) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        const resHandler = (res: any) => {
          if (res?.users) {
            let allUsersList = res.users;
            const userListWithoutLoginUser = allUsersList.filter(
              (user: any) => user.id != currentUserDetails.id
            );
            const finallist = userListWithoutLoginUser.filter(
              (user: any) =>
                !selectedUsers
                  .map((selUsr: any) => selUsr.id)
                  .includes(user.id)
            );
            setUserList(finallist);
          }
        }
        getUsers(AuthenticationService.getUserSuggestions(event.query.toLowerCase()), resHandler);
      }
    }, 250);
  };
  

  const onSelectionHandler = (event: any) => {
    if (event.rowData.is_published) {
      headerTitleChangeHandler(event?.rowData?.name,`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_LIST}`,'share');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_DETAILS}/${event.rowData.id}`
      );
    } else {
      headerTitleChangeHandler('Create Recommendation','','');
      navigate(
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.RECOMMENDATIONS_CREATE_ATTRIBUTE}/${event.rowData.id}`
      );
    }
  };
  const isCellSelectable = (event: any) => {
    const data = event.data;
    return data.field === "actions" ? false : true;
  };
  const statusBodyTemplate = (rowData: any) => {
    let status: string = "Draft";
    if (rowData.is_published) {
      status = "Published";
    }
    return (
      <span className={`customer-badge status-${status.toLowerCase()}`}>
        {status}
      </span>
    );
  };
  const createdByBodyTemplate = ({ creator, ...row }: any) => {
    return renderAvatar(creator, allCreators);
  };
  const nameBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.name.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listtooltip${rowData.id}`} position="bottom">
              {rowData.name}
            </Tooltip>
            <div className={`listtooltip${rowData.id}`}>
              {`${rowData.name.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.name
        )}
      </>
    );
  };
  const descriptionBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.description.length > 20 ? (
          <>
            <Tooltip className="list-tooltip-wrap" target={`.listdesctooltip${rowData.id}`} position="bottom">
              {rowData.description}
            </Tooltip>
            <div className={`listdesctooltip${rowData.id}`}>
              {`${rowData.description.substring(0, 20)}...`}
            </div>
          </>
        ) : (
          rowData.description
        )}
      </>
    );
  };
  const actionBodyTemplate = (rowData: any) => {
    let items = [{}];
    const updateMenuItemsHandler = (recommendation: any) => {
      items.push({
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => { },
      });
      items.push({
        label: "Export",
        icon: "pi pi-plus-circle",
        command: () => { },
      });
      items.push({
        label: "Mail .csv",
        icon: "pi pi-envelope",
        command: () => { },
      });
      if (recommendation.is_private === true) {
        items.push({
          label: "Share With",
          icon: "pi pi-share-alt",
          command: () => {
            setDisplayShareWithModal(true);
          },
        });
      }
      items.push({
        label: "View history",
        icon: "pi pi-history",
        command: () => { },
      });
      items.push({
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          setDisplayModal(true);
        },
      });
      setMenuItems(items);
    };

    const fetchRecommendationDetails = async (recommendation: any) => {
      setFlag(false);
      setRecommendation(recommendation);
      const allSelectedUsers: string[] = [];
      await Promise.allSettled(
        recommendation.can_be_accessed_by.map(
          async (id: any) =>
            await AuthenticationService.fetchUserDetails(id)
              .then((usr: any) => {
                allSelectedUsers.push(...allSelectedUsers, usr.user);
              })
              .catch((err: any) => console.error(err))
        )
      );
      const uniqueSharedUsers = [...Array.from(new Set(allSelectedUsers))];
      setSelectedUsers(uniqueSharedUsers);
    };

    return (
      <>
        <Menu model={menuItems} popup ref={menu} id="popup_menu" />{" "}
        <i
          className="pi pi-ellipsis-v"
          onClick={(event: any) => { menu?.current?.toggle(event); setDeleteRecommendationID(rowData.id); updateMenuItemsHandler(rowData); fetchRecommendationDetails(rowData) }}
        ></i>
      </>
    );
  };

  const sharedWithBodyTemplate = (rowData: any) => {
    let returnElem = <></>;
    let sharedElem = rowData.can_be_accessed_by
      .slice(0, 3)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let sharedrestElem = rowData.can_be_accessed_by
      .slice(3, rowData.can_be_accessed_by.length)
      ?.map((usrId: string) => renderAvatar(usrId, sharedByUsers));

    let allSharedElem = sharedElem.concat(sharedrestElem);

    if (rowData.can_be_accessed_by.length > 3) {
      returnElem = (
        <>
          {/* <CustomTooltip position="bottom" content={allSharedElem} containerClass="tooltipStyleChange">
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </CustomTooltip> */}

          <Tooltip className="list-tooltip-wrap" target={`.sharewithtooltip${rowData.id}`} position="bottom">
            {allSharedElem}
          </Tooltip>
          <div className={`sharewithtooltip${rowData.id}`}>
            {sharedElem} + {rowData.can_be_accessed_by.length - 3}
          </div>
        </>
      );
    } else {
      returnElem = <>{sharedElem}</>;
    }
    return returnElem;
  };
  const lastupdatedBodyTemplate = (rowData: any) => {

    let dateStr = new Date(rowData.modified_on);
    return dateStr.toLocaleDateString()
    // "05/08/2020"
  }
  const columns: Columns[] = [
    { field: "name", header: "Name", body: nameBodyTemplate, sort: true },
    {
      field: "description",
      header: "Description",
      body: descriptionBodyTemplate,
      sort: false,
    },
    { field: "customers", header: "Customers", sort: true },
    {
      field: "creator",
      header: "Created By",
      body: createdByBodyTemplate,
      sort: false,
    },
    {
      field: "can_be_accessed_by",
      header: "Shared With",
      body: sharedWithBodyTemplate,
      sort: false,
    },
    {
      field: "modified_on",
      header: "Last Updated",
      body: lastupdatedBodyTemplate,
      sort: true,
    },
    {
      field: "is_published",
      header: "Status",
      body: statusBodyTemplate,
      sort: true,
    },
    {
      field: "actions",
      header: "Actions",
      body: actionBodyTemplate,
      sort: false,
    },
  ];
  const renderAvatar = (userId: string, userData: { [key: string]: { [key: string]: string } }) => {
    const userDetails: { id: string, name: string, label: string, image: string } = { id: userId, name: '', label: '', image: '' };
    if (userData[userId]) {
      userDetails.label = UtilService.getShortName(String(userData[userId].name));
      userDetails.name = String(userData[userId].name);
    }
    return (
      <Avatar key={userId} className={`user-avatar ${UtilService.classes()[userDetails.name.length % 10]}`} label={userDetails.label} image={userDetails.image} shape="circle" size={"normal"} />
    );
  }
  const debouncedCallback = useDebounce((value: string) => {
    // Make your backend API call here using the value
    getRecommendationList(value, creatorKey.code, selectedStatus.code);
  }, 500); // You can customize the delay time here

  const recommendationTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const patt = /[^A-Za-z 0-9]/g;
    if (!(patt.test(event.currentTarget.value))) {
      setSearchKey(event.currentTarget.value);
      debouncedCallback(event.currentTarget.value);
    }
  };
  const creatorChangeHandler = (event: DropdownChangeParams) => {
    event.preventDefault();
    setCreatorKey(event.value);
    getRecommendationList(searchKey, event.value.code, selectedStatus.code);
  };
  const onStatusChange = (event: DropdownChangeParams) => {
    setSelectedStatus(event.value);
    getRecommendationList(searchKey, creatorKey.code, event.value.code);

  }
  const pageChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    getRecommendationList(searchKey, creatorKey.code, selectedStatus.code, event.page);
  };
  const tableConfig: any = {
    selection: { mode: "single", handler: onSelectionHandler },
    isDataSelectable: isCellSelectable,
  };
//   const deleteRecommendationCallback = () => {
//     const resHandler = (res: any) => {
//       dispatch({
//         type: StoreActions.TOAST_ON,
//         payload: {
//           severity: ToastSeverity.SUCCESS,
//           summary: "Information",
//           detail: res.detail,
//           life: 3000,
//         },
//       });
//       getRecommendationList(searchKey, creatorKey.code, selectedStatus.code, 0);
//     }
//     if (deleteRecommendationID) {
//       deleteRecommendation(ApiService.deleteRecommendation(deleteRecommendationID), resHandler);
//     }
//   }

//   const footerContent = (
//     <div>
//       <Button label="Update" onClick={() => updateSharedUsersDetailsCallback()} autoFocus />
//     </div>
//   );
//   const updateSharedUsersDetailsCallback = () => {
//     const resRecommendationHandler = (res: any) => {
//       setRecommendation(res.recommendation);
//       let audienceData = { ...recommendation };
//       if (flag === true) {
//         if (audienceData.is_private) {
//           if (audienceData.can_be_accessed_by.length) {
//             audienceData = {
//               ...recommendation,
//               can_be_accessed_by: [
//                 ...audienceData.can_be_accessed_by.map((item: any) => item.id),
//               ],
//             };
//           }
//         } else {
//           audienceData = { ...recommendation, can_be_accessed_by: [] };
//         }
//         updateRecommendation(
//           ApiService.updateRecommendation(audienceData, deleteRecommendationID),
//           resHandler
//         );
//       }
//     };
//     getRecommendation(
//       ApiService.getRecommendationGeneraldetail(deleteRecommendationID),
//       resRecommendationHandler
//     );
//     const resHandler = (res: any) => {
//       getRecommendationList(
//         searchKey,
//         creatorKey.code,
//         selectedStatus.code,
//         0
//       );
//     };
//     setDisplayShareWithModal(false);
//   };

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form audiencelist whiteboxlist-height'>
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input 
                                            type="search" 
                                            placeholder="Search" 
                                            aria-describedby="button-addon4" 
                                            className="form-control border-0" 
                                            onChange={recommendationTextSearchHandler}
                                            value={searchKey} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="selectlist-wrap">
                                        <div className='selectlist'>
                                            <label>Created by</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={creatorKey} options={creatorList} onChange={creatorChangeHandler} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                        <div className='selectlist'>
                                            <label>Status</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="welcome__create text-end">
                                <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.RECOMMENDATIONS_CREATE_GENERAL}`} className="welcome__create-anchor"><img src={mai_plus} alt="plus-icon" className="img-fluid welcome__plus-icon" /> Create Recommendation</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="card list-datatable audiencelist-datatable border-0">
                        {getRecommendationsLoader ? (
                            <Loader style={{ minHeight: "445px" }}></Loader>
                        ) : (
                            <>
                            <DataTable data={tableData} columns={columns} config={tableConfig}></DataTable>
                            </>
                        )}
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={tableDataCount}
                            onPageChange={pageChangeHandler}
                            className="justify-content-end listpagination"
                        ></Paginator>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RecommendationListComponent