import { Fragment, useCallback, useEffect, useState } from "react";
import './AudienceDetailInsights.scss';
import { NavLink, useParams } from "react-router-dom";
import {
    mai_arrow_down_blue,
    mai_export_icon,
    mai_jpg_icon,
    mai_pdf_icon,
    mai_pin_icon,
    mai_ppt_icon,
    mai_time_range
} from '../../../../../../assets/images/index';
import KpiCustomersComponent from "./kpi/KpiCustomersComponent";
import KpiRevenueComponent from "./kpi/KpiRevenueComponent";
import KpiPipelineComponent from "./kpi/KpiPipelineComponent";
import IndustryComponent from "./IndustryComponent";
import useHttp from "../../../../../../hooks/use-http";
import TopCustomersComponent from "./TopCustomersComponent";
import ApiService from "../../../../../../services/api.service";
import SegmentsComponent from "./SegmentsComponent";
import ProductsComponent from "./ProductsComponent";
import GeographyComponent from "./GeographyComponent";
import TransactionsComponent from "./TransactionsComponent";
import UtilService from "../../../../../../services/util.service";
import { AudienceDataColumn } from "../../../../../../models/dataTemplate";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
const AudienceDetailInsightsComponent = () => {
    const params = useParams();
    const { sendRequest: getAudienceInsightsProfile, loading: getAudienceInsightsProfileLoader } = useHttp();
    const { sendRequest: getAudienceDates } = useHttp();
    const { sendRequest: getAudienceRegions } = useHttp();
    const { sendRequest: getCohordUniverse } = useHttp();
    const [kpiItems, setKpiItems] = useState<any>({});
    const [customerKpiItems, setCustomerKpiItems] = useState<any>({});
    const [revenueKpiItems, setRevenueKpiItems] = useState<any>({});
    const [start_date, setStartDate] = useState<string>('');
    const [end_date, setEndDate] = useState<string>('');
    const [region, setRegion] = useState<{ name: string, code: string }>({ name: 'All', code: '' });
    const [regionList, setRegionList] = useState<{ name: string, code: string }[]>([{ name: 'All', code: '' }]);
    const [dateRange, SetDateRange] = useState<{ min_date: Date | undefined, max_date: Date | undefined }>({ min_date: undefined, max_date: undefined })
    const [cohortUniverseData, setCohortUniverseData] = useState<any>({});

    const [dates, setDates] = useState<any>(null);

    const getAudienceDateRegionRange = useCallback(() => {
        const resHandler = (res: any) => {
            setStartDate(res.min_date);
            setEndDate(res.max_date);
            SetDateRange({ min_date: new Date(res.min_date), max_date: new Date(res.max_date) })
            setDates([new Date(res.min_date), new Date(res.max_date)]);
            setRegionList([{ name: 'All', code: '' }].concat(res.region?.map((reg: string) => ({
                name: reg,
                code: reg
            }))));
        }
        if (params?.aid) {
            getAudienceDates(ApiService.getAudienceDateRegionRange(params?.aid), resHandler);
        }
    }, [getAudienceDates, params?.aid]);

    const dateChangeHandler = (e: any) => {
        if (e.value[0] && e.value[1]) {
            setStartDate(
                new Date(e.value[0].getTime() - e.value[0].getTimezoneOffset() * 60000)
                    .toISOString()
                    .split("T")[0]
            );
            setEndDate(
                new Date(e.value[1].getTime() - e.value[1].getTimezoneOffset() * 60000)
                    .toISOString()
                    .split("T")[0]
            );
        }
        setDates(e.value)
    }
    const getCohordUniverseData = useCallback(() => {
        const resHandler = (res: any) => {
            const data: any = {
                cohort: {
                    customer: res.data.cohort.customers,
                    revenue: res.data.cohort.raw_total,
                },
                universe: {
                    customer: res.data.universe.customers,
                    revenue: res.data.universe.raw_total
                }
            }
            setCohortUniverseData(data);
        }
        if (params?.aid) {
            getCohordUniverse(ApiService.getAudienceSummaryChart(params?.aid), resHandler);
        }
    }, [getCohordUniverse, params?.aid]);
    const audienceInsightsProfile = useCallback((groupBy: any) => {
        const resHandler = (res: any) => {
            const profile: any = [
                { label: 'Existing', value: 0, code: "0" },
                { label: 'New', value: 0, code: "1" },
                { label: 'Churned', value: 0, code: "2" }
            ]
            const customer: any = {
                title: 'Customers',
                count: cohortUniverseData && cohortUniverseData.cohort.customer,
                perc: cohortUniverseData && UtilService.percentageCalculator(cohortUniverseData.cohort.customer, cohortUniverseData.universe.customer),
                profile: profile.map((item: any) => {
                    const filtered = res.response.profile.find((prof: any) => prof.label === item.code);
                    return {
                        label: item.label,
                        value: filtered !== undefined ? filtered.customer : 0
                    }
                })
            };
            const revenue: any = {
                title: 'Revenue',
                count: cohortUniverseData && cohortUniverseData.cohort.revenue,
                perc: cohortUniverseData && UtilService.percentageCalculator(cohortUniverseData.cohort.revenue, cohortUniverseData.universe.revenue),
                profile: profile.map((item: any) => {
                    const filtered = res.response.profile.find((prof: any) => prof.label === item.code);
                    return {
                        label: item.label,
                        value: filtered !== undefined ? filtered.revenue : 0
                    }
                })
            }
            setCustomerKpiItems(customer);
            setRevenueKpiItems(revenue);
        }
        if (params.aid !== undefined && start_date !== undefined && end_date !== undefined) {
            const filter: any = [];
            const group_by: any = [groupBy];
            if (region.code) {
                filter.push({ field: AudienceDataColumn.REGION, label: "Region", value: { in: [region.code] }, operator: "null" });
            }
            getAudienceInsightsProfile(ApiService.getAudienceInsightsProfile({ aid: params.aid, group_by, start_date, end_date, filter }), resHandler);
        }
    }, [params.aid, start_date, end_date, region.code, cohortUniverseData, getAudienceInsightsProfile]);

    useEffect(() => {
        getAudienceDateRegionRange();
        getCohordUniverseData();
    }, [getAudienceDateRegionRange, getCohordUniverseData]);
    useEffect(() => {
        if (!!start_date && !!end_date) {
            audienceInsightsProfile(AudienceDataColumn.KPI);
        }
    }, [start_date, end_date, region.code, cohortUniverseData, audienceInsightsProfile])


    /*
    const [kpiData, setKpiData] = useState(kpiItems);
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();

    const dragStart = (e:any, position?:number) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };
    
    const dragEnter = (e:any, position?: number) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };
`   `
    const drop = (e:any) => {
        const copyListItems = [...kpiData];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setKpiData(copyListItems);
      };
    */
    return (
        <Fragment>
            <div className="audiencedetails__insight-topheading">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                        <div className="audiencedetails_calendar insights-rangecalendar">
                            <label htmlFor="minmax">Time</label>
                            <div> <Calendar panelClassName="range-calendar-pannel" value={dates} onChange={dateChangeHandler} selectionMode="range" dateFormat="M yy" minDate={dateRange.min_date} maxDate={dateRange.max_date} readOnlyInput /></div>
                            {/* <input type="text" placeholder="Feb 2019 - Jan 2022" data-bs-toggle="modal" data-bs-target="#calendarpopup" readOnly />
                            <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" /> */}
                        </div>
                        <div className="audiencedetails_region">
                            <div className="selectlist-wrap">
                                <div className="selectlist">
                                    <label>Region</label>
                                    <div className="monitoring-dropdownlist">
                                        <Dropdown value={region} onChange={(e) => setRegion(e.value)} options={regionList} optionLabel="name"
                                            placeholder="All" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="heading-button d-flex justify-content-end">
                            {/* <NavLink to="#"><img src={mai_pin_icon} alt="pin" className="img-fluid" /> Pin to dashboard</NavLink> */}
                            <div className="dropdown">
                                <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={mai_export_icon} alt="export" className="img-fluid" /> Export
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><img src={mai_pdf_icon} alt="pdf" className="img-fluid" /> Export as .pdf</a></li>
                                    <li><a className="dropdown-item" href="#"><img src={mai_jpg_icon} alt="jpeg" className="img-fluid" /> Export as .jpeg</a></li>
                                    <li><a className="dropdown-item" href="#"><img src={mai_ppt_icon} alt="ppt" className="img-fluid" /> Export as .pptx</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="insights__datawrapper">
                <div className="row">
                    <KpiCustomersComponent item={customerKpiItems} loading={getAudienceInsightsProfileLoader} />
                    <KpiRevenueComponent item={revenueKpiItems} loading={getAudienceInsightsProfileLoader} />
                    <KpiPipelineComponent start_date={start_date} end_date={end_date} region={region.code} />
                </div>
                {/* <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="insights__innerwrapper">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_email_blue_icon} alt="Email" className="img-fluid" /> <span> Email Engagement</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <ul className="list-inline insights-data">
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>20% <span className="increase_horizontal">+7%</span></h2>
                                                <p>Email Open Rate</p>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>9% <span className="increase_horizontal">+3%</span></h2>
                                                <p>Email CTR </p>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>25% <span className="increase_horizontal">+2%</span></h2>
                                                <p>Conversion Rate</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="insights__innerwrapper">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2 className='inner__heading'><img src={mai_product_engagement_icon} alt="product engagement" className="img-fluid" /> <span> Product Engagement</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <ul className="list-inline insights-data">
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>45 days</h2>
                                                <p>Avg. Order Gap</p>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>$500k <span className="increase_horizontal">+1.5%</span></h2>
                                                <p>Avg. Order Value </p>
                                            </div>
                                        </li>
                                        <li className="list-inline-item">
                                            <div className='innertext__size'>
                                                <h2>2 <span className="decrease_horizontal">-4%</span></h2>
                                                <p>Avg. Order Frequency</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="insights__innerwrapper insights-addkpi">
                            <NavLink to="#" className="addkpi-link" data-bs-toggle="modal" data-bs-target="#addkpi">
                                <img src={mai_plus_gray_circle} alt="plus" className="img-fluid" />
                                <span> Add KPI</span>
                            </NavLink>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="insights__innerwrapper insights-addkpi">
                            <NavLink to="#" className="addkpi-link" data-bs-toggle="modal" data-bs-target="#addkpi">
                                <img src={mai_plus_gray_circle} alt="plus" className="img-fluid" />
                                <span> Add KPI</span>
                            </NavLink>
                        </div>
                    </div>

                </div> */}

                <div className="row mt-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <IndustryComponent start_date={start_date} end_date={end_date} cohortUniverse={cohortUniverseData} region={region.code} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <ProductsComponent start_date={start_date} end_date={end_date} region={region.code} />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <SegmentsComponent start_date={start_date} end_date={end_date} cohortUniverse={cohortUniverseData} region={region.code} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <GeographyComponent start_date={start_date} end_date={end_date} region={region.code} />
                    </div>
                </div>

                <div className="row mt-4">
                    <TransactionsComponent start_date={start_date} end_date={end_date} region={region.code} />
                </div>

                <div className="row mt-4">
                    <TopCustomersComponent start_date={start_date} end_date={end_date} cohortUniverse={cohortUniverseData} region={region.code} />
                </div>
            </div>
            {/* Calendra Popup */}
            <div className="modal fade calendar-popup" id="calendarpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Time</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <label htmlFor="from">From</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="from" placeholder="2020" />
                                        </div>
                                        <div className="col-auto pe-0">
                                            <label htmlFor="to">To</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="to" placeholder="2022" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="selected-period">
                                        <p>Selected period <span>Feb 2020- Jan 2021</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <img src={mai_time_range} alt="time range" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Reset</button>
                            <button type="button" className="btn btn-primary mai-next-button">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add KPI Modal pop up  */}

            {/*<div className="modal fade audiencedetails__addkpimodal" id="addkpi" aria-labelledby="addkpiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Add KPI</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>KPI positioning</h2>
                                </div>
                            </div>
                            <div className="row mb-3">
                                {kpiData.map((item, index:number) => {
                                    return (
                                      <div
                                        className="col-lg-4 col-md-4 col-sm-12 mb-2"
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnter={(e) => dragEnter(e, index)}
                                        onDragEnd={drop}
                                        key={index}
                                        draggable
                                      >
                                        <div className="kpi-positioning">
                                          <img
                                            src={mai_gray_dots}
                                            alt="dots"
                                            className="img-fluid"
                                          />
                                          {item.title}
                                        </div>
                                      </div>
                                    );
                                })}
                                
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Customers
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Revenues
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Pipeline
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Email Engagement
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Product Engagement
                                    </div>
                                </div>
                            *
                                 <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning"></div>
                                </div> 
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>List of KPI </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked1">
                                                Customers
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked2">
                                                Product Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked3" />
                                            <label className="form-check-label" htmlFor="checked3">
                                                Attribution
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked4" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked4">
                                                Revenues
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked5">
                                                Email Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked6" />
                                            <label className="form-check-label" htmlFor="checked6">
                                                CLTV
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked7" />
                                            <label className="form-check-label" htmlFor="checked7">
                                                P.o.P %
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked8" />
                                            <label className="form-check-label" htmlFor="checked8">
                                                Churn Rate
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked9" />
                                            <label className="form-check-label" htmlFor="checked9">
                                                CSAT
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked10" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked10">
                                                Pipeline
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked11" />
                                            <label className="form-check-label" htmlFor="checked11">
                                                MRoI
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            */}
        </Fragment>
    )
}

export default AudienceDetailInsightsComponent
