import React, { useEffect } from "react";
import { Fragment, useCallback, useState } from "react"
import { NavLink, useParams } from "react-router-dom";
import {
  mai_export_icon,
  mai_search_bar,
  mai_save_audience_icon
} from '../../../../../../assets/images/index';
import { Columns, CustomersListResponse } from "../../../../../../models/types";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import Loader from "../../../../../../shared/components/loader/Loader";
import { Paginator } from "primereact/paginator";
import DataTable from "../../../../../../shared/components/datatable/DataTable";
import { Dropdown } from "primereact/dropdown";
import UtilService from '../../../../../../services/util.service';

const AudienceDetailCustomersComponent = () => {
  const params = useParams();
  const [tableData, setTableData] = useState<any>([]);
  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(10);
  const [tableDataCount, setTableDataCount] = useState<any>(0);
  const [searchKey, setSearchKey] = useState<string>("");
  const { sendRequest: getCustomers, loading: getCustomersLoader } = useHttp();

  const getCustomersList = useCallback((
    searchText = "",
    page = 0,
    sortBy = "modified_on",
    sortOrder = -1
  ) => {
    const resHandler = (res: CustomersListResponse) => {
      setTableDataCount(res.total);
      setTableData(res.customers);
    };
    getCustomers(
      ApiService.getCustomersList({
        page: page + 1,
        search: searchText,
        sort_by: sortBy,
        sort_order: sortOrder,
      }, params?.aid || ''),
      resHandler
    );
  }, [getCustomers, params?.aid]);

  useEffect(() => {
    getCustomersList();
  }, [getCustomersList]);
  const pageChangeHandler = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    getCustomersList(searchKey, event.page);
  };

  const annualSalesBodyTemplate = (rowData: any) => {
    return <>${UtilService.numberFormatter(Number(rowData.annual_sales))}</>
  }

  const columns: Columns[] = [
    {
      field: "customers",
      header: "Customers",
      sort: false,
      align: 'left'
    },
    {
      field: "annual_sales",
      header: "Annual Sales",
      sort: false,
      body: annualSalesBodyTemplate,
      align: 'left'
    },
    { field: "industry", header: "Industry", sort: false, align: 'left' },
    { field: "industry_segment", header: "Industry Segment", sort: false, align: 'left' },
  ];

  const customerTextSearchHandler = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setSearchKey(event.currentTarget.value);
    getCustomersList(event.currentTarget.value);
  };

  const [createdBy, setSelectedCreatedBy] = useState<any>(null);
  const onCreatedByChange = (e: { value: any }) => {
    setSelectedCreatedBy(e.value);
  }
  const createdby = [
    { name: 'Lease expiry < 6 months', code: 'Lease expiry < 6 months' },
    { name: 'Lease expiry > 6 months', code: 'Lease expiry > 6 months' },
    { name: 'Lease expiry < 7 months', code: 'Lease expiry < 7 months' },
    { name: 'Lease expiry < 8 months', code: 'Lease expiry < 8 months' }
  ];
  return (
    <Fragment>
      <div className='audiencecreate__form-wrapper p-0'>
        <div className='audiencecreate__form audience-customer-whitebox-height'>
          <div className='row'>
            <div className="card dataTable border-0">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row mb-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="rules__searchbox">
                          <div className="input-group">
                            <div className="input-group-prepend border-0">
                              <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                            </div>
                            <input
                              type="search"
                              placeholder="Search"
                              aria-describedby="button-addon4"
                              className="form-control border-0"
                              onChange={customerTextSearchHandler}
                              value={searchKey}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12">
                        {/* <div className='audiencelist__select'>
                          <label htmlFor="createdby">Show</label>
                          <select className="form-select" id="createdby" defaultValue={'Lease expiry &lt; 6months'}>
                            <option>Lease expiry &lt; 6 months</option>
                            <option value="Lease expiry &lt; 6 months">Lease expiry &gt; 6 months</option>
                            <option value="Lease expiry &lt; 7 months">Lease expiry &lt; 7 months</option>
                            <option value="Lease expiry &lt; 8 months">Lease expiry &lt; 8 months</option>
                          </select>
                        </div> */}
                        {/* <div className="selectlist-wrap">
                          <div className='selectlist'>
                            <label>Show</label>
                            <div className='monitoring-dropdownlist'>
                              <Dropdown value={createdBy} options={createdby} onChange={onCreatedByChange} optionLabel="name" placeholder="Lease expiry < 6months" />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="heading-button customer-button d-flex justify-content-end">
                      <NavLink to="#" data-bs-toggle="modal" data-bs-target="#selectattribute"><img src={mai_export_icon} alt="export" className="img-fluid" /> Export</NavLink>
                      {/* <div className="dropdown">
                                        <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src={mai_export_icon} alt="export" className="img-fluid" /> Export
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#"><img src={mai_pdf_icon} alt="pdf" className="img-fluid" /> Export as .pdf</a></li>
                                            <li><a className="dropdown-item" href="#"><img src={mai_jpg_icon} alt="jpeg" className="img-fluid" /> Export as .csv</a></li>
                                        </ul>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {getCustomersLoader ?
                <Loader style={{minHeight: "407px"}}></Loader>
                : <>
                  <div className="customers-datatable">
                    <DataTable
                      data={tableData}
                      columns={columns}
                    ></DataTable>
                  </div>
                </>
              }
              <Paginator
                first={first}
                rows={rows}
                totalRecords={tableDataCount}
                onPageChange={pageChangeHandler}
                className="justify-content-end listpagination"
              ></Paginator>
            </div>
          </div>
          {/* Select attribute Modal pop up  */}
          <div className="modal fade audiencedetails__addkpimodal saveasaudiencePopup" id="selectattribute" aria-labelledby="addkpiModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Select attributes</h1>
                  <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                          <label className="form-check-label" htmlFor="checked1">
                            Customers
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                          <label className="form-check-label" htmlFor="checked2">
                            Industry  Segment
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked3" />
                          <label className="form-check-label" htmlFor="checked3">
                            Payment Frequency
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked4" />
                          <label className="form-check-label" htmlFor="checked4">
                            Contact ID
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                          <label className="form-check-label" htmlFor="checked5">
                            Product number
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked6" defaultChecked />
                          <label className="form-check-label" htmlFor="checked6">
                            BPN
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked7" />
                          <label className="form-check-label" htmlFor="checked7">
                            PBI Segment
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked8" />
                          <label className="form-check-label" htmlFor="checked8">
                            Contact Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked9" />
                          <label className="form-check-label" htmlFor="checked9">
                            Annual Sales
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked10" />
                          <label className="form-check-label" htmlFor="checked10">
                            Contract No.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked11" />
                          <label className="form-check-label" htmlFor="checked11">
                            No. of Employess
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked12" />
                          <label className="form-check-label" htmlFor="checked12">
                            Rep ID
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked13" defaultChecked />
                          <label className="form-check-label" htmlFor="checked13">
                            Industry
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked14" />
                          <label className="form-check-label" htmlFor="checked14">
                            Duns No.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked15" />
                          <label className="form-check-label" htmlFor="checked15">
                            Avg. email open rate
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12">
                      <div className="listof-kpi">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checked16" />
                          <label className="form-check-label" htmlFor="checked16">
                            Rep Email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="heading-button d-flex justify-content-end segment-button">
                    <NavLink to="#" data-bs-toggle="modal" data-bs-target="#saveasaudience"><img src={mai_save_audience_icon} alt="save audience" className="img-fluid" /> Export as .csv</NavLink>
                    <NavLink to="#" data-bs-toggle="modal" data-bs-target="#customerprofile"><img src={mai_export_icon} alt="export" className="img-fluid" /> Export as .pdf</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AudienceDetailCustomersComponent;