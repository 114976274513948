import { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import IndustryProductComponent from "./IndustryProductComponent";
import useHttp from "../../../../../../hooks/use-http";
import ApiService from "../../../../../../services/api.service";
import UtilService from "../../../../../../services/util.service";
import Loader from "../../../../../../shared/components/loader/Loader";
import TransactionPersonaComponent from "./TransactionPersonaComponent";
import GeographyComponent from "./GeographyComponent";
import TopCountriesComponent from "./TopCountriesComponent";

const SegmentationPersonas = () => {
    let params: any = useParams();
    const [segmentPersonas, setSegmentPersonas] = useState([]);
    const [personaChecks, setPersonaChecks] = useState<any>([]);
    const { sendRequest: getSegmentPersonas, loading: getSegPersonasLoader } = useHttp();
    const { sendRequest: getSegmentationDates, loading: getSegmentationDatesLoader } = useHttp();
    const [dates, setDates] = useState<any>(null);
    const [start_date, setStartDate] = useState<string>('');
    const [end_date, setEndDate] = useState<string>('');
    const [dateRange, SetDateRange] = useState<{ min_date: Date | undefined, max_date: Date | undefined }>({ min_date: undefined, max_date: undefined })



    useEffect(() => {
        getAudienceDateRange();
        getPersonas();
    }, []);


    const getAudienceDateRange = useCallback(() => {
        const resHandler = (res: any) => {
            setStartDate(res.timeframe.min_date);
            setEndDate(res.timeframe.max_date);
            SetDateRange({ min_date: new Date(res.timeframe.min_date), max_date: new Date(res.timeframe.max_date) })
            setDates([new Date(res.timeframe.min_date), new Date(res.timeframe.max_date)]);
        }
        if (params?.sid) {
            getSegmentationDates(ApiService.getSegmentationDates(params?.sid), resHandler);
        }
    }, [getSegmentationDates, params?.sid]);

    const getPersonas = () => {
        const resHandler = (res: any) => {
            const checkboxData: any = [];
            const personaData = res?.response?.insights.map((persona: any, index: number) => {
                checkboxData.push({
                    check: true,
                    id: persona.segment_id,
                    name: persona.segment_name
                });
                return {
                    name: persona.segment_name,
                    id: persona.segment_id,
                    customerValue: persona?.customers_value,
                    revenueValue: persona?.total_yearly_revenue_value,
                    customer: UtilService.numberFormatter(persona?.customers_value),
                    revenue: UtilService.numberFormatter(persona?.total_yearly_revenue_value),
                    color: UtilService.returnColorSchema(true)[index],
                }
            });

            setSegmentPersonas(personaData);
            setPersonaChecks(checkboxData)
        }
        getSegmentPersonas(ApiService.getSegmentationSummary(params?.sid), resHandler)
    }
    const onPersonaCheckHandler = (e: any, index: number) => {
        console.log(e);
        if (!e.target.checked) {
            if (personaChecks.filter((item: any) => item.check === true).length > 1) {
                personaCheck(e, index);
            }
        } else {
            personaCheck(e, index);
        }
    }
    const personaCheck = (e: any, index: any) => {
        let clonePersonaChecks = [...personaChecks];
        clonePersonaChecks[index].check = e.target.checked;
        setPersonaChecks(clonePersonaChecks);
    }
    return (
        <Fragment>
            {/* <div className="audiencedetails__insight-topheading">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="audiencedetails_calendar">
                        <label htmlFor="minmax">Time</label>
                        <input type="text" placeholder="Feb 2019 - Jan 2022" readOnly />
                        <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" />
                    </div>
                    <div className="audiencedetails_region">
                        <div className='audiencelist__select'>
                            <label htmlFor="createdby">Region</label>
                            <select className="form-select" id="createdby" defaultValue={'All'}>
                                <option>All</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                            <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="heading-button d-flex justify-content-end">
                        <NavLink to="#"><img src={mai_pin_icon} alt="pin" className="img-fluid" /> Pin to dashboard</NavLink>
                        <div className="dropdown">
                            <NavLink className="dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={mai_export_icon} alt="export" className="img-fluid" /> Export
                            </NavLink>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="#"><img src={mai_pdf_icon} alt="pdf" className="img-fluid" /> Export as .pdf</NavLink></li>
                                <li><NavLink className="dropdown-item" to="#"><img src={mai_jpg_icon} alt="jpeg" className="img-fluid" /> Export as .jpeg</NavLink></li>
                                <li><NavLink className="dropdown-item" to="#"><img src={mai_ppt_icon} alt="ppt" className="img-fluid" /> Export as .pptx</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

            <div className="persona-comparison box-shadow">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h1 className="heading mb-0">Persona Comparison</h1>
                    </div>
                </div>
                <div className="row">
                    {getSegPersonasLoader
                        ? <Loader style={{minHeight: '88px'}}></Loader>
                        : <>
                            {segmentPersonas.map((persona: any, index: any) => {
                                console.log(persona, "iii");
                                return (
                                    <div className="col-3 mt-3" key={`persona_${index}`}>
                                        <div className="persona-innerwrap">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id={`champions_${index}`} checked={personaChecks[index].check} onChange={(e: any) => onPersonaCheckHandler(e, index)} />
                                                <label className="form-check-label" htmlFor="champions">{persona?.name}</label>
                                                <i className="bi bi-person-fill user" style={{ color: persona.color }}></i>
                                            </div>
                                            <div className="customer-revenue">
                                                <div className="customer-revenue-inner">
                                                    <div className="customer-revenue-inner__inner">
                                                        <p>{persona?.customer}</p>
                                                        <span>Customer</span>
                                                    </div>
                                                </div>
                                                <div className="customer-revenue-inner">
                                                    <div className="customer-revenue-inner__inner">
                                                        <p>${persona?.revenue}</p>
                                                        <span>Revenue</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            </div>
            {personaChecks.filter((item: any) => item.check === true).length >= 1 &&
                <div className="customer-segment-box box-shadow p-0 mt-3">
                    <IndustryProductComponent selectedPersonas={[...personaChecks.filter((item: any) => item.check === true)]} start_date={start_date} end_date={end_date} region={[]} segmentPersonas={segmentPersonas}></IndustryProductComponent>
                </div>
            }
            <div className="geography-countriesbox mt-3">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <GeographyComponent selectedPersonas={[...personaChecks.filter((item: any) => item.check === true)]} start_date={start_date} end_date={end_date} region={[]}></GeographyComponent>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <TopCountriesComponent selectedPersonas={[...personaChecks.filter((item: any) => item.check === true)]} start_date={start_date} end_date={end_date} region={[]}></TopCountriesComponent>
                    </div>
                </div>
            </div>
            <div className="customersegment-transactionbox mt-3">
                <div className="row mt-4">
                    <TransactionPersonaComponent selectedPersonas={[...personaChecks.filter((item: any) => item.check === true)]} start_date={start_date} end_date={end_date} region={[]}></TransactionPersonaComponent>
                </div>
            </div>
        </Fragment>
    )
}

export default SegmentationPersonas