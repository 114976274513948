import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TopCustomersComponent.scss';
import useHttp from '../../../../../hooks/use-http';
import ApiService from '../../../../../services/api.service';
import UtilService from '../../../../../services/util.service';
import Loader from '../../../../../shared/components/loader/Loader';
import DataTable from '../../../../../shared/components/datatable/DataTable';
import { Columns } from '../../../../../models/types';
import { mai_cagrminus, mai_cagrplus } from '../../../../../assets/images';

interface OwnProps {
    readonly selectedMatrix: { segment: string; rank: string, color: number | string };
}

const TopCustomersComponent = (props: OwnProps) => {
    let params = useParams();
    const [tableData, setTableData] = useState<any>([]);
    const [productColorSet, setProductColorSet] = useState<any>([]);
    const { sendRequest: getTopCustomersList, loading: getcustomersLoader } = useHttp();
    const getTopCustomers = useCallback(() => {
        const data = {
            customers: 20,
            products: 3,
            page: 1
        };
        const resHandler = (res: any) => {
            let productSet: any = [];
            res.top_customers.forEach((item: any) => productSet.push(...item.products));
            const newSet: any = new Set(productSet);
            setProductColorSet([...newSet]);
            const dataSet = res.top_customers.map((cust: any) => {
                const change_perc = cust.current_acv > 0 && cust.previous_acv > 0 ? (cust.change / cust.previous_acv) * 100 : 0;
                return {
                    ...cust,
                    change_perc
                }
            });
            setTableData(dataSet);
        }
        let filter;
        if (props.selectedMatrix.segment !== '' && props.selectedMatrix.rank !== '') {
            filter = { segment: props.selectedMatrix.segment, rank: props.selectedMatrix.rank };
        }
        getTopCustomersList(ApiService.clvInsightsTopCustomers(params.clvid!, data, filter), resHandler);
    }, [getTopCustomersList, params.clvid, props.selectedMatrix.rank, props.selectedMatrix.segment]);
    useEffect(() => {
        getTopCustomers();
    }, [getTopCustomers]);
    const customerNameTemplate = (rowData: any) => {
        return <>{UtilService.capitalizeTheFirstLetterOfEachWord(rowData.customer_name)}</>
    }

    const survivalProbabilityBodyTemplate = (rowData: any) => {
        // return <div className={`${rowData.survival_probability > 0 ? 'raise' : 'fall'}`}>{`${rowData.survival_probability > 0 ? '+' : ''}`}{Math.round(rowData.survival_probability.toFixed(2))}%</div>
        return <>{Math.round(rowData.survival_probability.toFixed(2))}%</>
    }

    const forecastRevenueBodyTemplate = (rowData: any) => {
        let classname: string = "text-success";
        let forecast_revenue = String(Math.round(rowData.forecast_revenue));
        if (forecast_revenue.indexOf('-') > -1) {
            classname = "text-danger";
        }
        return (
            <>
                <span>${UtilService.numberFormatter(Number(rowData.forecast_revenue))}</span>
                <img src={classname === "text-danger" ? mai_cagrminus : mai_cagrplus} alt="CAGR" />
            </>
        );
    }

    const avgCLVBodyTemplate = (rowData: any) => {
        return <>${UtilService.numberFormatter(Number(rowData.avg_clv))}</>
    }

    const topproductsBodyTemplate = (rowData: any) => {
        return <>
            {rowData.products.map((product: string, index: number) =>
                <span key={`cust_prod_${index}`} className={`product-badge`} style={{ 'background': `${productColorCode(product)}` }}>
                    {UtilService.capitalizeTheFirstLetterOfEachWord(product)}
                </span>
            )}
        </>
    }
    const productColorCode = (product: any) => {
        return UtilService.returnColorSchema()[productColorSet.indexOf(product)];
    }
    const columns: Columns[] = [
        {
            field: "customer_name",
            header: "Customer Name",
            sort: true,
            body: customerNameTemplate,
        },
        {
            field: "survival_probability",
            header: `Survival Probability (%)`,
            body: survivalProbabilityBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "avg_clv",
            header: "Avg. CLV ($)",
            body: avgCLVBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "forecast_revenue",
            header: `Forcast Revenue ($)`,
            body: forecastRevenueBodyTemplate,
            sort: true,
            align: "center",
        },
        {
            field: "products",
            header: "Top 3 Products",
            body: topproductsBodyTemplate,
            sort: false,
            align: "center"
        },
    ];
    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="insights__innerwrapper mb-0">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <h2 className='inner__heading'>
                                <span> Top 20 customers </span>
                                {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="tabs-wrapper">
                                <div className="card list-datatable audiencelist-datatable audiencedetails-table top20customerwrap border-0">
                                    {getcustomersLoader ? <Loader style={{minHeight: '27.8rem'}} /> :
                                        <DataTable
                                            data={tableData}
                                            columns={columns}
                                        ></DataTable>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TopCustomersComponent);