import { Fragment, useEffect, useRef, useState } from 'react';
import './SegmentationCustomerAttributes.scss';
import {
    mai_light_red_cross,
    mai_gray_dots,
    mai_blue_dots,
    mai_search_bar,
    mai_question_icon
} from '../../../../../../assets/images/index';
import { Url } from '../../../../../../models/constants';
import { useNavigate, useParams } from 'react-router-dom';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { BaseResponse, createSegmentAttributeResponse } from '../../../../../../models/types';
import UtilService from '../../../../../../services/util.service';
import Loader from '../../../../../../shared/components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { StoreState } from '../../../../../../store/store';

const SegmentationCustomerAttributesComponent = () => {
    let navigate = useNavigate();
    let params = useParams();
    const dispatch = useDispatch();
    let segmentId: any = params.sid;
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();
    const [disableCheck, setDisableCheck] = useState(true);
    const [columnDataSet, setColumnDataset] = useState<any>();
    const [allColumns, setAllColumns] = useState<any>();
    const [filteredCcolumnDataSet, setFilteredColumnDataset] = useState<any>();
    const { sendRequest: getAttributesColumns} = useHttp();
    const { sendRequest: getSegmentationDetails} = useHttp();
    const { sendRequest: runSegmentation, loading: runSegmentationLoader } = useHttp();
    const { sendRequest: createSegmentationAttribute, loading: createSegmentationAttributeLoader } = useHttp();
    const [includeRuleSet, setIncludeRuleSet] = useState<string[]>([]);
    const header:any = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create Segmentation")
      headerTitleChangeHandler("Create Segmentation",'','');
  })
    useEffect(() => {
        getColumns();
        if(params.sid) {
            const resHandler = (res: any) => {
                if(res.segmentation.customer_attributes && res.segmentation.customer_attributes.length) {
                    setIncludeRuleSet([...res.segmentation.customer_attributes]);
                    validatePage([...res.segmentation.customer_attributes]);
                }
            }
            getSegmentationDetails(ApiService.getSegmentationGeneraldetail(params?.sid), resHandler);
        }
    },[]);
    const getColumns = () => {
        const resHandler = (res: any) => {
            const dataSet: any = {
                customer_profile: [],
                firmographics: [],
                transactions: []
            };
            dataSet.customer_profile = res.customer_attributes?.filter((item: any) => item.category === 'customer_profile').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            dataSet.firmographics = res.customer_attributes?.filter((item: any) => item.category === 'firmographic').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            dataSet.transactions = res.customer_attributes?.filter((item: any) => item.category === 'transaction').map((item: any) => {
                return { category: item.category, key: item.id, label: UtilService.removeUnderscoreAsSpace(item.alias) };
            });
            setColumnDataset(dataSet);
            setAllColumns([...dataSet.customer_profile, ...dataSet.firmographics, ...dataSet.transactions]);
            setFilteredColumnDataset(dataSet);
        }
        getAttributesColumns(ApiService.getSegmentationAttributesColumnsLlist(params.sid!), resHandler);
    }
    const dragStartMessage = (event: any, key: 'customer_profile' | 'firmographics' | 'transactions', index: any) => {
        const element: any = document.getElementById(event.target.id);
        element.classList.add("on-drag");
        dragItem.current = { ...columnDataSet[key][index] };
    };
    const dragEnterMessage = (e: any, key: 'customer_profile' | 'firmographics' | 'transactions', position: any) => {
        dragOverItem.current = { ...columnDataSet[key][position] };
    };
    const dragEndMessage = (event: any) => {
        const element: any = document.getElementById(event.target.id);
        element.classList.remove("on-drag");
    };
    const onDropCompleted = (event: any) => {
        event.preventDefault();
        const dataSet: string[] = [...includeRuleSet];
        if(!dataSet.includes(dragItem.current.key)){
            dataSet.push(dragItem.current.key);
            updateDataSet(dataSet);
        }
        dragItem.current = null;
        dragOverItem.current = null;
        validatePage(dataSet);
    }
    const removeVariableHandler = (gindex: number) => {
        const dataSet = [...includeRuleSet] ;
        if (dataSet.length !== 0) {
            dataSet.splice(gindex, 1);
        }
        updateDataSet(dataSet);
        validatePage(dataSet);
    }
    const updateDataSet = (data: any) => {
        setIncludeRuleSet(data);
        
    }
    const validatePage = (dataSet1: any) => {
        let state1Check: boolean = true;
        if (dataSet1.length > 1 && dataSet1.length < 7 ) {
            state1Check = false;
        }
        setDisableCheck(state1Check);
    }
      const cancelHandler = () => {
        headerTitleChangeHandler('Segmentation List','','');
        navigate(
          `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_LIST}`
        )
      }
    const onBackHandler = () => {
        navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_DEFINE}/${segmentId}`);
    }
    const onNextHandler = () => {
        // navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_SUMMARY}/${segmentId}`);
        const resHandler = (res: BaseResponse) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}/${Url.CUSTOMER_SEGMENTATION_CREATE}/${Url.CUSTOMER_SEGMENTATION_CREATE_SUMMARY}/${params?.sid}`);
        }
        const runSegmentSummary = (res: any) => {
            if (params?.sid) {
              runSegmentation(ApiService.runSegmentationSummary(params?.sid), resHandler)
            }
        }
        if(params?.sid){
            createSegmentationAttribute(ApiService.patchSegmentation(params?.sid, {customer_attributes: includeRuleSet}), runSegmentSummary);
        }
    }
    const attributeFilter=(e:any)=>{
        const dataSet: any = {
            customer_profile: [],
            firmographics: [],
            transactions: []
        };
        e.target.value=e.target.value.toLowerCase();
        dataSet.customer_profile=columnDataSet.customer_profile.filter((obj:any)=>{const tempStr=obj.label.toLowerCase();return tempStr.includes(e.target.value)});
        dataSet.firmographics=columnDataSet.firmographics.filter((obj:any)=>{const tempStr=obj.label.toLowerCase();return tempStr.includes(e.target.value)});
        dataSet.transactions=columnDataSet.transactions.filter((obj:any)=>{const tempStr=obj.label.toLowerCase();return tempStr.includes(e.target.value)});
        setFilteredColumnDataset(dataSet);
    }
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Define</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Customer attributes</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>
            <Loader isLoading={createSegmentationAttributeLoader || runSegmentationLoader}>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form'>
                    <form>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="rules__wrapper customer-attribute-wrap">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Choose Attributes <img src={mai_question_icon} alt="help" className='img-fluid mx-2' />
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">
                                                            <div id="inc_group" className="rules__includeexclude-body">
                                                                <div className="row drop_area"
                                                                    onDrop={(e) => onDropCompleted(e)}
                                                                    onDragOver={(e) => e.preventDefault()}
                                                                >
                                                                    {(includeRuleSet.length < 1) &&
                                                                        <p className='rules__deagdrops'>Drag and drop attribute here</p>
                                                                    }
                                                                    <div className='rules_group_wrapper col-lg-6'>
                                                                        <div className={`rules__includes-data`}>
                                                                            {includeRuleSet.map((variable: any, vindex: number) => {
                                                                                return (
                                                                                    <div key={`inc_group_${vindex}_sec_value_${vindex}`} id={`inc_group_${vindex}`}>
                                                                                        <div className='rules__inner-body'>
                                                                                            <div className='rules__includes-inner'>
                                                                                                <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                                                <div className='rules__varfield'><p>{UtilService.removeUnderscoreAsSpace(allColumns?.find((col:any) => col.key === variable).label)}</p></div>                                                                                                    
                                                                                            </div>
                                                                                            <div className='rules__crosslight' onClick={() => removeVariableHandler(vindex)}>
                                                                                                <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='rules__rightwrapper'>
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="Search" aria-describedby="button-addon4" className="form-control border-0" onChange={(e)=>attributeFilter(e)}/>
                                        </div>
                                    </div>
                                    <div className='rules__custprofile'>
                                        <div className="accordion" id="mai-accordian-rules">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading1">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                            Customer Profile
                                                        </button>
                                                    </h2>
                                                    <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.customer_profile?.map((profile: any, index: number) => {
                                                                    return (
                                                                        <li key={`${profile.key}_${index}`} id={`${profile.key}_${index}`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'customer_profile', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'customer_profile', index)}
                                                                            onDragOver={(e) => { e.preventDefault() }}
                                                                            draggable={!includeRuleSet.includes(profile.key)}
                                                                            className={includeRuleSet.includes(profile.key) ? 'text-muted' : ''}
                                                                        >
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span>{profile.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading2">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                            Firmographics
                                                        </button>
                                                    </h2>
                                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.firmographics?.map((item: any, index: number) => {
                                                                    return (
                                                                        <li key={`${item.key}_${index}`} id={`${item.key}_${index}`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'firmographics', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'firmographics', index)}
                                                                            onDragOver={(e) => { e.preventDefault() }}
                                                                            draggable={!includeRuleSet.includes(item.key)}
                                                                            className={includeRuleSet.includes(item.key) ? 'text-muted' : ''}
                                                                            >
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span>{item.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading3">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                            Transaction
                                                        </button>
                                                    </h2>
                                                    <div id="collapse3" className="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#mai-accordian-rules">
                                                        <div className="accordion-body custom-Scroll">
                                                            <ul className='rules__rightsidelist'>
                                                                {filteredCcolumnDataSet?.transactions?.map((item: any, index: number) => {
                                                                    return (
                                                                        <li key={`${item.key}_${index}_trans`} id={`${item.key}_${index}_trans`}
                                                                            onDragStart={(e) => dragStartMessage(e, 'transactions', index)}
                                                                            onDragEnd={(e) => dragEndMessage(e)}
                                                                            onDragEnter={(e) => dragEnterMessage(e, 'transactions', index)}
                                                                            onDragOver={(e) => e.preventDefault()}
                                                                            draggable={!includeRuleSet.includes(item.key)}
                                                                            className={includeRuleSet.includes(item.key) ? 'text-muted' : ''}
                                                                            >
                                                                            <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                            <span>{item.label}</span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                            <button type="button" className="btn btn-primary mai-cancel-button" onClick={cancelHandler}>Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="button" className="btn btn-primary mai-cancel-button me-3" onClick={onBackHandler}>Back</button>
                                            <button type="submit" className="btn btn-primary mai-next-button" disabled={disableCheck} onClick={onNextHandler}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            </Loader>
        </Fragment>
    )
}

export default SegmentationCustomerAttributesComponent;