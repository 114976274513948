import React, { useEffect, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import map from 'fusioncharts/fusioncharts.maps';
// import world from 'fusioncharts/maps/fusioncharts.world';
import WC from "fusionmaps/maps/fusioncharts.worldwithcountries";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import TreeMap from 'fusioncharts/fusioncharts.treemap';
// import Widgets from 'fusioncharts/fusioncharts.widgets';
// import Gantt from 'fusioncharts/fusioncharts.gantt';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
// import CarbonTheme from 'fusioncharts/themes/fusioncharts.theme.carbon';
// import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
// import OceanTheme from 'fusioncharts/themes/fusioncharts.theme.ocean';
// import UmberTheme from 'fusioncharts/themes/fusioncharts.theme.umber';
// import ZuneTheme from 'fusioncharts/themes/fusioncharts.theme.zune';
import { ChartConfigType } from '../../../models/types';
import { ChartThemeList } from '../../../models/constants';
// import { Dropdown } from 'primereact/dropdown';

ReactFC.fcRoot(FusionCharts, Charts, map, WC, TreeMap, FusionTheme, PowerCharts);

//@ts-ignore 
FusionCharts.options['license']({
  'key': 'KqA8ydjB3B3E2A3A13A10D8E7F6C3C1H2C1ixrB1F2gcowc1B6A7A-13mB-9A3H2C2B2C1E7E7E1F5A4A1D3C11B2D3D2ippE1C3C11d1C7A3B4utcH3B5D7moB-9D3C4D2H-7geE3A3B2F4H2D3H2C8B1E4B1zllA33A8a1B9d1dB-11G4F4D3jyA7RA1E3wwi1A1A7A4B6B5F5D3A2H3I3A5D5A3C7E-11==',
   'creditLabel': false });
// ReactFC.fcRoot({
//   key: '9fE1jieC7E2B4C1H3E2E1C6C2B5D6D3H-9jI-8G2C9uuyerF2D1iC-21hD1B5D1B2C2A17A16A12A4B4C5D2E1H4B3B2B-31bbA3C4E3rH2C1C5gjC-13D2D5F1H-8H-7lB8A5C7oqsH4G1B8D3B6E2F6D1D3G4F2tC-16D-13B2A3ME2B-7ziA2D4E1xkB4e1B2B11aarA4B2C3A1E1A1B1A6E1G5D1D4E3J2h==',
//   creditLabel: false,
// });

type OwnProps = {
  chartConfigs: ChartConfigType;
}

const ChartComponent = (props: OwnProps) => {
  const [theme, setTheme] = useState<string>(ChartThemeList.FUSION);
  const [chartConfigs, setChartConfigs] = useState<ChartConfigType>(props.chartConfigs);

  useEffect(() => {
    const chartconfigClone = {...props.chartConfigs};
    chartconfigClone.dataSource.chart.theme = theme;
    setChartConfigs(chartconfigClone);
  }, [theme,  props.chartConfigs]);

  // const ThemeOptions: {name: string, value: string}[] = [
  //     { name: ChartThemeList.CANDY, value: ChartThemeList.CANDY },
  //     { name: ChartThemeList.CARBON, value: ChartThemeList.CARBON },
  //     { name: ChartThemeList.FUSION, value: ChartThemeList.FUSION },
  //     { name: ChartThemeList.GAMMEL, value: ChartThemeList.GAMMEL },
  //     { name: ChartThemeList.OCEAN, value: ChartThemeList.OCEAN },
  //     { name: ChartThemeList.UMBER, value: ChartThemeList.UMBER },
  //     { name: ChartThemeList.ZUNE, value: ChartThemeList.ZUNE },
  // ];
  return (
    <div className='chart-container'>
      {/* <div className='theme_selector mb-2 pull-right'>
        <Dropdown optionLabel="name" value={theme} options={ThemeOptions} onChange={(e) => {console.log("changeed:",e.value);setTheme(e.value)}} placeholder="Select Theme"/>
      </div> */}
      <ReactFC {...chartConfigs} />
    </div>
  )
}

export default (ChartComponent);