import { Fragment ,useEffect,useState} from "react";
import './AudienceDetails.scss';
import { useParams } from "react-router-dom";
import AudienceDetailRulesComponent from "./rules/AudienceDetailRulesComponent";
import AudienceDetailCustomersComponent from "./customers/AudienceDetailCustomersComponent";
import AudienceDetailInsightsComponent from "./insights/AudienceDetailInsightsComponent";
import { TabView, TabPanel } from "primereact/tabview";
import useHttp from "../../../../../hooks/use-http";
import { Url } from "../../../../../models/constants";
import ApiService from "../../../../../services/api.service";
import { useDispatch, useSelector } from "react-redux";
import { StoreActions } from "../../../../../store/actions";
import { StoreState } from "../../../../../store/store";
const AudienceDetailsComponent = () => {
  const dispatch = useDispatch();
  const { sendRequest: getAudience } = useHttp();
  const params: any = useParams();
  const userDetails = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const [audienceName, setAudienceName] = useState<string>("");

  const headerTitleChangeHandler = (
    title: string,
    backUrl: string,
    share: string
  ) => {
    const payload = { title, backUrl, share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  };
  useEffect(() => {
    if (params.aid) {
      const resHandler = (res: any) => {
        setAudienceName(res.audience.name);
      };
      getAudience(ApiService.getAudienceGeneraldetail(params.aid), resHandler);
    }
  }, [getAudience, params.aid]);
  useEffect(() => {
    if (audienceName && userDetails.title !== audienceName) {
      headerTitleChangeHandler( 
        audienceName,
        `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`,
        "true"
      );
    }
  });
  return (
    <Fragment>
      <div className="audiencecreate__form-wrapper audiencedetails audiencedetails-wrap px-0 py-0">
        <div className="audiencedetails__tabs customtabs-wrap">
          <TabView>
            <TabPanel header="Insights">
              <AudienceDetailInsightsComponent></AudienceDetailInsightsComponent>
            </TabPanel>
            <TabPanel header="Customers">
              <AudienceDetailCustomersComponent></AudienceDetailCustomersComponent>
            </TabPanel>
            <TabPanel header="Rules">
              <AudienceDetailRulesComponent></AudienceDetailRulesComponent>
            </TabPanel>
          </TabView>

          {/* <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" id="insights-tab" data-bs-toggle="tab" data-bs-target="#insights" href="">Insights</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="customers-tab" data-bs-toggle="tab" data-bs-target="#customers" href="#">Customers</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="rules-tab" data-bs-toggle="tab" data-bs-target="#rules" href="#">Rules</a>
                        </li>
                    </ul> */}
          {/* <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="insights" role="tabpanel" aria-labelledby="insights-tab">
                            <AudienceDetailInsightsComponent></AudienceDetailInsightsComponent>
                        </div>
                        <div className="tab-pane fade" id="customers" role="tabpanel" aria-labelledby="customers-tab">
                            <AudienceDetailCustomersComponent></AudienceDetailCustomersComponent>
                        </div>
                        <div className="tab-pane fade" id="rules" role="tabpanel" aria-labelledby="rules-tab">
                            <AudienceDetailRulesComponent></AudienceDetailRulesComponent>
                        </div>
                    </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default AudienceDetailsComponent;