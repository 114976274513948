import AuthModel from "./../models/authmodel";
import JwtInterceptor from "../interceptors/jwt-interceptor";
import { AudienceGeneralDetail, CLVGeneralDetail, RecommendationGeneralDetail, SegmentationGeneralDetail, SegmentAttributeDetail } from "../models/types";
import { HttpMethods } from "../models/constants";

const apiInstance = AuthModel.apiAuth();
const fileUploadApiInstance = AuthModel.fileUploadApiAuth();

// Interceptor to inspect HTTP calls
JwtInterceptor(apiInstance);
JwtInterceptor(fileUploadApiInstance);

const getIndustries = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/heatmap/industries`,
  };
}
const getHeatMapData = (data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/heatmap/card_details`,
    body: data
  };
}

const getAudienceGeneraldetail = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/fetch/${id}/general_details`,
  };
};

const createAudienceGeneraldetail = (data: AudienceGeneralDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: "/api/customer_lens/v1.0/audience/",
    body: data,
  };
};

const deleteAudience = (audienceId: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.DELETE,
    url: `/api/customer_lens/v1.0/audience/${audienceId}/delete`,
  };
}

const getAudienceList = (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  sort_by?: string;
  sort_order?: number;
  published?: string;
  is_private?: string;
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}&published=${Inputs.published}&is_private=${Inputs.is_private?Inputs.is_private:''}`,
  };
};
const getRulesColumnsLlist = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/data_template/columns`,
  };
}
const getAudienceRules = (aid: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/audience/${aid}/rules`,
  };
}
const getVariablePopupDetails = (data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/data_template/columns/${data.field}`,
  };
}
const createRule = (data: any, aid: string | undefined) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/latest/audience/${aid}/rules`,
    body: {...data}
  };
}
const getAudienceCreatorList = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/creators/list`,
  };
}

const getAudienceSummaryChart = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${id}/summary`,
  };
};

const getAudienceSummaryWaterfall = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${id}/insights/metrics/by_rule_group`,
  };
};
const updateAudience = (data: any, aid: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/audience/${aid}`,
    body: data
  };
}
const getAudienceInsightsProfile = (data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/audience/${data.aid}/insights/profile`,
    body: {
      group_by: data?.group_by,
      start_date: data?.start_date,
      end_date: data?.end_date,
      date_mode: data?.date_mode && data?.date_mode,
      filters: data?.filter && data?.filter
    }
  };
}
const getAudienceInsighthsTransactions = (aid: any, data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/audience/${aid}/insights/transactions?start_date=${data.start_date}&end_date=${data.end_date}&region=${data.region}`,
  };
}
const audienceInsightsTopCustomers = (aid: any, data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/audience/${aid}/insights/top_n_customer/${data.customers}/${data.products}`,
  };
}
const getAudienceInsightsPipeline = (id: any, year: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${id}/insights/${year}/opportunity`,
  };
};
const getSegmentationGeneraldetail = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/fetch/${id}/general_details`,
  };
};

const createSegmentationGeneraldetail = (data: SegmentationGeneralDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/segmentation/`,
    body: data
  };
};

const getSegmentationCreatorList = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url:`/api/customer_lens/latest/segmentation/creators/list`,
  };
}
const patchSegmentation = (id: string, data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/segmentation/${id}`,
    body: data
  };
};

const getSegmentationDefine = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/customer_segmentation/fetch/${id}/define/`,
  };
};
const getSegmentationDefineTimeFrame = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/${id}/timeframe/bounds`,
  };
};
const runSegmentationInvokeRollup = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PUT,
    url: `/api/customer_lens/v1.0/segmentation/${id}/invoke_rollup`,
  };
};

const getSegmentationList = (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  sort_by?: string;
  sort_order?: number;
  published?: string;
  is_private?: string;
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/segmentation/segment/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}&published=${Inputs.published}&is_private=${Inputs.is_private?Inputs.is_private:''}`,
  };
};

const getSegmentationAttributesColumnsLlist = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/attributes?id=${id}`,
  };
}


const createSegmentationAttributes = (id: string, data: SegmentAttributeDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/select_attributes/?id=${id}`,
    body: data
  };
}

const runSegmentationSummary = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PUT,
    url: `/api/customer_lens/v1.0/segmentation/${id}/run`,
  };
}
const getSegmentationSummary = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/${id}/summary`,
  };
}

const renameSegmentation = (id: string, data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/segmentation/${id}/rename_segment`,
    body: data
  };
}
const mergeSegmentation = (id: string, segments: string[]) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/segmentation/${id}/merge_segment`,
    body: {segment_ids : segments}
  };
}
const deleteSegments = (id: string, segments: string[]) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/segmentation/${id}/delete_segment`,
    body: {segment_ids : segments}
  };
}
const deleteSegmentation = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.DELETE,
    url: `/api/customer_lens/v1.0/segmentation/${id}/delete`
  };
}
const getSegmentationPersonaDetails = (id: string, groupBy: string, segments: string[], start_date: string, end_date: string, filters: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/segmentation/${id}/segment_distibution`,
    body: {
      group_by: [groupBy],
      segments,
      start_date: start_date && start_date,
      end_date: end_date && end_date,
      filters: filters && filters 
    },
  };
}
const getSegmentationPersonaTransactionDetails = (id: string, groupBy: string, segments: string[], start_date: string, end_date: string, filters: any,dateMode:string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/segmentation/${id}/transaction `,
    body: {
      group_by: [groupBy],
      segments,
      start_date: start_date && start_date,
      end_date: end_date && end_date,
      filters: filters && filters ,
      date_mode: dateMode,
      amount_agg_func: "sum"
    },
  };
}
const getSegmentationPersonaGeographyDetails = (id: string, tab: any, segments: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/${id}/persona/segment/${tab}?segments=${segments}`,
  };
}
const getSegmentationPersonaTopCountries = (id: string, segments: string[], start_date: string, end_date: string, region: string[]) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/segmentation/${id}/persona/top_countries`,
    body: {segments: segments, start_date, end_date, region}
  };
}
const runSegmentationMigration = (
  id: string,
  data: { timeframe_1: string; timeframe_2: string; timeframe_3?: string }
) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PUT,
    url: `/api/customer_lens/v1.0/segmentation/${id}/migration`,
    body: data,
  };
};

const getSegmentationPersonaDistributionDetails = (id: string, groupBy: string, segments: string[], start_date: string, end_date: string, filters: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/segmentation/${id}/persona`,
    body: {
      group_by: [groupBy],
      segments,
      start_date: start_date && start_date,
      end_date: end_date && end_date,
      filters: filters && filters 
    },
  };
}

const getSegmentationDates = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/segmentation/${id}/persona/date_range`,
  };
};

const getCustomersList = (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  sort_by?: string;
  sort_order?: number;
}, AudienceId: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${AudienceId}/customer_list?page=${Inputs.page}&search=${Inputs.search}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}`,
  };
};

const getAudienceDateRegionRange = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${id}/insights/date_region_range`,
  };
};
const getAudienceRegions = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/audience/${id}/insights/regions`,
  };
};

const createCampaignGeneraldetail = (data: AudienceGeneralDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: "/api/campaign_lens/v1.0/campaign",
    body: data,
  };
}

const getCampaignList =  (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  status?: string;
  sort_by?: string;
  sort_order?: number;
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/campaign_lens/v1.0/campaign/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&status=${Inputs.status}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}`,
  };
};

const getCampaignGeneraldetail = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/campaign_lens/v1.0/campaign/fetch/${id}/general_details`,
  };
};

const patchCampaign = (id: string, data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/campaign_lens/v1.0/campaign/${id}`,
    body: data
  };
};

const getCLVList = (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  sort_by?: string;
  sort_order?: number;
  published?: string;
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/clv/clv/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}&published=${Inputs.published}`,
  };
};

const getClvCreatorList = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/clv/creators/list`,
  };
}

const createCLVGeneraldetail = (data: CLVGeneralDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/clv/`,
    body: data
  };
};

const getClvTimeFrame = (Inputs: {
  source: 'audience' | 'segmentation', ids_list: string
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/clv/timeframe/bounds?source=${Inputs.source}${Inputs.ids_list? `&ids_list=${Inputs.ids_list}` : ""}`,
  };
};

const getClvGeneralDetails = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/clv/fetch/${id}/general_details`,
  };
};

const updateClvDetails = (data: any, clvid: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/clv/${clvid}`,
    body: data
  };
}

const runClvModel = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PUT,
    url: `/api/customer_lens/v1.0/clv/${id}/run`
  };
};
const clvSummary = (id: string|undefined) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/clv/${id}/summary`,
  };
}


const getSubSegmentsList = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/latest/segmentation/${id}/summary`,
  };
}
const clvInsightsTopCustomers = (cid: string, data: any, filter?: { segment: string, rank: string }) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/clv/top_n_customer/${data.customers}/${
      data.products
    }?id=${cid}&page=${data.page}&search=${data.searchText ?? ""}`,
    body: { matrix_filter: filter && filter },
  };
}

const getCLVDistributionProfile = (id: string, groupBy: string, filter?: { segment: string, rank: string }) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/clv/${id}/insights/profile`,
    body: {
      group_by: [groupBy],
      matrix_filter: filter && filter
    },
  };
}
const getClvInsightMatrix = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `api/customer_lens/v1.0/clv/${id}/insights/clv_metrix`
  };
}

const getClvInsightPredict = (id: string, data: {segment: string, rank: string}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `api/customer_lens/v1.0/clv/${id}/insights/predict?${data.segment && data.rank ? `segment=${data.segment}&rank=${data.rank}` : ''}`
  }
}

const deleteCLV = (clvid: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.DELETE,
    url: `/api/customer_lens/v1.0/clv/${clvid}/delete`,
  };
}

const getCampaignCreatorList = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/campaign/creators/list`,
  };
}
const getRecommendationGeneralDetails = (id: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/recommendation/fetch/${id}/general_details`,
  };
};
const createRecommendationGeneraldetail = (data:RecommendationGeneralDetail) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/customer_lens/v1.0/recommendation/`,
    body: data,
  };
};
const updateRecommendationGeneraldetail = (data:RecommendationGeneralDetail,id:string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.PATCH,
    url: `/api/customer_lens/v1.0/recommendation/${id}`,
    body: data,
  };
};

const getRecommendationList = (Inputs: {
  page?: number;
  search?: string;
  created_by?: string;
  sort_by?: string;
  sort_order?: number;
  published?: string;
  is_private?: string;
}) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/recommendation/list?page=${Inputs.page}&search=${Inputs.search}&created_by=${Inputs.created_by}&sort_by=${Inputs.sort_by}&sort_order=${Inputs.sort_order}&published=${Inputs.published}&is_private=${Inputs.is_private?Inputs.is_private:''}`,
  };
};

const getRecommendationCreatorList = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/recommendation/creators/list`,
  };
}

const ApiService = {
  getIndustries,
  getHeatMapData,
  getAudienceGeneraldetail,
  createAudienceGeneraldetail,
  deleteAudience,
  getAudienceList,
  getRulesColumnsLlist,
  getAudienceRules,
  getVariablePopupDetails,
  createRule,
  getAudienceCreatorList,
  updateAudience,
  getAudienceSummaryChart,
  getAudienceSummaryWaterfall,
  getAudienceInsightsProfile,
  getAudienceInsightsPipeline,
  getAudienceInsighthsTransactions,
  audienceInsightsTopCustomers,
  getSegmentationGeneraldetail,
  createSegmentationGeneraldetail,
  patchSegmentation,
  getSegmentationDefine,
  getSegmentationList,
  getSegmentationCreatorList,
  getSegmentationAttributesColumnsLlist,
  createSegmentationAttributes,
  getSegmentationSummary,
  runSegmentationSummary,
  renameSegmentation,
  mergeSegmentation,
  deleteSegments,
  deleteSegmentation,
  getSegmentationDefineTimeFrame,
  getSegmentationPersonaDetails,
  getSegmentationPersonaTransactionDetails,
  getSegmentationPersonaGeographyDetails,
  getSegmentationPersonaDistributionDetails,
  getSegmentationPersonaTopCountries,
  runSegmentationMigration,
  runSegmentationInvokeRollup,
  getCustomersList,
  getAudienceDateRegionRange,
  getAudienceRegions,
  getSegmentationDates,
  createCampaignGeneraldetail,
  getCampaignList,
  getCampaignGeneraldetail,
  patchCampaign,
  getCLVList,
  getClvCreatorList,
  createCLVGeneraldetail,
  getClvTimeFrame,
  getClvGeneralDetails,
  updateClvDetails,
  runClvModel,
  getSubSegmentsList,
  clvInsightsTopCustomers,
  getCLVDistributionProfile,
  clvSummary,
  getClvInsightMatrix,
  getClvInsightPredict,
  deleteCLV,
  getCampaignCreatorList,
  getRecommendationGeneralDetails,
  createRecommendationGeneraldetail,
  updateRecommendationGeneraldetail,
  getRecommendationList,
  getRecommendationCreatorList
};

export default ApiService;