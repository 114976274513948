import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
    mai_search_bar,
    mai_question_icon,
    mai_map_highlighter,
    mai_reset,
    mai_zoomin,
    mai_zoomout
} from "../../../../../assets/images/index";
import './AudienceWorldMap.scss';
import { CustomTab } from "../../../../../shared/components/customtab/CustomTab";
import { MultiSelect } from 'primereact/multiselect';
import { StoreActions } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import MapChartComponent from "./MapChartComponent";
import useHttp from "../../../../../hooks/use-http";
import ApiService from "../../../../../services/api.service";
import UtilService from "../../../../../services/util.service";
import Loader from "../../../../../shared/components/loader/Loader";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "primereact/tooltip";
const AudienceWorldMapComponent = (props: any) => {
    let searchRef = useRef<HTMLInputElement>(null!);
    const dispatch = useDispatch();
    const mapchartRef = useRef<any>();
    const listRef = useRef<HTMLDivElement>(null);
    const [countryData, setCountryData] = useState<any>(null);
    const [regionData, setRegionData] = useState<any>(null);
    const [mapData, setMapData] = useState<any>(null);
    const [filteredMapData, setFilteredMapData] = useState<any>(null);
    const [mappingTabIndex, setMappingTabIndex] = useState(0);
    const [valueTabIndex, setValueTabIndex] = useState(0);
    const [industriesList, setIndustriesList] = useState<any>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<any>(null);
    const { sendRequest: getIndustries, loading: getIndustriesLoader } = useHttp();
    const { sendRequest: getHeatmapData, loading: getHeatmapDataLoader } = useHttp();
    const [zoomPosition, setZoomPosition] = useState<any>({ coordinates: [0, 0], zoom: 1 });
    const [year, setYear] = useState<string>('');

    const generateRegionData = useCallback((data: any, industryFilter: any) => {
        const _uniqueRegions: any = new Set(data.map((item: any) => item.gmov_region));
        const regionData: any = [..._uniqueRegions].map((region: string) => {
            const countries: string[] = [];
            // const industries: String[] = [];
            let tamTotal: number = 0;
            let sowTotal: number = 0;
            data.filter((res: any) => res.gmov_region === region).forEach((item: any) => {
                countries.push(item.gmov_country);
                // industries.push(item.INDUSTRY);
                tamTotal += item.tam;
                sowTotal += item.sow;
            });
            return {
                gmov_country: countries,
                gmov_region: region,
                // industry: industries,
                tam: tamTotal,
                sow: sowTotal
            }
        });
        return regionData;
    }, [])
    const formattingMapData = useCallback((data: any, valueIndex: number = 0) => {
        const formattedData = data.map((item: any) => (
            {
                ...item,
                opportunity: item.tam - item.sow,
                opportunity_perc: ((item.tam - item.sow) / item.tam) * 100,
                sow_perc: (item.sow / item.tam) * 100,
                value: valueIndex ? item.sow : item.tam,
                year: item.year
            }
        ));
        console.log(valueIndex, formattedData);
        // if (selectedIndustry.code === 'All') {
        return formattedData;
        // } else {
        //     if (mappingTabIndex === 1) {    // country
        //         return formattedData.filter((item: any) => item.industry === selectedIndustry.code);
        //     } else {
        //         return formattedData.filter((item: any) => item.industry.includes(selectedIndustry.code));
        //     }
        // }

    }, []);
    const getHeatmapDetails = useCallback((selectedIndustries: any) => {
        const resHandler = (res: any) => {
            console.log(res);
            const response: any = res.card_details.map((item: any) => ({
                ...item,
                gmov_country: item.country,
                gmov_region: item.region,
                tam: item.tam,
                sow: item.sow,
                year: item.year
                // industry: item.INDUSTRY
            }));
            setYear(res?.card_details[0]?.year ?? '');
            const _regionData = generateRegionData(response, selectedIndustries);
            setCountryData(response); //Raw response storing in country data
            setRegionData(_regionData);
            let _mapData = [];
            if (mappingTabIndex === 1) {
                _mapData = formattingMapData(response, valueTabIndex);
            } else {
                _mapData = formattingMapData(_regionData, valueTabIndex);
            }
            setMapData(_mapData);
            setFilteredMapData(_mapData);
        }
        if (selectedIndustries && selectedIndustries.length) {
            const data = [...selectedIndustries.map((item: any) => item.code)];
            getHeatmapData(ApiService.getHeatMapData(data), resHandler);
        }
    }, [formattingMapData, generateRegionData, getHeatmapData, mappingTabIndex, valueTabIndex]);
    const getIndustriesList = useCallback(() => {
        const resHandler = (res: any) => {
            console.log(res);
            setIndustriesList(res.industries.map((item: string) => ({ name: item, code: item })));
            setSelectedIndustry(res.industries.map((item: string) => ({ name: item, code: item })));
            getHeatmapDetails(res.industries.map((item: string) => ({ name: item, code: item })));
        }
        getIndustries(ApiService.getIndustries(), resHandler);
    }, [getHeatmapDetails, getIndustries]);

    useEffect(() => {
        getIndustriesList();
    }, [getIndustriesList])

    const headerTitleChangeHandler = (content: any) => {
        const payload = { title: content };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    }
    const mappingTabChangeHandler = (event: any, valueIndex: any) => {
        resetTileHighlights();
        setSelectedIndustry([...industriesList]);
        mapchartRef.current.resetMap();
        let _mapData = [];
        setMappingTabIndex(event.index)
        setValueTabIndex(valueIndex);
        if (event.index === 1) { // Country
            _mapData = formattingMapData(countryData, valueIndex);
        } else { // Region
            _mapData = formattingMapData(regionData, valueIndex);
        }
        // Based on the selection of valuetabindex data changes.
        // let newMapData = [];
        // if (valueIndex === 1) { // SoW
        //     newMapData = _mapData.map((item: any) => ({ ...item, value: item.sow }))
        // } else { // Tam
        //     newMapData = _mapData.map((item: any) => ({ ...item, value: item.tam }))
        // }
        setMapData(_mapData);
        setFilteredMapData(_mapData);
    }
    const valueTabChangeHandler = (event: any, mappingIndex: any) => {
        setValueTabIndex(event.index);
        let _mapData = [];
        if (event.index === 1) { // SoW
            _mapData = mapData.map((item: any) => ({ ...item, value: item.sow }))
        } else { // Tam
            _mapData = mapData.map((item: any) => ({ ...item, value: item.tam }))
        }
        setMapData(_mapData);
        setFilteredMapData(_mapData);
    }

    const onIndustryChange = (e: { value: any }) => {
        if (e.value.length) {
            setSelectedIndustry(e.value);
            getHeatmapDetails(e.value)
            // const _regionData = generateRegionData(countryData, e.value);
            // let _mapData = [];
            // if (mappingTabIndex === 1) {
            //     const selectedIndustries = e.value.map((item: any) => item.code);
            //     _mapData = formattingMapData(countryData.filter((item: any) => selectedIndustries.includes(item.industry)));
            // } else {
            //     _mapData = formattingMapData(_regionData);
            // }
            // setMapData(_mapData);
            // setFilteredMapData(_mapData);
        }
    }
    const resetTileHighlights = () => {
        mapData.forEach((item: any, index: number) => {
            const idKey = mappingTabIndex ? item?.gmov_country : item?.gmov_region;
            const elementId: any = document.getElementById(`mapdata_${idKey}`);
            elementId.className = 'tiles-box';
        });
    }
    const selectTileHighlights = (id: string | Array<string>, maptolist: boolean = false) => {
        const elementId: any = document.getElementById(`mapdata_${id}`);
        elementId.className = 'tiles-box tiles-shadow';
        let cloneData = mapData;
        if (mappingTabIndex === 0) {
            cloneData = UtilService.sortArrayBasedOnValues(mapData, Array.isArray(id) ? id : [id], 'gmov_region');
        } else {
            cloneData = UtilService.sortArrayBasedOnValues(mapData, Array.isArray(id) ? id : [id], 'gmov_country');
        }
        setMapData(cloneData);
        if (listRef.current && maptolist) {
            listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
        // window.scrollTo(0, listRef.current.offsetTop);
    }
    const geoTileHighlighter = (selectedData: any, geoId: any) => {
        resetTileHighlights();
        selectTileHighlights(mappingTabIndex ? selectedData?.gmov_country : selectedData?.gmov_region, true);
    }
    const onClickTileHandler = (country: string | Array<string>, region: string) => {
        resetTileHighlights();
        if (mappingTabIndex) {
            selectTileHighlights(country);
        } else {
            selectTileHighlights(region);
        }
        mapchartRef.current.handleCountryHighlight(country);
    }
    const searchTileHandler = (event: any) => {
        const searchKey = searchRef.current.value.toLowerCase();
        if (searchKey.length === 0) {
            setMapData(filteredMapData);
        } else {
            let _filteredMapData = [];
            if (mappingTabIndex === 0) {
                _filteredMapData = filteredMapData.filter((item: any) => (item.gmov_region.toLowerCase().match(`${searchKey}`) !== null && item.gmov_region.toLowerCase().match(`${searchKey}`).length > 0));
            } else {
                _filteredMapData = filteredMapData.filter((item: any) => (item.gmov_country.toLowerCase().match(`${searchKey}`) !== null && item.gmov_country.toLowerCase().match(`${searchKey}`).length > 0));
            }
            setMapData(_filteredMapData);


            // const $box: any = document.getElementById('heatmap-tilesbox');
            // const regex = new RegExp(searchKey, 'gi');
            // let text = $box.innerHTML;
            // text = text.replace(/(<mark class="heighlited-color">|<\/mark>)/gim, '');

            // const newText = text.replace(regex, '<mark class="heighlited-color">$&</mark>');
            // $box.innerHTML = newText;
        }
    }
    const tileClickActionHandler = (data: any, tabIndex: number) => {
        props.tabChangeDetailsHandler(data, tabIndex);
    }
    return (
        <Fragment>
            <div className="audiencecreate__form-wrapper pb-0">
                <div className="audiencecreate__form whitebox-heatmap-height">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h2 className='inner__heading px-1'>
                                <span> HeatMap</span>
                                <img src={mai_question_icon} alt="quest" className='img-fluid heatmap-tooltip' />
                                {/* <span>{year ? `Year-${year}` : ''}</span> */}
                            </h2>
                            {/* <ReactTooltip className="custom-tooltip" id="ansysbusinesshighlights" place="right" effect="solid">
                            HeatMap
                        </ReactTooltip> */}
                            <Tooltip target=".heatmap-tooltip" className="list-tooltip-wrap">
                                HeatMap
                            </Tooltip>
                            <div className="rules__searchbox mt-4 px-1">
                                <div className="input-group">
                                    <div className="input-group-prepend border-0">
                                        <button
                                            id="button-addon4"
                                            type="button"
                                            className="btn btn-link text-info"
                                        >
                                            <img
                                                src={mai_search_bar}
                                                alt="search"
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                    <input
                                        type="search"
                                        placeholder={`Search out of ${mapData?.length} ${mappingTabIndex === 0 ? 'Regions' : 'Countries'}`}
                                        pattern="[A-Za-z\s\^]+"
                                        aria-describedby="button-addon4"
                                        className="form-control border-0"
                                        ref={searchRef}
                                        onChange={searchTileHandler}
                                    // value={searchKey}
                                    />
                                </div>
                            </div>

                            <div className="heatmap-tilesbox-wrapper custom-Scroll mt-2 px-1" id="heatmap-tilesbox" ref={listRef}>
                                {mapData ? <>
                                    {mapData.map((item: any, index: number) => {
                                        return (
                                            <div role="button" className="tiles-box"
                                                id={`mapdata_${mappingTabIndex === 0 ? item?.gmov_region : item?.gmov_country}`}
                                                key={`mapdata_${mappingTabIndex === 0 ? item?.gmov_region : item?.gmov_country}`}
                                                onClick={() => onClickTileHandler(item?.gmov_country, item?.gmov_region)}> {/* tiles-shadow */}
                                                <h3>{mappingTabIndex === 0 ? item?.gmov_region : item?.gmov_country}</h3>
                                                {mappingTabIndex === 1 &&
                                                    <div className="tilesbox-titels">
                                                        <div><p>Region</p></div>
                                                        <div><strong>{item?.gmov_region}</strong></div>
                                                    </div>
                                                }
                                                <div className="tilesbox-titels">
                                                    <div><p>Total Addressable Market</p></div>
                                                    <div><strong className={`${valueTabIndex ? '' : 'heighlited-color'}`}>{`$${UtilService.numberFormatter(Math.round(item?.tam), 'M')}`}</strong></div>
                                                </div>
                                                <div className="tilesbox-titels">
                                                    <div><p>Share of Wallet</p></div>
                                                    <div><strong className={`${valueTabIndex ? 'heighlited-color' : ''}`}>{`$${UtilService.numberFormatter(Math.round(item?.sow), 'M')}`} <span>{`${item?.sow_perc.toFixed(2)}%`}</span></strong></div>
                                                </div>
                                                <div className="tilesbox-titels">
                                                    <div><p>Opportunity</p></div>
                                                    <div><strong>{`$${UtilService.numberFormatter(Math.round(item?.opportunity), 'M')}`} <span>{`${item?.opportunity_perc.toFixed(2)}%`}</span></strong></div>
                                                </div>

                                            </div>
                                        );
                                    })}
                                </> : <Loader style={{ minHeight: '398px' }} />}
                            </div>

                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="heatmap-tab-wrap customtabs-wrap">
                                <div className="row justify-content-end">
                                    <div className="col-auto w-auto col-sm-12 mt-0">
                                        <CustomTab mappingTabChangeEmitter={mappingTabChangeHandler} valueTabChangeEmitter={valueTabChangeHandler} disabled={getHeatmapDataLoader || getIndustriesLoader}></CustomTab>
                                    </div>

                                    <div className="col-auto w-auto col-sm-12">
                                        <div className="heatmap-column-top industry-column">
                                            <div className="selectlist-wrap p-0">
                                                <div className='selectlist'>
                                                    <label className="me-3">Industry</label>
                                                    <div className='user-dropdown-list'>
                                                        <MultiSelect className="multiselect-wrap" panelClassName="multiselect-Pannel" value={selectedIndustry} onChange={onIndustryChange} options={industriesList} optionLabel="name"
                                                            filter placeholder="Select Industry" maxSelectedLabels={1} selectedItemsLabel={`${selectedIndustry?.length === industriesList?.length ? 'All' : `${selectedIndustry?.length} items selected`}`}
                                                            disabled={getHeatmapDataLoader || getIndustriesLoader}
                                                        >
                                                        </MultiSelect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mapwrap">
                                            {mapData ?
                                                <MapChartComponent ref={mapchartRef} data={mapData} mappingTabIndex={mappingTabIndex} tileSelectionHandler={geoTileHighlighter} onPositionChange={(pos: any) => setZoomPosition(pos)}></MapChartComponent>
                                                : <Loader style={{ minHeight: '400px' }} />}

                                            <div className="zoomin-listicon">
                                                <button className="me-2" onClick={() => { resetTileHighlights(); mapchartRef.current.resetMap() }}>
                                                    <img src={mai_reset} alt="reset" className="img-fluid" />
                                                </button>

                                                <div className="zoomin-out">
                                                    <button className="zoomin" onClick={() => mapchartRef.current.handleZoomIn()} disabled={zoomPosition.zoom >= 8}>
                                                        {/* <span className="icon-Zoomin"></span> */}
                                                        <img src={mai_zoomin} alt="zoomin" className="img-fluid" />
                                                    </button>
                                                    <button className="zoomout" onClick={() => mapchartRef.current.handleZoomOut()} disabled={zoomPosition.zoom <= 1}>
                                                        {/* <span className="icon-Zoomout"></span> */}
                                                        <img src={mai_zoomout} alt="zoomin" className="img-fluid" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="maphighlighter-wrap">
                                            <div className="maphighlighter">
                                                <img src={mai_map_highlighter} alt="" className="img-fluid" />
                                                <div className="highlow-title">
                                                    <span>High</span>
                                                    <span>Low</span>
                                                </div>
                                            </div>
                                            <div className="year22"><p>* Above information is for the <span>{year ? `Year-${year}` : ''}</span></p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AudienceWorldMapComponent;