import React, { useCallback, useEffect, useState } from 'react'
import { ProgressBar } from 'primereact/progressbar'
import { useParams } from 'react-router-dom';
import useHttp from '../../../../../hooks/use-http';
import UtilService from '../../../../../services/util.service';
import { AudienceDataColumn } from '../../../../../models/dataTemplate';
import ApiService from '../../../../../services/api.service';
import { Columns } from '../../../../../models/types';
import Loader from '../../../../../shared/components/loader/Loader';
import DataTable from '../../../../../shared/components/datatable/DataTable';

interface OwnProps {
    readonly selectedMatrix: { segment: string; rank: string, color: number | string };
}
const IndustryComponent = (props: OwnProps) => {
    const [tableData, setTableData] = useState<any>([]);
    let params = useParams();
    const { sendRequest: getCLVIndustry, loading: getCLVIndustryLoader } = useHttp();
    const audienceInsightsProfile = useCallback((groupBy: any) => {
        const resHandler = (res: any) => {
            const totalCustomers = res.response.profile.reduce((acc: number, profile: any) => acc + profile.customer, 0);
            const totalRevenue = res.response.profile.reduce((acc: number, profile: any) => acc + profile.avg_clv, 0);
            const updatedIndustrySet: any = res.response.profile.map((profile: any) => {
                return {
                    name: profile.label,
                    customers: {
                        value: profile.customer,
                        percent: UtilService.percentageCalculator(profile.customer, totalCustomers)
                    },
                    avg_clv: {
                        value: profile.avg_clv,
                        percent: UtilService.percentageCalculator(profile.avg_clv, totalRevenue)
                    }
                }
            });
            setTableData(updatedIndustrySet);
        }
        let filter;
        if (props.selectedMatrix.segment !== '' && props.selectedMatrix.rank !== '') {
            filter = { segment: props.selectedMatrix.segment, rank: props.selectedMatrix.rank };
        }
        getCLVIndustry(ApiService.getCLVDistributionProfile(params.clvid!, groupBy, filter), resHandler);
    }, [getCLVIndustry, params.clvid, props.selectedMatrix.rank, props.selectedMatrix.segment]);
    useEffect(() => {
        audienceInsightsProfile(AudienceDataColumn.INDUSTRY);
    }, [audienceInsightsProfile, props]);

    const customersBodyTemplate = (rowData: any) => {
        return <div className="progressbar">
            <h4>{rowData?.customers?.value}</h4>
            <ProgressBar value={rowData?.customers?.percent}></ProgressBar>
        </div>
    }
    const avgCLVBodyTemplate = (rowData: any) => {
        return <h3 className="data-per">${UtilService.numberFormatter(Number(rowData?.avg_clv?.value))}<span>{rowData?.avg_clv?.percent?.toFixed(0)}%</span></h3>
    }

    const columns: Columns[] = [
        { field: "name", header: "", sort: false },
        {
            field: "customers.value",
            header: "Customers",
            body: customersBodyTemplate,
            sort: true,
        },
        {
            field: "avg_clv.value",
            header: "Avg CLV",
            body: avgCLVBodyTemplate,
            sort: true,
        },
    ];
    return (
        <>
            {/* <Loader isLoading={getCLVIndustryLoader}> */}
            <div className="insights__innerwrapper industries-customerRevenue">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2 className='inner__heading mb-2'><span> Industries</span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                            <div className="dropdown industries-dropdown">
                                <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="pi pi-ellipsis-v dots3"></span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                    <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                </ul>
                            </div>
                        </div> */}
                </div>
                <div className='industries_datatable'>
                    {getCLVIndustryLoader ? <Loader style={{ minHeight: '344px' }} /> :
                        <DataTable
                            data={tableData}
                            columns={columns}
                        ></DataTable>
                    }
                </div>
            </div>
            {/* </Loader> */}
        </>
    )
}

export default React.memo(IndustryComponent)