import React, { Fragment, useEffect, useState } from "react";

import { NavLink, useNavigate, useParams } from 'react-router-dom';

import './CustomerSegmentationDetails.scss';
import {
    mai_share_icon,
    mai_back_icon,
    mai_gray_dots,
} from '../../../../../assets/images/index';
import { TabView, TabPanel } from 'primereact/tabview';
import SegmentationSettings from "./settings/SegmentationSettings";
import SegmentationMigration from "./migration/SegmentationMigration";
import SegmentationPersonas from "./personas/SegmentationPersonas";
import SegmentationSegment from "./segments/SegmentationSegment";
import { StoreActions } from "../../../../../store/actions";
import ApiService from "../../../../../services/api.service";
import { Url } from "../../../../../models/constants";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../../../../../hooks/use-http";
import { StoreState } from "../../../../../store/store";

const CustomerSegmentationDetailsComponent = () => {

    const dispatch=useDispatch();
    const { sendRequest: getsegment } = useHttp();
    const params=useParams();
    const userDetails = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const [segmentName, setsegmentName] = useState<string>("");
    const headerTitleChangeHandler = (
      title: string,
      backUrl: string,
      share: string
    ) => {
      const payload = { title, backUrl, share };
      dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    };
    useEffect(() => {
      if (params.sid) {
        const resHandler = (res: any) => {
          setsegmentName(res.segmentation.name);
        };
        getsegment(
          ApiService.getSegmentationGeneraldetail(params.sid),
          resHandler
        );
      }
    }, [getsegment, params.sid]);
    useEffect(() => {
      if (segmentName && userDetails.title !== segmentName) {
        headerTitleChangeHandler(
          segmentName,
          `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_LIST}`,
          "true"
        );
      }
    });

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper audiencedetails audiencedetails-wrap px-0 py-0'>
                <div className="audiencedetails__tabs customtabs-wrap">
                    <TabView>
                        <TabPanel header="Segments">
                            <SegmentationSegment></SegmentationSegment>
                        </TabPanel>
                        <TabPanel header="Segment Personas">
                            <SegmentationPersonas></SegmentationPersonas>
                        </TabPanel>
                         <TabPanel header="Segment Migration">
                            <SegmentationMigration></SegmentationMigration>
                        </TabPanel>
                    {/*
                    <TabPanel header="Settings">
                        <SegmentationSettings></SegmentationSettings>
                    </TabPanel> */}
                    </TabView>
                </div>

                 {/* <div className="segmentdetails__tabs">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link active" id="segments-tab" data-bs-toggle="tab" data-bs-target="#segments" href="#">Segments</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="segmentpersonas-tab" data-bs-toggle="tab" data-bs-target="#segmentpersonas" href="#">Segment Personas</a>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" id="segmentmigration-tab" data-bs-toggle="tab" data-bs-target="#segmentmigration" to="#">Segment Migration</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" to="#">Settings</NavLink>
                        </li>
                    </ul>

                    <div className="tab-content" id="tabContent">

                        <div className="tab-pane fade show active" id="segments" role="tabpanel" aria-labelledby="segments-tab">
                            <SegmentationSegment></SegmentationSegment>

                        </div>
                        <div className="tab-pane fade" id="segmentpersonas" role="tabpanel" aria-labelledby="segmentpersonas-tab">
                            <SegmentationPersonas></SegmentationPersonas>
                        </div>
                        <div className="tab-pane fade segmentmigration" id="segmentmigration" role="tabpanel" aria-labelledby="segmentmigration-tab">
                            <SegmentationMigration></SegmentationMigration>
                        </div>
                        <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                            <SegmentationSettings></SegmentationSettings>
                        </div>
                    </div>
                </div>  */}
            </div>

            {/* Add KPI Modal pop up  */}
            <div className="modal fade segmentdetails__addkpimodal" id="addkpi" aria-labelledby="addkpiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Add KPI</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>KPI positioning</h2>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Customers
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Revenues
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Pipeline
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Email Engagement
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning">
                                        <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                        Product Engagement
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="kpi-positioning"></div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>List of KPI </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked1">
                                                Customers
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked2">
                                                Product Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked3" />
                                            <label className="form-check-label" htmlFor="checked3">
                                                Attribution
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked4" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked4">
                                                Revenues
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked5">
                                                Email Engagement
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked6" />
                                            <label className="form-check-label" htmlFor="checked6">
                                                CLTV
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked7" />
                                            <label className="form-check-label" htmlFor="checked7">
                                                P.o.P %
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked8" />
                                            <label className="form-check-label" htmlFor="checked8">
                                                Churn Rate
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked9" />
                                            <label className="form-check-label" htmlFor="checked9">
                                                CSAT
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked10" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked10">
                                                Pipeline
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked11" />
                                            <label className="form-check-label" htmlFor="checked11">
                                                MRoI
                                                <span>Lorem ipsum dolor sit amet, consectetur </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">

                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filter Popup */}
            <div className="modal fade filter-popup" id="filterpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Filter</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="countries" className="form-label">Country</label>
                                    <select className="form-select" id="countries" name="Country" defaultValue="USA">
                                        <option>USA</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <label htmlFor="region" className="form-label">Region</label>
                                    <select className="form-select" id="region" name="Region" defaultValue="Select Region">
                                        <option disabled>Select Rgion</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <select className="form-select" id="state" name="State" defaultValue="Select State">
                                        <option disabled>Select State</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Reset</button>
                            <button type="button" className="btn btn-primary mai-next-button">Apply</button>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    );
}

export default CustomerSegmentationDetailsComponent;

function headerTitleChangeHandler(segmentName: any, arg1: string, arg2: string) {
    throw new Error("Function not implemented.");
}
