import React, { Fragment } from "react";
import "./Loader.scss";
type Myprops = {
    isLoading?: boolean;
    children?: React.ReactNode;
    style?: any;
}

const Loader = ({isLoading = true, children, style = {}}: Myprops) => {
    if(!isLoading){
        return <>{children}</>
    }
    return (
      <Fragment>
        <div id="new_loader">
          {children && <div className="overlay">{children}</div>}
          <div
            className={`before_page_load ${children ? "loaderoverlay" : ""}`} style={style}
          >
            <div className="loader_pulse">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default Loader;