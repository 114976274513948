import React, { Fragment, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './CampaignOptimizationList.scss';

import { mai_search_bar, mai_plus, mai_users } from '../../../../../assets/images/index';
import { Dropdown } from 'primereact/dropdown';
const CampaignOptimizationListComponent = () => {
    const data = [
        { "campaignid": "1000", "createdby": "AJ", "campaignname": "Recommendations", "description": "Product Description", "sharedwith": "user.png", "startdate": "26/06/22", "phase": "Published", "actions": 5 },
        { "campaignid": "1001", "createdby": "GJ", "campaignname": "Recommendations 1", "description": "Product Description", "sharedwith": "black-watch.jpg", "startdate": "24/06/22", "phase": "Draft", "actions": 4 },
        { "campaignid": "1002", "createdby": "GH", "campaignname": "Recommendations 2", "description": "Product Description", "sharedwith": "blue-band.jpg", "startdate": "15/06/22", "phase": "Published", "actions": 3 },
        { "campaignid": "1003", "createdby": "AJ", "campaignname": "Recommendations 3", "description": "Product Description", "sharedwith": "blue-t-shirt.jpg", "startdate": "15/06/22", "phase": "Published", "actions": 5 },
        { "campaignid": "1004", "createdby": "AJ", "campaignname": "Recommendations 4", "description": "Product Description", "sharedwith": "bracelet.jpg", "price": 15, "startdate": "12/06/22", "phase": "Published", "actions": 4 },
        { "campaignid": "1005", "createdby": "PJ", "campaignname": "Recommendations 5", "description": "Product Description", "sharedwith": "brown-purse.jpg", "startdate": "19/06/22", "phase": "Draft", "actions": 4 },
        { "campaignid": "1006", "createdby": "DK", "campaignname": "Recommendations 6", "description": "Product Description", "sharedwith": "chakra-bracelet.jpg", "startdate": "15/06/22", "phase": "Published", "actions": 3 },
        { "campaignid": "1007", "createdby": "MM", "campaignname": "Recommendations 7", "description": "Product Description", "sharedwith": "galaxy-earrings.jpg", "startdate": "15/06/22", "phase": "Published", "actions": 5 },
        { "campaignid": "1008", "createdby": "VM", "campaignname": "Recommendations 8", "description": "Product Description", "sharedwith": "game-controller.jpg", "startdate": "22/06/22", "phase": "Published", "actions": 4 },
        { "campaignid": "1009", "createdby": "CM", "campaignname": "Recommendations 9", "description": "Product Description", "sharedwith": "gaming-set.jpg", "startdate": "15/06/22", "phase": "Published", "actions": 3 }
    ]

    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>(null);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [createdBy, setSelectedCreatedBy] = useState<any>(null);

    const onBusinessUnitChange = (e: { value: any }) => {
        setSelectedBusinessUnit(e.value);
    }
    const onProductChange = (e: { value: any }) => {
        setSelectedProduct(e.value);
    }
    const onStatusChange = (e: { value: any }) => {
        setSelectedStatus(e.value);
    }
    const onCreatedByChange = (e: { value: any }) => {
        setSelectedCreatedBy(e.value);
    }


    const businessunits = [
        { name: 'Marketing', code: 'MK' },
        { name: 'Sales', code: 'SL' },
    ];
    const products = [
        { name: 'Product Ar', code: 'prod1' },
        { name: 'Product SG', code: 'prod2' },
        { name: 'Product Ri', code: 'prod3' },
        { name: 'Product Fd', code: 'prod4' },
        { name: 'Product Cr', code: 'prod5' }
    ];
    const status = [
        { name: 'Published', code: 'Published' },
        { name: 'Draft', code: 'Draft' }
    ];
    const createdby = [
        { name: 'Dharmendra', code: 'Dharmendra' },
        { name: 'John', code: 'John' },
        { name: 'William', code: 'William' },
        { name: 'Nikhil', code: 'Nikhil' },
        { name: 'Parveen', code: 'Parveen' },
    ];

    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form audiencelist whiteboxlist-height'>
                    {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h1 className='titles'>Campaign Optimization</h1>
                        </div>
                    </div> */}
                    <div className="row mb-3">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="Search" aria-describedby="button-addon4" className="form-control border-0" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="selectlist-wrap">
                                        <div className='selectlist'>
                                            <label>Created by</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={createdBy} options={createdby} onChange={onCreatedByChange} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                        <div className='selectlist'>
                                            <label>Status</label>
                                            <div className='monitoring-dropdownlist'>
                                                <Dropdown value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="All" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                            <div className='monitoring-dropdownlist'>
                                <Dropdown value={selectedBusinessUnit} options={businessunits} onChange={onBusinessUnitChange} optionLabel="name" placeholder="Business Unit" />
                                <Dropdown value={selectedProduct} options={products} onChange={onProductChange} optionLabel="name" placeholder="Products" />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="card list-datatable border-0">
                            <DataTable value={data} responsiveLayout="scroll" data-bs-toggle="modal" data-bs-target="#optimizationpopup">
                                <Column field="campaignname" header="Campaign Name"></Column>
                                <Column field="campaignid" header="Campaign ID"></Column>
                                <Column field="description" header="description"></Column>
                                <Column field="createdby" header="createdby"></Column>
                                <Column field="sharedwith" header="sharedwith"></Column>
                                <Column field="startdate" header="Start Date"></Column>
                                <Column field="phase" header="Phase"></Column>
                                <Column field="actions" header="actions"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>


            {/* Campaign Optimization Popup */}
            <div className="modal fade filter-popup optimizationPopup" id="optimizationpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">What do you want to optimize?</h1>
                            {/* <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="revenue" value="Revenue" defaultChecked />
                                        <label className="form-check-label mai-radio-label" htmlFor='revenue'>Revenue</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="cost" value="Cost" />
                                        <label className="form-check-label mai-radio-label" htmlFor='cost'>Cost</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button bg-transparent" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary mai-next-button">Okay</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CampaignOptimizationListComponent