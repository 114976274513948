import { Fragment, useEffect, useState } from 'react';
import './Summary.scss';
import {
  mai_question_icon,
  mai_tick,
  mai_cross
} from '../../../../../../assets/images/index'
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import { ChartConfigType, summaryChartResponse, summaryWaterfallResponse } from '../../../../../../models/types';
import { useNavigate, useParams } from 'react-router-dom';
import ChartComponent from '../../../../../../shared/components/chart/ChartComponent';
import UtilService from '../../../../../../services/util.service';
import CustomTooltip from '../../../../../../shared/components/customtooltip/CustomTooltip';
import { Url } from '../../../../../../models/constants';
import Loader from '../../../../../../shared/components/loader/Loader';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { StoreActions } from '../../../../../../store/actions';
import { StoreState } from '../../../../../../store/store';

type chartData = {
  universe: {
    min: string;
    max: string;
    median: string;
    mean: string;
    q1: string;
    q3: string;
    total: string;
    customers: string;

  },
  cohort: {
    min: string;
    max: string;
    median: string;
    mean: string;
    q1: string;
    q3: string;
    total: string;
    customers: string;
  }
};
type waterfallDataType = {
  label: string,
  customer_count?: number | string,
  key: 'include' | 'exclude' | "null",
  percent: number
}
const SummaryComponent = () => {
  const params = useParams();
  let navigate = useNavigate();
  const { sendRequest: getAudienceChart, loading: getAudienceChartLoader } = useHttp();
  const { sendRequest: getAudienceWaterfall, loading: getAudienceWaterfallLoader } = useHttp();
  const { sendRequest: publishAudience, loading: publishLoader } = useHttp();
  const [chartData, setChartData] = useState<chartData>();
  const [waterfallData, setWaterfallData] = useState<waterfallDataType[]>([]);
  const [pieChartConfigs, setPieChartConfigs] = useState<{
    cohort: ChartConfigType;
    revenue: ChartConfigType;
    whisker: ChartConfigType;
  }>({
    cohort: {
      type: "pie2d",
      width: 215,
      height: "25%",
      containerBackgroundOpacity: "0",
      dataFormat: "json",
      dataSource: {
        chart: {
          pieRadius: "70%",
          numberPrefix: "$",
          showPercentInTooltip: "0",
          decimals: "1",
          bgColor: "#fafafa",
          enableSmartLabels: "0",
          showLabels: "0",
          showValues: "0",
          toolTipBorderRadius: "20",
          legendPosition: "bottom",
          showToolTip: "0",
          showLegend: "0",
          chartLeftMargin: "0",
          chartRightMargin: "0",
          chartTopMargin: "0",
          chartBottomMargin: "0",
          enableSlicing: "0"
        },
      },
    },
    revenue: {
      type: "pie2d",
      width: 215,
      height: "25%",
      containerBackgroundOpacity: "1",
      dataFormat: "json",
      dataSource: {
        chart: {
          pieRadius: "70%",
          numberPrefix: "$",
          showPercentInTooltip: "0",
          decimals: "1",
          bgColor: "#fafafa",
          //Theme
          enableSmartLabels: "0",
          showLabels: "0",
          showValues: "0",
          toolTipBorderRadius: "20",
          legendPosition: "bottom",
          showToolTip: "0",
          showLegend: "0",
          chartLeftMargin: "0",
          chartRightMargin: "0",
          chartTopMargin: "0",
          chartBottomMargin: "0",
          enableSlicing: "0"
        },
      },
    },
    whisker: {
      type: "boxandwhisker2d",
      width: "100%",
      height: "32%",
      containerBackgroundOpacity: "1",
      dataFormat: "json",
      dataSource: {
        chart: {
          numberPrefix: "$",
          showLegend: "0",
          plotSpacePercent: "55",
          showalloutliers: "0",
          bgColor: "#fafafa",
          mediancolor: "#FFFFFF",
          // plotToolText:
          //   "Max: <b>$maxDataValue </b><br>Median: <b>$median</b><br>Mean: <b>$mean</b><br>Min: <b>$minDataValue</b>",
          plotToolText: "<div style='min-width: 150px'><div style='display: flex; justify-content: space-between'><div>Max:</div> <div><b>$maxDataValue</b></div> </div> <div style='display: flex; justify-content: space-between; margin-top: 5px'> <div>Median:</div> <div><b>$median</b></div> </div> <div style='display: flex; justify-content: space-between; margin-top: 5px'><div>Mean:</div> <div><b>$mean</b></div> </div> <div style='display: flex; justify-content: space-between; margin-top: 5px'><div>Min:</div> <div><b>$minDataValue</b></div> </div> </div> ",
          chartLeftMargin: "0",
          chartRightMargin: "0",
          chartTopMargin: "10",
          chartBottomMargin: "0",
          toolTipBgColor: "#444444",
          toolTipColor: "#fff",
          toolTipBorderColor: "#444444",
          toolTipBorderRadius: "5",
        },
        categories: [
          {
            category: [
              {
                label: "Universe",
              },
              {
                label: "Cohort",
              },
            ],
          },
        ],
      },
    },
  });
  const dispatch=useDispatch();
  const header = useSelector<StoreState, any>(
    (state) => state.headerContent
  );
  const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
    const payload = { title,backUrl,share };
    dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
  }
  useEffect(()=>{
    if(header.title!=="Create Audience")
      headerTitleChangeHandler("Create Audience",'','');
  })
  useEffect(() => {
    const resHandler = (res: summaryChartResponse) => {
      let cloneConfig = { ...pieChartConfigs };
      cloneConfig.cohort.dataSource.data = [
        {
          label: 'Universe',
          value: Number(res.data.universe.customers) - Number(res.data.cohort.customers),
          color: "#dfdfdf"
        },
        {
          label: 'Cohort',
          value: res.data.cohort.customers,
          color: "#86a3e7"
        }
      ];
      cloneConfig.revenue.dataSource.data = [
        {
          label: 'Universe',
          value: Number(res.data.universe.raw_total) - Number(res.data.cohort.raw_total),
          color: "#dfdfdf"
        },
        {
          label: 'Cohort',
          value: res.data.cohort.raw_total,
          color: "#86a3e7"
        }
      ];
      cloneConfig.whisker.dataSource.dataset = [
        {
          seriesname: "Revenue",
          lowerBoxColor: "#dfdfdf",
          upperBoxColor: "#afafaf",
          data: [res.data.universe, {}],
        },
        {
          seriesname: "Revenue",
          lowerBoxColor: "#ccdbff",
          upperBoxColor: "#86a3e7",
          data: [{}, res.data.cohort],
        },
      ];
      setPieChartConfigs(cloneConfig);
      setChartData(res.data);
    }
    if (params?.aid) {
      getAudienceChart(ApiService.getAudienceSummaryChart(params.aid), resHandler);
    }
  }, [params]);

  useEffect(() => {
    const resHandler = (res: summaryWaterfallResponse) => {
      let cloneWaterFallConfig = [];
      let universe: any = res.data?.find((item) => item.key === "null");
      cloneWaterFallConfig = res.data.map(
        ({ label, key, customer_count }) => ({
          label: label,
          customer_count: UtilService.numberFormatter(Number(customer_count)),
          percent: Number(customer_count) && Number(universe?.customer_count) ? (Number(customer_count) * 100 / Number(universe.customer_count)) : 0,
          key: key
        })
      );
      setWaterfallData(cloneWaterFallConfig);
    };

    if (params?.aid) {
      getAudienceWaterfall(ApiService.getAudienceSummaryWaterfall(params.aid), resHandler);
    }
  }, [params]);

  const cancelHandler = () => {
    navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`);
  }
  const onBackHandler = () => {
    navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_CREATE}/${Url.AUDIENCE_CREATE_RULES}/${params?.aid}`);
  }
  const publishHandler = () => {
    const data = {
      is_published: true
    }
    const resHandler = (res: any) => {
      cancelHandler();
    }
    publishAudience(ApiService.updateAudience(data, params?.aid), resHandler);
  }

  return (
    <Fragment>
      <div className='audiencecreate__steps sticky-top stickytopRecomd'>
        <ul className='list-inline'>
          <li className='list-inline-item audiencecreate__steps--active'>General details</li>
          <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
          <li className='list-inline-item audiencecreate__steps--active'>Rules</li>
          <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
          <li className='list-inline-item audiencecreate__steps--active'>Summary</li>
        </ul>
      </div>
      <Loader isLoading={publishLoader}>
        <div className='audiencecreate__form-wrapper'>
          <div className='audiencecreate__form p-0 bg-transparent'>
            <div className="row">
              <div className="col-lg-12 col-md-12 co-sm-12">
                <div className="summery__waterfall summery-shadow bg-white">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <h1 className='summery__heading'>Waterfall <img src={mai_question_icon} alt="quest" className='img-fluid' /></h1>
                    </div>
                  </div>
                  <Loader isLoading={getAudienceWaterfallLoader}>
                    {waterfallData?.map(({ customer_count, label, key, percent }: any, index: number) => (
                      <div className="row" key={`summary_chart_${index}`}>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <ul className='summery__waterfalllist'>
                            <li>
                              {<img src={key === 'exclude' ? mai_cross : mai_tick} alt={key} className='img-fluid' />}
                              {label.length > 35 ?
                                // <CustomTooltip
                                //   position="bottom"
                                //   content={UtilService.removeUnderscoreAsSpace(label)}
                                //   containerClass="tooltipStyleChange">
                                //   {`${UtilService.removeUnderscoreAsSpace(label).substring(0, 35)}...`}
                                // </CustomTooltip>
                                <>
                                  <Tooltip className="list-tooltip-wrap" target={`.waterfalllabeltooltip${index}`} position="bottom">
                                    {UtilService.removeUnderscoreAsSpace(label)}
                                  </Tooltip>
                                  <div className={`waterfalllabeltooltip${index}`}>
                                    {`${UtilService.removeUnderscoreAsSpace(label).substring(0, 35)}...`}
                                  </div>
                                </>
                                : UtilService.removeUnderscoreAsSpace(label)
                              }
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                          <div className='summery__waterfall-chart'>
                            <div className='universe bluecolorshade' style={{ width: `${percent}%` }}>
                              {/* <CustomTooltip
                                position="bottom"
                                content={customer_count}
                                containerClass="tooltipStyleChange">
                                {customer_count}
                              </CustomTooltip> */}

                              <Tooltip className="list-tooltip-wrap waterfallchart-tooltip" target={`.waterfallcharttooltip${index}`} position="bottom">
                                {customer_count}
                              </Tooltip>
                              <div className={`waterfallcharttooltip${index}`}>
                                {customer_count}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                  </Loader>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 co-sm-12 mt-3">
                <div className="summery__waterfall summery-shadow bg-white">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 co-sm-12">
                      <div className='summary__audience-revenue'>
                        <h1 className='summery__heading'>Audience <img src={mai_question_icon} alt="quest" className='img-fluid' /></h1>
                        <div className="row">

                          <div className="col-lg-6 col-md-6 col-sm-12 px-2">
                            <Loader isLoading={getAudienceChartLoader}>
                              <div className='cohart_container'>
                                <ChartComponent chartConfigs={pieChartConfigs.cohort} />
                              </div>
                              <div className='summary__size'>
                                <h2 className='audience_chartdata'><span> {chartData?.cohort?.customers ?? 0}</span> <sub className='ms-2'>{chartData && Number(chartData.cohort.customers) && Math.round((Number(chartData.cohort.customers) * 100) / Number(chartData.universe.customers))} %</sub></h2>
                                <p>Cohort Size</p>
                              </div>
                            </Loader>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 px-2">
                            <Loader isLoading={getAudienceChartLoader}>
                              <div className='cohart_container'>
                                <ChartComponent chartConfigs={pieChartConfigs.revenue} />
                              </div>
                              <div className='summary__size'>
                                <h2 className='audience_chartdata'><span> $</span> <span> {UtilService.numberFormatter(Number(chartData?.cohort?.total ?? 0))} </span> <sub className='ms-2'>{chartData && Number(chartData.universe.total) ? Math.round((Number(chartData.cohort.total) * 100) / Number(chartData.universe.total)) : ''}%</sub></h2>
                                <p>Cohort Revenue</p>
                              </div>
                            </Loader>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 co-sm-12">
                      <div className='summary__audience-revenue'>
                        <h1 className='summery__heading'>Revenue <img src={mai_question_icon} alt="quest" className='img-fluid' /></h1>
                        <Loader isLoading={getAudienceChartLoader} style={{ minHeight: "280px" }}>
                          <div className='px-2'>
                            <ChartComponent chartConfigs={pieChartConfigs.whisker} />
                          </div>
                        </Loader>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="rules__border-top">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                            <button type="submit" className="btn btn-primary mai-cancel-button" onClick={cancelHandler}>Cancel</button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                            <button type="submit" className="btn btn-primary mai-cancel-button me-3" onClick={onBackHandler}>Back</button>
                            <button type="submit" className="btn btn-primary mai-next-button" onClick={publishHandler}>Publish</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </Fragment>
  )
}

export default SummaryComponent;