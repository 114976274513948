import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { AudienceDeatil, AudienceGeneralDetail, AudienceListResponse, RecommendationGeneralDetail, SegmentationDetail, SegmentationListResponse, SegmentationSummaryResponse, SubSegmentDetails, segmentationGeneralDetailResponse } from '../../../../../../models/types';
import { AutoComplete } from "primereact/autocomplete";
import './RecommendationGeneralDetails.scss';
import useHttp from '../../../../../../hooks/use-http';
import ApiService from '../../../../../../services/api.service';
import AuthenticationService from '../../../../../../services/authentication.service';
import { Url } from '../../../../../../models/constants';

import { mai_plus_solid_blue, mai_question_icon } from '../../../../../../assets/images/index';
import { StoreActions } from '../../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../../../store/store';

const RecommendationGeneralDetailsComponents = () => {
    const params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    // const [audience, setAudience] = useState<AudienceGeneralDetail>({
    //     name: "",
    //     description: "",
    //     is_private: false,
    //     can_be_accessed_by: [],
    // });
    const [recommendation, setRecommendation] = useState<RecommendationGeneralDetail>({
        name: "",
        description: "",
        is_private: false,
        can_be_accessed_by: [],
        source: 'audience',
        source_id: []
    });
    const { sendRequest: createRecommendation, loading: createRecommendationLoader, error } = useHttp();
    const { sendRequest: updateRecommendation } = useHttp();
    const { sendRequest: getRecommendation, loading: getRecommendationLoader } = useHttp();
    const { sendRequest: getSegmentation, loading: getSegmentationLoader } = useHttp();
    const { sendRequest: getAudience, loading: getAudienceLoader } = useHttp();
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [filteredUsers, setFilteredUsers] = useState<any>([]);
    const [userList, setUserList] = useState<any>([]);
    const [enableNext, setEnableNext] = useState<boolean>(false);
    const { sendRequest: getAudiences } = useHttp();
    const { sendRequest: getSegmentations } = useHttp();
    const { sendRequest: getSubSegments } = useHttp();
    const [selectedSubSegments, setSelectedSubSegments] = useState<any>([]);
    const [selectedSegments, setSelectedSegments] = useState<any>();
    const [selectedAudience, setSelectedAudience] = useState<any>();
    const [filteredAudienceList, setFilteredAudienceList] = useState< AudienceDeatil[]>([]);
    const [filteredSegmentList, setFilteredSegmentList] = useState<SegmentationDetail[]>([]);
    const [filteredSubSegmentList, setFilteredSubSegmentList] = useState<SubSegmentDetails[]>([]);
    const [allSubSegmentList, setAllSubSegmentList] = useState<SubSegmentDetails[]>([]);
    const header = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const headerTitleChangeHandler = (title: string,backUrl:string,share:string) => {
        const payload = { title,backUrl,share };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      }
      useEffect(()=>{
        if(header.title!=="Create Recommendation")
          headerTitleChangeHandler("Create Recommendation",'','');
      })
    useEffect(() => {
        AuthenticationService.fetchUsers().then((res: any) => {
            let allUsers = res.users.map((usr: any) => ({
                id: usr.name, ...usr
            }));
            setUserList(allUsers);
            setFilteredUsers(allUsers);
        });
    }, [])
    const getSubSegList=(segId:string,res?:any)=>{
        const subsegresHandler = async (summaryres: SegmentationSummaryResponse) => {
            let arr: SubSegmentDetails[] = [];
            if (summaryres.response.insights.length > 0) {
                arr = summaryres.response.insights.map((item) => ({
                id: item.segment_id.toString(),
                name: item.segment_name.toString(),
              }));
              setAllSubSegmentList(arr);
            }
            if(res)
            {
                const selSubSegList = arr.filter((sub: SubSegmentDetails) => res?.recommendation.source_id.includes(Number(sub.id)))
                setSelectedSubSegments(selSubSegList);
            }
          };
          getSubSegments(ApiService.getSubSegmentsList(segId), subsegresHandler);
    }
    useEffect(() => {
        if (params?.rid) {
            const resHandler = (res: any) => {
                setRecommendation(res.recommendation);
                if (res.recommendation.source === 'segmentation') {
                    const segresHandler = (segres: any) => {
                      setSelectedSegments(segres?.segmentation.name);
                    };
                    getSegmentation(
                      ApiService.getSegmentationGeneraldetail(res?.segmentation_id),
                      segresHandler
                    );
                    getSubSegList(res.segmentation_id,res);
                      
                  } else {
                    const audresHandler = (audres: any) => {
                      setSelectedAudience(audres?.audience.name);
                    };
                    getAudience(
                      ApiService.getAudienceGeneraldetail(res?.recommendation.source_id[0]),
                      audresHandler
                    );
                  }
            }
            getRecommendation(ApiService.getRecommendationGeneralDetails(params?.rid), resHandler)
        }
    }, [params])

    useEffect(() => {
        if (recommendation.name === '' || recommendation.description === ''|| recommendation.source_id.length ===0 || (recommendation.is_private === false && recommendation.can_be_accessed_by.length === 0)) {
            setEnableNext(false);
        } else {
            setEnableNext(true);
        }
    }, [recommendation]);

    // useEffect(() => {
    //     setRecommendation({ ...recommendation, can_be_accessed_by: selectedUsers.map((usr: any) => usr.email) });
    // }, [selectedUsers]);

    const searchUser = (event: any) => {
        setTimeout(() => {
            let _filteredUsers;
            if (!event.query.trim().length) {
                _filteredUsers = [...userList];
            } else {
                _filteredUsers = userList.filter((usr: any) => !selectedUsers.map((selusr: any) => selusr.id).includes(usr.id)).filter((usr: any) => {
                    return usr.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredUsers(_filteredUsers);
        }, 250);
    }
    const userSelectionHandler = (event: any) => {
        setSelectedUsers(event.value);
        setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
          ...oldRecommendation,
          can_be_accessed_by: event.value.map((item:any)=>item.id)
        }));
      };
    useEffect(()=>{
        if(params?.sid)
        {
            setRecommendation({...recommendation,source:'segmentation'});
            const resHandler=(res:any)=>{
                setSelectedSegments(res.segmentation.name);
            }
            getSegmentation(ApiService.getSegmentationGeneraldetail(params.sid),resHandler);
            getSubSegList(params.sid);
        }
        else if(params?.aid)
        {
            setRecommendation({...recommendation,source:'audience',source_id:[params.aid]});
            const resHandler=(res:any)=>{
                setSelectedAudience(res.audience.name);
            }
            getAudience(ApiService.getAudienceGeneraldetail(params?.aid),resHandler)
        }
    },[])
    const getAudienceList = useCallback(
    (
      searchText = "",
      createdBy = "",
      page = 0,
      sortBy = "modified_on",
      sortOrder = 1,
      published = "True",
    ) => {
      const resHandler = (res: AudienceListResponse) => {
        setFilteredAudienceList(res.audiences);
      };
      getAudiences(
        ApiService.getAudienceList({
          page: page,
          search: searchText,
          created_by: createdBy,
          sort_by: sortBy,
          sort_order: sortOrder,
          published
        }),
        resHandler
      );
    },
    [getAudiences, recommendation.is_private]
  );

  const getSegmentationList = useCallback(
    (
      searchText = "",
      createdBy = "",
      published = "True",
      page = 0,
      sortBy = "modified_on",
      sortOrder = 1
    ) => {
      const resHandler = async (res: SegmentationListResponse) => {
        setFilteredSegmentList(res.segmentation);
      };
      getSegmentations(
        ApiService.getSegmentationList({
          page: page + 1,
          search: searchText,
          created_by: createdBy,
          sort_by: sortBy,
          sort_order: sortOrder,
          published,
        }),
        resHandler
      );
    },
    [getSegmentations, recommendation.is_private]
  );

  const getSubSegmentsList = useCallback(
    (clv_base_id: string) => {
      const resHandler = async (res: SegmentationSummaryResponse) => {
        let arr: SubSegmentDetails[] = [];
        if (res.response.insights.length > 0) {
          arr = res.response.insights.map((item) => ({
            id: item.segment_id.toString(),
            name: item.segment_name.toString(),
          }));
          setAllSubSegmentList(arr);
        }
      };
      getSubSegments(ApiService.getSubSegmentsList(clv_base_id), resHandler);
    },
    [getSubSegments]
  );
  const searchAudience = (event: { query: string }) => {
    setTimeout(() => {
      getAudienceList(event.query);
    }, 250);
  };

  const searchSegments = (event: { query: string }) => {
    setTimeout(() => {
      getSegmentationList(event.query);
    }, 250);
  };
  const searchSubSegments = (event: { query: string }) => {
    setTimeout(() => {
      if (event.query.trim().length >= 3) {
        if (allSubSegmentList.length) {
          const filteredSubSegList = allSubSegmentList
            .filter(
              (sub: SubSegmentDetails) =>
                !selectedSubSegments
                  ?.map((item: SubSegmentDetails) => item.id)
                  .includes(sub.id)
            )
            .filter((sub: SubSegmentDetails) =>
              sub.name.toLowerCase().includes(event.query.trim().toLowerCase())
            );
          setFilteredSubSegmentList(filteredSubSegList);
        }
      }
    }, 250);
  };
const createRecommendationHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if(params?.rid)
    {
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.RECOMMENDATIONS_CREATE_ATTRIBUTE}/${params.rid}`);
        }
        updateRecommendation(ApiService.updateRecommendationGeneraldetail(recommendation,params.rid), resHandler)
    }
    else{
        const resHandler = (res: any) => {
            navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_CREATE}/${Url.RECOMMENDATIONS_CREATE_ATTRIBUTE}/${res.id}`);
        }
        createRecommendation(ApiService.createRecommendationGeneraldetail(recommendation), resHandler)
    }

}
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Attributes</li>
                    <li className='list-inline-item audiencecreate__steps--line'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>
            <div className='audiencecreate__form-wrapper recomdgenaraldetailswrapper'>
                <div className='audiencecreate__form'>
                    <form onSubmit={createRecommendationHandler}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label mai-form-label">Name</label>
                                            <input type="text" className="form-control mai-input" id="name" name="name" maxLength={200} value={recommendation.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecommendation({ ...recommendation, name: e.target.value })} aria-describedby="emailHelp" placeholder='Enter recommendation name' />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="">
                                            <label htmlFor="audienceDesc" className="form-label mai-form-label">Description</label>
                                            <textarea id="audienceDesc" className='form-control mai-textarea' maxLength={500} value={recommendation.description} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRecommendation({ ...recommendation, description: e.target.value })} placeholder='Enter description'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="my-4">
                                            <div className="form-check form-check-inline ps-0">
                                                <label className="form-check-label mai-label-access">Access</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="public" value="Public" checked={recommendation.is_private === false} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setSelectedUsers([]);setRecommendation({ ...recommendation, is_private: false })}} />
                                                <label className="form-check-label mai-radio-label" htmlFor="public">Public</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input mai-radio-button" type="radio" name="inlineRadioOptions" id="private" value="Private" checked={recommendation.is_private === true} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecommendation({ ...recommendation, is_private: true,can_be_accessed_by:[]}) }/>
                                                <label className="form-check-label mai-radio-label" htmlFor="private">Private</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="mb-4">
                                            {recommendation.is_private&&(<><label htmlFor="sharewith" className="form-label mai-label-sharewith">Share With <span>Optional</span></label>
                                            <AutoComplete
                                                id="clv_general_user"
                                                className="form-control"
                                                placeholder={
                                                selectedUsers.length === 0
                                                    ? "Search users and add"
                                                    : ""
                                                }
                                                value={selectedUsers}
                                                suggestions={filteredUsers}
                                                completeMethod={searchUser}
                                                field="name"
                                                multiple
                                                onChange={userSelectionHandler}
                                                aria-label="User"
                                            /></>)}
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12"></div>

                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <div className="">
                                            <label htmlFor="choosedata" className="form-label mai-form-label choosedata-label">
                                                <div>
                                                    <div className="form-check form-check-inline ps-0">
                                                        <label className="form-check-label mai-label-access">Choose data <img src={mai_question_icon} alt="quest" className='img-fluid ms-1' /></label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadiosegments" id="segments" value="segments" checked={recommendation.source==='segmentation'} onClick={()=>{setSelectedSegments('');setRecommendation({...recommendation,source: 'segmentation',source_id:[]})}} />
                                                        <label className="form-check-label mai-radio-label" htmlFor="segments">Segments</label>
                                                    </div>
                                                    <div className="form-check form-check-inline me-0">
                                                        <input className="form-check-input mai-radio-button" type="radio" name="inlineRadiosegments" id="audience" value="audience" checked={recommendation.source==='audience'} onClick={()=>{setSelectedAudience('');setRecommendation({...recommendation,source: 'audience',source_id:[]})}} />
                                                        <label className="form-check-label mai-radio-label" htmlFor="audience">Audience</label>
                                                    </div>
                                                </div>

                                            </label>
                                            {recommendation.source === 'audience' && (
                                                <AutoComplete
                                                className="form-control chooseaudience_input mai-input"
                                                placeholder="Search for Audience"
                                                value={selectedAudience}
                                                suggestions={filteredAudienceList}
                                                completeMethod={searchAudience}
                                                field="name"
                                                onChange={(e: any) => {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                    ...oldRecommendation,
                                                    source_id: [],
                                                    }));
                                                    if (e.value.id !== undefined) {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                        ...oldRecommendation,
                                                        source_id: [e.value.id],
                                                    }));
                                                    setSelectedAudience(e.value.name);
                                                    } else {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                        ...oldRecommendation,
                                                        source_id: [],
                                                    }));
                                                    setSelectedAudience(e.value);
                                                    }
                                                }}
                                                // onSelect={fetchTimeframe}
                                                aria-label="chooseaudience"
                                                />
                                            )}
                                            {recommendation.source === 'segmentation' && (
                                                <AutoComplete
                                                className="form-control chooseaudience_input mai-input"
                                                placeholder="Search for Segments"
                                                value={selectedSegments}
                                                suggestions={filteredSegmentList}
                                                completeMethod={searchSegments}
                                                field="name"
                                                onChange={(e: any) => {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                    ...oldRecommendation,
                                                    Recommendation_base_id: '',
                                                    source_id: [],
                                                    }));
                                                    setSelectedSubSegments([]);
                                                    if (e.value.id !== undefined) {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                        ...oldRecommendation,
                                                        Recommendation_base_id: e.value.id,
                                                    }));
                                                    setSelectedSegments(e.value.name);
                                                    } else {
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                        ...oldRecommendation,
                                                        Recommendation_base_id: '',
                                                        source_id: [],
                                                    }));
                                                    setSelectedSegments(e.value);
                                                    }
                                                }}
                                                onSelect={(e: any) => {
                                                    getSubSegmentsList(e.value.id);
                                                }}
                                                aria-label="chooseaudience"
                                                />
                                            )}

                                            {recommendation.source === 'segmentation' && (
                                                <AutoComplete
                                                className="form-control chooseaudience_input subsegment-field"
                                                placeholder="Search for Sub Segments"
                                                value={selectedSubSegments}
                                                suggestions={filteredSubSegmentList}
                                                completeMethod={searchSubSegments}
                                                field="name"
                                                multiple
                                                onChange={(e: any) => {
                                                    const selSeg =
                                                    e.value?.map((item: any) =>
                                                        Number(item.id)
                                                    ) ?? [];
                                                    setRecommendation((oldRecommendation: RecommendationGeneralDetail) => ({
                                                    ...oldRecommendation,
                                                    source_id: selSeg,
                                                    }));
                                                    setSelectedSubSegments(e.value);
                                                }}
                                                // onSelect={fetchTimeframe}
                                                aria-label="chooseaudience"
                                                />
                                            )}
                                            {/* <input type="text" className="form-control mai-input" id="choosedata" name="choosedata" placeholder='North-east Region Renewal' /> */}
                                            {/* <select name="choosedata" id="choosedata" className='form-select mai-input'>
                                                <option value="Search for audience and segments " selected disabled>Search for audience and segments </option>
                                                <option value="North-east Region Renewal" >North-east Region Renewal</option>
                                                <option value="Bottom of the line">Bottom of the line</option>
                                                <option value="Middle of the line leads">Middle of the line leads</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-start ps-0">
                                        <div className='createcampaign-or'>
                                            <span> OR</span>
                                        </div>
                                        <div className='campaignbutton'>
                                            <NavLink to="" className="createbutton"><img src={mai_plus_solid_blue} alt="plus" className='img-fluid' /> Create audience</NavLink>
                                        </div>
                                    </div>
                                    {/* { audience.is_private && 
                                    <div className="mb-3">
                                        <label htmlFor="sharewith" className="form-label mai-label-sharewith">Share with <span> Optional</span></label>
                                        <input type="text" className="form-control mai-input" value={audience.can_be_accessed_by} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAudience({...audience, name:e.target.value})}  id="sharewith" placeholder='Search users and add' />
                                        <AutoComplete
                                            className="form-control"
                                            placeholder="Search users and add"
                                            value={selectedUsers}
                                            suggestions={filteredUsers}
                                            completeMethod={searchUser}
                                            field="id"
                                            multiple
                                            onChange={(e: any) => setSelectedUsers(e.value)}
                                            aria-label="User"
                                            />
                                    </div>
                                } */}


                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="rules__border-top">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                        <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>                                        
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary mai-cancel-button me-3">Prev</button>
                                        <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                                            <button className="btn btn-primary mai-cancel-button" onClick={() => navigate(`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}/${Url.RECOMMENDATIONS_LIST}`)}>Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-5 text-end">
                                            <button type="submit" className="btn btn-primary mai-next-button" disabled={!enableNext}>Next</button>
                                            {/* <button type="submit" className="btn btn-primary mai-next-button" disabled>Next</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default RecommendationGeneralDetailsComponents;