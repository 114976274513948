import React, { useEffect, useState } from 'react'
import ChartComponent from '../../shared/components/chart/ChartComponent';
import { Dropdown } from 'primereact/dropdown';
import { ChartConfigType, ChartDataType, DataSetData, DataSourceType } from '../../models/types';

const ChartExampleComponent = () => {
    const [currentChart, setCurrentChart] = useState<{name: string, code: string}>({name: "Area Chart", code:"area2d"});
    const [showData, setShowData] = useState<any>({});
    const [chartConfig, setChartConfig] = useState<ChartConfigType>({
        type: "area2d",
        width: 500,
        height: 500,
        containerBackgroundOpacity: '0',
        dataFormat: "json",
        dataSource: {
            chart: {} as ChartDataType,
            dataset: {} as DataSetData[]
        }
    });

    const ChartOptions: {name: string, code: string}[] = [
        { name: 'Area Chart', code: 'area2d' },
        { name: 'Multi Series Combination', code: 'mscombi2d' },
        { name: 'Box And Whisker 2D', code: 'boxandwhisker2d' },
        { name: 'Column 2D', code: 'column2d' },
        { name: 'World', code: 'world' },
        { name: 'Bar 2D', code: 'bar2d' },
        { name: 'Doughnut 2D', code: 'doughnut2d' },
        { name: 'Pie 2D', code: 'pie2d' },
        { name: 'Funnel', code: 'funnel' },
        { name: 'HeatMap', code: 'heatmap' },
    ];

    const mscombi2ddata = {
      data: [
        {
          label: "2013",
          value: "4374560000",
        },
        {
          label: "2014",
          value: "5504660000",
        },
        {
          label: "2015",
          value: "6779510000",
        },
        {
          label: "2016",
          value: "8830670000",
        },
        {
          label: "2017",
          value: "11692710000",
        },
        {
          label: "2018",
          value: "12862520000",
        },
      ],
    }
    const mscombi2d: DataSourceType = {
      chart: {
        // bgColor: "#ffffff",
        // canvasBgColor: "#ffffff",
        // animation: "0",
        theme: "candy",
        plotFillAlpha: "15",
        // plotFillAngle: "90",
        // showCanvasBorder: "0",
        showLabels: "1",
        // divLineAlpha: "50",
        // divLineColor: "#434456",
        showYAxisValues: "1",
        showDivLine: "1",
        yAxisMinValue: "1000000000",
        yAxisMaxValue: "14500000000",
        // placeValuesInside: "0",
        // showLegend: "0",
        // showShadow: "0",
        // paletteColors: "#62B58F",
        // drawAnchors: "0",
        // showAlternateHGridColor: "0",
        // drawCrossLine: "0",
        // plotColorinTooltip: "0",
        // baseFontColor: "#4e24e2",
        // valueFontColor: "#b4b4b6",
        // valueFontSize: "14",
        // baseFontSize: "12",
        // baseFont: "Roboto",
        // toolTipBgColor: "#525357",
        // toolTipColor: "#F8F8F8",
        // toolTipBorderColor: "#525357",
        // toolTipBorderAlpha: "20",
        // showToolTipShadow: "1",
        // toolTipPadding: "10",
        numberPrefix: "$",
        formatNumberScale: "1",
        numberScaleUnit: "B",
        numberScaleValue: "1000000000",
        plotToolText:
          "<b>Global Revenue</b></br><b>Year : </b>$label</br><b>Revenue : </b> $dataValue",
      },
      categories: [
        {
          category: [
            {
              label: "2013",
            },
            {
              label: "2014",
            },
            {
              label: "2015",
            },
            {
              label: "2016",
            },
            {
              label: "2017",
            },
            {
              label: "2018",
            },
          ],
        },
      ],
      dataset: [
        {
          renderAs: "splinearea",
          showValues: "0",
          data: [
            {
              value: "4374560000",
            },
            {
              value: "5504660000",
            },
            {
              value: "6779510000",
            },
            {
              value: "8830670000",
            },
            {
              value: "11692710000",
            },
            {
              value: "12862520000",
            },
          ],
        },
        {
          renderAs: "spline",
          showValues: "1",
          data: [
            {
              value: "4374560000",
            },
            {
              value: "5504660000",
            },
            {
              value: "6779510000",
            },
            {
              value: "8830670000",
            },
            {
              value: "11692710000",
            },
            {
              value: "12862520000",
            },
          ],
        },
      ],
    };
    const area2d: DataSourceType = {
      chart: {
        // bgColor: "#ffffff",
        // canvasBgColor: "#ffffff",
        // animation: "0",
        theme: "candy",
        plotFillAlpha: "15",
        // plotFillAngle: "90",
        // showCanvasBorder: "0",
        showLabels: "1",
        showValues: "1",
        // divLineAlpha: "50",
        // divLineColor: "#434456",
        showYAxisValues: "1",
        showDivLine: "1",
        yAxisMinValue: "100",
        yAxisMaxValue: "1000",
        // placeValuesInside: "0",
        // showLegend: "0",
        // showShadow: "0",
        // paletteColors: "#62B58F",
        // drawAnchors: "0",
        // showAlternateHGridColor: "0",
        // drawCrossLine: "0",
        // plotColorinTooltip: "0",
        // baseFontColor: "#4e24e2",
        // valueFontColor: "#b4b4b6",
        // valueFontSize: "14",
        // baseFontSize: "12",
        // baseFont: "Roboto",
        // toolTipBgColor: "#525357",
        // toolTipColor: "#F8F8F8",
        // toolTipBorderColor: "#525357",
        // toolTipBorderAlpha: "20",
        // showToolTipShadow: "1",
        // toolTipPadding: "10",
        numberPrefix: "$",
        formatNumberScale: "1",
        numberScaleUnit: "B",
        numberScaleValue: "100",
        plotToolText:
          "<b>Global Revenue</b></br><b>Year : </b>$label</br><b>Revenue : </b> $dataValue",
      },
      data: [
        {
          label: "Aug",
          value: "203",
        },
        {
          label: "Sep",
          value: "814",
        },
        {
          label: "Oct",
          value: "414",
        },
        {
          label: "Nov",
          value: "614",
        },
      ],
    };
    const column2d: DataSourceType = {
      chart: {
        canvasBgColor: "#1D1B41",
        showBorder: "0",
        divLineAlpha: "0",
        animation: "0",
        showCanvasBorder: "0",
        showAlternateHGridColor: "0",
        bgColor: "#1D1B41",
        bgAlpha: "0",
        showValues: "1",
        canvasBgAlpha: "0",
        baseFontSize: "14",
        baseFont: "Nunito Sans Regular",
        baseFontColor: "#000000",
        divLineThickness: "2",
        numberPrefix: "$",
        showLimits: "0",
        showDivLineValues: "0",
        paletteColors: "#58E2C2",
        usePlotGradientColor: "0",
        divLineColor: "#979797",
        divLineDashed: "1",
        divLineDashLen: "5",
        valueFontSize: "15",
        canvasRightMargin: "200",
        canvasLeftMargin: "35",
        // canvasBottomMargin: "60",
        // canvasTopMargin: "60",
        placeValuesInside: "0",
        toolTipBgcolor: "#3AAC99",
        toolTipPadding: "7",
        toolTipBorderRadius: "3",
        toolTipBorderAlpha: "30",
        toolTipBorderThickness: "0.7",
        toolTipColor: "#000000",
        yAxisMaxValue: "280",
        transposeAnimation: "1",
        showLegend: "1",
        legendPosition: "bottom"
      },

      annotations: {
        autoscale: "1",
        groups: [
          {
            items: [
              {
                id: "indicator",
                type: "text",
                text: "▲ 5.42%",
                color: "#E8506B",
                fontSize: "30",
                x: "$canvasEndX + 100",
                y: "$canvasEndY - 200",
              },
              {
                id: "indicator",
                type: "text",
                text: "INCREASE IN COST",
                color: "#E8506B",
                fontSize: "14",
                x: "$canvasEndX + 100",
                y: "$canvasEndY - 150",
              },
            ],
          },
        ],
      },

      data: [
        {
          label: "Aug",
          value: "203",
          alpha: "55",
        },
        {
          label: "Sep",
          value: "214",
        },
      ],
    };
    const worddata = {
      items: [
        {
          id: "lon",
          x: "340.23",
          y: "125.9",
          label: "LHR",
          value: "16.0",

          tooltext:
            "Heathrow International Airport {br} Total Passengers: $value M{br} IACL Code : EGLL",
        },
        {
          id: "atl",
          x: "160.14",
          y: "164.9",
          label: "ATL",
          value: "21.6",
          tooltext:
            "Hartsfield Jackson Atlanta International Airport {br} Total Passengers: $value M{br} IACL Code : KATL",
        },
        {
          id: "dub",
          x: "458.14",
          y: "203.9",
          label: "DXB",
          value: "18.3",
          tooltext:
            "Dubai International Airport {br} Total Passengers: $value M{br} IACL Code : OMDB",
        },
        {
          id: "tok",
          x: "628.14",
          y: "165.9",
          label: "HND",
          value: "16.6",
          tooltext:
            "Tokyo Haneda Airport {br} Total Passengers: $value M{br} IACL Code : RJTT",
          labelpos: "bottom",
        },
        {
          id: "beij",
          x: "573.14",
          y: "161.9",
          label: "PEK",
          value: "20.5",
          tooltext:
            "Beijing Capital International Airport {br} Total Passengers: $value M{br} IACL Code : ZBAA",
        },
      ],
    }
    const world: DataSourceType = {
      chart: {
        // bgColor: "#282A2E",
        // canvasBgColor: "#282A2E",
        showMarkerLabels : "1",
        showValues: "1",
        // animation: "0",
        // divLineAlpha: "0",
        // formatNumberScale: "0",
        // baseFontSize: "12",
        // baseFontColor: "#81809C",
        // legendItemFontSize: "12",
        // baseFont: "Roboto",
        // showValue: "0",
        // showBorder: "1",
        // borderAlpha: "80",
        // fillAlpha: "100",
        // borderColor: "#5F636E",
        // borderThickness: "1",
        // showHoverEffect: "1",
        // showEntityHoverEffect: "1",
        // labelFontColor: "#81809C",
        // legenditemfontcolor: "#ffffff",
        // toolTipBgColor: "#525357",
        // toolTipColor: "#F8F8F8",
        // toolTipBorderColor: "#525357",
        // toolTipBorderAlpha: "20",
        // showToolTipShadow: "1",
        // toolTipPadding: "10",
        // legendBorderThickness: "5",
        // entityToolText:
        //   "<b>Region : </b>$lname</br><b> Subscribers : </b>$dataValue",
        markerBgColor: "#3399FF",
        theme: "fusion",
        plotToolText:"",
      },
      markers: {
        items: [
          {
            id: "lon",
            shapeid: "circle",
            x: "340.23",
            y: "125.9",
            label: "LHR",
            value: "16.0",

            tooltext:
              "Heathrow International Airport {br} Total Passengers: $value M{br} IACL Code : EGLL",

            labelpos: "left",
          },
          {
            id: "atl",
            shapeid: "circle",
            x: "160.14",
            y: "164.9",
            label: "ATL",
            value: "21.6",
            tooltext:
              "Hartsfield Jackson Atlanta International Airport {br} Total Passengers: $value M{br} IACL Code : KATL",
            labelpos: "bottom",
          },
          {
            id: "dub",
            shapeid: "circle",
            x: "458.14",
            y: "203.9",
            label: "DXB",
            value: "18.3",
            tooltext:
              "Dubai International Airport {br} Total Passengers: $value M{br} IACL Code : OMDB",
            labelpos: "bottom",
          },
          {
            id: "tok",
            shapeid: "circle",
            x: "628.14",
            y: "165.9",
            label: "HND",
            value: "16.6",
            tooltext:
              "Tokyo Haneda Airport {br} Total Passengers: $value M{br} IACL Code : RJTT",
            labelpos: "bottom",
          },
          {
            id: "beij",
            shapeid: "circle",
            x: "573.14",
            y: "161.9",
            label: "PEK",
            value: "20.5",
            tooltext:
              "Beijing Capital International Airport {br} Total Passengers: $value M{br} IACL Code : ZBAA",
            labelpos: "bottom",
          },
        ],
      },
    };
    const bar2d: DataSourceType = {
        chart: {
            bgColor: "#282A2E",
            paletteColors: "#C79E24",
            divLineAlpha: "0",
            canvasBgColor: "#282A2E",
            labelFontColor: "#ffffff",
            theme: "fusion",
            showDivLineValues: "1",
            showValues: "1",
            baseFont: "Roboto",
            valueFontColor: "#b4b4b6",
            plotSpacePercent: "60",
            axisLineAlpha: "80",
            showYAxisValues: "1",
            yaxislinecolor: "#434456",
            showYAxisLine: "1",
            baseFontColor:"#ffffff",
            baseFontSize: "12",
            toolTipBgColor: "#525357",
            toolTipColor: "#F8F8F8",
            toolTipBorderColor: "#525357",
            toolTipBorderAlpha: "20",
            showToolTipShadow: "1",
            toolTipPadding: "10",
            anchorRadius: "60",
            plotToolText: "<b>$seriesName<b></br><b>Genre :</b> $label</br><b>Rating :</b> $Value"
        },
        data: [{
            label: "Action",
            value: "8.4"
          },
          {
            label: "Animation",
            value: "8.5"
          },
          {
            label: "Biography",
            value: "8.8"
          },
          {
            label: "Comedy",
            value: "7"
          },
          {
            label: "Documentary",
            value: "8.6"
          },
          {
            label: "Drama",
            value: "8.5"
          },
          {
            label: "Reality-TV",
            value: "8.4"
          },
          {
            label: "Thriller",
            value: "8.2"
          }
        ]
  
    }
    const pie2d: DataSourceType = {
      chart: {
        // caption: "Split of revenue by product categories",
        // subcaption: "Last year",
        numberPrefix: "$",
        showPercentInTooltip: "0",
        decimals: "1",
        //Theme
        theme: "fusion",
        enableSmartLabels: "0",
        showLabels: "0",
        showValues: "0",
        toolTipBorderRadius: "20",
        legendPosition: "right",
        plotToolText:
          "<b>Genre :</b> $label</br><b>Rating :</b> $Value",
        //smart lines customization
        // smartLineColor: "#ff0000",
        // smartLineThickness: "2",
        // smartLineAlpha: "100",
        // isSmartLineSlanted: "0",
      },
      data: [
        {
          label: "Action",
          value: "8.4",
        },
        {
          label: "Animation",
          value: "8.5",
        },
        {
          label: "Biography",
          value: "8.8",
        },
        {
          label: "Comedy",
          value: "7",
        },
        {
          label: "Documentary",
          value: "8.6",
        },
        {
          label: "Drama",
          value: "8.5",
        },
        {
          label: "Reality-TV",
          value: "8.4",
        },
        {
          label: "Thriller",
          value: "8.2",
        },
      ],
    };
    const doughnut2d: DataSourceType = {
      chart: {
        // bgColor: "#282A2E",
        pieRadius: "50",
        doughnutRadius: "40",
        // paletteColors: "#D9D4F6,#8E81E3,#6957DA",
        // canvasBgColor: "#282A2E",
        // plotBorderalpha: "0",
        // divLineAlpha: "0",
        // showDivLineValues: "0",
        // animation: "0",
        theme: "gammel",
        // enableSlicing: "0",
        enableSmartLabels: "0",
        // showHoverEffect: "0",
        // plotHoverEffect: "0",
        // enableRotation: "0",
        // plotBorderHoverAlpha: "0",
        // showLegend: "0",
        // startingAngle: "90",
        // valueFontColor: "#ffffff",
        // labelFontColor: "#ffffff",
        // smartLineColor: "#81809C",
        // baseFontSize: "12",
        // baseFontColor: "#61DAFB",
        // toolTipBgColor: "#525357",
        // toolTipColor: "#F8F8F8",
        // toolTipBorderColor: "#525357",
        // toolTipBorderAlpha: "20",
        // showToolTipShadow: "1",
        // toolTipPadding: "10",
        plotToolText:
          "<b>Age Group :</b> $label</br> <b>Subscribers :</b> $datavalue%",
      },
      data: [
        {
          label: "50-64 Years",
          value: "32",
        },
        {
          label: "30-49 Years",
          value: "36",
        },
        {
          label: "18-29 Years",
          value: "32",
        },
      ],
    };
    const boxandwhisker2ddata = {
      data: [
        {
          label: "2010",
          value:
            "12297, 12819, 14632, 14184, 14922, 14273, 13955, 14709, 13520, 14387",
          // outliers: "18415, 26642"
        },
        {
          label: "2011",
          value:
            "12857, 14178, 14513, 14485, 14719, 13901, 14675, 13597, 14263",
          // outliers: "11895, 18035, 26243"
        },
        {
          label: "2012",
          value:
            "11428, 13165, 14445, 13576, 14108, 13908, 13145, 14710, 13124, 13485",
          // outliers: "17615, 24604"
        },
        {
          label: "2013",
          value:
            "11313, 12047, 13927, 12474, 13944, 13251, 12594, 14139, 12500, 13309",
          // outliers: "16882, 23814"
        },
        {
          label: "2014",
          value:
            "10579, 11583, 13223, 13055, 13970, 12957, 12670, 14113, 12111, 13150, 16804",
          // outliers: "23853"
        },
        {
          label: "2015",
          value:
            "10797, 11231, 13178, 12349, 13692, 12561, 12555, 13653, 12023, 12936",
          // outliers: "16301, 23425"
        },
        {
          label: "2016",
          value:
            "10221, 11208, 12648, 11925, 12536, 12308, 11836, 12628, 11355, 11945",
          // outliers: "17473, 23962"
        }
      ]
    }
    const boxandwhisker2d: DataSourceType = {
      chart: {
        caption: "Annual Retail Industry Sales Distribution for US",
        subcaption: "2010-2016",
        YAxisName: "Sales (in million $)",
        yAxisMaxValue: "28000",
        paletteColors: "#5D62B5, #979AD0",
        yAxisMinValue: "9000",
        theme: "fusion",
        showLegend: "0",
        plotSpacePercent: "55",
        showalloutliers: "1",
        outliericonsides: "20",
        outliericonalpha: "40",
        outliericonshape: "triangle",
        outliericonradius: "4",
        mediancolor: "#FFFFFF",
        plotToolText:
          "<b>Sales for $label:</b><br>Max: <b>$maxDataValue million</b><br>Q3: <b>$Q3 million</b><br>Median: <b>$median million</b><br>Q1: <b>$Q1 million</b><br>Min: <b>$minDataValue million</b>"
      },
      categories: [
        {
          category: [
            {
              label: "2010"
            },
            {
              label: "2011"
            },
            {
              label: "2012"
            },
            {
              label: "2013"
            },
            {
              label: "2014"
            },
            {
              label: "2015"
            },
            {
              label: "2016"
            }
          ]
        }
      ],
      dataset: [
        {
          seriesname: "US",
          data: [
            {
              value:
                "12297, 12819, 14632, 14184, 14922, 14273, 13955, 14709, 13520, 14387",
              // outliers: "18415, 26642"
            },
            {
              value:
                "12857, 14178, 14513, 14485, 14719, 13901, 14675, 13597, 14263",
              // outliers: "11895, 18035, 26243"
            },
            {
              value:
                "11428, 13165, 14445, 13576, 14108, 13908, 13145, 14710, 13124, 13485",
              // outliers: "17615, 24604"
            },
            {
              value:
                "11313, 12047, 13927, 12474, 13944, 13251, 12594, 14139, 12500, 13309",
              // outliers: "16882, 23814"
            },
            {
              value:
                "10579, 11583, 13223, 13055, 13970, 12957, 12670, 14113, 12111, 13150, 16804",
              // outliers: "23853"
            },
            {
              value:
                "10797, 11231, 13178, 12349, 13692, 12561, 12555, 13653, 12023, 12936",
              // outliers: "16301, 23425"
            },
            {
              value:
                "10221, 11208, 12648, 11925, 12536, 12308, 11836, 12628, 11355, 11945",
              // outliers: "17473, 23962"
            }
          ]
        }
      ]
    };
    const heatmap: DataSourceType = {
      chart: {
        caption: "Top Smartphone Ratings",
        subcaption: "By Features",
        xAxisName: "Features",
        YAxisName: "Model",
        showPlotBorder: "1",
        xAxisLabelsOnTop: "1",
        plotToolText:
          "<div id='nameDiv' style='font-size: 12px; border-bottom: 1px dashed #666666; font-weight:bold; padding-bottom: 3px; margin-bottom: 5px; display: inline-block; color: #888888;' >$rowLabel :</div>{br}Rating : <b>$dataValue</b>{br}$columnLabel : <b>$tlLabel</b>{br}<b>$trLabel</b>",

        //Cosmetics
        showValues: "0",
        showBorder: "0",
        // bgColor: "#ffffff",
        showShadow: "0",
        usePlotGradientColor: "0",
        // toolTipColor: "#ffffff",
        toolTipBorderThickness: "0",
        toolTipBgColor: "#000000",
        toolTipBgAlpha: "80",
        toolTipBorderRadius: "2",
        toolTipPadding: "5",
        theme: "fusion",
      },
      dataset: [
        {
          data: [
            {
              rowid: "Samsung Galaxy S5",
              columnid: "Processor",
              value: "8.7",
              tllabel: "Quad Core 2.5 GHz",
              trlabel: "OS : Android 4.4 Kitkat",
            },
            {
              rowid: "Samsung Galaxy S5",
              columnid: "Screen Size",
              value: "8.5",
              tllabel: "5.1 inch",
              trlabel: "AMOLED screen",
            },
            {
              rowid: "Samsung Galaxy S5",
              columnid: "Price",
              value: "9.3",
              tllabel: "$600",
            },
            {
              rowid: "Samsung Galaxy S5",
              columnid: "Battery Backup",
              value: "9.7",
              tllabel: "29 Hrs",
              trlabel: "Battery : 2800 MAH",
            },
            {
              rowid: "Samsung Galaxy S5",
              columnid: "Camera",
              value: "8",
              tllabel: "16 MP",
              trlabel: "Front Camera : 2.1 MP",
            },
            {
              rowid: "HTC One (M8)",
              columnid: "Processor",
              value: "9.2",
              tllabel: "Quad Core 2.3 GHz",
              trlabel: "OS : Android 4.4 Kitkat",
            },
            {
              rowid: "HTC One (M8)",
              columnid: "Screen Size",
              value: "8.3",
              tllabel: "5 inch",
              trlabel: "LCD screen",
            },
            {
              rowid: "HTC One (M8)",
              columnid: "Price",
              value: "7.3",
              tllabel: "$600",
            },
            {
              rowid: "HTC One (M8)",
              columnid: "Battery Backup",
              value: "8.8",
              tllabel: "20 Hrs",
              trlabel: "Battery : 2600 MAH",
            },
            {
              rowid: "HTC One (M8)",
              columnid: "Camera",
              value: "8.7",
              tllabel: "4 MP",
              trlabel: "Front Camera : 5 MP",
            },
            {
              rowid: "Apple iPhone 5S",
              columnid: "Processor",
              value: "9.1",
              tllabel: "Dual Core",
              trlabel: "OS : iOS 7",
            },
            {
              rowid: "Apple iPhone 5S",
              columnid: "Screen Size",
              value: "8.6",
              tllabel: "4 inch",
              trlabel: "Retina LCD screen",
            },
            {
              rowid: "Apple iPhone 5S",
              columnid: "Price",
              value: "7.2",
              tllabel: "$649",
            },
            {
              rowid: "Apple iPhone 5S",
              columnid: "Battery Backup",
              value: "8.4",
              tllabel: "10 Hrs",
              trlabel: "Battery : 1560 MAH",
            },
            {
              rowid: "Apple iPhone 5S",
              columnid: "Camera",
              value: "9.5",
              tllabel: "8 MP",
              trlabel: "Front Camera : 1.2 MP",
            },
            {
              rowid: "Nokia Lumia 1520",
              columnid: "Processor",
              value: "8.8",
              tllabel: "Quad Core 2.2 GHz",
              trlabel: "OS: Windows Phone 8",
            },
            {
              rowid: "Nokia Lumia 1520",
              columnid: "Screen Size",
              value: "9.1",
              tllabel: "6 inch",
              trlabel: "LCD screen",
            },
            {
              rowid: "Nokia Lumia 1520",
              columnid: "Price",
              value: "9.7",
              tllabel: "$470",
            },
            {
              rowid: "Nokia Lumia 1520",
              columnid: "Battery Backup",
              value: "9.2",
              tllabel: "27 Hrs",
              trlabel: "Battery : 3400 MAH",
            },
            {
              rowid: "Nokia Lumia 1520",
              columnid: "Camera",
              value: "8.1",
              tllabel: "20MP",
              trlabel: "Front Camera : 1.2 MP",
            },
          ],
        },
      ],
      colorrange: {
        gradient: "0",
        minvalue: "0",
        code: "E24B1A",
        startlabel: "Poor",
        endlabel: "Good",
        color: [
          {
            code: "E24B1A",
            minvalue: "1",
            maxvalue: "5",
            label: "Bad",
          },
          {
            code: "F6BC33",
            minvalue: "5",
            maxvalue: "8.5",
            label: "Average",
          },
          {
            code: "6DA81E",
            minvalue: "8.5",
            maxvalue: "10",
            label: "Good",
          },
        ],
      },
    };
    const funnel: DataSourceType = {
      chart: {
        caption: "Website - Harry's SuperMart",
        subcaption: "Visit to purchase - Conversion analysis for last year",
        decimals: "1",
        isHollow: "1",
        labelDistance: "15",
        is2D: "1",
        plotToolText: "Success : $percentOfPrevValue",
        showPercentValues: "1",
        theme: "fusion",
      },
      data: [
        {
          label: "Unique Website Visits",
          value: "1460000",
        },
        {
          label: "Programme Details Section Visits",
          value: "930000",
        },
        {
          label: "Attempts to Register",
          value: "540000",
        },
        {
          label: "Successful Registrations",
          value: "210000",
        },
        {
          label: "Logged In",
          value: "190000",
        },
        {
          label: "Purchased on Introductory Offers",
          value: "120000",
        },
      ],
    };
    const getChartDataSource = (chart: string) => {
        if (chart === 'area2d'){
            return area2d;
        }else if (chart === 'mscombi2d'){
            return mscombi2d;
        } else if ( chart === 'column2d') {
            return column2d;
        }else if (chart === 'world') {
            return world;
        }else if (chart === 'bar2d') {
            return bar2d;
        }else if (chart === 'doughnut2d') {
            return doughnut2d;
        }else if (chart === 'pie2d') {
            return pie2d;
        }else if (chart === 'boxandwhisker2d') {
            return boxandwhisker2d;
        }else if (chart === 'funnel') {
            return funnel;
        }else if (chart === 'heatmap') {
            return heatmap;
        }else {
            return area2d;
        }
    }
    useEffect(() => {
        setChartConfig({...chartConfig, type: currentChart?.code, dataSource: getChartDataSource(currentChart?.code)})
    }, [currentChart]);

    useEffect(() => {
      const {chart, annotations, ...showRawData} = {...chartConfig.dataSource};
      if(currentChart?.code === 'boxandwhisker2d'){
        setShowData(boxandwhisker2ddata);
      }else if(currentChart?.code === 'mscombi2d'){
        setShowData(mscombi2ddata);
      }else if(currentChart?.code === 'world'){
        setShowData(worddata);
      }else if(currentChart?.code === 'heatmap'){
        setShowData(chartConfig.dataSource.dataset?.[0]);
      }else{
        setShowData(showRawData);
      }
    }, [chartConfig, currentChart]);

    // useEffect(() => {
    //     console.log('chartConfig',chartConfig);
    // }, [chartConfig]);

    
  return (
    <>
        <h4>Chart Examples</h4>
        <Dropdown optionLabel="name" value={currentChart} options={ChartOptions} onChange={(e) => setCurrentChart(e.value)} placeholder="Select Chart"/>
        <div className='row mt-2'>
          <div className='col'>
            <h4> Chart </h4>
            <ChartComponent chartConfigs={chartConfig}/>
            {/* <MapComponent/> */}
          </div>
          <div className='col overflow-hidden'>
            <h4> Chart Data </h4>
            <div className='overflow-auto h-50'>
              <pre>
                {JSON.stringify(showData , undefined, 2)}
              </pre>
            </div>
          </div>
        </div>
    </>
  )
}

export default ChartExampleComponent