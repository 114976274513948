import { Fragment } from "react";
import './CampaignOptimizationSummary.scss';
import {
    mai_usericon_solid_blue,
    mai_cohort_indicator_icon,
    mai_revenue_icon,
    mai_total_revenue,
    mai_question_icon,
    mai_configure_icon,
    mai_email_blue_icon,
    mai_download_icon,
    mai_product_engagement_icon,
    mai_clv_industry,
    mai_geography_map_dummy,
    mai_search_bar,
    mai_export_icon,
    mai_arrow_down_blue,
    mai_time_range,
    mai_product_icon
}
    from '../../../../../../assets/images/index';
import { NavLink } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const CampaignOptimizationSummaryComponent = () => {
    const recomendationlist = [
        { "customers": "1000", "createdby": "AJ", "name": "Recommendations", "description": "Product Description", "sharedwith": "user.png", "lastupdated": "26/06/22", "status": "Published", "actions": 5 },
        { "customers": "1001", "createdby": "GJ", "name": "Recommendations 1", "description": "Product Description", "sharedwith": "black-watch.jpg", "lastupdated": "24/06/22", "status": "Draft", "actions": 4 },
        { "customers": "1002", "createdby": "GH", "name": "Recommendations 2", "description": "Product Description", "sharedwith": "blue-band.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 },
        { "customers": "1003", "createdby": "AJ", "name": "Recommendations 3", "description": "Product Description", "sharedwith": "blue-t-shirt.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 5 },
        { "customers": "1004", "createdby": "AJ", "name": "Recommendations 4", "description": "Product Description", "sharedwith": "bracelet.jpg", "price": 15, "lastupdated": "12/06/22", "status": "Published", "actions": 4 },
        { "customers": "1005", "createdby": "PJ", "name": "Recommendations 5", "description": "Product Description", "sharedwith": "brown-purse.jpg", "lastupdated": "19/06/22", "status": "Draft", "actions": 4 },
        { "customers": "1006", "createdby": "DK", "name": "Recommendations 6", "description": "Product Description", "sharedwith": "chakra-bracelet.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 },
        { "customers": "1007", "createdby": "MM", "name": "Recommendations 7", "description": "Product Description", "sharedwith": "galaxy-earrings.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 5 },
        { "customers": "1008", "createdby": "VM", "name": "Recommendations 8", "description": "Product Description", "sharedwith": "game-controller.jpg", "lastupdated": "22/06/22", "status": "Published", "actions": 4 },
        { "customers": "1009", "createdby": "CM", "name": "Recommendations 9", "description": "Product Description", "sharedwith": "gaming-set.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 }
    ]

    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>Input Parameters</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Summary</li>
                </ul>
            </div>

            <div className="campaignoptimization-summarywrap">
                <div className="clientscohort">
                    <div className="row mt-4">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h1><img src={mai_usericon_solid_blue} alt="user" className="img-fluid" /> Clients Cohort Converted</h1>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                            <h2><span> 68</span>/340</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12"></div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="cohort-perWrap">
                                <div className="cohort-perInner text-center">
                                    20%
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <img src={mai_cohort_indicator_icon} alt="indicator" className="img-fluid" />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-lg-4 col-md-4 col-sm-12"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                            <div className="cohortSummary">
                                Summary based on the cohort conversion
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12"></div>
                    </div>

                </div>

                <div className="summaryWrapDesc">
                    <div className="row mb-3">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="customer__segment-chart boxshadow d-block p-3">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h2 className='inner__heading'><img src={mai_revenue_icon} alt="users" className='img-fluid' /> <span> Revenue</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                                        <p className="increaserate d-inline me-2">
                                            $1.23M <span>+23%</span>
                                        </p>
                                        <div className="dropdown industries-dropdown d-inline">
                                            <NavLink className="" to="#">
                                                <span className="pi pi-ellipsis-v dots3"></span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="customer_chart">
                                    <img src={mai_total_revenue} alt="revenue" className="img-fluid w-100" />
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="customer__segment-chart boxshadow d-block p-3">
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <h2 className='inner__heading'><img src={mai_product_icon} alt="product" className='img-fluid' /> <span> Product Usage</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                                        <p className="increaserate d-inline me-2">
                                            $1.23M <span>+23%</span>
                                        </p>
                                        <div className="dropdown industries-dropdown d-inline">
                                            <NavLink className="" to="#">
                                                <span className="pi pi-ellipsis-v dots3"></span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="customer_chart">
                                    <img src={mai_total_revenue} alt="revenue" className="img-fluid w-100" />
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">

                            <div className="insights__innerwrapper dropdownmain-wrapper-end mb-3">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <h2 className='inner__heading'><img src={mai_product_engagement_icon} alt="email" className="img-fluid" /> <span> Average Tenure</span> </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className='insights-data'>
                                            <div>
                                                <div className='innertext__size'>
                                                    <h2 className='blueshade'>1 Y <span className="increase_horizontal">+4%</span></h2>
                                                    <p>FY2020-2022</p>
                                                </div>
                                            </div>
                                            <div></div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="insights__innerwrapper dropdownmain-wrapper-end">
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-12">
                                        <h2 className='inner__heading'><img src={mai_product_engagement_icon} alt="email" className="img-fluid" /> <span> Product Engagement</span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h2>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 text-end">
                                        <div className="dropdown industries-dropdown dropdownmenu-wrapper">
                                            <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="pi pi-ellipsis-v dots3"></span>
                                            </a>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#"><img src={mai_configure_icon} alt="configure" className="img-fluid" /> Configure</a></li>
                                                <li><a className="dropdown-item" href="#"><img src={mai_download_icon} alt="download" className="img-fluid" /> Download</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <ul className="list-inline insights-data">
                                            <li className="list-inline-item">
                                                <div className='innertext__size'>
                                                    <h2>45 days</h2>
                                                    <p>Avg. Order Gap</p>
                                                </div>
                                            </li>
                                            <li className="list-inline-item">
                                                <div className='innertext__size'>
                                                    <h2>$ 500 <span className="increase_horizontal">+3%</span></h2>
                                                    <p>Avg. Order Value </p>
                                                </div>
                                            </li>
                                            <li className="list-inline-item">
                                                <div className='innertext__size'>
                                                    <h2>2 <span className="decrease_horizontal">-2%</span></h2>
                                                    <p>Avg. Order Frequency</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="insights__innerwrapper">
                                <img src={mai_clv_industry} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="insights__innerwrapper">
                                <img src={mai_geography_map_dummy} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="audiencecreate__form audiencelist boxshadow p-3">
                                <div className="row mb-3">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <h2 className='inner__heading'><span> Customers</span> </h2>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="rules__searchbox">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend border-0">
                                                            <button
                                                                id="button-addon4"
                                                                type="button"
                                                                className="btn btn-link text-info"
                                                            >
                                                                <img
                                                                    src={mai_search_bar}
                                                                    alt="search"
                                                                    className="img-fluid"
                                                                />
                                                            </button>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-describedby="button-addon4"
                                                            className="form-control border-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className='audiencelist__select'>
                                                    <label htmlFor="createdby">Show</label>
                                                    <select className="form-select w-auto" id="createdby" defaultValue={'Lease expiry &lt; 6months'}>
                                                        <option>Lease expiry &lt; 6 months</option>
                                                        <option value="Lease expiry &lt; 6 months">Lease expiry &gt; 6 months</option>
                                                        <option value="Lease expiry &lt; 7 months">Lease expiry &lt; 7 months</option>
                                                        <option value="Lease expiry &lt; 8 months">Lease expiry &lt; 8 months</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 text-end">
                                                <div className="heading-button segment-button">
                                                    <NavLink to="#" data-bs-toggle="modal" data-bs-target="#customerprofile"><img src={mai_export_icon} alt="export" className="img-fluid" /> Export</NavLink>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div className="audiencedetails__tabs">
                                            <div className="tab-content py-0">
                                                <div className="audiencedetails__insight-topheading">
                                                    <div className="audiencedetails_calendar">
                                                        <label htmlFor="minmax">Show</label>
                                                        <input type="text" placeholder="Feb 2019 - Jan 2022" data-bs-toggle="modal" data-bs-target="#calendarpopup" readOnly />
                                                        <img src={mai_arrow_down_blue} alt="arrow" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}




                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="card audiencelist-datatable border-0">
                                        <DataTable value={recomendationlist} responsiveLayout="scroll">
                                            <Column field="name" header="Name"></Column>
                                            <Column field="customers" header="customers"></Column>
                                            <Column field="description" header="description"></Column>
                                            <Column field="createdby" header="createdby"></Column>
                                            <Column field="sharedwith" header="sharedwith"></Column>
                                            <Column field="lastupdated" header="lastupdated"></Column>
                                            <Column field="status" header="status"></Column>
                                            <Column field="actions" header="actions"></Column>
                                        </DataTable>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Calendra Popup */}
            <div className="modal fade calendar-popup" id="calendarpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Time</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="row align-items-center">
                                        <div className="col-auto pe-0">
                                            <label htmlFor="from">From</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="from" placeholder="2020" />
                                        </div>
                                        <div className="col-auto pe-0">
                                            <label htmlFor="to">To</label>
                                        </div>
                                        <div className="col-auto">
                                            <input type="text" className="form-control" id="to" placeholder="2022" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12">
                                    <div className="selected-period">
                                        <p>Selected period <span>Feb 2020- Jan 2021</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                    <img src={mai_time_range} alt="time range" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mai-cancel-button" data-bs-dismiss="modal">Reset</button>
                            <button type="button" className="btn btn-primary mai-next-button">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default CampaignOptimizationSummaryComponent;