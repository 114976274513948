import React, { Fragment, useState } from "react";
import './SpendOptimizationList.scss';
import { Dropdown } from "primereact/dropdown";
import { NavLink } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Url } from '../../../../../models/constants';

import {
    mai_search_bar,
    mai_plus,
    mai_noscenarios_created
} from '../../../../../assets/images/index';

const SpendOptimizationListComponent = () => {
    const [isListCount, setisListCount] = useState<number>(0);
    const data = [
        { "createdby": "AJ", "name": "EMEA Region", "description": "Product Description", "sharedwith": "user.png", "lastupdated": "26/06/22", "status": "Published", "actions": 5 },
        { "createdby": "GJ", "name": "North America", "description": "Product Description", "sharedwith": "black-watch.jpg", "lastupdated": "24/06/22", "status": "Draft", "actions": 4 },
        { "createdby": "GH", "name": "Social Media Marketing", "description": "Product Description", "sharedwith": "blue-band.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 3 },
        { "createdby": "AJ", "name": "EMEA Region", "description": "Product Description", "sharedwith": "blue-t-shirt.jpg", "lastupdated": "15/06/22", "status": "Published", "actions": 5 },
        { "createdby": "AJ", "name": "North America", "description": "Product Description", "sharedwith": "bracelet.jpg", "price": 15, "lastupdated": "12/06/22", "status": "Published", "actions": 4 },
        { "createdby": "PJ", "name": "Social Media Marketing", "description": "Product Description", "sharedwith": "brown-purse.jpg", "lastupdated": "19/06/22", "status": "Draft", "actions": 4 }
    ]

    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const onStatusChange = (e: { value: any }) => {
        setSelectedStatus(e.value);
    }
    const status = [
        { name: 'Published', code: 'Published' },
        { name: 'Draft', code: 'Draft' }
    ];
    const [createdBy, setSelectedCreatedBy] = useState<any>(null);
    const onCreatedByChange = (e: { value: any }) => {
        setSelectedCreatedBy(e.value);
    }
    const createdby = [
        { name: 'Dharmendra', code: 'Dharmendra' },
        { name: 'John', code: 'John' },
        { name: 'William', code: 'William' },
        { name: 'Nikhil', code: 'Nikhil' },
        { name: 'Parveen', code: 'Parveen' },
    ];



    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper'>
                <div className='audiencecreate__form audiencelist whiteboxlist-height'>

                    {!isListCount ?
                        <div className="noscenarios-created">
                            <img src={mai_noscenarios_created} alt="" className="img-fluid" />
                            <h2>No scenarios created yet!</h2>
                            <div className="welcome__create text-end">
                                <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.MARKETINGLENS_WORKFLOW}/${Url.SPEND_OPTIMIZATION}/${Url.SPEND_OPTIMIZATION_CREATE}/${Url.SPEND_OPTIMIZATION_CREATE_GENERAL}`} className="welcome__create-anchor"><img src={mai_plus} alt="plus-icon" className="img-fluid welcome__plus-icon" /> Create Scenarios</NavLink>
                            </div>
                        </div>
                        :
                        <>
                            <div className="row mb-3">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                            <div className="rules__searchbox">
                                                <div className="input-group">
                                                    <div className="input-group-prepend border-0">
                                                        <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                                    </div>
                                                    <input type="search" placeholder="Search" aria-describedby="button-addon4" className="form-control border-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-sm-12">
                                            <div className="selectlist-wrap">
                                                <div className='selectlist'>
                                                    <label>Created by</label>
                                                    <div className='monitoring-dropdownlist'>
                                                        <Dropdown value={createdBy} options={createdby} onChange={onCreatedByChange} optionLabel="name" placeholder="All" />
                                                    </div>
                                                </div>
                                                <div className='selectlist'>
                                                    <label>Status</label>
                                                    <div className='monitoring-dropdownlist'>
                                                        <Dropdown value={selectedStatus} options={status} onChange={onStatusChange} optionLabel="name" placeholder="All" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="welcome__create text-end">
                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.MARKETINGLENS_WORKFLOW}/${Url.SPEND_OPTIMIZATION}/${Url.SPEND_OPTIMIZATION_CREATE}/${Url.SPEND_OPTIMIZATION_CREATE_GENERAL}`} className="welcome__create-anchor"><img src={mai_plus} alt="plus-icon" className="img-fluid welcome__plus-icon" /> Create Scenarios</NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="card list-datatable audiencelist-datatable border-0">
                                    <DataTable value={data} responsiveLayout="scroll">
                                        <Column field="name" header="Name"></Column>
                                        <Column field="description" header="description"></Column>
                                        <Column field="createdby" header="createdby"></Column>
                                        <Column field="sharedwith" header="sharedwith"></Column>
                                        <Column field="lastupdated" header="lastupdated"></Column>
                                        <Column field="status" header="status"></Column>
                                        <Column field="actions" header="actions"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </Fragment>
    )
}
export default SpendOptimizationListComponent;