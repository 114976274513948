import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {  useParams } from 'react-router-dom';
import './ClvDetails.scss';
import TopCustomersComponent from "./TopCustomersComponent";
import GeographyComponent from "./GeographyComponent";
import IndustryComponent from "./IndustryComponent";
import SegmentsComponent from "./SegmentsComponent";
import PredictedClvChart from "./PredictedClvChart";
import CustomersComponent from "./customers/CustomersComponent";
import { StoreActions } from "../../../../../store/actions";
import ApiService from "../../../../../services/api.service";
import { Url } from "../../../../../models/constants";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../store/store";
import useHttp from "../../../../../hooks/use-http";

const ClvDetailsComponent = () => {
    const [selectedMatrix, setSelectedMatrix] = useState<{segment: string, rank: string, color: number | string}>({segment: "", rank: "", color: 0});
    const dispatch=useDispatch();
    const { sendRequest: getAudience } = useHttp();
    const params=useParams();
    const userDetails = useSelector<StoreState, any>(
        (state) => state.headerContent
      );
    const [audienceName, setAudienceName] = useState<string>("");
    const getMatrixColor = useMemo(() => {
        return [
            0,
            '#258049',
            '#69AA69',
            '#77B896',
            '#69AA69',
            '#77B896',
            '#E69864',
            '#77B896',
            '#E69864',
            '#BF3145'
        ];
    }, []);

    const matrixHandler = useCallback((segment: string, rank: string, colorIndex = 0) => {
        const prevSelectedMatrix = {...selectedMatrix};
        if (prevSelectedMatrix.segment !== segment || prevSelectedMatrix.rank !== rank) {
            setSelectedMatrix(prevState => ({...prevState, segment, rank, color: getMatrixColor[colorIndex]}));
        }
    },[getMatrixColor, selectedMatrix]);
    const headerTitleChangeHandler = (
        title: string,
        backUrl: string,
        share: string
      ) => {
        const payload = { title, backUrl, share };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
      };
      useEffect(() => {
        if (params.clvid) {
          const resHandler = (res: any) => {
            setAudienceName(res.clv.name);
          };
          getAudience(ApiService.getClvGeneralDetails(params.clvid), resHandler);
        }
      }, [getAudience, params.clvid]);
      useEffect(() => {
        if (audienceName && userDetails.title !== audienceName) {
            headerTitleChangeHandler( 
                audienceName,
                `/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}/${Url.CUSTOMER_LIFETIME_VALUE_LIST}`,
                "true"
            );
        }
      });

    
    
    return (
        <Fragment>
            <div className='audiencecreate__form-wrapper recomdsummary-wrapper customtabs-wrap p-0'>
                <div className="audiencedetails__tabs">
                    <ul className="nav sticky-top stickytopRecomd audiencecreate__steps">
                        <li className="nav-item">
                            <a className="nav-link active" id="segments-tab" data-bs-toggle="tab" data-bs-target="#segments" href="#">Insights</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="segmentpersonas-tab" data-bs-toggle="tab" data-bs-target="#segmentpersonas" href="#">Customers</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="tabContent">
                        <div className="tab-pane fade show active" id="segments" role="tabpanel" aria-labelledby="segments-tab">
                            <div className="row">
                                <PredictedClvChart selectedMatrix={selectedMatrix} matrixHandler={matrixHandler} />
                            </div>
                            <div className="row">
                                <GeographyComponent selectedMatrix={selectedMatrix} />
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <IndustryComponent selectedMatrix={selectedMatrix} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <SegmentsComponent selectedMatrix={selectedMatrix} />
                                </div>
                            </div>

                            <div className="row">
                                <TopCustomersComponent selectedMatrix={selectedMatrix} />
                            </div>


                        </div>
                        <div className="tab-pane fade" id="segmentpersonas" role="tabpanel" aria-labelledby="segmentpersonas-tab">
                            <CustomersComponent/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Save as Audience Modal pop up  */}
            <div className="modal fade audiencedetails__addkpimodal saveasaudiencePopup" id="saveasaudience" aria-labelledby="addkpiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title popup-title fs-5" id="addkpiModalLabel">Save as audience</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked1" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked1">
                                                Customers
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked2" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked2">
                                                Industry  Segment
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked3" />
                                            <label className="form-check-label" htmlFor="checked3">
                                                Payment Frequency
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked4" />
                                            <label className="form-check-label" htmlFor="checked4">
                                                Contact ID
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked5" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked5">
                                                Product number
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked6" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked6">
                                                BPN
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked7" />
                                            <label className="form-check-label" htmlFor="checked7">
                                                PBI Segment
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked8" />
                                            <label className="form-check-label" htmlFor="checked8">
                                                Contact Email
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked9" />
                                            <label className="form-check-label" htmlFor="checked9">
                                                Annual Sales
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked10" />
                                            <label className="form-check-label" htmlFor="checked10">
                                                Contract No.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked11" />
                                            <label className="form-check-label" htmlFor="checked11">
                                                No. of Employess
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked12" />
                                            <label className="form-check-label" htmlFor="checked12">
                                                Rep ID
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked13" defaultChecked />
                                            <label className="form-check-label" htmlFor="checked13">
                                                Industry
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked14" />
                                            <label className="form-check-label" htmlFor="checked14">
                                                Duns No.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked15" />
                                            <label className="form-check-label" htmlFor="checked15">
                                                Avg. email open rate
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="listof-kpi">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="checked16" />
                                            <label className="form-check-label" htmlFor="checked16">
                                                Rep Email
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filter Popup */}
            <div className="modal fade filter-popup recomd-filter" id="filterpopup" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Filters</h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <label htmlFor="countries" className="form-label">Recommended products</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <h2>Personas</h2>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                    <label htmlFor="countries" className="form-label">Industry</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <label htmlFor="countries" className="form-label">Segment</label>
                                    <input type="text" className="form-control mai-input" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mai-next-button">Save</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* Customer Profile Popup */}
            {/* <div className="modal fade customerprofilepop-wrap" id="customerprofile" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 popup-title" id="exampleModalLabel">Customer profile <NavLink to="#" className="ms-2"><img src={mai_share_icon} alt="share" className="img-fluid" /></NavLink></h1>
                            <button type="button" className="btn-close popup-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-4">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="customerprofilepic text-center">
                                        <img src={mai_customer_profile_pic} alt="profile pic" className="img-fluid" />
                                        <h3>Jack Wills Corp.</h3>
                                        <p>Onboarded in 2015</p>
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12">
                                    <div className="customerprofiledesc">
                                        <div className="row mb-3">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_products_icon} alt="products" className="img-fluid profilesicons" />
                                                    <div className="profiletitle profiletitlewidth">
                                                        <h4>Primary Products</h4>
                                                    </div>
                                                    <div className="profilesname">
                                                        <span className="color1">Ar</span>
                                                        <span className="color2">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_revenue_icon} alt="" className="img-fluid profilesicons" />
                                                    <div className="profiletitle profiletitlewidth">
                                                        <h4>Annual Revenue</h4>
                                                    </div>
                                                    <div className="profilesname">
                                                        <h2 className="totalrevenuenumber">$ 6k <p className="increase_horizontal">+7%</p></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_region_icon} alt="region" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>North-East, USA</h4>
                                                        <p className="subtitle">Region</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_industry_icon} alt="industry" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Manufacturing</h4>
                                                        <p className="subtitle">Industry</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_tenure_icon} alt="tenure" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>4 years</h4>
                                                        <p className="subtitle">Tenure</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_payment_method_icon} alt="payment method" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Monthly</h4>
                                                        <p className="subtitle">Payment method</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_lease_icon} alt="lease" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>Lease</h4>
                                                        <p className="subtitle">Contract type</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="custprofileblock">
                                                    <img src={mai_contract_expiry_date} alt="expiry" className="img-fluid profilesicons" />
                                                    <div className="profiletitle">
                                                        <h4>23/11/2023</h4>
                                                        <p className="subtitle">Contract expiry date</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <h2 className="inner__heading mb-2">
                                        <span>Product adoption path</span>
                                        <img src={mai_question_icon} alt="quest" className="img-fluid" />
                                    </h2>
                                    <div className="productsadoppathwrap custprofilebox">
                                        <img src={mai_prod_adoption_path_chart} alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className="inner__heading mb-2">
                                        <span>Existing revenue vs forecast</span>
                                        <img src={mai_question_icon} alt="quest" className="img-fluid" />
                                    </h2>
                                    <div className="productsadoppathwrap custprofilebox">
                                        <img src={mai_existing_revenue_chart} alt="" className="img-fluid" />
                                        <div className="custprofile-chartindicator">
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <img src={mai_existingrevenue_indicate} alt="existing revenue" className="img-fluid" />
                                                    <span> Existing revenue</span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <img src={mai_productforcast_indicate} alt="product" className="img-fluid" />
                                                    <span> Recommended product forecast</span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <img src={mai_currentprodforcast_indicate} alt="current product" className="img-fluid" />
                                                    <span> Current products forecast</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mai-next-button">Done</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    );
}

export default ClvDetailsComponent;

function getAudience(arg0: any, resHandler: (res: any) => void) {
    throw new Error("Function not implemented.");
}
