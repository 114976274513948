import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import './CustomTab.scss';

export interface TabMenuModel {
    label: string;
    disabled: boolean;
}
export type CustomTabProps = {
    mappingTabChangeEmitter: Function;
    valueTabChangeEmitter: Function;
    disabled?: boolean;
}
export const CustomTab = (props: CustomTabProps) => {
    const [activeMappingIndex, setActiveMappingIndex] = useState(0);
    const [activeValueIndex, setActiveValueIndex] = useState(0);
    const mappingTabs: TabMenuModel[] = [
        { label: 'Region', disabled:!!props.disabled },
        { label: 'Country', disabled:!!props.disabled }
    ];
    const valuesTabs: TabMenuModel[] = [
        { label: 'TAM', disabled:!!props.disabled },
        { label: 'SoW', disabled:!!props.disabled }
    ];
    const mappingTabChangeHandler = (event: any) => {
        props.mappingTabChangeEmitter(event, activeValueIndex);
    }
    const valueTabChangeHandler = (event: any) => {
        props.valueTabChangeEmitter(event, activeMappingIndex);
    }
    return (
        <Fragment>
            <div className="cutomtabswrapper">
                <div className='me-3'>
                    <TabMenu model={mappingTabs} activeIndex={activeMappingIndex} onTabChange={(e) => { setActiveMappingIndex(e.index); mappingTabChangeHandler(e)}}/>
                </div>
                <div>
                    <TabMenu model={valuesTabs} activeIndex={activeValueIndex} onTabChange={(e) => { setActiveValueIndex(e.index); valueTabChangeHandler(e) }}/>
                </div>
            </div>
        </Fragment>
    )
}
