import React, { Fragment, useCallback, useEffect, useState } from "react"
import { TabView, TabPanel } from 'primereact/tabview';
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import { mai_question_icon } from "../../../../../../assets/images";
import useHttp from "../../../../../../hooks/use-http";
import { useParams } from "react-router-dom";
import ApiService from "../../../../../../services/api.service";
import { FusionChartCountriesLabels } from "../../../../../../models/constants";
import { AudienceDataColumn } from "../../../../../../models/dataTemplate";
import Loader from "../../../../../../shared/components/loader/Loader";
import UtilService from "../../../../../../services/util.service";

const GeographyComponent = (props: any) => {
    const params = useParams();
    const { sendRequest: getAudienceInsightsProfile, loading: getAudienceInsightsProfileLoader } = useHttp();
    const [customerWorldmapChartConfig, setCustomerWorldmapChartConfig] = useState<any>({
        type: 'worldwithcountries',
        width: "100%",
        height: "16%",
        containerBackgroundOpacity: "0",
        dataFormat: 'json',
        dataSource: {
            chart: {
                "caption": "",
                "theme": "fusion",
                "formatNumberScale": "0",
                "chartLeftPadding": "0",
                "chartRightPadding": "0",
                "chartBottomPadding": "0",
                "chartTopPadding": "0",
                "showLegend": "0",
                "toolTipBgColor": "#444444",
                "toolTipColor": "#fff",
                "toolTipBorderColor": "#444444",
                "toolTipBorderRadius": "5",
                "chartLeftMargin": "0",
                "chartRightMargin": "0",
                "chartTopMargin": "0",
                "chartBottomMargin": "0",

            },
            "data": []
        }
    });
    const [revenueWorldmapChartConfig, setRevenueWorldmapChartConfig] = useState<any>({
        type: 'worldwithcountries',
        width: "100%",
        height: "16%",
        containerBackgroundOpacity: "0",
        dataFormat: 'json',
        dataSource: {
            chart: {
                "caption": "",
                "theme": "fusion",
                "formatNumberScale": "0",
                "numberPrefix": "$",
                "chartLeftPadding": "0",
                "chartRightPadding": "0",
                "chartBottomPadding": "0",
                "chartTopPadding": "0",
                "showLegend": "0",
                "toolTipBgColor": "#444444",
                "toolTipColor": "#fff",
                "toolTipBorderColor": "#444444",
                "toolTipBorderRadius": "5"
            },
            "data": []
        }
    });
    const audienceInsightsProfile = useCallback((groupBy: any) => {
        const filter: any = [];
        const group_by: any = [groupBy];
        const resHandler = (res: any) => {
            const _customerMap = res.response.profile.map((profile: any, index: number) => {
                return {
                    id: FusionChartCountriesLabels[profile.label] ? FusionChartCountriesLabels[profile.label] : '104',
                    value: profile.customer.toString(),
                    color: UtilService.returnColorSchema()[index >= 10 ? (index % 10) : index],
                    toolText: `$lName: ${UtilService.numberFormatter(Number(profile.customer))}`,
                }
            });
            const _revenueMap = res.response.profile.map((profile: any, index: number) => {
                return {
                    id: FusionChartCountriesLabels[profile.label] ? FusionChartCountriesLabels[profile.label] : '104',
                    value: UtilService.numberFormatter(Number(profile.revenue),'M'),
                    color: UtilService.returnColorSchema()[index >= 10 ? (index % 10) : index],
                    toolText: `$lName: ${UtilService.numberFormatter(Number(profile.revenue))}`,
                }
            });
            let cloneCustomerConfig = { ...customerWorldmapChartConfig };
            let cloneRevenueConfig = { ...revenueWorldmapChartConfig }
            cloneCustomerConfig.dataSource.data = [..._customerMap];
            cloneRevenueConfig.dataSource.data = [..._revenueMap];
            setCustomerWorldmapChartConfig(cloneCustomerConfig);
            setRevenueWorldmapChartConfig(cloneRevenueConfig)
        }
        if (props.region) {
            filter.push({field: AudienceDataColumn.REGION, label: "Region", value: {in: [props.region]}, operator: "null"});
        }
        if (props.start_date && props.end_date) {
            getAudienceInsightsProfile(ApiService.getAudienceInsightsProfile({ aid: params.aid, group_by, start_date: props.start_date, end_date: props.end_date, filter }), resHandler)
        }
    }, [getAudienceInsightsProfile, params.aid, props.end_date, props.region, props.start_date]);
    useEffect(() => {
        audienceInsightsProfile(AudienceDataColumn.GEOGRAPHY);
    }, [audienceInsightsProfile, props]);

    return (
        <Fragment>
            <div className="insights__innerwrapper customerRevenue customtabs-wrap">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <h2 className='inner__heading'><span> Geography </span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                        {/* <span className="pi pi-ellipsis-v dots3"></span> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="tabs-wrapper customtabs maptabview-wrap">
                            <TabView>
                                <TabPanel header="Customers">
                                    {getAudienceInsightsProfileLoader && !customerWorldmapChartConfig.dataSource.data.length ? <Loader style={{ minHeight: "300px" }} /> :
                                        <ChartComponent chartConfigs={customerWorldmapChartConfig} />
                                    }
                                </TabPanel>
                                <TabPanel header="Revenue">
                                    {getAudienceInsightsProfileLoader && !revenueWorldmapChartConfig.dataSource.data.length ? <Loader style={{ minHeight: "300px" }} /> :
                                        <ChartComponent chartConfigs={revenueWorldmapChartConfig} />
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default React.memo(GeographyComponent)