import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    mai_home,
    mai_dashboard,
    mai_lens,
    mai_data,
    mai_settings,
    mai_logo_white,
    mai_menu_switch_bar,
    mai_recommendation_icon,
    mai_clv_icon,
    mai_customer_segment_icon,
    mai_audience_builder_icon,
    mai_create_campaign_icon,
    mai_contact_strategy_icon,
    mai_ab_testing_icon,
    mai_campaign_monitering_icon,
    mai_campaign_optimization
}
    from '../../assets/images/index'
import { Url } from "../../models/constants";
import { StoreActions } from "../../store/actions";
import './Navbar.scss';
const NavbarComponent = () => {
    const dispatch = useDispatch();
    const headerTitleChangeHandler = (content: any) => {
        const payload = { title: content };
        dispatch({ type: StoreActions.UPDATE_HEADER_TITLE, payload });
    }
    return (
        <Fragment>
            <div className="navbarwrapper">
                <div className="navbar__listdesc">
                    <div className="navbar__menulist">
                        <ul className="navbar__ul nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <li className="navbar__list nav-link" data-bs-target="#sidebar" data-bs-toggle="collapse" role="button">
                                {/* <NavLink to="" className="navbar__anchor"> */}
                                <img src={mai_menu_switch_bar} alt="menu-switch" className="img-fluid menu__switchImg" />
                                {/* </NavLink> */}
                            </li>
                            <li className="navbar__list navbar__list--active nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <NavLink to="" className="navbar__anchor" onClick={() => headerTitleChangeHandler('Home')}>
                                    <img src={mai_home} alt="home" className="img-fluid navbar__img" />
                                </NavLink>
                            </li>
                            <li className="navbar__list nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <NavLink to="" className="navbar__anchor">
                                    <img src={mai_dashboard} alt="dashboard" className="img-fluid navbar__img" />
                                </NavLink>
                            </li>
                            <li className="navbar__list nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" role="tab" aria-controls="v-pills-disabled" aria-selected="false">
                                <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`} onClick={() => headerTitleChangeHandler('Audience List')} className="navbar__anchor">
                                    <img src={mai_lens} alt="lens" className="img-fluid navbar__img" />
                                </NavLink>
                            </li>
                            <li className="navbar__list nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <NavLink to="" className="navbar__anchor">
                                    <img src={mai_data} alt="data" className="img-fluid navbar__img" />
                                </NavLink>
                            </li>
                            <li className="navbar__list nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                <NavLink to="" className="navbar__anchor">
                                    <img src={mai_settings} alt="settings" className="img-fluid navbar__img" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar__logosection collapse collapse-horizontal" id="sidebar">
                        <div className="navbar__logo">
                            <NavLink to="">
                                <img src={mai_logo_white} alt="mai-logo" className="img-fluid navbar__logo-img" />
                            </NavLink>
                        </div>
                        <div className="tab-content navbar__tab-content" id="v-pills-tabContent">
                            <div className="accordion" id="accordionExample">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <nav>
                                        <ul className="navbar__tab-ul" id="navbar__ul">
                                            <li className="navbar__tab-ullist">
                                                <NavLink to="" className="navbar__tab-anchor navbar__tab--active">CustomerLens</NavLink>
                                            </li>
                                            <li className="navbar__tab-ullist">
                                                <NavLink to="" className="navbar__tab-anchor">CampaignLens</NavLink>
                                            </li>
                                            <li className="navbar__tab-ullist">
                                                <NavLink to="" className="navbar__tab-anchor">MarketingLens <span className="navbar__comingsoon">Coming soon</span></NavLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="tab-pane fade ps-3 pr-0" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <ul className="navbar__tab-ul">
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="headingOne">
                                                <NavLink to="" className="navbar__tab-anchor accordion-button px-0" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">CustomerLens</NavLink>
                                            </div>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to="#"><img src={mai_lens} alt="" /> Very active customers</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to="#"><img src={mai_lens} alt="" /> Marketing focus customers</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="headingTwo">
                                                <NavLink to="" className="navbar__tab-anchor accordion-button collapsed px-0" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">CampaignLens</NavLink>
                                            </div>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to="" className="navbar__coming-soon">Coming Soon</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="heading3">
                                                <NavLink to="" className="navbar__tab-anchor accordion-button collapsed px-0" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">MarketingLens</NavLink>
                                            </div>
                                            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to="" className="navbar__coming-soon">Coming Soon</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-pane fade ps-3 pr-0" id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab">
                                    <ul className="navbar__tab-ul" id="accordionExample2">
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="heading4">
                                                <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}/${Url.AUDIENCE_LIST}`} onClick={() => headerTitleChangeHandler('Audience List')} className="navbar__tab-anchor accordion-button px-0" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">CustomerLens</NavLink>
                                            </div>
                                            <div id="collapse4" className="accordion-collapse collapse show" aria-labelledby="heading4" data-bs-parent="#accordionExample2">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.AUDIENCE_BUILDER}`} onClick={() => headerTitleChangeHandler('Audience List')}><img src={mai_audience_builder_icon} alt="" /> Audience Builder</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_SEGMENTATION}`} onClick={() => headerTitleChangeHandler('Segmentation List')}><img src={mai_customer_segment_icon} alt="" /> Customer Segmentation</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.CUSTOMER_LIFETIME_VALUE}`} onClick={() => headerTitleChangeHandler('CLV List')}><img src={mai_clv_icon} alt="" /> Customer Lifetime Value</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CUSTOMERLENS_WORKFLOW}/${Url.RECOMMENDATIONS}`}><img src={mai_recommendation_icon} alt="" /> Recommendations</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="heading5">
                                                <NavLink to="" className="navbar__tab-anchor accordion-button collapsed px-0" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">CampaignLens</NavLink>
                                            </div>
                                            <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN}`} onClick={() => headerTitleChangeHandler('Campaigns')} className="submenu--active"><img src={mai_create_campaign_icon} alt="" /> Create Campaign</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CONTACT_STRATEGY}`} onClick={() => headerTitleChangeHandler('Contact Strategy')}><img src={mai_contact_strategy_icon} alt="create campaign" /> Contact Strategy</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.AB_TEST}/${Url.AB_TEST_LIST}`}><img src={mai_ab_testing_icon} alt="ab testing" /> A/B Test</NavLink>
                                                    </li>
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN_MONITORING}`} onClick={() => headerTitleChangeHandler('Campaign Monitoring')}><img src={mai_campaign_monitering_icon} alt=" campaign monitering" /> Campaign Monitoring</NavLink>
                                                    </li>
                                                    {/* <li className="navbar__submenu-list">
                                                        <NavLink to=""><img src={mai_campaign_roi_icon} alt="campaign roi" /> Campaign ROI</NavLink>
                                                    </li> */}
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.CAMPAIGNLENS_WORKFLOW}/${Url.CAMPAIGN_OPTIMIZATION}`} onClick={() => headerTitleChangeHandler('Campaign Optimization')}><img src={mai_campaign_optimization} alt="campaign optimization" /> Campaign Optimization</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className="navbar__tab-ullist accordion-item">
                                            <div id="heading6">
                                                <NavLink to="" className="navbar__tab-anchor accordion-button collapsed px-0" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="true" aria-controls="collapse6">MarketingLens</NavLink>
                                            </div>
                                            <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                                <ul className="navbar__submenu">
                                                    <li className="navbar__submenu-list">
                                                        <NavLink to={`/${Url.MAIN}/${Url.WORKFLOW}/${Url.MARKETINGLENS_WORKFLOW}/${Url.SPEND_OPTIMIZATION}`} onClick={() => headerTitleChangeHandler('Spend Optimization Scenarios')}><img src={mai_audience_builder_icon} alt="" /> Spend Optimization</NavLink>
                                                    </li>
                                                    {/* <li className="navbar__submenu-list">
                                                        <NavLink to="" className="navbar__coming-soon">Coming Soon</NavLink>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-pane fade px-3 pt-4" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">Data</div>
                                <div className="tab-pane fade px-3 pt-4" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">Setting</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default NavbarComponent;