import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import {    
    mai_dashboard_audience_icon,
    mai_north_east_region,
    mai_topoftheline,
    mai_saas_profiling,
    mai_clv1_chart,
    mai_middle_line_chart,
    mai_north_east_region_chart,
    mai_operational_dashboard_icon

} from "../../../../assets/images/index";

import './VeryActiveCustomers.scss';


const VeryActiveCustomersComponent = () => {
    return (
        <Fragment>
            <div className="mai-home__body">
                <div className="home__modules row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="home__modules-heading mt-0 mb-2">Executive dashboards </h2>
                    </div>
                </div>

                <div className="home__module-inner row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_dashboard_audience_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> North-east region <span className="subtext">Audience</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_north_east_region} alt="audience" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_dashboard_audience_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> Top of the line customer <span className="subtext">Audience</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_topoftheline} alt="top profiling" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_dashboard_audience_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> SaaS profiling <span className="subtext">Audience</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_saas_profiling} alt="saas" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home__modules row mt-4">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h2 className="home__modules-heading mt-0 mb-2">Operational dashboards </h2>
                    </div>
                </div>

                <div className="home__module-inner row">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_operational_dashboard_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> North-east region <span className="subtext">Audience</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_north_east_region_chart} alt="chart" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_operational_dashboard_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> Middle of the line leads <span className="subtext">Segmentation</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_middle_line_chart} alt="chart" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="home__module-cols">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <h2 className='inner__heading dashboard-column'>
                                        <div><img src={mai_operational_dashboard_icon} alt="audience" className='img-fluid' /> </div>
                                        <div> CLV1 <span className="subtext">Customer Lifetime Value</span></div>
                                    </h2>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-end">
                                    <div className="dropdown industries-dropdown">
                                        <NavLink to="#">
                                            <span className="pi pi-ellipsis-v dots3"></span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <img src={mai_clv1_chart} alt="clv1" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="lastseen">
                                <p>Last seen 12/04/2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VeryActiveCustomersComponent;