import React, { createContext, useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import en from '../assets/i18n/en.json'
import hi from '../assets/i18n/hi.json'
import { LanguageList } from '../models/constants';

i18n.use(initReactI18next).init({
  fallbackLng: LanguageList.EN,
  lng: LanguageList.EN,
  resources: {
    en: {
      translations: en
    },
    hi: {
      translations: hi
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = [LanguageList.EN, LanguageList.HI];

interface LangContextType {
    locale: any;
    changeLocale: (language: string) => void;
}
const LangContext = createContext<LangContextType>(null!);

export const InternationalizationProvider = ({ children }: {children: React.ReactNode}) => {
    const { t: locale, i18n } = useTranslation();
    const changeLocale: any = (language: string) => {
        i18n.changeLanguage(language);
    }
    const value = { locale, changeLocale };
    return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

export const useLanguage = () => { 
    return useContext(LangContext);
}

export default i18n;