import { Fragment } from "react"
import { Outlet } from "react-router-dom";

const ContactStartegyComponent = () => {
    return (
        <Fragment>
            <Outlet />
        </Fragment>
    )
}
export default ContactStartegyComponent;