import React, { Fragment, useCallback, useEffect, useState } from "react"
import { TabView, TabPanel } from 'primereact/tabview';
import ChartComponent from "../../../../../../shared/components/chart/ChartComponent";
import { mai_question_icon } from "../../../../../../assets/images";
import useHttp from "../../../../../../hooks/use-http";
import { useParams } from "react-router-dom";
import ApiService from "../../../../../../services/api.service";
import { FusionChartCountriesLabels } from "../../../../../../models/constants";
import UtilService from "../../../../../../services/util.service";
import Loader from "../../../../../../shared/components/loader/Loader";
import { SegmentationDataColumn } from "../../../../../../models/dataTemplate";

type CountryData = {
    country: string;
    count_customer_no: string;
    sum_acv: string;
};

type DataObject = Record<string, CountryData[]>;

const GeographyComponent = (props: any) => {
    const params: any = useParams();
    const { sendRequest: getSegmentPersonaGeography, loading: geographyLoader } = useHttp();
    const [customerWorldmapChartConfig, setCustomerWorldmapChartConfig] = useState<any>({
        type: 'worldwithcountries',
        width: "100%",
        height: "19%",
        containerBackgroundOpacity: "0",
        dataFormat: 'json',
        dataSource: {
            chart: {
                "caption": "",
                "theme": "fusion",
                "formatNumberScale": "0",
                "toolTipBgColor": "#444444",
                "toolTipColor": "#fff",
                "toolTipBorderColor": "#444444",
                "toolTipBorderRadius": "5",
                "chartLeftMargin": "0",
                "chartRightMargin": "0",
                "chartTopMargin": "0",
                "chartBottomMargin": "0",
                "chartLeftPadding": "0",
                "chartRightPadding": "0",
                "chartBottomPadding": "0",
                "chartTopPadding": "0",
                "showLegend": "0",
            },
            "data": []
        }
    });
    const [revenueWorldmapChartConfig, setRevenueWorldmapChartConfig] = useState<any>({
        type: 'worldwithcountries',
        width: "100%",
        height: "19%",
        containerBackgroundOpacity: "0",
        dataFormat: 'json',
        dataSource: {
            chart: {
                "caption": "",
                "theme": "fusion",
                "formatNumberScale": "0",
                "numberPrefix": "$",
                "toolTipBgColor": "#444444",
                "toolTipColor": "#fff",
                "toolTipBorderColor": "#444444",
                "toolTipBorderRadius": "5",
                "chartLeftMargin": "0",
                "chartRightMargin": "0",
                "chartTopMargin": "0",
                "chartBottomMargin": "0",
                "chartLeftPadding": "0",
                "chartRightPadding": "0",
                "chartBottomPadding": "0",
                "chartTopPadding": "0",
                "showLegend": "0",
            },
            "data": []
        }
    });


    const processResponseData = (data: DataObject): Record<string, { segment: string, count_customer_no: string, sum_acv: string }[]> => {
        return Object.entries(data).reduce((result, [segmentKey, segmentData]) => {
            segmentData.forEach(({ country, ...segmentValue }: CountryData) => {
                const countryData = {
                    segment: segmentKey,
                    ...segmentValue
                };
                if (!result[country]) {
                    result[country] = [];
                }
                result[country].push(countryData);
            });

            return result;
        }, {} as Record<string, { segment: string, count_customer_no: string, sum_acv: string }[]>);
    }

    const getGeographyPersona = useCallback((groupBy: any) => {
        const selectedSegments = props.selectedPersonas.map((item: any) => item.id);
        const resHandler = (res: any) => {
            const processedData = processResponseData(res.response as DataObject);
            const _customerMap = Object.entries(processedData).map(([country, profile]: any, index: number) => {
                const values: any = {};
                let value = 0;
                let tooltext = `${country}`;
                profile.forEach((item: any) => {
                    values[item.segment] = item.count_customer_no ?? 0;
                    tooltext = tooltext + `{br} ${props.selectedPersonas.find((persona: any) => persona.id.toString() === item.segment.substring(8))?.name}: ${item.count_customer_no ?? 0}`;
                    value = value + (Number(item.count_customer_no) ?? 0);
                });
                return {
                    id: FusionChartCountriesLabels[UtilService.capitalizeTheFirstLetterOfEachWord(country)] ?? '104',
                    tooltext,
                    color: UtilService.returnColorSchema(true)[index >= 10 ? (index % 10) : index],
                    value
                }
            });
            const _revenueMap = Object.entries(processedData).map(([country, profile]: any, index: number) => {
                const values: any = {};
                let tooltext = `${country}`;
                let value = 0;
                profile.forEach((item: any) => {
                    values[item.segment] = item.sum_acv ?? 0;
                    tooltext = tooltext + `{br} ${props.selectedPersonas.find((persona: any) => persona.id.toString() === item.segment.substring(8))?.name}: ${UtilService.numberFormatter(item.sum_acv ?? 0)}`;
                    value = value + (Number(item.sum_acv) ?? 0);
                });
                return {
                    id: FusionChartCountriesLabels[UtilService.capitalizeTheFirstLetterOfEachWord(country)] ?? '104',
                    tooltext,
                    color: UtilService.returnColorSchema(true)[index >= 10 ? (index % 10) : index],
                    value
                }
            });
            let cloneCustomerConfig = { ...customerWorldmapChartConfig };
            let cloneRevenueConfig = { ...revenueWorldmapChartConfig }
            cloneCustomerConfig.dataSource.data = [..._customerMap];
            cloneRevenueConfig.dataSource.data = [..._revenueMap];
            setCustomerWorldmapChartConfig(cloneCustomerConfig);
            setRevenueWorldmapChartConfig(cloneRevenueConfig)
        }
        getSegmentPersonaGeography(ApiService.getSegmentationPersonaDistributionDetails(params.sid, groupBy, selectedSegments, props.start_date, props.end_date, []), resHandler)
    }, [getSegmentPersonaGeography, params.sid, props.end_date, props.selectedPersonas, props.start_date]);
    useEffect(() => {
        if (props.selectedPersonas.length > 0) {
            getGeographyPersona(SegmentationDataColumn.GEOGRAPHY);
        }
    }, [getGeographyPersona, props.selectedPersonas]);

    return (
        <Fragment>
            <div className="insights__innerwrapper customtabs-wrap chartsminheight">
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <h2 className='inner__heading'><span> Geography </span>
                            {/* <img src={mai_question_icon} alt="quest" className='img-fluid' /> */}
                        </h2>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 text-end">
                        {/* <span className="pi pi-ellipsis-v dots3"></span> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="tabs-wrapper customtabs persona-geography-wrap">
                            <TabView>
                                <TabPanel header="Customers">
                                    {geographyLoader ? <Loader style={{minHeight: '320px'}} /> :
                                        (!geographyLoader ? <ChartComponent chartConfigs={customerWorldmapChartConfig} /> : '')}
                                </TabPanel>
                                <TabPanel header="Revenue">
                                    {geographyLoader ? <Loader style={{minHeight: '320px'}} /> :
                                        (!geographyLoader ? <ChartComponent chartConfigs={revenueWorldmapChartConfig} /> : '')
                                    }
                                </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default React.memo(GeographyComponent)