import AuthModel from "./../models/authmodel";
import JwtInterceptor from "../interceptors/jwt-interceptor";
import { environment } from "./../environment";
import { HttpMethods, TenantProviders } from "../models/constants";
import { TenantPayload, TenantType } from "../models/types";

const apiInstance = AuthModel.authApiAuth();

// Interceptor to inspect HTTP calls
JwtInterceptor(apiInstance);
const getDefaultTenant = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: "/api/oauth2/default-tenant",
  };
};
const getAuthProvider = (data: TenantType) => {
    return {
        instance: apiInstance,
        method: HttpMethods.GET,
        url: `/api/oauth2/auth-provider/${data.tenant}`,
      };
}
const azureLogin = (data: TenantType) => {
    return `${environment.authApiUrl}/api/oauth2/login/${data.tenant}`;
}
const adfsLogin = (data: any) => {
    return `${environment.authApiUrl}/api/saml/login/${data.tenant}`;
}
const normalLogin = (data: any) => {
  return {
    instance: apiInstance,
    method: HttpMethods.POST,
    url: `/api/oauth2/login/${data.tenant}`,
    body: data,
  };
};
const ssoLogin = (data: TenantType) => {
    return {
        instance: apiInstance,
        method: HttpMethods.GET,
        url: `/api/oauth2/login/${data.tenant}`,
      };
  };
const fetchUserDetails = (uid: any) => {
  return apiInstance[HttpMethods.GET](`/api/users/${uid}`);
  // return {
  //   instance: apiInstance,
  //   method: HttpMethods.GET,
  //   url: `/api/users/${uid}`,
  // };
}
const fetchUsers = () => {
  return apiInstance[HttpMethods.GET](`/api/users/`);
}
const getUserSuggestions = (filter: string) => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/users?filter=${filter}`,
  };
}
const getUserAvatar = (usernameOrEmail: string) => {
  return apiInstance[HttpMethods.GET](`/api/users/${usernameOrEmail}/avatar`);
};
const getRulesColumnsLlist = () => {
  return {
    instance: apiInstance,
    method: HttpMethods.GET,
    url: `/api/customer_lens/v1.0/data_template/columns`,
  };
}
const setSessionStorage = (key: string, value: any): void => {
  sessionStorage.setItem(
    key,
    typeof value === "object" ? JSON.stringify(value) : value
  );
};
const removeSessionStorage = (key: string): any => {
  return sessionStorage.removeItem(key);
};
const getSessionStorage = (key: string): any => {
  return sessionStorage.getItem(key);
};
const getUserDetails = (): any => {
  return JSON.parse(sessionStorage.getItem("currentuser") || "null");
};
const setUserDetails = (data: any): void => {
  setSessionStorage("currentuser", data);
};
const logOut = () => sessionStorage.clear();

const AuthenticationService = {
  getDefaultTenant,
  getAuthProvider,
  azureLogin,
  adfsLogin,
  normalLogin,
  ssoLogin,
  fetchUserDetails,
  getUserAvatar,
  fetchUsers,
  getUserSuggestions,
  setSessionStorage,
  removeSessionStorage,
  getSessionStorage,
  getUserDetails,
  setUserDetails,
  getRulesColumnsLlist,
  logOut,
};

export default AuthenticationService;
