import { Fragment, useRef, useState } from 'react';
import './RecommendationAttribute.scss';
import {
    mai_light_red_cross,
    mai_gray_dots,
    mai_blue_dots,
    mai_arrow_right,
    mai_search_bar,
    mai_question_icon
} from '../../../../../../assets/images/index';

const RecommendationAttributeComponent = () => {
    const dragItem = useRef<any>();
    const dragOverItem = useRef<any>();
    const dataSet = {
        customer_profile: [
            { label: 'SFDC Account ID', key: 'SFDC Account ID' },
            { label: 'Customer Number', key: 'Customer Number' },
            { label: 'Account Type', key: 'Account Type' },
            { label: 'Product Segment', key: 'Product Segment' },
            { label: 'Product', key: 'Product' },
            { label: 'Segmentation Type', key: 'Segmentation Type' },
            { label: 'Tenure', key: 'Tenure' },
            { label: 'Usage Segment', key: 'Usage Segment' },
            { label: 'Contract Type', key: 'Contract Type' }
        ],
        firmographics: [
            { label: 'Subscription', key: 'Subscription' },
            { label: 'Region', key: 'Region' },
            { label: 'Annual Sales', key: 'Annual Sales' },
            { label: 'No. of employees', key: 'No. of employees' },
            { label: 'Multi/single location', key: 'Multi/single location' }
        ],
        transactions: [
            { label: 'Subscription', key: 'Subscription' },
            { label: 'Region', key: 'Region' },
            { label: 'Annual Sales', key: 'Annual Sales' },
            { label: 'No. of employees', key: 'No. of employees' },
            { label: 'Multi/single location', key: 'Multi/single location' }
        ]
    };
    const [includeRuleSet, setIncludeRuleSet] = useState([
        [
            [
                {
                    field: "",
                    value: "",
                    operator: "null"
                }
            ]
        ]
    ]);
    const [excludeRuleSet, setExcludeRuleSet] = useState([
        [
            [
                {
                    field: "",
                    value: "",
                    operator: "null"
                }
            ]
        ]
    ]);
    const dragStartMessage = (event: any, key: 'customer_profile' | 'firmographics' | 'transactions', index: any) => {
        console.log('drag start', event, index);
        const element: any = document.getElementById(event.target.id);
        element.classList.add("on-drag");
        dragItem.current = { ...dataSet[key][index] };
    };
    const dragEnterMessage = (e: any, key: 'customer_profile' | 'firmographics' | 'transactions', position: any) => {
        console.log('drag enter', e);
        dragOverItem.current = { ...dataSet[key][position] };
    };
    const dragEndMessage = (event: any) => {
        const element: any = document.getElementById(event.target.id);
        element.classList.remove("on-drag");
        console.log('drag end', event);
    };
    const onDropCompleted = (event: any, key: 'include' | 'exclude', gindex: number, sindex: number = 0) => {
        event.preventDefault();
        console.log(event, gindex, includeRuleSet, sindex);
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        console.log(dataSet);
        if (dataSet[gindex][sindex][0].field.length !== 0) {
            if (dataSet[gindex][sindex].length < 2) {
                dataSet[gindex][sindex].push({
                    field: dragItem.current.key,
                    value: "",
                    operator: ""
                });
            } else {
                if (dataSet[gindex].length < 2) {
                    dataSet[gindex].push([
                        {
                            field: dragItem.current.key,
                            value: "",
                            operator: ""
                        }
                    ]);
                }
            }

        } else {
            dataSet[gindex][sindex][0].field = dragItem.current.key;
        }
        updateDataSet(key, dataSet);
        dragItem.current = null;
        dragOverItem.current = null;

    }
    const operatorSelectionHandler = (key: 'include' | 'exclude', op: string, gindex: number, sindex: number, vindex: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        dataSet[gindex][sindex][vindex].operator = op;
        updateDataSet(key, dataSet);
    }
    const operatorChangeHandler = (key: 'include' | 'exclude', gindex: number, sindex: number, vindex: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        dataSet[gindex][sindex][vindex].operator = '';
        updateDataSet(key, dataSet);
    }
    const removeVariableHandler = (key: 'include' | 'exclude', gindex: number, sindex: any, vindex: number) => {
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        console.log(dataSet);
        if (sindex === 0) {
            if (vindex === 0) {
                dataSet[gindex][sindex][vindex].field = '';
            } else {
                dataSet[gindex][sindex].splice(vindex, 1);
            }
        } else {
            dataSet[gindex][sindex].splice(vindex, 1);
            if (dataSet[gindex][sindex].length === 0) {
                dataSet[gindex].splice(sindex, 1);
            }
        }
        updateDataSet(key, dataSet);
    }
    const addingGroupHandler = (key: 'include' | 'exclude') => {
        console.log('adding group');
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        dataSet.push(
            [
                [
                    {
                        field: "",
                        value: "",
                        operator: "OR"
                    }
                ]
            ]);
        updateDataSet(key, dataSet);
    };
    const deletingGroupHandler = (key: 'include' | 'exclude', index: number) => {
        console.log(includeRuleSet, index);
        const dataSet = key === 'include' ? [...includeRuleSet] : [...excludeRuleSet];
        if (index !== 0) {
            dataSet.splice(index, 1);
            updateDataSet(key, dataSet);
        }
    }
    const updateDataSet = (key: 'include' | 'exclude', data: any) => {
        if (key === 'include') {
            setIncludeRuleSet(data);
        } else {
            setExcludeRuleSet(data);
        }
    }
    return (
        <Fragment>
            <div className='audiencecreate__steps sticky-top stickytopRecomd'>
                <ul className='list-inline'>
                    <li className='list-inline-item audiencecreate__steps--active'>General details</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active-rules'></li>
                    <li className='list-inline-item audiencecreate__steps--active'>Attributes</li>
                    <li className='list-inline-item audiencecreate__steps--line steps__active'></li>
                    <li className='list-inline-item'>Summary</li>
                </ul>
            </div>

            <div className='audiencecreate__form-wrapper recomdattribute-wrapper'>
                <div className='audiencecreate__form'>
                    <form>
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="rules__wrapper">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h3 className='inner__heading mb-3'><span> Choose attributes </span> <img src={mai_question_icon} alt="quest" className='img-fluid' /></h3>
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    <div className='accordian-text'>  Persona <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></div>
                                                    <div className='attributeNumb'>0 attribute added</div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">

                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    {/* <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Product Segment</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div> */}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h2 className="accordion-header" id="flush-headingtwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                                                    <div className='accordian-text'>  Product <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></div>
                                                    <div className='attributeNumb'>3 attribute added</div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapsetwo" className="accordion-collapse collapse" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">

                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Product Segment</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Usage Segment</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h2 className="accordion-header" id="flush-headingthree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                                                    <div className='accordian-text'>  Similarity Index <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></div>
                                                    <div className='attributeNumb'>0 attribute added</div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapsethree" className="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">

                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item pb-0 mt-2 border-none">
                                            <h2 className="accordion-header" id="flush-headingfour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                                    <div className='accordian-text'>  Product Adoption Path <img src={mai_question_icon} alt="quest" className='img-fluid ms-2' /></div>
                                                    <div className='attributeNumb'>0 attribute added</div>
                                                </button>
                                            </h2>
                                            <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body p-0">

                                                    <div className="rules__includeexclude-body">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className='rules__includes-data ms-0 mt-2'>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='rules__inner-body'>
                                                                        <div className='rules__includes-inner'>
                                                                            <div className='rules__bluedots'><img src={mai_blue_dots} alt="dots" className='img-fluid' /></div>
                                                                            <div className='rules__varfield'><p>Account type</p></div>
                                                                            <div className='rules__arrowright'><img src={mai_arrow_right} alt="arrow" className='img-fluid' /></div>
                                                                        </div>
                                                                        <div className='rules__crosslight'>
                                                                            <img src={mai_light_red_cross} alt="arrow" className='img-fluid' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <p className='rules__deagdrops'>
                                                                    Drag and drop attribute here
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className='rules__rightwrapper'>
                                    <div className="rules__searchbox">
                                        <div className="input-group">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info"><img src={mai_search_bar} alt="search" className='img-fluid' /></button>
                                            </div>
                                            <input type="search" placeholder="What're you searching for?" aria-describedby="button-addon4" className="form-control border-0" />
                                        </div>
                                    </div>
                                    <div className='rules__custprofile'>
                                        <div className="accordion" id="mai-accordian-rules">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading1">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                        Customer Profile
                                                    </button>
                                                </h2>
                                                <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                SFDC Account ID
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Customer Number
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Account Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Product
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription Type
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Tenure
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Usage Segment
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Contract Type
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading2">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                        Firmographics
                                                    </button>
                                                </h2>
                                                <div id="collapse2" className="accordion-collapse collapse show" aria-labelledby="heading2" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <ul className='rules__rightsidelist'>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Subscription
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Region
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Annual sales
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                No. of employees
                                                            </li>
                                                            <li>
                                                                <img src={mai_gray_dots} alt="dots" className='img-fluid' />
                                                                Multi/single location
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="heading3">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                        Transaction
                                                    </button>
                                                </h2>
                                                <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#mai-accordian-rules">
                                                    <div className="accordion-body custom-Scroll">
                                                        <p className='coming-soon m-0'> Coming Soon</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="rules__border-top">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                                            <button type="submit" className="btn btn-primary mai-cancel-button">Cancel</button>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-3 text-end">
                                            <button type="submit" className="btn btn-primary mai-cancel-button me-3">Back</button>
                                            <button type="submit" className="btn btn-primary mai-next-button">Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

            <div className="modal fade subscription-modalpopup" id="subscriptionModal" aria-labelledby="subscriptionModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-1">
                            <h1 className="modal-title" id="subscriptionModal">Subscription</h1>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body pb-4">
                            <select className="form-select" aria-label="Default select example">
                                <option selected disabled>Select amount</option>
                                <option value="$8">$ 8</option>
                                <option value="$15">$ 15</option>
                                <option value="$20">$ 20</option>
                            </select>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button type="button" className="btn btn-primary popup-button">Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade subscription-modalpopup annualsales" id="annualsalesModal" aria-labelledby="annualsalesModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-1">
                            <h1 className="modal-title" id="annualsalesModalLabel">Annual Sales</h1>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body pb-0">
                            <div className="row mb-4">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <label htmlFor="operator">Operator</label>
                                    <select className="form-select" id="operator">
                                        <option value="&gt;=" selected> &gt;= </option>
                                    </select>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <label htmlFor="operator">From</label>
                                    <input type="text" className='form-control' placeholder='$500k' />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12"></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <label htmlFor="operator">Operator</label>
                                    <select className="form-select" id="operator">
                                        <option value="&lt;=" selected> &lt;= </option>
                                    </select>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <label htmlFor="operator">To</label>
                                    <input type="text" className='form-control' placeholder='$2M' />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12"></div>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-end border-0">
                            <button type="button" className="btn btn-primary popup-button">Done</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade subscription-modalpopup" id="regionModal" aria-labelledby="regionModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-1">
                            <h1 className="modal-title" id="regionModal">Region</h1>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="modal-body pb-4">
                            <select className="form-select" aria-label="Default select example">
                                <option selected disabled>Select region</option>
                                <option value="Midwest">Midwest</option>
                                <option value="Midwest">Midwest</option>
                                <option value="Midwest">Midwest</option>
                            </select>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button type="button" className="btn btn-primary popup-button">Done</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default RecommendationAttributeComponent;