import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "../header/HeaderComponent";
import NavbarComponent from "../navbar/NavbarComponent";
import './Main.scss';


const MainComponent = () => {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col-auto px-0">
                        <NavbarComponent></NavbarComponent>
                    </div>
                    <main className="col px-0">
                        <HeaderComponent></HeaderComponent>
                        <Outlet />
                    </main>
                </div>
            </div>
        </Fragment>
    );
}

export default MainComponent;